import Vue from 'vue'


Vue.directive('$focus', {
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
});


Vue.directive('langErr', function (value) {

  setTimeout(() => {
    if (value.innerText.length == 0) {
      value.innerText = 'key missing'
    }
  }, 10000);

});


Vue.directive('$model', {
  bind: function (el, binding, vnode) {
    el.oninput = () => (vnode.context[binding.expression] = el.value)
  }
})



// placeholder directive
Vue.directive('phLangErr', function (value) {
  setTimeout(() => {
    if (value.placeholder.length == 0) {
      value.placeholder = 'i18n key missing'
    }
  }, 2500);


});