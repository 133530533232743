import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};
import i18n from "../../../i18n";

export default {


  changeSelectedLang({ commit }, lang) {
    console.log('changing language', lang)
    return new Promise(resolve => {
      commit("selected_language", lang);
      resolve();
    });
  },
  changeEditable({ commit }) {
    return new Promise(resolve => {
      commit("change_editable");
      resolve();
    });
  },
  changeLanguage({ commit, dispatch }, translations) {
    console.log('change lang triggered', translations.locale)
    return new Promise(resolve => {
      // alert(translations.locale)
      commit("selected_language", translations.locale);
      dispatch("loadTranslations", translations);
      resolve();
    });
  },


  loadTranslations({ commit }, translations) {
      // console.log(i18n.t('en').keys.keys)
    let locale = translations.locale;
    console.log("loadTranslations", locale);
    return new Promise((resolve, reject) => {
      axios({ url: BASE_URL + `/translations/${locale}`, method: "GET" },handlerEnabled)
        .then(resp => {
          i18n.t(i18n.locale).keys["keys"] = resp.data.data[0].translations.keys;
          translations.keys["keys"] = resp.data.data[0].translations.keys;
          resolve(resp);
        })
        .catch(err => {
          commit("translations_error");// make this error latter
          reject(err);
        });
      resolve();
    });
  },
 // eslint-disable-next-line
  async postTranslations({},translations) { 
    let locale = translations.locale;
    let data = {
      translations: {
        keys: translations.keys
      }
    };

    await axios.put(BASE_URL + `/translations/${locale}`, data);
  },

  async getLocales({ commit }) {
    const resp = await axios.get(BASE_URL + `/translations/locales/`,handlerEnabled);
    commit("load_locales", resp.data.data[0]);
  },

  async addLanguage({ dispatch }, language) {
    const languageName = language.split(" ");
    let data = {
      locale: languageName[0]
    };
    await axios.post(BASE_URL + `/translations/new`, data);

    dispatch("getLocales");
  }
};
