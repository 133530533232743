<template>
  <div id="matchesSeachBar">
    <div class="leftContainer">
      <div class="iconWrapper">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle_white-icon.svg')" class="Matches puzzle icon" />
        <!-- <p v-if="filteredMatchesCount != 0">{{filteredMatchesCount}}</p> -->
      </div>
      <!--
      <div class="searchBarWrapper">
          <input class="searchBar" v-model="matchesSearchValue" type="text" :placeholder="$t($i18n.locale).keys.membershipArea.membershipMatches.searchPlaceholder">
      </div>
      -->
    </div>
    <div class="title">
        {{ $t($i18n.locale + '.keys.membershipArea.membershipMatches.title') }}
    </div>
    <div class="rightContainer">
        <div class="galleryViewIcon" @click="$store.dispatch('toggleGalleryView')" v-bind:class="{ active: $store.getters.galleryView }" ></div>
        <div class="listViewIcon" @click="$store.dispatch('toggleListView')" v-bind:class="{ active: $store.getters.listView }"></div>
        <router-link to="/membership-area/matches-preferences">
          <img class="preferencesIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'preferences-icon.svg')" alt="Preferences icon" />
        </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "MatchesSearchBar",
  components: {},
  data() {
    return {
      matchesSearchValue: "",
      pl: "pl"
    };
  },
  watch:{
    matchesSearchValue: function(){
      console.log(this.matchesSearchValue, 'search value')
      this.$store.dispatch("setSearchValue", this.matchesSearchValue);
    }
  },
  computed: {
    ...mapGetters(["galleryView", "listView", "matchesQuantity", "filteredMatchesCount"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {
    ...mapActions(["toggleGalleryView", "toggleListView", "setSearchValue"]),
  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
</style>