export default {
    galleryViewVisitors: state => state.galleryViewVisitors,
    listViewVisitors: state => state.listViewVisitors,
    allVisitors: state => state.visitors,
    allFilteredVisitors: state => state.filteredVisitors,
    filteredVisitorsCount: state => state.filteredVisitors.length,
    visitorsQuantity: state => state.visitorsQuantity,
    searchValueVisitors: state => state.visitorsSearchValue,
    getBookmarksVisitors: state => state.visitorsBookmarks,
    getDefaultFiltersVisitors: state => state.defaultVisitorsFilters,
    getVisitorsList: state => state.visitorsList,
    getActiveVisitorsFilters: state => state.activeVisitorsFilters,
};