<template>
  <div class="loadingSpinnerWrap">
    <span  class="spinner"></span>
  </div>
</template>

<script>
export default {
  name: "Spinner",

  data() {
    return {
      // show: false
    };
  },

  mounted() {
  //  setTimeout(() => {
  //   //  console.log(this.show);
  //    this.show = true;
  //         // console.log(this.show);
  //  }, 400);
  }
};
</script>

<style lang="scss" scoped>
</style>