<template>
  <div class="chatMessage typeInit " >
    <div class="msgBox">
      <p
        class="messageText initText"
      >
        {{ messageData.message }}
      </p>

    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>
</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeInit",
  props: ["messageData"],
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
