export default {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, token) {
        state.status = 'success'
        state.token = token
    },
    auth_error(state) {
        state.status = 'error'
    },
    auth_logout(state) {
        state.status = ''
        state.token = ''
    },
    set_role(state, payload) {
        state.userRole = payload;
    },
    set_auth_user(state, payload) {
        state.auth_user = payload;
    },
    set_locale(state, payload) {
        state.locale = payload;
    },
    set_country(state, payload) {
        state.country = payload;
    },
    set_emailConfirmed(state, payload) {
        state.emailConfirmed = payload;
    },
    setAdditionalTranslations(state, payload) {
        state.additionalTranslations = payload;
        console.log(state.additionalTranslations, 'addit mutation')
    }
}