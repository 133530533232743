import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

// eslint-disable-next-line
const plugins = [
    store => {
      window.addEventListener("resize", async () => {
        //await store.dispatch('destroyAllHumans', true)
        console.log('RESIZE');
        if (window.innerWidth < 992) {
            store.commit('set_isMobile', true)
          }else{
            store.commit('set_isMobile', false)
          }

      });
    }
  ]

const globalModule = {
    state,
    mutations,
    actions,
    getters
}

export default globalModule;