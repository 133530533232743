var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"questionType9"}},[(_vm.variant != 2)?_c('h1',{staticClass:"questionText"},[_vm._v(" "+_vm._s(_vm.questionData.question.translations[_vm.selectedLanguage].question)+" ")]):_vm._e(),(_vm.editable && _vm.questionData.skippable == 1)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),(_vm.questionData.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"skippableQuestionMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.skippableQuestionMessage =
        $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.skippableMessage)+" ")]):_vm._e(),_c('div',{staticClass:"sliderWrapper"},[_c('vue-slider',{staticStyle:{"width":"90%"},attrs:{"id":"distanceFilter","min":_vm.sliderMin,"max":_vm.sliderMax,"tooltip":'none',"marks":_vm.distanceMarks2,"adsorb":true,"included":true},on:{"change":function($event){return _vm.setDistanceRange()}},model:{value:(_vm.distance),callback:function ($$v) {_vm.distance=$$v},expression:"distance"}})],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"40vh","margin":"30px 0"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"map-type-id":"terrain","options":{
      streetViewControl: false,
      mapTypeControl: false
    }}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index + 'marker',attrs:{"position":m.position,"clickable":true,"draggable":true,"icon":{ url: require('../../assets/images/MapMarker.svg') }},on:{"click":function($event){_vm.center = m.position}}})}),_c('GmapCircle',{key:_vm.componentKey,attrs:{"center":_vm.markers[0].position,"radius":_vm.mapRadius,"visible":true,"options":{
        fillColor: '#008860',
        fillOpacity: 0.3,
        strokeColor: '#008860',
        strokeWeight: 2
      }}})],2),_c('button',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"genericButton btnContinue",class:{ 'btnContinue-disabled': _vm.distance == _vm.answerExist() },attrs:{"contenteditable":_vm.editable,"disabled":_vm.distance == _vm.answerExist},on:{"input":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn = $event.target.innerText},"click":function($event){_vm.sendAnswer();
      _vm.resendAnswer();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn),expression:"$t($i18n.locale).keys.generic.continueBtn"}],attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "continueBtn", $event.target.value)}}}):_vm._e(),(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),(_vm.questionData.question.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"answerLaterMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.answerLaterMessage =
        $event.target.innerText},"click":function($event){_vm.emptyAnswers();
      _vm.sendAnswer();
      _vm.resendAnswer();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.answerLaterMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }