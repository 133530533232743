<template>
        <div 
            class="notification animate" 
            @click="isMobile ? $emit('navigateTo', { target: notification.target, id: notification.id}) : ''"
        >
            <div class="notification_wrapper" :class="[ notification.type ]">
                <!-- Message Notification -->
                <template v-if="notification.type == 'message'">
                    <div class="profile_picture">
                        <img :src="notification.user.picture"/>
                    </div>
                    <div class="content_wrapper">
                        <h4>
                            <span 
                                class="status"
                                :class="{'online': notification.user.online}"
                            ></span>
                            {{notification.user.username}} {{ $t($i18n.locale + '.keys.siteNotifications.newMessage') }}
                        </h4>
                        <p>
                            <Icons :icon="'envelope'" :type="'solid'"/> 
                            <span v-if="notification.data.message && !notification.data.blurred">
                                {{ notification.data.message }}
                            </span>
                            <span v-else>
                                {{ $t($i18n.locale + '.keys.siteNotifications.blurredMessage') }}
                            </span>
                        </p>
                    </div>
                </template>
                <!-- Visit Notification -->
                <template v-else-if="notification.type == 'visit'">
                    <div class="profile_picture">
                        <img :src="notification.user.picture"/>
                    </div>
                    <div class="content_wrapper">
                        <h4>
                            <span 
                                class="status"
                                :class="{'online': notification.user.online}"
                            ></span>
                            {{notification.user.username}}
                        </h4>
                        <p>
                            <Icons :icon="'eye'" :type="'solid'"/> 
                            <span >
                                {{ $t($i18n.locale + '.keys.siteNotifications.visitMessage') }}
                            </span>
                        </p>
                    </div>
                </template>
                <!-- Match Notification -->
                <template v-else-if="notification.type == 'match'">
                    <div class="profile_picture">
                        <img :src="notification.user.picture"/>
                    </div>
                    <div class="content_wrapper">
                        <h4>
                            <span 
                                class="status"
                                :class="{'online': notification.user.online}"
                            ></span>
                            {{notification.user.username}}
                        </h4>
                        <p>
                            <Icons :icon="'puzzle'" :type="'solid'"/> 
                            <span >
                                {{ $t($i18n.locale + '.keys.siteNotifications.matchMessage') }}
                            </span>
                        </p>
                    </div>
                </template>
                <!-- Matches Notification -->
                <template v-else-if="notification.type == 'matches'">
                    <div class="content_wrapper">
                        <h4>
                            <Icons :icon="'puzzle'" :type="'solid'"/> 
                            <span>
                                {{ $t($i18n.locale + '.keys.siteNotifications.matchesMessage', {count: notification.data.count}) }} 
                            </span>
                        </h4>
                    </div>
                </template>
                
                <div class="dismiss_notification" @click.stop.prevent="$emit('dismissNotification', { id: notification.id })">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="link_wrapper" v-if="!isMobile" @click="$emit('navigateTo', { target: notification.target, id: notification.id})">
                <button v-if="notification.type == 'message'">
                    {{ $t($i18n.locale + '.keys.siteNotifications.messageButton') }}
                </button>
                <button v-else-if="notification.type == 'matches'">
                    {{ $t($i18n.locale + '.keys.siteNotifications.matchesButton') }}
                </button>
                <button v-else>
                    {{ $t($i18n.locale + '.keys.siteNotifications.profileButton') }}
                </button>
            </div>
        </div>
   

</template>

<script>

export default {

    name: "SiteNotificationElement",
    data() {
        return {

        };
    },

    props: [
        "notification"
    ],
    computed: {
      isMobile() {
        return this.$store.getters.isMobile;
      },
      
    },
   
}

</script>