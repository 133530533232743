<template>
  <div
    id="membershipMisc"
    :class="{admin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editingMode: $store.getters.editable, premium: $store.getters.userRole.includes('ROLE_PREMIUM')}"
  >
    <div class="profilePhotoWrapper">
      <img class="profileBackground" :src="profileBackgroundURLs[selectedBackground-1]" />
      <div class="photoEditButtonWrapper" v-if="!$route.path.includes('membership-area/home')">
        <img
            class="photoEditButton"
            @click="$bvModal.show('bv-modal-backgrounds')"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
            alt="Edit button"
        />
      </div>
      
      <div class="container-lg photoContainer">
        <div class="profileWrapper" :key="componentProfileCardKey">
          <div class="photoWrapper">
            <div
              v-if="profilePicture"
              class="profilePhoto"
              v-bind:style="{ backgroundImage: 'url(' + profilePicture + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }"
            ></div>
            <img
              class="showBackgroundIcon"
              @click="$bvModal.show('bv-modal-backgrounds')"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'background-icon.svg')"
              alt="Mobile background icon"
            />
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 1)"
              class="profilePhoto"
              :style="{backgroundImage: 'url('+require('../../assets/images/silhouette-man.svg')}"
            ></div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 2)"
              class="profilePhoto"
              :style="{backgroundImage: 'url('+require('../../assets/images/silhouette-woman.svg')}"
            ></div>
            <router-link to="/membership-area/user-profile/gallery">
              <img
                @click="openProfileGallery(1)"
                class="editButton"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
            </router-link>
          </div>
          <div class="userInfoWrapper">
            <div class="userInfo">
                <h1 v-if="profileName">
                  {{profileName}}
                </h1>
                <p>
                  {{ profileAge }}, {{ profileLocation }}
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-membership" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && !hideBanner">
      <div class="bannerWrapper"  @click="trackClick">
        <div class="bannerImage"></div>
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'BlueBand-banner.png')" class="bannerOverlay" />
        <div class="offerText">
          <div class="textLeft">
            <h1
              class="specialOfferText1"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipMisc.specialOfferText1 =
                  $event.target.innerText
              "
            >
              {{
              $t($i18n.locale).keys.membershipArea.membershipMisc.specialOfferText1
              }}
            </h1>
            <h1 class="specialOfferText2">
              <span
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.specialOfferText2 =
                    $event.target.innerText
                "
              >
              {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .specialOfferText2
                }}
              </span>
              <span
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.specialOfferText3 =
                    $event.target.innerText
                  "
                >
                 {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .specialOfferText3
                 }}
                <!--
                {{
                $t($i18n.locale + '.keys.membershipArea.membershipMisc.specialOfferText2', {curr: currency})
                }}
                -->

              </span>

            </h1>
              <button class="bannerButton">
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .bannerButton
                }}
              </button>
              <input
                v-if="editable"
                v-model="
                        $t($i18n.locale).keys.membershipArea.membershipMisc
                          .bannerButton
                      "
                type="text"
              />
          </div>
          <div class="textRight">
            <div class="advantagesText">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-icon.svg')" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.unlimitedCommunication =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .unlimitedCommunication
                }}
              </p>
            </div>
            <div class="advantagesText">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-icon.svg')" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.viewVisitors =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .viewVisitors
                }}
              </p>
            </div>
            <div class="advantagesText">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-icon.svg')" class="Advantages icon" />
              <p
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipMisc.accessAllPhotos =
                    $event.target.innerText
                "
              >
                {{
                $t($i18n.locale).keys.membershipArea.membershipMisc
                .accessAllPhotos
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-backgrounds" hide-footer>
      <template
        v-slot:modal-title
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipMisc.backgroundImage =
            $event.target.innerText
        "
      >
        {{
        $t($i18n.locale).keys.membershipArea.membershipMisc.backgroundImage
        }}
      </template>
      <div class="text-center" v-if="profileBackgroundURLs.length">
        <div
          v-for="i in 23"
          class="backgroundChoicesWrapper"
          :class="{ active: selectedBackground == i,
                    notPremium: (!$store.getters.userRole.includes('ROLE_PREMIUM') && i > 4)  }"
          @click="setBackground(i)"
          :key="i"
        >
          <div class="backgroundChoices" :style="{ backgroundImage: 'url(' + profileBackgroundURLs[i-1] + ')' }"></div>
          <div class="premiumMembersTextWrapper" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && i > 4">
            <img class="starIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'star-white-icon.svg')" alt="Star icon" />
            <p
              class="onlyForText"
              v-langErr
            >
              {{ $t($i18n.locale).keys.membershipArea.membershipMisc.onlyFor }}
            </p>
            <p
              class="premiumMembers"
              v-langErr
            >
              {{ $t($i18n.locale).keys.membershipArea.membershipMisc.premiumMembers }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "MembershipMisc",
  components: {},
  props: {
    hideBanner: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      currency: '€',
      url: process.env.VUE_APP_URL,
      componentKey: 0,
      userJob: "",
      showEditJob: false,
      profileName: "",
      profileAge: 0,
      profilePicture: null,
      profileJob: "",
      profileLocation: "",
      mainBackground: "",
      jobStatus: false,
      answerCorrect: true,
      emptyAnswer: false,
      profileGender: 1,
      profileEntertainment: 1,
      componentProfileCardKey: 0,
      selectedBackground: 1,
    };
  },
  watch: {
    getProfilePictureChange: function() {
      this.$store.dispatch("fetchProfileData").then(() => {
        this.profilePicture = this.$store.getters.getProfileCard.profilePicture;
        this.componentProfileCardKey++;
      });
    }
  },
  computed: {
    ...mapGetters(["editable", "getProfilePictureChange"]),
    profileBackgroundURLs: function() {
      var bgUrls = [];
      for(var i=1; i<=23; i++){
        bgUrls.push(require('@/assets/images/profileBackgrounds/BG' + i + '.jpg'))
      }
      return bgUrls;
    }
  },
  mounted() {
    //  this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");

    this.profileName = this.$store.getters.getProfileCard.name;
    this.profilePicture = this.$store.getters.getProfileCard.profilePicture;
    this.profileGender = parseInt(
      this.$store.getters.getProfileCard.gender
    );
    this.profileEntertainment = parseInt(
      this.$store.getters.getProfileCard.entertainment
    );

    const birthday = this.$store.getters.getProfileCard.age;
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.profileAge = age;
    this.profileJob = this.$store.getters.getProfileCard.job;

    this.profileLocation = this.$store.getters.getProfileCard.location;
    if(this.$store.getters.getProfileCard.job) this.jobStatus = this.$store.getters.getProfileCard.job.status;
    
    
    var backgroundID = this.$store.getters.getProfileCard.profileBackground;
    if(typeof backgroundID == 'undefined') backgroundID = 1;
    this.mainBackground = `url(${this.profileBackgroundURLs[backgroundID-1]})`;
    this.selectedBackground = backgroundID;

  },
  methods: {
    trackClick() {
      this.$gtm.trackEvent({
            event: 'payment',
            category: 'Payment',
            action: 'Banner',
            label: 'Click'
          });

      this.$router.push(`/premium-plans/plans?direct=1`);
    },
    checkJobAnswer() {
      let validateText = /^[0-9a-zA-Z@]+$/;
      if (this.profileJob.match(validateText) && this.profileJob != "") {
        this.answerCorrect = true;
        this.emptyAnswer = false;
      } else if (this.profileJob == "") {
        this.emptyAnswer = true;
      } else {
        this.answerCorrect = false;
      }
    },
    setBackground(backgroundID) {

      if(!this.$store.getters.userRole.includes('ROLE_PREMIUM') && backgroundID > 4){
        this.$router.push('/premium-plans/plans');
        return false;
      }

      this.$store.dispatch("setBackground", backgroundID);
      this.mainBackground = `url(${this.profileBackgroundURLs[backgroundID-1]})`;
      this.selectedBackground = backgroundID;

      this.$bvModal.hide('bv-modal-backgrounds');
    },
    sendJobAnswer() {
      if (this.answerCorrect && !this.emptyAnswer) {
        const data = {
          customerAnswerId: this.$store.getters.getProfileCard.job.id,
          answer: [this.profileJob.answer[0]]
        };
        this.$store.dispatch("updatePersonalDescription", data).then(res => {
          if (res == "error") {
            // show error
          }
        });
        this.$store.dispatch("setJobStatus");
        this.jobStatus = this.$store.getters.getProfileCard.job.status;

        this.componentKey++;
      }
    },
    setUserJob() {
      if (this.showEditJob == true && this.$store.getters.getProfileCard.job) {
        this.userJob = this.profileJob.answer[0];
      }
    },
    restoreProfileJob() {
      if(this.profileJob) this.profileJob.answer[0] = this.userJob;
    },
    openProfileGallery(value) {
      this.$emit("galleryChosen", value);
      const el = document.getElementById("userTabs");
      el.scrollIntoView(true);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>


