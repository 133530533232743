import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
    async fetchUserSexualPreferences({
        commit
    }, id) {
        console.log('fetchUserSexualPreferences', id);
        try {
            if(typeof id == 'undefined') id = '';
            else id = '/'+id;
            const response = await axios.get(BASE_URL + `/api/profile/sexual_preferences${id}`,handlerEnabled);
            console.log(response.data.data, 'sexual_preferences');
            commit('setUserSexualPreferences', response.data.data)
        } catch (error) {
            return 'error';
        }
    },
    async fetchUserDesires({
        commit
    },id) {
        console.log('fetchUserDesires', id);
        try {
            if(typeof id == 'undefined') id = '';
            else id = '/'+id;
            const response = await axios.get(BASE_URL + `/api/profile/desires${id}`,handlerEnabled);
            commit('setUserDesires', response.data.data)
        } catch (error) {
            return 'error';
        }
    },
    async sendPreferences(_, data) {

        try {
            await axios.post(BASE_URL + '/api/profile/sexual_preferences', data,handlerEnabled);
            //commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    },
    async sendDesires(data) {

        try {
            await axios.post(BASE_URL + '/api/profile/desires', data,handlerEnabled);
            //commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    },
    /*
    async updateInterestsAnswer({
        commit
    }, data) {

        try {
            await axios.put(BASE_URL + '/api/personality/answer/update', data,handlerEnabled);
            commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    },
    async sendInterestsAnswer({
        commit
    }, data) {

        try {
            await axios.post(BASE_URL + '/api/personality/answer', data,handlerEnabled);
            commit('updateInterestsAnswer');
        } catch (error) {
            return 'error';
        }
    }
    */
}