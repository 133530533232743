<template>
  <div id="notifications" :key="componentKey" class="preferencesBox">
    <div class="notificationsWrapper preferencesContentWrapper" v-if="notifications.notification_matches">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="$t($i18n.locale).keys.membershipArea.myAccount.notifications.notifications =$event.target.innerText"
        >{{$t($i18n.locale).keys.membershipArea.myAccount.notifications.notifications}}</h1>
      </div>
      <div class="mainWrapper">
        <div class="mainTextWrapper textWrapper">
          <h3
            class="mainText"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.membershipArea.myAccount.notifications.receiveNotifications =$event.target.innerText"
          >{{$t($i18n.locale).keys.membershipArea.myAccount.notifications.receiveNotifications}}</h3>
        </div>
        <div class="filterWrapper">
          <div class="switchWrapper">
            <input
              :value="matchesReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: matchesReceivedSlider }"
              @click="changeMatchesReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_matches['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              :value="profileVisitsSlider"
              class="switchInput"
              v-bind:class="{ active: profileVisitsSlider }"
              @click="changeProfileVisitsFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_visits['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              :value="messagesReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: messagesReceivedSlider }"
              @click="changeMessagesReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_messages['text'] }}</p>
          </div>
          <div class="switchWrapper">
            <input
              :value="meetingsReceivedSlider"
              class="switchInput"
              v-bind:class="{ active: meetingsReceivedSlider }"
              @click="changeMeetingsReceivedFilterValue()"
              type="range"
              min="0"
              max="1"
              step="1"
            />
            <p class="switchName">{{ notifications.notification_meetings['text'] }}</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Notifications",

  data() {
    return {
      componentKey: 0,
      matchesReceivedSlider: 0,
      profileVisitsSlider: 0,
      messagesReceivedSlider: 0,
      meetingsReceivedSlider: 0,
      notifications: []
      
    };
  },
  computed: {
    ...mapGetters(["getClientSettings"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {

  if(this.getClientSettings)  this.setNotificationSettings();

  },
  methods: {
    setNotificationSettings() {
      console.log('setNotificationSettings');
      this.notifications = this.$store.getters.getClientSettings.notifications;
      this.matchesReceivedSlider = this.notifications.notification_matches.value;
      
      this.profileVisitsSlider = this.notifications.notification_visits.value;
      
      this.messagesReceivedSlider = this.notifications.notification_messages.value;
      
      this.meetingsReceivedSlider = this.notifications.notification_meetings.value;
     
    },
    changeMatchesReceivedFilterValue() {
      
      this.matchesReceivedSlider = (this.matchesReceivedSlider == 0) ? 1 : 0;
     
      const data = {
        value: (this.matchesReceivedSlider == 0) ? false : true
      };
      console.log('changeMatchesReceivedFilterValue')
      this.$store.dispatch("updateMatches", data);
     
    },
    
    changeProfileVisitsFilterValue() {
      this.profileVisitsSlider = (this.profileVisitsSlider == 0) ? 1 : 0;
     
      const data = {
        value: (this.profileVisitsSlider == 0) ? false : true
      };
        this.$store.dispatch("updateVisits", data);
     
    },
    
    changeMessagesReceivedFilterValue() {
      this.messagesReceivedSlider = (this.messagesReceivedSlider == 0) ? 1 : 0;
     
      const data = {
        value: (this.messagesReceivedSlider == 0) ? false : true
      };
      this.$store.dispatch("updateMessages", data);

    },
    changeMeetingsReceivedFilterValue() {
      this.meetingsReceivedSlider = (this.meetingsReceivedSlider == 0) ? 1 : 0;
     
      const data = {
        value: (this.messagesReceivedSlider == 0) ? false : true
      };
      this.$store.dispatch("updateMeetings", data);

    },
    
  }
};
</script>
