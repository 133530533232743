export default {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  userToken: state => state.token,
  userRole: state => state.userRole,
  getAuthUser: state => state.auth_user,
  getLocale: state => state.locale,
  getCountry: state => state.country,
  emailConfirmed: state => state.emailConfirmed,
  getAdditionalTranslations: state => state.additionalTranslations,
  getCountryDomainLanding: (state) =>
   {
     if(!state.country){
      if(domain == 'uk') return 'co.uk';
      else if(domain == 'nz') return 'co.nz';
      else if(domain == 'za') return 'co.za';
      else if(domain == 'au') return 'com.au';
      else return domain;
     }else{
      if (state.country == 'uk') {
        return 'co.uk'
      }else if(state.country == 'nz'){
        return 'co.nz'
      }else if(state.country == 'za'){
        return 'co.za'
      }else if(state.country == 'au'){
        return 'com.au'
      }else if(state.country == 'us'){
        return 'com'
      } else {
        return state.country
      }
    }
  }
};