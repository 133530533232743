<template>
  <div>
    <MembershipMisc v-if="showMisc" />
    <div class="mainAreaWrapper">
      <div class="container-lg">
        <div class="full-width flex-container">
          <div class="half-width">
            <MembershipMatches />
            <MembershipMessages v-if="showMessages"/>
            <MembershipMeetings v-if="totalMeetings" />
            <MembershipVisitors v-if="!totalMeetings" class="left"/>
          </div>
          <div class="half-width">
            <MembershipMessages v-if="!showMessages" />
            <MembershipVisitors v-if="totalMeetings" />
          </div>
        </div>
        <div class="full-width">
          <MembershipOnline v-if="$store.getters.getHomeOnline.length > 0" ref="onlineMembers" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipMatches from "@/components/membership-area/MembershipMatches.vue";
import MembershipMeetings from "@/components/membership-area/MembershipMeetings.vue";
import MembershipMessages from "@/components/membership-area/MembershipMessages.vue";
import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import MembershipVisitors from "@/components/membership-area/MembershipVisitors.vue";
import MembershipOnline from "@/components/membership-area/MembershipOnline.vue";

import { mapGetters } from "vuex";

export default {
  name: "MembershipHome",
  components: {
    MembershipMatches,
    MembershipMeetings,
    MembershipMessages,
    MembershipMisc,
    MembershipVisitors,
    MembershipOnline,
  },
  computed: {
    ...mapGetters([
      "totalMeetings",
    ])
  },
  data() {
    return {
      showMessages: false,
      showMisc:false
    };
  },
  mounted() {
    //this.$store.dispatch("getEmailConfirmed");
    window.scrollTo(0,0);
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");

    this.windowWidth();
    this.$store.dispatch('fetchHome').then((res)=>{
      //this.$store.dispatch('hideLoader');
      console.log('fetchHome', res);
      //console.log('onlineMembers', this.$refs.onlineMembers.$refs.onlineSwiper);

        if(res == 'error'){
          //this.$store.dispatch('setErrorVisibility', true);
        } else {
          //this.$store.dispatch('showContent');
          setTimeout(()=>{
            //this.$refs.onlineMembers.$refs.onlineSwiper.$swiper.update();
          },250)

        }
    })
    this.$store.dispatch("fetchProfileData").then(() => {
      this.$store.dispatch("hideLoader");
      this.$store.dispatch("showContent");
      this.showMisc = true;
    });
  },
  methods: {
    windowWidth() {
      if (window.innerWidth < 992) {
        this.showMessages = true;
      }
    }
  }
};
</script>
