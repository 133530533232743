<template>
  <div id="paymentSummaryTab">
    <div id="pricingSection" v-if="summaryVariant == 2">
      <div class="pricingDetails">
        <h4>{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_headline') }}</h4>
        <p>{{ this.infoTextDuration }}
          <span>
            <template v-if="selectedLanguage == 'en'">
            {{ this.getActivePlan.plansPrice.currencySign }}{{ this.getActivePlan.plansPrice.visiblePrice }}.{{ this.getActivePlan.plansPrice.visiblePriceCents }}/{{ this.getActivePlan.plansPrice.perMonth }},
            </template>
            <template v-else>
            {{ this.getActivePlan.plansPrice.visiblePrice }},{{ this.getActivePlan.plansPrice.visiblePriceCents }}{{ this.getActivePlan.plansPrice.currencySign }}/{{ this.getActivePlan.plansPrice.perMonth }},
            </template>
          </span>

          {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_sum') }}
          <template v-if="selectedLanguage == 'en'">
            <span v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice">
              {{ this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.initialFullPrice }}
            </span>
            <span v-else>{{ this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice}}</span>
          </template>
          <template v-else>
            <span v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice">
              {{ this.getActivePlan.plansPrice.initialFullPrice.replace('.', ',') + this.getActivePlan.plansPrice.currencySign }}
            </span>
            <span v-else>{{ this.getActivePlan.plansPrice.fullPrice.replace('.', ',') + this.getActivePlan.plansPrice.currencySign }}</span>
          </template>

        </p>
      </div>
    </div>
    <div class="payment-errors">{{ paymentErrors }}</div>
    <iframe id="magnius3DSframeSummary" ref="magnius3DSframe"></iframe>
    <div class="paymentAdvantagesWrapper">
      <div id="priceDetailsWrapper" v-if="summaryVariant != 2">
        <h3 class="summaryHeadline">
          {{ $t($i18n.locale +'.keys.premiumPlans.plansTabs.summaryHeadline') }}
        </h3>
        <div class="priceInfoWrapper">
          <p
            class="priceInfoText1"
            v-langErr
          >
            {{ $t($i18n.locale).keys.premiumPlans.processPayments.duration }}:
          </p>
          <p
            class="priceInfoText2"
          >
            {{ this.getActivePlan.plansInfoBody.headlinePeriod }}
          </p>
        </div>
        <div class="priceInfoWrapper">
          <p class="priceInfoText1" >
            {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.perWeek') }}:
          </p>
          <p class="priceInfoText2" v-if="this.getActivePlan">
            <span>
              <template v-if="selectedLanguage == 'en'">
                {{ this.getActivePlan.plansPrice.currencySign }}{{ this.getActivePlan.plansPrice.visiblePrice }}.{{ this.getActivePlan.plansPrice.visiblePriceCents }}
              </template>
              <template v-else>
                {{ this.getActivePlan.plansPrice.visiblePrice }},{{ this.getActivePlan.plansPrice.visiblePriceCents }}{{ this.getActivePlan.plansPrice.currencySign }}
              </template>
            </span>
          </p>
        </div>
      </div>

      <p
        :class="[chosenTitle, domain == 'uk' ? 'uk' : '']"
        v-langErr
      >
        {{ $t($i18n.locale).keys.premiumPlans.processPayments.yourChosen }}
      </p>

      <h1
        :class="[chosenPlanTitle, domain == 'uk' ? 'uk' : '']"
      >
        {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.premiumMembershipDuration',
          { duration: this.getActivePlan.plansInfoBody.headlinePeriod })
        }}
      </h1>
      <h2 class="membershipAdvantagesHl">{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.membershipAdvantagesHL') }}</h2>
      <div class="membershipAdvantagesWrapper">
        <div class="membershipAdvantage" v-for="(bullet, index) in advantagesBullets" :key="index">
          <div class="leftAdvantageWrapper">
            <img
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')"
              alt="Check icon"
            />
            <p>
              {{ bullet }}
            </p>
          </div>
        </div>
      </div>
      <!--
      <h4 class="infoTextHeadline">
        {{
          $t($i18n.locale).keys.premiumPlans.processPayments.duration
        }}
      </h4>
      -->
      <div id="formWrapper">
        <form>
          <input type="submit" id="mobileSubmitBtn" class="submitBtn" :value="$t($i18n.locale +'.keys.premiumPlans.processPayments.processPaymentButton')"
          @click.stop.prevent="submitSummary"
          ref="summarySubmitButton"
          />
        </form>
      </div>
      <div
        class="infoTextWrapper"
        v-if="showSummaryInfoText"
        :class="domain"
      >
        <div v-if="infoTextAB" class="mainInfoText">
          {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.mainInfoText') }}
        </div>
        <div v-if="infoTextAB" class="showInfoTextBtn" @click="toggleInfoText" ref="showInfoTextBtn" >
          <span>{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.showInfoTextBtn') }}</span><i class="fa fa-caret-right"></i>
        </div>
        <div class="subInfoText" v-if="showAdditionalInfoText || !infoTextAB">
          <span
            v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice"
            v-html="$t($i18n.locale +'.keys.premiumPlans.processPayments.thisOfferFullPromotion',
                {
                  duration: this.infoTextDuration,
                  fullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice.replace('.', ','),
                  initialFullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.initialFullPrice.replace('.', ','),
                  productName: fullDomain,
                  supportEmail: $store.getters.supportEmail,
                  descriptorText: $store.getters.getDescriptorText,
                })"
          ></span>
          <span
          v-else
          v-html="$t($i18n.locale +'.keys.premiumPlans.processPayments.thisOfferFull',
                {
                  duration: this.infoTextDuration,
                  fullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice.replace('.', ','),
                  productName: fullDomain,
                  supportEmail: $store.getters.supportEmail,
                  descriptorText: $store.getters.getDescriptorText,
                })"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

import Magnius_helpers from "../../../helpers/payments/Magnius_functions";
import Shift4_helpers from "../../../helpers/payments/Shift4_functions";
import SEPA_helpers from "../../../helpers/payments/SEPA_functions";

export default {
  name: "PaymentSummaryTab",
  props: [
    "paymentVariant",
    "checkoutId",
    "processor",
    "secForm",
    "sepa_form_data",
    "magniusDataSummary",
    "selectedMethod",
    "securionPrimaryKey",
    "securionFallbackKey",
    "countPaymentAttempt"
  ],
  data() {
    return {
      BASE_URL: process.env.VUE_APP_URL,
      domain: ()=>{return window.domain},
      chosenTitle: 'chosenTitle',
      chosenPlanTitle: 'chosenPlanTitle',
      showSummaryInfoText: false,
      showAdditionalInfoText: false,
      advantagesBullets: [
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedReading'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages'),
      ],
      paymentErrors:null,
      loadedWindowHeight: window.innerHeight,
    };
  },
  computed: {
    ...mapGetters(["getPlans", "selectedLanguage", "user", "getActivePlan", "isMobile"]),
    summaryVariant: function() {
      
      if(this.$store.getters.isMobile) return 2;
      else return this.paymentVariant

    },
    infoTextAB: function(){
      
      if(this.$store.getters.user.affiliate_id) return true;
      else return false;

    },
    infoTextDuration: function(){
      return this.getActivePlan.plansInfoBody.headlinePeriod || '';
    },
    
    fullDomain: function(){
      var finalUrl = host + '.' + domain;
      if(domain == 'uk') finalUrl = host + '.co.uk';
      else if(domain == 'nz') finalUrl = host +  '.co.nz';
      else if(domain == 'za') finalUrl = host +  '.co.za';
      else if(domain == 'au') finalUrl = host +  '.com.au';
      
      //return host + tld
      return finalUrl;
    }
  },
  mounted() {
        console.log('SUMMARY CREATED')

        this.$nextTick(function () {

        console.log('NEXT TICK')
        this.summaryBulletsCalc()
        window.addEventListener("resize", this.resizeHandler);

        }.bind(this))

        document.addEventListener('scroll', () => {
          console.log('loadedWindowHeight', this.loadedWindowHeight, window.innerHeight);
          if(this.loadedWindowHeight <= window.innerHeight){
            var offset = window.innerHeight - this.loadedWindowHeight;
            if(offset < 10 ) offset = 10;
            $('#formWrapper').css('padding-bottom',  offset+'px' );
          }
        });

        setTimeout(() => {
          if(this.summaryVariant == 2 ) {
            console.log('PRICING SCROLL')
            window.scrollTo(0,document.querySelector('#pricingSection').offsetHeight - 10);
          }else {
            window.scrollTo(0,0);
          }
        }, 100);
        
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler: async function(){
      if(this.$store.getters.isMobile){
          this.summaryBulletsCalc()
      }
    },
    summaryBulletsCalc(){
      //console.log('summaryBulletsCalc')

      const wrapper = document.querySelector('.membershipAdvantagesWrapper')
      var offsetTop = wrapper.getBoundingClientRect().top + window.scrollY
      if(this.summaryVariant == 2 ){
        offsetTop = wrapper.getBoundingClientRect().top + window.scrollY - document.querySelector('#pricingSection').offsetHeight - 10
      }
      console.log('summaryBulletsCalc', offsetTop, window.scrollY)
      const buyButton = document.querySelector('#formWrapper')
      const wrapperHeight = window.innerHeight - offsetTop - buyButton.offsetHeight -20
      wrapper.style.height = wrapperHeight + 'px';

      this.showSummaryInfoText = true;

    },
    toggleInfoText($event) {

    console.log('toggleInfoText', $event)

    this.$refs.showInfoTextBtn.remove();

    this.showAdditionalInfoText = !this.showAdditionalInfoText;
    },
    submitSummary: function(evt){
      evt.preventDefault();
      if(this.selectedMethod == 'CARD' && this.processor == 'sec'){
        //this.submitSecurionPayMobile(this.secForm);
        console.log('finalize Shift4', this.secForm)
        //SEND TO HELPER FUNCTION 
        Shift4_helpers._submitData({ isSummary: true }, this)

      }else if(this.selectedMethod == 'CARD' && this.processor == 'mag'){
        //this.submitMagniusMobile(this.magniusDataSummary)
        console.log('finalize Magnius', this.magniusDataSummary)
        //SEND TO HELPER FUNCTION 
         Magnius_helpers._finalizePayment({paymentData: this.magniusDataSummary, isSummary: true}, this)

      }else if(this.selectedMethod == 'SEPA'){
        //this.submitSEPAMobile(this.sepa_form_data);
        console.log('finalize SEPA', this.sepa_form_data)
        //SEND TO HELPER FUNCTION
        SEPA_helpers._sendData(this.sepa_form_data,this)

      }else{
        return false;
      }
    },

  },
};
</script>

<style lang="scss" >
#paymentSummaryTab {
  .membershipAdvantagesWrapper {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 0 !important;

  }
  #formWrapper {
    //position: relative !important;
    padding-bottom:20px !important;
  }

  .infoTextWrapper {
    margin-top:150px;
    .showInfoTextBtn {
        margin: 5px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
  }

  #magnius3DSframeSummary {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    background: rgb(255, 255, 255);
    z-index: 99999;
    border: none;
  }
}
</style>