<template>
<div>

</div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
         .then(()=>{
          let sub = 'www';
          if(subdomain == 'dev' || subdomain == 'local') sub = subdomain
          window.location.href = 'https://' + sub +'.' + host +'.'+ this.$store.getters.getCountryDomainLanding
        })
        .catch(err => console.log(err));
    }
  },
  beforeMount() {
    this.logout();
  }
};
</script>