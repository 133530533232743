<template>
  <div id="matchesPreferences">
    <!--
    <MembershipMisc />
    -->
    <div class="container-lg preferencesContainer">
      <!--
      <div class="leftContainer">
        <PreferencesMenu />
      </div>
      -->
      <div class="rightContainer">
        <div class="preferencesBox">
          <div class=" preferencesContentWrapper">
            <div class="titleWrapper">
              <h1
                class="title"
                v-langErr
                :contenteditable="editable"
                @blur="$t($i18n.locale).keys.matchesPreferences.title = $event.target.innerText"
              >
                {{$t($i18n.locale + '.keys.matchesPreferences.title')}}
              </h1>
              <img
                v-if="editable"
                :style="{marginLeft : '20px'}"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
            </div>
          </div>
        </div>
        <AgeAndHeight />
        <Distance />
        <MatchCriteria />
      </div>
    </div>
  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
//import PreferencesMenu from "@/components/membership-area/matches-preferences/PreferencesMenu.vue";
import AgeAndHeight from "@/components/membership-area/matches-preferences/AgeAndHeight.vue";
import Distance from "@/components/membership-area/matches-preferences/Distance.vue";
import MatchCriteria from "@/components/membership-area/matches-preferences/Criteria.vue";

export default {
  name: "MatchesPreferences",
  components: {
    //PreferencesMenu,
    AgeAndHeight,
    //MembershipMisc,
    Distance,
    MatchCriteria,
  },

  data() {
    return {
      idBlocks: [],
      // activeBlock: "ageAndHeight"
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.idBlocks = [
      "ageAndHeight",
      "distance",
      "criteria"
    ];
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchPreferences").then(() => {
      this.$store.dispatch("hideLoader");
    });
  },

  methods: {
    handleScroll() {
      let ids = this.idBlocks.filter((number) => {
        const el = document.getElementById(number);
        if (el != null) {
          const rect = el.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
          const vertInView =
            rect.top <= windowHeight && rect.top + rect.height - 175 >= 0;
          return vertInView ? number : "";
        }
      });

      let menuItems = Array.prototype.slice.call(
        document.querySelectorAll(".menuItem")
      );
      menuItems.forEach((item) => {
        item.classList.remove("active");
      });
      document.querySelector("." + ids[0] + "")?.classList.add("active");
    },
  },
};
</script>
