<template>
  <div id="questionType3"  class="questionComponent">
    
    <h1 v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>

    <div :class="{'chatStyleAnswer' : variant == 2}">
      <div class="answerWrapper">
        <p
          class="skippableQuestionMessage"
          v-if="questionData.skippable == 1"
        >{{$t($i18n.locale).keys.generic.skippableQuestionMessage}}</p>
        <p
          class="additionalMessage"
        >{{$t($i18n.locale).keys.questionnaire.type3.nameVisibilityMessage}}</p>
        <input
          v-model="answer"
          class="genericInput"
          type="text"
          :placeholder="$t($i18n.locale).keys.questionnaire.type3.typeHere"
          :maxlength="questionData.question.id != 130 ? 32 : ''"
          :class="{'error': questionError}"
        />
        <p class="questionError" v-if="questionError">
          {{questionError}}
        </p>
      </div>
    </div>

    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="answer.length < 3"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />

    <button
      v-else 
      v-langErr 
      @click="sendAnswer"
      v-bind:class="{ 'btnContinue-disabled': answer.length < 3 }"
      :disabled="answer.length < 3"
      class="genericButton btnContinue"
    >{{$t($i18n.locale).keys.generic.continueBtn}}</button>

    <p
      v-langErr 
      v-if="questionData.question.skippable == 1"
      @click="sendAnswer({skip:true})"
      class="answerLaterMessage"
    >{{ $t($i18n.locale).keys.generic.answerLaterMessage }}</p>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  components: { QuestionNavigation },
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType3",
  data() {
    return {
      answer: "",
      lockSend: false,
      verifyRegex: /^.*$/,
      questionError: null,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
        selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
  },
  methods: {
    verifyAnswer(answer){
      if(this.questionData.question.id == 130) return true; //skip validation for about me

      let trimmedAnswer = answer.trim();
      if(
        this.verifyRegex.test(trimmedAnswer) &&
        trimmedAnswer.length >= 2 &&
        trimmedAnswer.length <= 32
        ){
        this.questionError = null
        return true
      }else{
        this.questionError = this.$t(this.$i18n.locale +'.keys.questionnaire.type2.usernameError')
        return false
      }
    },
    sendAnswer(data = {}) {
      if(this.lockSend) return true;
      if(!this.verifyAnswer(this.answer)) return true;
      if(data.skip){
        this.answersArray = [];
      }
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [this.answer]);
      }
      else if (this.questionData.answered) {
        this.lockSend = true;
        this.$emit("put-answer", {
          answered: [this.answer],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if(this.lockSend) return true;
      if(!this.verifyAnswer(this.answer)) return true
      if (this.questionData.answered) {
        this.lockSend = true;
        this.$emit("put-answer", {
          answered: [this.answer],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    answerExist() {
      if (this.questionData.answered) {
        return this.questionData.answered.answer;
      } else {
        return false;
      }
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.answer = this.questionData.answered.answer[0];
      }
    },
  },
  mounted() {
    this.loadAnswers();
  }
};
</script>
