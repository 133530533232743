<template>
  <div id="membershipCoaching">
    <div class="infoSubmitWrapper" v-if="showInfo">
      <div class="profileMakeover">
        <h1
          class="tittle"
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.membershipArea.membershipCoaching.title =
              $event.target.innerText
          "
        >
          {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.title }}
        </h1>
        <img
          class="bubble"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Bubble-Green.svg')"
          alt="Bubble"
        />

        <p
          v-langErr :contenteditable="editable"
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.membershipCoaching.profileMakeover =
              $event.target.innerText
          "
        >
          {{
            $t($i18n.locale).keys.membershipArea.membershipCoaching
              .profileMakeover
          }}
        </p>
      </div>
      <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
        alt="Translation icon"
      />
      <div class="profileForm">
        <div class="phone">
          <img
            class="phoneImg"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Phone-icon.svg')"
            alt="Phone"
          />
          <h1
            class="profileForm-phone"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipCoaching.phone =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.phone }}
          </h1>
        </div>

        <input
          class="phoneInput"
          v-model="phoneValue"
          type="text"
          :placeholder="
            $t($i18n.locale).keys.membershipArea.membershipCoaching
              .writeNumberHere
          "
        />
        <div class="availability">
          <img
            class="availabilityImg"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Clock-icon.svg')"
            alt="Phone"
          />
          <h1
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.availability =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.membershipArea.membershipCoaching
                .availability
            }}
          </h1>
        </div>
        <textarea
          type="text"
          :placeholder="
            $t($i18n.locale).keys.membershipArea.membershipCoaching
              .reachThroughPhone
          "
          v-model="availabilityValue"
        ></textarea>

        <div class="acceptTerms">
          <input type="checkbox" />
          <p>
            <span
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.iAgree =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.membershipArea.membershipCoaching.iAgree
              }}
            </span>
            <u>
              <a href="http://">
                <span
                  v-langErr :contenteditable="editable"
                  @blur="
                    $t(
                      $i18n.locale
                    ).keys.membershipArea.membershipCoaching.termsConditions =
                      $event.target.innerText
                  "
                >
                  {{
                    $t($i18n.locale).keys.membershipArea.membershipCoaching
                      .termsConditions
                  }}
                </span>
              </a>
            </u>
            <span
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.and =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.and }}
            </span>
            <u>
              <a href="http://">
                <span
                  v-langErr :contenteditable="editable"
                  @blur="
                    $t(
                      $i18n.locale
                    ).keys.membershipArea.membershipCoaching.privacyPolicy =
                      $event.target.innerText
                  "
                >
                  {{
                    $t($i18n.locale).keys.membershipArea.membershipCoaching
                      .privacyPolicy
                  }}
                </span>
              </a>
            </u>
          </p>
        </div>
        <Button
          @click="
            showInfo = false;
            showPayment = true;
          "
        >
          {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.payBtn }}
        </Button>
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
        <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.membershipCoaching.payBtn
          "
          type="text"
        />
      </div>
    </div>
    <div class="paymentWrapper" v-if="showPayment">
      <div class="profileMakeover">
        <h1
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.membershipArea.membershipCoaching.title =
              $event.target.innerText
          "
        >
          {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.title }}
        </h1>
        <div class="infoWrapper">
          <div
            class="label"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.yourPhone =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.membershipArea.membershipCoaching.yourPhone
            }}:
          </div>
          <div class="value">{{ phoneValue }}</div>
        </div>
        <div class="infoWrapper">
          <div
            class="label"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.availability =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.membershipArea.membershipCoaching
                .availability
            }}:
          </div>
          <div class="value">{{ availabilityValue }}</div>
        </div>
        <div class="infoWrapper">
          <div
            class="label"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipCoaching.price =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.price }}:
          </div>
          <div class="value">0.99&euro;</div>
        </div>
        <div class="modifyBtnWrapper">
          <p
            @click="
              showInfo = true;
              showPayment = false;
            "
            class="modifyBtn"
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipCoaching.modify =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.modify }}
          </p>
        </div>
      </div>
      <div class="creditCardLine"
                      v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.creditCard =
                $event.target.innerText
            "
      >
        {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.creditCard }}
      </div>
      <div class="paymentInfoWrapper" v-if="!mobileView">
        <div class="leftContainer">
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.firstName =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .firstName
              }}:</label
            >
            <input
              v-model="firstName"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .firstName
              "
              type="text"
            />
          </div>
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.cardNumber =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .cardNumber
              }}:</label
            >
            <input
              v-model="cardNumber"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .cardNumber
              "
              type="text"
            />
          </div>
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.CID =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching.CID
              }}:</label
            >
            <input
              class="ccv"
              v-model="ccv"
              maxlength="3"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.CID
              "
              type="text"
            />
          </div>
        </div>
        <div class="rightContainer">
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.lastName =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .lastName
              }}:</label
            >
            <input
              v-model="lastName"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.lastName
              "
              type="text"
            />
          </div>
          <div class="inputWrapper">
            <label
                            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.creditCard =
                $event.target.innerText
            "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .creditCard
              }}:</label
            >
            <select id="creditCards">
              <option value="visa"
                              v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.visa =
                $event.target.innerText
            ">{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching.visa
              }}</option>
            </select>
          </div>
          <div class="inputWrapper">
            <label

                            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.validUntil =
                $event.target.innerText
            "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .validUntil
              }}:</label
            >
            <div id="validUntilWrapper">
              <select id="validMonth">
                <option value="01">01</option>
                <option value="01">02</option>
                <option value="01">03</option>
                <option value="01">04</option>
                <option value="01">05</option>
                <option value="01">06</option>
                <option value="01">07</option>
                <option value="01">08</option>
                <option value="01">09</option>
                <option value="01">10</option>
                <option value="01">11</option>
                <option value="01">12</option>
              </select>
              <select id="validYear">
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="paymentInfoWrapper" v-if="mobileView">
        <div class="leftContainer">
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.firstName =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .firstName
              }}:</label
            >
            <input
              v-model="firstName"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .firstName
              "
              type="text"
            />
          </div>
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.lastName =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .lastName
              }}:</label
            >
            <input
              v-model="lastName"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching.lastName
              "
              type="text"
            />
          </div>
          <div class="inputWrapper">
            <label
              v-langErr :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.membershipCoaching.cardNumber =
                  $event.target.innerText
              "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .cardNumber
              }}:</label
            >
            <input
              v-model="cardNumber"
              :placeholder="
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .cardNumber
              "
              type="text"
            />
          </div>
        </div>
        <div class="rightContainer">
          <div class="inputWrapper">
            <label
                            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.creditCard =
                $event.target.innerText
            "
              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching
                  .creditCard
              }}:</label
            >
            <select id="creditCards">
              <option value="visa"
                              v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.visa =
                $event.target.innerText
            "

              >{{
                $t($i18n.locale).keys.membershipArea.membershipCoaching.visa
              }}</option>
            </select>
          </div>
          <div class="mobile">
            <div class="inputWrapper">
              <label
                v-langErr :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipArea.membershipCoaching.CID =
                    $event.target.innerText
                "
                >{{
                  $t($i18n.locale).keys.membershipArea.membershipCoaching.CID
                }}:</label
              >
              <input
                class="ccv"
                v-model="ccv"
                maxlength="3"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.membershipCoaching.CID
                "
                type="text"
              />
            </div>
            <div class="inputWrapper">
              <label
                              v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.membershipCoaching.validUntil =
                $event.target.innerText
            "
                >{{
                  $t($i18n.locale).keys.membershipArea.membershipCoaching
                    .validUntil
                }}:</label
              >
              <div id="validUntilWrapper">
                <select id="validMonth">
                  <option value="01">01</option>
                  <option value="01">02</option>
                  <option value="01">03</option>
                  <option value="01">04</option>
                  <option value="01">05</option>
                  <option value="01">06</option>
                  <option value="01">07</option>
                  <option value="01">08</option>
                  <option value="01">09</option>
                  <option value="01">10</option>
                  <option value="01">11</option>
                  <option value="01">12</option>
                </select>
                <select id="validYear">
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="acceptTerms">
        <input type="checkbox" />
        <p>
          <span
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipCoaching.iAgree =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.iAgree }}
          </span>
          <u>
            <a href="http://">
              <span
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipCoaching.termsConditions =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.membershipCoaching
                    .termsConditions
                }}
              </span>
            </a>
          </u>
          <span
            v-langErr :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.membershipArea.membershipCoaching.and =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.and }}
          </span>
          <u>
            <a href="http://">
              <span
                v-langErr :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.membershipCoaching.privacyPolicy =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.membershipCoaching
                    .privacyPolicy
                }}
              </span>
            </a>
          </u>
        </p>
      </div>
      <div class="buttonWrapper">
        <button>
          {{ $t($i18n.locale).keys.membershipArea.membershipCoaching.pay }}
        </button>
              <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.membershipCoaching.pay
          "
          type="text"
        />
      </div>
      <div class="sslMessage">
        {{
          $t($i18n.locale).keys.membershipArea.membershipCoaching.privacyPolicy
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coaching",
  data() {
    return {
      showPayment: false,
      showInfo: true,
      phoneValue: "",
      availabilityValue: "",
      firstName: "",
      cardNumber: "",
      ccv: "",
      lastName: "",
      mobileView: false
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    this.windowWidth();
  },
  methods: {
    windowWidth() {
      if (window.innerWidth < 768) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
