export default {
    lastCompleted: state => state.lastCompleted,
    allSkippedQuestions: state => state.skippedQuestions,
    skippedMode: state => state.skippedMode,
    getAdditionalQuestions: state => state.additionalQuestions,
    getCompletedQuestionnaire: state => state.questionnaireCompleted,
    getRandomNames: state => state.randomNames,
    getAdditionalQuestionsAnswered: state => state.additionalQuestionsAnswered,
    hideFirstMessage: state => state.hideFirstMessage,
    completed: state => state.completed,
    finished: state => state.finished,
    personalityTest: state => state.personalityTest,
    personalityTestVariant: state => state.personalityTestVariant,
    vitalQuestionsMissing: state => state.vitalQuestionsMissing,
    questionnaireQuestionsMissing: state => state.questionnaireQuestionsMissing,
};