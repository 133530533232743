import axios from "axios";
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
const handlerEnabled = {
  timeout: false,
};

export default {
  selectedPlan({ commit }) {
    commit("set_planState", true);
  },

  setPlan({ commit }, data) {
    console.log("plan settt",data, data.planId, data.plan);
    localStorage.setItem("paymentPlan", JSON.stringify(data.plan));
    commit("setActivePlan", data.plan);
  },

  async getPlans({ commit }) {
    try {
      var params = {};
      if (window.innerWidth > 767) {
        params = {};
      }else{
        //Mobile tariffs
        params = {
          category:1
        };
        
      }
      const resp = await axios.get(
        BASE_URL + `/api/payments/plans`,
        {
          params: params
        },
        handlerEnabled
      );


      //check and set visible price for all plans
      resp.data.data.forEach(plan => {
        if(!plan.plansPrice.visiblePrice){
          if(plan.plansPrice.initialPrice){
            plan.plansPrice.visiblePrice = plan.plansPrice.initialPrice;
            plan.plansPrice.visiblePriceCents = plan.plansPrice.initialPriceCents;
          }else{
            plan.plansPrice.visiblePrice = plan.plansPrice.price;
            plan.plansPrice.visiblePriceCents = plan.plansPrice.priceCents;
          }
        }
      });

      commit("set_plans", resp.data.data);
      commit("setActivePlan", resp.data.data[0]);
      return resp.data.data;
    } catch (error) {
      return "error";
    }
  },
  async getProduct({ commit },data) {
    var productID = data[0];
    var method = data[1];
    try {
      const resp = await axios.get(
        BASE_URL + `/api/payments/init/1/${productID}/${method}`,
        handlerEnabled
      );
      console.log(resp.data);
              commit("set_product", resp.data);
      return resp.data;
    } catch (error) {
      return "error";
    }
  },
  /* eslint-disable */
  async completePayment({ commit },checkoutID) {
    try {
      const resp = await axios.post(
        BASE_URL + `/api/payments/complete/${checkoutID}`
      );
      console.log(resp.data.data, 'detailscountry?')
      const payload = {
        'detailsCountry': resp.data.data.detailsCountry,
        'detailsCity': resp.data.data.detailsCity
      }
      commit('prefillData', payload)
      return resp.data;
    } catch (error) {
      return "error";
    }
  },
  async completePaymentRegistered({ commit },checkoutID) {
    try {
      const resp = await axios.post(
        BASE_URL + `/api/payments/payment_registered/${checkoutID}`
      );
      console.log(resp.data.data, 'detailscountry?')
      const payload = {
        'detailsCountry': resp.data.data.detailsCountry,
        'detailsCity': resp.data.data.detailsCity
      }
      commit('prefillData', payload)
      return resp.data;
    } catch (error) {
      return "error";
    }
  },

  setDescriptor({ commit }, data){
    console.log('setDescriptor', data, store.getters.getAuthUser.user_id );
    let descriptorText = '';
    if(data.payment == 'sec'){
      switch(data.brand){
        
        case 'fremdgehboerse': 
          descriptorText = 'FREMDGEHBORSE DATING LIONS'
        break;
        case 'affaireclub': 
          descriptorText = 'AFFAIRECLUB DATING LIONS'
        break;
        case 'casualxdates': 
          descriptorText = 'CASUALXDATES DATING LIONS'
        break;
        case 'affaerentreff': 
          descriptorText = 'AFFAERENTREFF DATING LIONS'
        break;
        case 'illicitoasis': 
          descriptorText = 'ILLICITOASIS DATING LIONS'
        break;
        case 'affaere1': 
         descriptorText = 'AFFARE1 DATING LIONS'
        break; 
        
        
        case 'credorax': 
          descriptorText = 'DATING LIONS AUSTRIA'
        break;

        case 'server': 
          descriptorText = data.text
        break;

        default: 
          descriptorText = 'Dating Lions GMBH AUSTRIA'
      }
    
      if(data.type == 'fallback') {
        let multiDescriptorText = descriptorText + ' or ' + store.getters.getDescriptorText
        if(store.getters.selectedLanguage == 'de'){
          multiDescriptorText = descriptorText + ' oder ' + store.getters.getDescriptorText
        }
        
        console.log('multiDescriptorText', multiDescriptorText) 
        commit("set_descriptor", multiDescriptorText);
       }else commit("set_descriptor", descriptorText);

    }else if(data.payment == 'mag'){
      
      switch(data.brand){
       
        case 'casualxdates':
          if(domain == 'uk') descriptorText = 'Dating/casualxdatescouk'
          if(domain == 'au') descriptorText = 'Dating/casualxdatescoau'
          else descriptorText = `Dating/${data.brand}.${domain}`
        break;
        case 'xn--affre1-dua': 
          descriptorText = `Dating/affare1.${domain}`
        break;
        case 'xn--fremdgehbrse-djb': 
          descriptorText = `Dating/fremdgehborse.${domain}`
        break;

        default: 
          descriptorText = `Dating/${data.brand}.${domain}`
        
      }

      commit("set_descriptor", descriptorText);
    }else if(data.payment == 'sepa'){
      
      descriptorText = `${store.getters.productName.toLowerCase()}.${domain} / ${store.getters.getActivePlan.plansInfoHeader.titlePremium} ${store.getters.getActivePlan.plansInfoBody.headlinePeriod} / ${store.getters.getAuthUser.user_id}`
      commit("set_descriptor", descriptorText);
    }

  }


};
