<template>
  <div class="chatMessage typeSmile">
    <div class="msgBox" >
      <p
      :class="{ blurred: messageData.blurred }"
        class="messageText"
      >
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'smile-green-kumpis.svg')" alt="" />
        <span class="msgText" v-if="$store.getters.userRole.includes('ROLE_PREMIUM') || true">
          {{
            messageData.message
          }}
        </span>
        <span class="msgBlindtext" v-else>
          {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnly')}}

        </span>
        <span class="premiumLinkWrapper" v-if="messageData.blurred">
          <router-link to="/premium-plans" class="premiumLink" >
            {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
          </router-link>
        </span>
      </p>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>
</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeSmile",
  props: ["messageData"],
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
     checkPremium(){
      if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')){
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Clicked Blurred Message',
          label: 'Like'
        });

        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }else return true;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
