import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const homeModule = {
    state,
    mutations,
    actions,
    getters
}

export default homeModule;