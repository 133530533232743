<template>
  <div id="userProfile">
    <!-- <MembershipHeader /> -->
    <MembershipMisc @galleryChosen="redirectToGallery" v-if="showProfileCard" />
    <div class="container-lg profile">
      <ProfileTabs @clicked="onClickChildNav" />
      <carousel
        v-if="showCarousel"
        :per-page="1"
        :mouse-drag="false"
        :paginationEnabled="false"
        :adjustableHeight="true"
        :touchDrag="false"
        :navigateTo="[tabNumber,true]"
      >
        <slide>
          <Profile v-if="tabNumber == 0" />
        </slide>
        <slide>
          <Gallery v-if="tabNumber == 1" />
        </slide>
        <slide>
          <SexualPreferences v-if="tabNumber == 2" />
        </slide>
        <!--
        <slide>
          <Desires v-if="tabNumber == 3" />
        </slide>
        -->
      </carousel>
    </div>
  </div>
</template>

<script>
// import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";
import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import ProfileTabs from "./ProfileTabs";
import { Carousel, Slide } from "vue-carousel";
import Profile from "@/components/membership-area/membership-profile/user-profile/PersonalDescription";
import Gallery from "@/components/membership-area/membership-profile/user-gallery/UserGallery";
import SexualPreferences from "@/components/membership-area/membership-profile/user-sexualpreferences/UserSexualPreferences";
//import Desires from "@/components/membership-area/membership-profile/user-desires/UserDesires";

export default {
  name: "UserProfile",
  components: {
    // MembershipHeader,
    MembershipMisc,
    ProfileTabs,
    Carousel,
    Slide,
    Profile,
    SexualPreferences,
    Gallery,
    //Desires,
  },
  data() {
    return {
      tabNumber: 0,
       showCarousel: false,
       showProfileCard: false
    };
  },

  computed: {
  },
  mounted() {
    window.scrollTo(0, 0);

    this.$store.dispatch("fetchProfileData").then(() => {
      this.$store.dispatch("hideLoader");
      this.$store.dispatch("showContent");
      this.showCarousel = true;
      this.showProfileCard = true;
    })
  },
  created() {
    if(this.$route.path.indexOf('user-profile/profile') != -1) this.tabNumber = 0;
    else if(this.$route.path.indexOf('user-profile/gallery') != -1) this.tabNumber = 1;
    else if(this.$route.path.indexOf('user-profile/sexual-preferences') != -1) this.tabNumber = 2;
    //else if(this.$route.path.indexOf('user-profile/desires') != -1) this.tabNumber = 3;



  },

  methods: {
    onClickChildNav(value) {
      this.tabNumber = value;
    },
    redirectToGallery(value) {
      this.tabNumber = value;
    },
  },
};
</script>

<style lang="sccs"></style>
