<template>
<div>
  <b-modal 
    id="bv-modal-questionaire" 
    size="lg" 
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @hide="close" 
    ref="modalQuestionaire"
  >
      <template v-slot:modal-title>
        {{ $t($i18n.locale + '.keys.modalQuestionaire.header') }}
      </template>
      <div class="innerContentWrapper">
        <PersonalityTest :modalTest="true" @finished="close"/> 
      </div>

    </b-modal>
</div>
</template>

<script>

import PersonalityTest from "@/components/personality/PersonalityTest.vue";

export default {
  name: "ModalQuestionaire",
  components: { PersonalityTest },
  data() {
    return {
      target: '',
      url: process.env.VUE_APP_URL,
      
    };
  },
 
  mounted() {


  },
  methods: {
    
    show(profile_id, target){
      this.profile_id = profile_id;
      if(typeof target != "undefined"){
        this.target = target;
      }
      this.$refs.modalQuestionaire.show();
    },
    close(bvModalEvent ) {

      console.log('bvModalEvent ', bvModalEvent );

      this.target = '';
      if(bvModalEvent != 'finished') this.dismissQuestionaire()
      else {
        this.$store.dispatch('checkCompleted');
      }

      //this.$refs.modalQuestionaire.hide();
    },
    dismissQuestionaire() {
      const URL = this.url + '/api/personality/dismiss_questionnaire';
      this.$axios.post(URL).then((res)=>{
        console.log('dismissQuestionaire', res)
        this.$store.dispatch('checkCompleted');
        //this.$refs.modalQuestionaire.hide();
      });
    }
  }

};
</script>

<style lang="scss" scoped>

</style>
