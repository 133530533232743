import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
  async fetchPreferences({
    commit
  }) {

    try {
      const response = await axios.get(BASE_URL + '/api/client/preferences/', handlerEnabled);
      commit('setAgeAndHeight', response.data.data.age_and_height);
      commit('setDistance', response.data.data.distance);
      commit('setDesireToHaveChildren', response.data.data.desire_to_have_children);
      commit('setEducationAndIncome', response.data.data.education_and_income);
      commit('setSmokingAndDrinkingHabits', response.data.data.smoking_and_drinking_habits);
      commit('ethnicityAndReligion', response.data.data.ethnicity_and_religion);

    } catch (error) {
      return 'error';
    }

  },
  async updateAgeAndHeight({
    commit
  }, ageAndHeight) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/1', ageAndHeight, handlerEnabled);
      if(response.status == 200){
        //dispatch('showSuccessStatusMessage');
      }
      let data = response.data.data;
      let minAgeDate = data.minAge;
      const today = new Date();
      const minBirthDate = new Date(minAgeDate);
      let minAge = today.getFullYear() - minBirthDate.getFullYear();
      const minMonth = today.getMonth() - minBirthDate.getMonth();
      if (minMonth < 0 || (minMonth === 0 && today.getDate() < minBirthDate.getDate())) {
        minAge--;
      }
      data.minAge = minAge;

      let maxAgeDate = data.maxAge;
      const maxBirthDate = new Date(maxAgeDate);
      let maxAge = today.getFullYear() - maxBirthDate.getFullYear();
      const maxMonth = today.getMonth() - maxBirthDate.getMonth();
      if (maxMonth < 0 || (maxMonth === 0 && today.getDate() < maxBirthDate.getDate())) {
        maxAge--;
      }
      data.maxAge = maxAge;

      commit('updateAgeAndHeight', data);
    } catch (error) {
      return 'error';
    }
  },
  async updateDistance({
    commit
  }, distance) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/2', distance, handlerEnabled);
      if(response.status == 200){
        //dispatch('showSuccessStatusMessage');
      }
      commit('updateDistance', response.data.data)
    } catch (error) {
      return 'error';
    }
  },
  async updateDesireToHaveChildren({
    commit, dispatch
  }, desireToHaveChildren) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/3', desireToHaveChildren, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      commit('updateDesireToHaveChildren', response.data.data.childrenImportance);
    } catch (error) {
      return 'error';
    }
  },
  async updateEducationAndIncome({
    commit, dispatch
  }, educationAndIncome) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/4', educationAndIncome, handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      commit('updateEducationAndIncome', response.data.data);
    } catch (error) {
      return 'error';
    }
  },
  async updateSmokingAndDrinkingHabits({
    commit, dispatch
  }, smokingAndDrinkingHabits) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/5', smokingAndDrinkingHabits,handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      commit('updateSmokingAndDrinkingHabits', response.data.data);
    } catch (error) {
      return 'error';
    }
  },
  async updateEthnicityAndReligion({
    commit, dispatch
  }, ethnicityAndReligion) {

    try {
      const response = await axios.put(BASE_URL + '/api/client/preferences/update/6', ethnicityAndReligion,handlerEnabled);
      if(response.status == 200){
        dispatch('showSuccessStatusMessage');
      }
      commit('updateEthnicityAndReligion', response.data.data);
    } catch (error) {
      return 'error';
    }
  },

  //match criteria
  async fetchMatchCriteria({
    commit
  }) {

    try {

      const response = await axios.get(BASE_URL + '/api/client/preferences/matching_criteria', handlerEnabled);
      console.log('setMatchCriteria', response);
      commit('setMatchCriteria', response.data.data);

    } catch (error) {
      return 'error';
    }

  },
  async updateMatchCriteria(_,data) {

    console.log("updateMatchCriteria", data);

    var processedData = data.map(e => {

      var selectedAnswers = e.answerOptions.filter(a => a.selected);
      selectedAnswers = selectedAnswers.map(a => {
        return a.id;
      });
      var x = {
        id:e.id,
        answers: selectedAnswers
      };
      return x;
    });

    try {
      const response = await axios.post(BASE_URL + '/api/client/preferences/matching_criteria', processedData,handlerEnabled);
      console.log('setMatchCriteria', response);
      //commit('updateMatchCriteria', response.data.data);
    } catch (error) {
      return 'error';
    }
  },

};