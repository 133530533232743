var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preferencesBox",attrs:{"id":"userAccountMembership"}},[_c('div',{staticClass:"userAccountMembershipWrapper preferencesContentWrapper"},[_c('div',{staticClass:"titleWrapper"},[_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"title",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
            _vm.$i18n.locale
          ).keys.membershipArea.userAccountMembership.membership =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership .membership)+" ")])]),_c('div',{staticClass:"membershipWrapper"},[_c('p',{staticClass:"membershipText textWrapper"},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.userAccountMembership.memberSince =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea .userAccountMembership.memberSince)+" ")]),_vm._v("  "),_c('span',{staticClass:"date"},[_c('strong',[_vm._v(" "+_vm._s(_vm.membershipSince))])])]),_c('p',{staticClass:"membershipText textWrapper"},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.userAccountMembership.typeOfMembership =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea .userAccountMembership.typeOfMembership)+" ")]),_vm._v("  "),_c('strong',[_c('span',{staticClass:"membershipType"},[_vm._v(" "+_vm._s(_vm.membershipType)+" ")])])]),_c('p',{staticClass:"membershipText textWrapper"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.userAccountMembership.contact_id'))+" ")]),_vm._v("  "),_c('strong',[_c('span',{staticClass:"membershipType"},[_vm._v(_vm._s(_vm.contact_id)+" ")])])]),(!this.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('div',{staticClass:"mainBenefitsWrapper"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitsTitleText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
              _vm.$i18n.locale
            ).keys.membershipArea.userAccountMembership.benefitsAs =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea .userAccountMembership.benefitsAs)+" ")]),_c('div',{staticClass:"benefitsWrapper"},[_c('div',{staticClass:"benefitWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'check-green.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t( _vm.$i18n.locale ).keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication)+" ")])]),_c('div',{staticClass:"benefitWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'check-green.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.premiumPlans.plansInfo.plansBenefits.unlimitedReading =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedReading'))+" ")])]),_c('div',{staticClass:"benefitWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'check-green.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t( _vm.$i18n.locale ).keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors)+" ")])]),_c('div',{staticClass:"benefitWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'check-green.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t( _vm.$i18n.locale ).keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos)+" ")])]),_c('div',{staticClass:"benefitWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'check-green.svg'),"alt":"Check icon"}}),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"benefitText",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                    _vm.$i18n.locale
                  ).keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t( _vm.$i18n.locale ).keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages)+" ")])])])]):_c('div',[_c('p',{staticClass:"membershipText"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.userAccountMembership.cancelCode'))+": "),_c('strong',[_vm._v(_vm._s(this.cancellationCode))])])])]),(!this.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('div',{staticClass:"btnWrapper"},[_c('router-link',{attrs:{"to":"/premium-plans"}},[_c('button',{staticClass:"btnPremium"},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea .userAccountMembership.becomeA)+" ")]),_c('span',{staticClass:"premiumMember"},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea .userAccountMembership.premiumMember))])])]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            .becomeA
        ),expression:"\n          $t($i18n.locale).keys.membershipArea.userAccountMembership\n            .becomeA\n        "}],attrs:{"type":"text"},domProps:{"value":(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            .becomeA
        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            , "becomeA", $event.target.value)}}}):_vm._e(),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            .premiumMember
        ),expression:"\n          $t($i18n.locale).keys.membershipArea.userAccountMembership\n            .premiumMember\n        "}],attrs:{"type":"text"},domProps:{"value":(
          _vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            .premiumMember
        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.userAccountMembership
            , "premiumMember", $event.target.value)}}}):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }