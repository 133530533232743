var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"visitorsList"}},[(_vm.$store.getters.allFilteredVisitors.length)?_c('div',{staticClass:"visitorsWrapper",class:[
      _vm.$store.getters.galleryViewVisitors ? 'galleryView' : '',
      _vm.$store.getters.listViewVisitors ? 'listView' : '',
      _vm.isMobile ? 'mobileView' : ''
    ]},[_vm._l((_vm.filteredVisitorsList),function(visitor,i){return _c('div',{key:i + visitor.id + 'list',staticClass:"visitorWrapper",class:{
        onlineUser: visitor.online
      },on:{"click":function($event){$event.stopPropagation();return _vm.goToProfile(visitor)}}},[_c('div',{staticClass:"imageWrapper"},[(visitor.public_path)?_c('img',{staticClass:"visitorsImage",attrs:{"src":visitor.public_path}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 1))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg')}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 2))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg')}}):_vm._e(),_c('div',{staticClass:"imageOverlay",class:[visitor.new == 1 ? 'new' : '']},[(visitor.new == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"newMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new)+" ")]):_vm._e()]),(!visitor.public_path && visitor.photo_request)?_c('div',{staticClass:"photoRequestButton",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendPhotoRequest(visitor.matched_user_id, $event)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton'))+" ")]):_vm._e(),(visitor.expires  && !_vm.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('Countdown',{attrs:{"end-date":new Date(visitor.expires*1000),"timertype":'inline_timer'},on:{"timer-ended":function($event){return _vm.countdownEnded(visitor)}}}):_vm._e()],1),_c('div',{staticClass:"infoWrapper"},[_c('div',{staticClass:"nameAgeWrapper"},[_c('div',{staticClass:"nameAge"},[_c('span',{staticClass:"username"},[_vm._v(_vm._s(visitor.firstname))]),_vm._v(", "+_vm._s(_vm.getAge(visitor.birthday))+" ")])]),_c('div',{staticClass:"basicInfoWrapper"},[_c('div',{staticClass:"locationWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'house-icon.svg'),"alt":"Location icon"}}),_c('p',{staticClass:"locationText"},[_vm._v(_vm._s(visitor.city))])])]),_c('div',{staticClass:"actionsWrapper"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"iconChat icons",attrs:{"title":_vm.$t(_vm.$i18n.locale + '.keys.membershipVisitors.visitorsList.sendMessage')},on:{"click":function($event){return _vm.initiateConversation(visitor)}}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"iconSmile icons",class:{ 
              smileSent: visitor.smile_sent == 1,
              loading: _vm.smileLoading 
            },attrs:{"id":'visitor'+visitor.id,"title":_vm.$t(_vm.$i18n.locale + '.keys.tooltips.Smile_button')},on:{"click":function($event){$event.stopPropagation();return _vm.smileSent(visitor, $event)}}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"iconFavourites icons",class:[visitor.bookmark == 1 ? 'added' : ''],attrs:{"id":'bookmark'+visitor.id,"title":[visitor.bookmark == 1 ? _vm.$t(_vm.$i18n.locale + '.keys.tooltips.FavRemove_button') : _vm.$t(_vm.$i18n.locale + '.keys.tooltips.Fav_button')]},on:{"click":function($event){$event.stopPropagation();return _vm.toggleBookmark(visitor, $event)}}})])])])}),_vm._l((_vm.filteredVisitorsGallery),function(visitor,i){return _c('div',{key:i + visitor.id + 'gallery',staticClass:"visitorWrapperGallery",class:{
        onlineUser: visitor.online
      },on:{"click":function($event){$event.stopPropagation();return _vm.goToProfile(visitor)}}},[_c('div',{staticClass:"imageWrapper"},[(visitor.public_path)?_c('img',{staticClass:"visitorsImage",attrs:{"src":visitor.public_path}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 1))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg')}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 2))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg')}}):_vm._e(),_c('div',{staticClass:"imageOverlay",class:[visitor.new == 1 ? 'new' : '']},[(visitor.new == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"newMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new)+" ")]):_vm._e(),(visitor.expires  && !_vm.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('Countdown',{attrs:{"end-date":new Date(visitor.expires*1000),"timertype":'inline_timer'},on:{"timer-ended":function($event){return _vm.countdownEnded(visitor)}}}):_vm._e(),_c('div',{staticClass:"bottomTextWrapper"},[_c('div',{staticClass:"nameWrapper"},[_c('div',{staticClass:"nameAgeText"},[_vm._v(" "+_vm._s(visitor.firstname.length > 15 ? visitor.firstname.substring(0, 14).concat('...') : visitor.firstname)+" ")])]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"nameAgeTextWrapper"},[_c('div',{staticClass:"nameAgeText"},[_vm._v(" "+_vm._s(_vm.getAge(visitor.birthday))+", "+_vm._s(visitor.city)+" ")])])])]),_c('div',{staticClass:"overlayWrapper"},[_c('div',{staticClass:"darkOverlay"}),_c('div',{staticClass:"basicInfoWrapper"},[_c('div',{staticClass:"nameAgeTextWrapper"},[_c('div',{staticClass:"nameText"},[_vm._v(" "+_vm._s(visitor.firstname)+" ")])]),_c('div',{staticClass:"locationAgeText"},[_vm._v(_vm._s(_vm.getAge(visitor.birthday))+", "+_vm._s(visitor.city))]),_c('div',{staticClass:"jobText"},[_vm._v(_vm._s(visitor.profession))]),(!visitor.public_path && visitor.photo_request)?_c('div',{staticClass:"photoRequestButton",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendPhotoRequest(visitor.matched_user_id, $event)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton'))+" ")]):_vm._e()])])],1)])])}),_vm._l((_vm.filteredVisitorsMobile),function(visitor,i){return _c('div',{key:i + visitor.id + 'mobile',staticClass:"visitorWrapperMobile",class:{
        onlineUser: visitor.online
      },on:{"click":function($event){$event.stopPropagation();return _vm.goToProfile(visitor)}}},[_c('div',{staticClass:"imageWrapper"},[(visitor.public_path)?_c('img',{staticClass:"visitorsImage",attrs:{"src":visitor.public_path}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 1))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg')}}):_vm._e(),(!visitor.public_path && (parseInt(visitor.gender) == 2))?_c('img',{staticClass:"visitorsImage",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg')}}):_vm._e(),_c('div',{staticClass:"imageOverlay",class:[visitor.new == 1 ? 'new' : '']},[(visitor.new == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"newMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipVisitors.visitorsList.new)+" ")]):_vm._e()]),(!visitor.public_path && visitor.photo_request)?_c('div',{staticClass:"photoRequestButton",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendPhotoRequest(visitor.matched_user_id, $event)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton'))+" ")]):_vm._e(),(visitor.expires  && !_vm.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('Countdown',{attrs:{"end-date":new Date(visitor.expires*1000),"timertype":'inline_timer'},on:{"timer-ended":function($event){return _vm.countdownEnded(visitor)}}}):_vm._e()],1),_c('div',{staticClass:"infoWrapper"},[_c('div',{staticClass:"nameAgeText"},[_c('span',{staticClass:"visitorsUsername"},[_vm._v(_vm._s(visitor.firstname > 8 ? visitor.firstname.substring(0, 7).concat('...') : visitor.firstname))]),_vm._v(", "+_vm._s(_vm.getAge(visitor.birthday))+" ")]),_c('div',{staticClass:"locationText"},[_vm._v(_vm._s(visitor.city))]),_c('div',{staticClass:"iconsWrapper"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"iconFavourites",class:[visitor.bookmark == 1 ? 'added' : ''],attrs:{"id":'bookmark'+visitor.id,"title":[visitor.bookmark == 1 ? _vm.$t(_vm.$i18n.locale + '.keys.tooltips.FavRemove_button') : _vm.$t(_vm.$i18n.locale + '.keys.tooltips.Fav_button')]},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleBookmark(visitor, $event)}}})])])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }