<template>
  <footer
    id="membershipFooter"
    :class="{payment: payment}"
  >
    <div class="links">
      <a
        class="imprint"
        @click="modalImprintShow = !modalImprintShow"
        v-langErr
        :contenteditable="editable"
        @blur="$t($i18n.locale).keys.footer.imprint = $event.target.innerText"
        >{{ $t($i18n.locale).keys.footer.imprint }}</a
      >
      <a
        class="privacy"
        @click="modalPrivacyShow = !modalPrivacyShow"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.privacyPolicy = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.privacyPolicy }}</a
      >
      <a
        class="terms"
        @click="modalTermsShow = !modalTermsShow"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.termsConditions = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.termsConditions }}</a
      >
      <a
        :href="supportLink"
        @click.prevent="openContactForm"
        target="_blank"
        v-langErr
        :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.footer.helpSupport = $event.target.innerText
        "
        >{{ $t($i18n.locale).keys.footer.helpSupport }}</a
      >
    </div>
    <div class="address_block">
      <span>{{addressBlock.name}}</span>
      <span>{{addressBlock.address_line_1}}</span>
      <span>{{addressBlock.address_line_2}}</span>
      <span>{{addressBlock.country}}</span>
    </div>

    <b-modal id="termsModal" v-model="modalTermsShow" hide-footer hide-header>
      <div class="exit-button-terms" @click="modalTermsShow = false">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-Grey.svg')" alt="Exit icon" />
      </div>
      <div class="termsWrapper">
        <div
          v-html="termsAndPolicies_.termsOfService"
        ></div>
      </div>
    </b-modal>

    <b-modal
      id="policyModal"
      v-model="modalPrivacyShow"
      hide-footer
      hide-header
    >
      <div class="exit-button-policy" @click="modalPrivacyShow = false">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-Grey.svg')" alt="Exit icon" />
      </div>
      <div class="policyWrapper">
        <div
          v-html="termsAndPolicies_.privacyPolicy"
        ></div>
      </div>
    </b-modal>
    <b-modal
      id="imprintModal"
      v-model="modalImprintShow"
      hide-footer
      hide-header
    >
      <div class="exit-button-policy" @click="modalImprintShow = false">
        <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'Close-cross-Grey.svg')" alt="Exit icon" />
      </div>
      <div class="imprintWrapper">
        <div v-html="termsAndPolicies_.imprint"></div>
      </div>
    </b-modal>
  </footer>
</template>

<script>
export default {
  name: "MembershipFooter",
  data() {
    return {
      modalTermsShow: false,
      modalPrivacyShow: false,
      modalImprintShow: false,
    };
  },
  props: {
    payment: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    //Footer pushdown
    let footerEl = document.getElementById('membershipFooter')
    footerEl.style.bottom = `-${footerEl.offsetHeight}px`

    console.log('FOOTER', 'i18n: ' + this.$i18n.locale, 'selected: ' + this.selectedLanguage)

    //Load language specific contact form
    var LA_interval = setInterval(() => {
      console.log('LA_interval', contactButton)
      if(contactButton != null) {
        clearInterval(LA_interval);
        return true;
      }
      if(typeof LiveAgent != "undefined"){
        if(this.selectedLanguage == 'de'){
          contactButton = LiveAgent.createButton('q2dm1s7e', LA_event);
        }
        else if( this.selectedLanguage == 'cz'){
          contactButton = LiveAgent.createButton('8fdq42xw', LA_event);
        }
        else if( this.selectedLanguage == 'pl'){
          contactButton = LiveAgent.createButton('1jh8x2t9', LA_event);
        }
        else {
          contactButton = LiveAgent.createButton('7rubyw9g', LA_event);
        }
        //PRE-SET FORM DATA
        LiveAgent.setUserDetails(this.$store.getters.user.email, this.$store.getters.user.username);
        LiveAgent.addTicketField('country_code', this.$store.getters.getCountry);
        LiveAgent.addTicketField('product', this.$store.getters.productName);
        LiveAgent.addTicketField('user_id', this.$store.getters.user.user_id);
        clearInterval(LA_interval);
      }
    }, 250);
    setTimeout(()=>{clearInterval(LA_interval);}, 10000);
  },
  computed: {
    termsAndPolicies_: function() {
      return this.$store.getters.globalTermsAndPolicies
    },
    addressBlock: function(){
      var address = {
        name: 'Dating Lions GmbH',
        address_line_1: 'Anton-Melzer-Str. 7',
        address_line_2: '6020 Innsbruck',
        country: 'Austria'
      }

      if(domain == 'com' && (host == 'casualxdates' || host == 'illicitoasis')) {
        address = {
          name: 'Mens Choice Brands LLC',
          address_line_1: '75 South Broadway 4th Floor',
          address_line_2: 'White Plains, NY, 10601',
          country: 'USA | 1-800-671-5579'
        }
      }

      return address;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    editable: function() {
      return this.$store.getters.editable;
    },
    supportLink: function () {

      return 'mailto:' + this.$store.getters.supportEmail;

    }
  },
  methods: {
    openContactForm(){
        try {

        contactButton.onClick()
        }catch{
        window.location.href = this.supportLink;
        }
        
    },
    
  },
  
};
</script>

<style lang="scss" scoped>
.extraMargin {
  margin-bottom: 30px;
}
</style>
