<template>
  <div id="paymentsError">
   <MembershipHeader :premium = "true" />
    <div class="container-lg">
      <div class="containerNotFound" :class="{mobileView: mobileView}">
        <div class="messageContainer">
          <h1
            class="notFoundTitle"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.premiumPlans.paymentError.oops =$event.target.innerText"
          >{{$t($i18n.locale).keys.premiumPlans.paymentError.oops}}</h1>

          <p
            class="notFoundMessage"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.premiumPlans.paymentError.errorOccured =$event.target.innerText"
          >{{$t($i18n.locale).keys.premiumPlans.paymentError.errorOccured}}</p>

          <p class="failedTransactionText">
            <span
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.premiumPlans.paymentError.noCharge1 =$event.target.innerText"
            >{{$t($i18n.locale).keys.premiumPlans.paymentError.noCharge1}}</span>

            <br />

            <span
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.premiumPlans.paymentError.noCharge2 =$event.target.innerText"
            >{{$t($i18n.locale).keys.premiumPlans.paymentError.noCharge2}}</span>
          </p>

          <button @click="$router.push('/premium-plans/plans?direct=1')" class="backBtn">{{$t($i18n.locale).keys.premiumPlans.paymentError.goToPayments}}</button>
                  <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.premiumPlans.paymentError.goToPayments
          "
            type="text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";

export default {
  name: "PaymentsError",
  components: {
    MembershipHeader
  },
  data() {
    return {
      mobileView: false
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    this.windoWidth();
    this.scrollFunction();
  },
  methods: {
    scrollFunction(){
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    windoWidth() {
      return this.$store.getters.isMobile;
    }
  }
};
</script>