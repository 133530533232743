<template>
  <div id="membershipMeetings" >
    <header class="meetingsHeader">
               <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
        alt="Translation icon"
      />
      <p
        class="meetingsTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipMeetings.haveYouMet =
            $event.target.innerText
        "
      >
        {{ $t($i18n.locale).keys.membershipArea.membershipMeetings.haveYouMet }}
      </p>
      <div class="meetingsDataWrapper">
        <div class="meetingsQuantity" >{{ $store.getters.totalMeetings }}</div>
        <!--
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green-icon.svg')"
          alt="Meetings icon"
        />
        -->
      </div>
    </header>
    <div class="meetingsContentWrapper">
      <div class="meetingsImageWrapper" v-if="getHomeMeetings" >
        <img
          :src="`${$store.getters.getHomeMeetings.public_path}`"
          class="meetingsImage"
          v-if="$store.getters.getHomeMeetings.public_path"
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
          class="meetingsImage"
          v-if="!$store.getters.getHomeMeetings.public_path && (parseInt($store.getters.getHomeMeetings.gender) == 1)"
          alt=""
        />
        <img
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
          class="meetingsImage"
          v-if="!$store.getters.getHomeMeetings.public_path && (parseInt($store.getters.getHomeMeetings.gender) == 2)"
          alt=""
        />
        <div class="imageOverlay">
          <div class="wrapper">
            <p class="matchNameAge">
              {{
                $store.getters.getHomeMeetings != null
                  ? $store.getters.getHomeMeetings.firstname
                  : ""
              }}
            </p>
            <div class="insideWrapper">
              <!--
              <img v-if="parseInt($store.getters.getHomeMeetings.entertainment) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart icon">
              -->
              <p class="matchAgeLocation">
              {{
                $store.getters.getHomeMeetings != null
                  ? $store.getters.getHomeMeetings.age
                  : ""
              }},
              {{
                $store.getters.getHomeMeetings != null
                  ? $store.getters.getHomeMeetings.city
                  : ""
              }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="membershipButtonWrapper">
       <img
        v-if="editable"
        :style="{ marginLeft: '20px' }"
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
        alt="Translation icon"
      />
      <router-link to="/membership-area/meetings">
        <button
          class="membershipButton"
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.membershipArea.membershipMeetings.meet =
              $event.target.innerText
          "
        >
          {{ $t($i18n.locale).keys.membershipArea.membershipMeetings.meet }}
          {{
            $store.getters.getHomeMeetings != null
              ? $store.getters.getHomeMeetings.firstname
              : ""
          }}
        </button>

      </router-link>
                          <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.membershipArea.membershipMeetings.meet
          "
          type="text"
        />
    </div>


    <!-- <div class="meetingsFreeAccountWrapper" v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')" >
      <h3>As a Premium Member you can arrange meetings with other users</h3>
      <div class="membershipButtonWrapper" v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')">

        <router-link to="/premium-plans">
          <button
            class="membershipButton"
          >
            Become premium member
          </button>
        </router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MembershipMeetings",
  components: {},
  computed: {
    ...mapGetters(["editable", "getHomeMeetings"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_URL
    };
  },

  methods: {}
};
</script>

<style lang="scss" scoped></style>
