import axios from "axios";
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
  toggleGalleryViewVisitors({
    commit
  }) {
    commit('toggleGalleryViewVisitors');
  },
  toggleListViewVisitors({
    commit
  }) {
    commit('toggleListViewVisitors');
  },
  async fetchVisitors({
    commit
  }, request) {



    try {

      console.log('fetchVisitors data',request);

      const filters = store.getters.getActiveVisitorsFilters;
      let urlFilters = new URLSearchParams(filters).toString();

      let offset = 0;
      let limit = 10;

      if(typeof request != 'undefined' && request && request != 'autoupdate' ){
        if(request.offset) offset = request.offset;
        if(request.limit) limit = request.limit;
      }

      const response = await axios.get(
        BASE_URL + `/api/visitors/?offset=${offset}&limit=${limit}&${urlFilters}`,handlerEnabled
      );
      const data = response.data.data.visitors;
      // console.log(data);
      data.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      const defaultFilters = response.data.data.defaultFilters;
      const today = new Date();
      const minBirthDate = new Date(defaultFilters.minAge);
      let minAge = today.getFullYear() - minBirthDate.getFullYear();
      const minMonth = today.getMonth() - minBirthDate.getMonth();
      if (minMonth < 0 || (minMonth === 0 && today.getDate() < minBirthDate.getDate())) {
        minAge--;
      }
      defaultFilters.minAge = minAge;
      const maxBirthDate = new Date(defaultFilters.maxAge);
      let maxAge = today.getFullYear() - maxBirthDate.getFullYear();
      const maxMonth = today.getMonth() - maxBirthDate.getMonth();
      if (maxMonth < 0 || (maxMonth === 0 && today.getDate() < maxBirthDate.getDate())) {
        maxAge--;
      }
      defaultFilters.maxAge = maxAge;
      var bookmarks = [];
      let i;
      for (i = 0; i < data.length; i++) {
        let favourites = {
          id: data[i].id,
          bookmark: data[i].bookmark
        }
        bookmarks.push(favourites);
      }
      commit('setBookmarksVisitors', bookmarks);
      
      if(offset != 0) commit('updateVisitors', data);
      else commit('setVisitors', data);

      commit('setVisitorsDefaultFilters', defaultFilters);

      return response.data.data;
    } catch (error) {
      return 'error';
    }
  },
  filterVisitors({
    commit
  }, filters) {
    commit('filterVisitors', {
      //ageFrom: filters.ageFrom,
      //ageTo: filters.ageTo,
      //heightFrom: filters.heightFrom,
      //heightTo: filters.heightTo,
      newFilter: filters.newFilter,
      photoFilter: filters.photoFilter,
      favouritesFilter: filters.favouritesFilter,
      contactFilter: filters.contactFilter,
      //distanceFilter: filters.distanceFilter
    });
  },
  setSearchValueVisitors({
    commit
  }, searchValue) {
    commit('setSearchValueVisitors', searchValue);
  },
  async visitorsCount({
    commit
  }) {

    try {
      const response = await axios.get(
        BASE_URL + '/api/visitors/new'
      );
      commit('countVisitors', response.data.data);
    } catch (error) {
      return 'error';
    }
  },
  reRenderVisitorsList({
    commit
  }) {
    commit('changeVisitorsList');
  },
  async toggleBookmarkVisitors({
    commit
  }, visitor) {

    try {
      await axios.get(
        BASE_URL + `/api/matches/bookmark/${visitor.match_id}`
      );
      commit('toggleBookmarkVisitors', visitor.id);
      // commit('changeVisitorsList')
      commit('changeVisitorBookmark', visitor.id);
    } catch (error) {
      return 'error';
    }
  },
setActiveVisitorsFilters({commit}, filters){
    commit('setActiveVisitorsFilters', filters)
  }
};