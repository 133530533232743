<template>
  <div id="desktopPlans_variant_2" class="plansWrapper" >

    <div class="wrapper">
      <!-- light plan -->
      <div
        id="desktopPlan_light"
        class="desktopPlanWrapper variant2"
        @click="selectPlan(light.id, light.plansPrice.planId)"
      >

        <div class="plansInfoHeader light">
          <h1>
            <span>{{ light.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{ light.plansInfoHeader.titleType }}</span>
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansInfoPic">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_lite.png')" alt="">
          </div>
          <div class="plansDetails">
            <div class="plansPrice" :class="{'leadCurrency':(selectedLanguage == 'en')}">
              <h1>
                <span>
                  <template v-if="selectedLanguage == 'en'">
                    <span class="currency">{{ light.plansPrice.currencySign }}</span>
                    <span class="mainPrice">{{ light.plansPrice.visiblePrice }}.</span>
                    <span class="cent">{{ light.plansPrice.visiblePriceCents }}</span>
                    <span class="interval" :class="domain">{{ light.plansPrice.perMonth }}</span>
                  </template>
                  <template v-else>
                    <span class="mainPrice">{{ light.plansPrice.visiblePrice }},</span>
                    <span class="cent">{{ light.plansPrice.visiblePriceCents }}</span>
                    <span class="currency">{{ light.plansPrice.currencySign }}</span>
                    <span class="interval" :class="domain">{{ light.plansPrice.perMonth }}</span>
                  </template>

                </span>
              </h1>
            </div>
            <p>
              {{ light.plansInfoBody.headlinePeriod }}
            </p>
          </div>
        </div>
        <div class="buttonWrapper">
          <button class="btnContinue">
            {{ $t($i18n.locale).keys.generic.continueBtn }}
          </button>
        </div>
      </div>
      <!-- end light plan -->

      <!-- comfort plan -->
      <div
        id="desktopPlan_comfort"
        class="desktopPlanWrapper variant2 best"
        @click="selectPlan(comfort.id, comfort.plansPrice.planId)"
      >

        <div class="plansInfoHeader comfort">
          <h1>
            <span>{{ comfort.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{ comfort.plansInfoHeader.titleType }}</span>
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansInfoBest">
            <i class="fa fa-star"></i>
              {{ $t($i18n.locale + '.keys.premiumPlans.bestOffer') }}
            <i class="fa fa-star"></i>
          </div>
          <div class="plansInfoPic">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_classic.png')" alt="">
          </div>
          <div class="plansDetails">
            <div class="plansPrice" :class="{'leadCurrency':(selectedLanguage == 'en')}">
              <h1>
                <span>
                  <template v-if="selectedLanguage == 'en'">
                    <span class="currency">{{ comfort.plansPrice.currencySign }}</span>
                    <span class="mainPrice">{{ comfort.plansPrice.visiblePrice }}.</span>
                    <span class="cent">{{ comfort.plansPrice.visiblePriceCents }}</span>
                    <span class="interval" :class="domain">{{ comfort.plansPrice.perMonth }}</span>
                  </template>
                  <template v-else>
                    <span class="mainPrice">{{ comfort.plansPrice.visiblePrice }},</span>
                    <span class="cent">{{ comfort.plansPrice.visiblePriceCents }}</span>
                    <span class="currency">{{ comfort.plansPrice.currencySign }}</span>
                    <span class="interval" :class="domain">{{ comfort.plansPrice.perMonth }}</span>
                  </template>

                </span>
              </h1>
            </div>
            <p>
              {{ comfort.plansInfoBody.headlinePeriod }}
            </p>
          </div>
        </div>
        <div class="buttonWrapper">
          <button class="btnContinue">
            {{ $t($i18n.locale).keys.generic.continueBtn }}
          </button>
        </div>
      </div>
      <!-- end comfort plan -->

      <!-- classic plan -->
      <div
        id="desktopPlan_classic"
        class="desktopPlanWrapper variant2"
        @click="selectPlan(classic.id, classic.plansPrice.planId)"
      >

        <div class="plansInfoHeader classic">
          <h1>
            <span>{{ classic.plansInfoHeader.titlePremium }}</span>
            <span class="title">{{ classic.plansInfoHeader.titleType }}</span>
          </h1>
        </div>
        <div class="plansInfoBody">
          <div class="plansInfoBest">
            <i class="fa fa-star"></i>
              {{ $t($i18n.locale + '.keys.premiumPlans.bestOffer') }}
            <i class="fa fa-star"></i>
          </div>
          <div class="plansInfoPic">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_comfort.png')" alt="">
          </div>
          <div class="plansDetails">
            <div class="plansPrice" :class="{'leadCurrency':(selectedLanguage == 'en')}">
              <h1>
                <span>
                  <template v-if="selectedLanguage == 'en'">
                    <span class="currency">{{ classic.plansPrice.currencySign }}</span>
                    <span class="mainPrice">{{ classic.plansPrice.visiblePrice }}.</span>
                    <span class="cent">{{ classic.plansPrice.visiblePriceCents }}</span>
                    <span class="interval" :class="domain">{{ classic.plansPrice.perMonth }}</span>
                  </template>
                  <template v-else>
                    <span class="mainPrice">{{ classic.plansPrice.visiblePrice }},</span>
                    <span class="cent">{{ classic.plansPrice.visiblePriceCents }}</span>
                    <span class="currency">{{ classic.plansPrice.currencySign }}</span>
                    <span class="interval" :class="domain">{{ classic.plansPrice.perMonth }}</span>
                  </template>

                </span>
              </h1>
            </div>
            <p>
              {{ classic.plansInfoBody.headlinePeriod }}
            </p>
          </div>
        </div>
        <div class="buttonWrapper">
          <button class="btnContinue">
            {{ $t($i18n.locale).keys.generic.continueBtn }}
          </button>
        </div>
      </div>
      <!-- end classic plan -->

      



    </div>
  </div>
</template>

<script>


export default {
  name: "DesktopPlans_variant2",
  props: [
    "comfort",
    "classic",
    "light",
    "selectedLanguage",
    "domain"
  ],
  data() {
    return {

    };
  },
  computed: {
    user: function(){
        return this.$store.getters.user
      }
  },

  mounted() {

  },

  created() {

  },
  methods: {
    selectPlan(planId){
      this.$emit('selectPlan', planId);
    }
  },
};
</script>

<style lang="scss">



</style>