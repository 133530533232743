


const BASE_URL = process.env.VUE_APP_URL;


const SEPA_helpers = {

    _validation(data, context){

        //var isValid = false;
        var stringmatch = /^[a-zA-ZäöüÄÖÜß-]+( [a-zA-ZäöüÄÖÜß-]+)*$/;
  
        context.SEPA_errors.hasErrors = false;
  
        //Validate IBAN
        if(context.iban_validator.isValid(context.SEPA_IBAN)){
          //isValid = true;
          context.SEPA_errors.SEPA_IBAN = null;
        }else{
          context.SEPA_errors.hasErrors = true;
          context.SEPA_errors.SEPA_IBAN = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorSepaIBAN');
        }
  
        //validate first name
        if(stringmatch.test(context.SEPA_firstname.trim())){
          //isValid = true;
          context.SEPA_errors.SEPA_firstname = null;
        }else{
          context.SEPA_errors.hasErrors = true;
          context.SEPA_errors.SEPA_firstname = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorSepaFirstname');
        }
  
        //validate last name
        if(stringmatch.test(context.SEPA_lastname.trim())){
          //isValid = true;
          context.SEPA_errors.SEPA_lastname = null;
        }else{
          context.SEPA_errors.hasErrors = true;
          context.SEPA_errors.SEPA_lastname = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorSepaLastname');
        }
  
        //final answer
        if(context.SEPA_errors.hasErrors) return false;
        else return true;

    },

    _sendData(data, context){
        if(typeof data == 'undefined' || !data) return false;

          var url = BASE_URL + `/api/payments/SEPA/subscribe/${context.getActivePlan.plansPrice.planId}`;
          /*
          if (context.SEPA_AIS_AB) {
            url = context.BASE_URL + `/api/payments/SEPA/create_mandate/${context.getActivePlan.plansPrice.planId}`;
          }
          */
          context.$axios.post(
              url,
              data
          ).then((resp)=>{
            if (resp.data.data.success == true && resp.data.data.token) {
            localStorage.setItem("token", resp.data.data.token);
              if(resp.data.data.detailsEntered == true){
                context.$router.push({ path: "/payments-success", query: { cid: context.$route.query.id } });
              } else if(resp.data.data.detailsEntered == false) {
                context.$router.push({ path: "/payments-success-info", query: { cid: context.$route.query.id } });
              }
              
            } else {
    
                context.$router.push("/payments-error");
    
            }
          }).catch((error) => {
            console.log('SEPA error', error)
          context.$router.push("/payments-error");
        });
    },

    _submitData(data,context){
        console.log('SUBMIT SEPA');
  
        if(!context.validateSEPA()){
          console.log('NOT VALID')
          return false;
        }
  
        // Disable form submit button to prevent repeatable submits
        context.formLocked = true;
  
        context.SEPA_data = {
                  'iban': context.SEPA_IBAN,
                  'firstname': context.SEPA_firstname.trim(),
                  'lastname': context.SEPA_lastname.trim(),
                  'count': context.countPaymentAttempt,
                };
  
        if(context.isMobile){
          console.log('emit showSummarySEPA')
          context.$emit('showSummary', {sepa_data: context.SEPA_data});
        }else{
          context.sendSEPAData(context.SEPA_data);
        }
  
        return false;
    }
}

export default SEPA_helpers