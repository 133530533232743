
export default {
    galleryViewVisitors: false,
    listViewVisitors: true,
    visitors: [],
    filteredVisitors: [],
    visitorsQuantity: 0,
    visitorsSearchValue: "",
    visitorsBookmarks: [],
    defaultVisitorsFilters: [],
    visitorsList: 0,
    activeVisitorsFilters: {
        contactFilter: 0,
        favouritesFilter: 0,
        newFilter: 0,
        photoFilter: 0,
    },
};