<template>
<div id="styleguide" >
   <!--
    <h1 class="title">Styleguide<br>{{ $store.getters.productName }}</h1>
    -->
    <Icon_styles />

    <Color_styles />
                   
    
</div>
</template>

<script>

import Icon_styles from './Icon_styles.vue';
import Color_styles from './Color_styles.vue';

export default {

    name: "Styleguide",
    components: {
        Icon_styles,
        Color_styles
    }
    
}


</script>

<style lang="scss">
#styleguide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 40px 15px;
    gap: 20px;
    flex-wrap: wrap;

    .title {
        font-size: 10vw;
        position: absolute;
        top: 20px;
        left: 20px;
        color: rgba(0, 0, 0, 0.03);
        font-weight: bold;
        z-index: -1;
    }

    h3 {
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 20px;
    }

    h4 {
        display: block;
        font-size: 1.2em;
    }
    
   
}
</style>