import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
  async meetingsConversation({
    dispatch, getters
  }, data) {

    try {
      if (getters.userRole.includes("ROLE_PREMIUM")){
        await axios.post(BASE_URL + `/api/meetings/message/${data.meeting_id}`, {
          message: data.message
        },handlerEnabled);
        dispatch("getTotalMeetings"),
          dispatch("getConversations"),
          dispatch("getNewMessagesNumber");
      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }

    } catch (error) {
      return 'error';
    }

  },

  async getMeetings({
    commit,
    dispatch,
    getters
  }) {
    if (getters.userRole.includes("ROLE_PREMIUM")){
      try {
        const resp = await axios.get(BASE_URL + `/api/meetings/`,handlerEnabled);
          commit("set_meetings", resp.data.data);
          dispatch("getTotalMeetings");
          dispatch("getConversations");
          dispatch("getNewMessagesNumber");
      } catch (error) {
        return 'error';
      }
    }
  },

  async sendMeetingSmile({
    dispatch, getters
  }, meeting_id) {

    try {
      if (getters.userRole.includes("ROLE_PREMIUM")){
        await axios.get(BASE_URL + `/api/meetings/smile/${meeting_id}`, handlerEnabled);
        dispatch("getTotalMeetings");
        dispatch("getConversations");
        dispatch("getNewMessagesNumber");
      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }

    } catch (error) {
      return 'error';
    }
  },

  async getTotalMeetings({
    commit, getters
  }) {

    if (getters.userRole.includes("ROLE_PREMIUM")){
      try {
        const resp = await axios.get(BASE_URL + `/api/meetings/new`, handlerEnabled);
          commit("set_totalMeetings", resp.data.data);
      } catch (error) {
        return 'error';
      }
    }else{
      commit("set_totalMeetings", 0);
      return true;
    }
  },

  async sendMeetingRemove({
    dispatch, getters
  }, meeting_id) {
    
    try {
      if (getters.userRole.includes("ROLE_PREMIUM")){
        await axios.delete(BASE_URL + `/api/meetings/remove/${meeting_id}`, handlerEnabled);
        dispatch("getTotalMeetings");
        dispatch("getConversations");
        dispatch("getNewMessagesNumber");
      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }

        
    } catch (error) {
      return 'error';
    }
  },
  addMeetingsCount({commit}){
    commit('addMeetingsCount');
  }
};