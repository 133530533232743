<template>
  <div class="chatMessage typeExpire ">
    <div class="msgBox" >
      <Countdown 
        v-if="expires"
        :end-date="new Date(expires*1000)" 
        @timer-ended="timerEnded"
        :timertype="'timer_box'"
        />
    </div>
  </div>

</template>

<script>

import Countdown from "@/components/generic/Countdown.vue";

export default {
  name: "TypeExpire",
  components: {
    Countdown
  },
  props: ["expires", "match_id"],
  data() {
    return {
    };
  },
  methods: {
    timerEnded() {
      console.log('timer ended, do something!')
      this.$store.dispatch("deleteConversation", this.match_id);
    }
  },
};
</script>