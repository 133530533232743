import axios from "axios";
import i18n from '../../../i18n';
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};
export default {

  async fetchHome({
    commit
  }) {

    try {
      const response = await axios.get(
        BASE_URL + '/api/dashboard/',handlerEnabled
      );
      let matchesData = response.data.data.matches;
      matchesData.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      let messagesData = response.data.data.messages;
      if(response.data.data.messages.length <= 2){
        let firstName = store.getters.welcomeMessageSender; //i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.sender',{productName: store.getters.productName,});
        /*
        if(store.getters.productName == 'CasualXdates'){
          firstName = 'Jasmin Wagner';
        }
        */
        var gM = {
          birthday: "",
          created_at: "",
          entertainment: "0",
          firstName: firstName,
          gender: "2",
          id: "1",
          matched_user_id: "1",
          message: i18n.t(i18n.locale + '.keys.membershipChat.welcomeMsg.subject',{productName: store.getters.productName,sender: firstName}),
          messageId: "1",
          new: "0",
          photo_id: "1",
          public_path: require('@/assets/images/' + store.getters.assetsPath + '50sloveAvatar.jpg'),
          type: "1",
          blurred: false,
          blockSend: true
        }

        response.data.data.messages.push(gM);
      }
      messagesData.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      let visitorsData = response.data.data.visitors;
      visitorsData.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      let meetingsData = response.data.data.meeting;
      if (meetingsData != null) {
        const today = new Date();
        const birthDate = new Date(meetingsData.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        meetingsData.age = age;
      }

      let onlineData = response.data.data.online;
      onlineData.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      // console.log(response.data.data, 'home')
      commit('setHomeMatches', matchesData)
      commit('setHomeVisitors', visitorsData)
      commit('setHomeMeetings', meetingsData);
      commit('setHomeMessages', messagesData)
      setTimeout(() => {
        commit('setHomeOnline', onlineData)

      }, 1000);

      return response;

    } catch (error) {
      return 'error';
    }
  }
};