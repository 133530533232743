
  export default function formatDate(item, locale, timeonly) {
    //console.log('formatDate',locale);
    if(typeof locale == "undefined") locale = 'default';
    if(typeof timeonly == "undefined") timeonly = false;
    //setTimeZone
    item += '+00:00';

    let date = new Date(item.replace(/ /g, "T")); //iOS Safari needs this format

  if (isNaN(date)) {
    return ' '
  }

  var hours = (date.getHours() < 10) ? '0' + date.getHours(): date.getHours();
  var minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes(): date.getMinutes();
  var month = date.toLocaleString(locale, { month: 'long' });
  if(timeonly) return  hours + ':' + minutes;
  else return  date.getDate() +' ' + month +' '+ date.getFullYear() +" - " + hours + ':' + minutes;
}


