<template>
  <div class="overlay">
    <div class="overlay__inner">
      <div class="overlay__content">
        <span  class="spinner"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",

  data() {
    return {
      // show: false
    };
  },

  mounted() {
  //  setTimeout(() => {
  //   //  console.log(this.show);
  //    this.show = true;
  //         // console.log(this.show);
  //  }, 400);
  }
};
</script>

<style>
.overlay {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  /* background:#F3F4F6; */
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 160px;
  height: 160px;
  display: inline-block;
  background: url("../../assets/images/spinner.svg");
  background-size: cover;
}
/* .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
} */

/* @keyframes spin {
  100% {
    transform: rotate(360deg);
  } */
/* } */
</style>