<template>
  <div id="criteria" class="preferencesBox">
    <div class="criteriaWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="$t($i18n.locale).keys.matchesPreferences.criteria.title = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.matchesPreferences.criteria.title')}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
      <template v-if="matchCriteria">
      <div v-for="question in matchCriteria" :key="question.id" class="filterWrapper" >
        <div class="ethnicityTextWrapper textWrapper">
          <h3
            class="ethnicityText"
          >{{ question.question }}</h3>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
        <div class="switchWrapper" v-for="(answer,index) in question.answerOptions" :key="index">


          <label class="switch">
            <input
              v-model="answer.selected"
              type="checkbox"
              @change="saveCriteria"
            >
            <span class="slider round"></span>
          </label>
          <p
            class="switchName"
          >{{answer.answer}}</p>

        </div>
        <div class="badFormatError" v-if="false">Bitte wählen Sie min. 1 Antwort aus</div>

      </div>

      <!--
      <div class="buttonWrapper">
        <button
          @click="saveCriteria()"
          class="btnSave"
          :disabled = "false"
        >{{$t($i18n.locale).keys.generic.save}}</button>
        <input
          :style="{marginLeft : '20px'}"
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
      -->
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MatchCriteria",

  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("fetchMatchCriteria").then(res => {
      console.log("fetchMatchCriteria", res);
    });
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["matchCriteria"]),
    editable: function() {
      return this.$store.getters.editable;
    },
  },
  methods: {
    saveCriteria: function(){
      console.log("saveCriteria", this.matchCriteria);
      this.$store.dispatch("updateMatchCriteria", this.matchCriteria)
    }
  }
};
</script>
