<template>
  <div>
    <!--
    <div class="answered">{{progressData.currentQuestion}}/{{progressData.totalQuestions}}</div>
    -->
    <div id="progressbar">
      <div class="bar" v-bind:style="{ 'width': progressData.percentage + '%'  }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["progressData"],
  name: "ProgressBar"
};
</script>
