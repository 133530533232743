export default {
    setErrorVisibility(state, value){
        state.showError = value;
        // console.log(value)
    },
    showLoader(state){
        state.showLoader = true;
        // console.log('show laoder')
    },
    hideLoader(state){
        state.showLoader = false;
        // console.log('hide loader')
    },
    showContent(state){
        state.showContent = true;
        // console.log('SHOW CONTENT')
    },
    hideContent(state){
        state.showContent = false;
    }
}