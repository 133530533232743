var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBar)?_c('div',{key:_vm.componentKey,attrs:{"id":"ActionTab"}},[(!_vm.isFirst)?_c('span',{staticClass:"profileButton buttonBack",on:{"click":_vm.loadPrevious}},[_vm._v("<")]):_vm._e(),_c('button',{staticClass:"sendMessage",on:{"click":_vm.sendMessage}},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'envelope-fixed-white.svg'),"alt":"Like icon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.memberProfile.actionTab.sendMessage)+" ")])]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$t(_vm.$i18n.locale).keys.memberProfile.actionTab.sendMessage
          ),expression:"\n            $t($i18n.locale).keys.memberProfile.actionTab.sendMessage\n          "}],attrs:{"type":"text"},domProps:{"value":(
            _vm.$t(_vm.$i18n.locale).keys.memberProfile.actionTab.sendMessage
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.memberProfile.actionTab, "sendMessage", $event.target.value)}}}):_vm._e(),[(_vm.showFunctionButtons)?_c('span',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"smileButton",staticClass:"actionButton sendSmile",class:{
            sent: _vm.MemberData.smile_sent == 1,
            loading: _vm.smileLoading
          },attrs:{"title":_vm.MemberData.smile_sent == 1 ? '': _vm.$t(_vm.$i18n.locale + '.keys.tooltips.Smile_button')},on:{"click":_vm.sendSmile}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.MemberData.smile_sent == 1),expression:"MemberData.smile_sent == 1"}],attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'smile-grey-button.svg'),"alt":"Like icon"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.MemberData.smile_sent),expression:"!MemberData.smile_sent"}],attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'smile-green-button.svg'),"alt":"Like icon"}})]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"favoriteButton",staticClass:"actionButton makeFavorite",attrs:{"title":_vm.MemberData.bookmark ? 
          _vm.$t(_vm.$i18n.locale + '.keys.tooltips.FavRemove_button') : _vm.$t(_vm.$i18n.locale + '.keys.tooltips.Fav_button')},on:{"click":_vm.toggleBookmark}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.MemberData.bookmark == 1),expression:"MemberData.bookmark == 1"}],attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'favourite-selected-button.svg'),"alt":"Favorite icon"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.MemberData.bookmark),expression:"!MemberData.bookmark"}],attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'favourite-green-button.svg'),"alt":"Favorite icon"}})]),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"deleteMessage",attrs:{"title":_vm.$t(_vm.$i18n.locale + '.keys.tooltips.Del_button'),"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'delete-grey-button.svg'),"alt":"Delete icon"},on:{"click":_vm.removeMatch}})]):_vm._e(),(!_vm.MemberData.reported)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"reportUserBtn",attrs:{"title":_vm.$t(_vm.$i18n.locale + '.keys.tooltips.Report_button')},on:{"click":_vm.showReport}},[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e()],(!_vm.isLast)?_c('span',{staticClass:"profileButton buttonNext",on:{"click":_vm.loadNext}},[_vm._v(">")]):_vm._e(),_c('ModalUserReport',{ref:"modalUserReport"})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }