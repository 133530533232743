import axios from "axios";
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;

// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};
export default {

  async updateMemberData({ commit }, memberData) {

      commit("updateMemberData", memberData);

  },
  async fetchMemberData({ commit }, data) {
    console.log('fetchMemberData',data, typeof data );
    var memberID, reset;
    if(typeof data != 'object') {
      memberID = data;
      reset = false;
    }else{
      memberID = data.memberID;
      reset = data.reset;
    }
    if(memberID && !reset){
      commit("setMemberData", {
        profileCard: {}
      });
    }
    // /{handlerEnabled: true}
    try {
      if(typeof memberID == 'undefined' || !memberID) throw "Undefined ID";
      const response = await axios.get(
        BASE_URL + `/api/profile/view/${memberID}`, handlerEnabled
              );
              
              console.log(response, 'member action')
      commit("setProfileID", memberID);
      commit("setMemberData", response.data.data);
      return response.data.data
    }
    catch (error) {
      commit('setErrorVisibility', true);
      return "error";
    }
  },
  async fetchInterestsData({ commit }, memberID) {
    try {
      if(typeof memberID == 'undefined' || !memberID) throw "Undefined ID";
      const response = await axios.get(
        BASE_URL + `/api/profile/interests/${memberID}`, handlerEnabled
      );

      commit("setInterestsData", response.data);
    } catch (error) {
      //commit('setErrorVisibility', true);
      return "error";
    }
  },
  async fetchPersonalityData({ commit }, data) {
    
    try {
      if(typeof data.profileID == 'undefined' || !data.profileID) throw "Undefined ID";
      const response = await axios.get(
        BASE_URL + `/api/profile/personality/${data.locale}/${data.profileID}` , handlerEnabled
      );
      console.log(response.data.data);
      commit("setPersonalityData", response.data.data);
    } catch (error) {
      commit('setErrorVisibility', true);
      return "error";
    }
  },
  async fetchGalleryData({ commit }, memberID) {
    try {
      if(typeof memberID == 'undefined' || !memberID) throw "Undefined ID";
      const response = await axios.get(
        BASE_URL + `/api/profile/gallery/${memberID}` , handlerEnabled
      );
      commit("setGalleryData", response.data.data);
    } catch (error) {
      commit('setErrorVisibility', true);
      return "error";
    }
  },
  async likeAnswer({ commit,dispatch }, answerID) {
      try {
        const response = await axios.get(
          BASE_URL + `/api/profile/like_answer/${answerID}` , handlerEnabled
        );
        commit("update_likes_comments", null);
        dispatch("getNewMessagesNumber");
          return response.data.data
      } catch (error) {

        return "error";
      }
    
  },
  async commentAnswer({ commit, dispatch }, data) {
    
      try {
      
        const response = await axios.post(
          BASE_URL +  `/api/profile/comment_answer/${data.answerID}`, 
          {"comment": data.comment},  handlerEnabled
        );
    
        commit("update_likes_comments", null);
        dispatch("getNewMessagesNumber");
        return response.data.data
        
      } catch (error) {
        console.log('commentAnswer action error', error);
        return "error";
      }
    
  },
  async toggleBookmark({
    commit
  }, match_id) {

    try {
      var res = await axios.get(
        BASE_URL + `/api/matches/bookmark/${match_id}`
      );
      commit('toggleBookmark', match_id);
      // commit('changeMatchesList')
      commit('changeMatchBookmark', match_id);
      return res;
    } catch (error) {
      return 'error';
    }
  },

  async sendReport(_,data) {

    try {
     
      const response = await axios.post(
        BASE_URL +  `/api/profile/report/${data.profile_id}`, 
        {"reason": data.reason},  
      );
  
      return response.data.data
      
    } catch (error) {
      console.log('sendReport action error', error);
      return "error";
    }
  

  }
};
