<template>
  <div id="memberDescription">
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr :contenteditable="editable"
        @blur="$t($i18n.locale).keys.memberProfile.userProfile.personalDescription.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.memberProfile.userProfile.personalDescription.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
        alt="Translation icon"
      />
    </div>
    <div class="sectionItemsWrapper">
      <div class="leftContainer">
        <div v-if="this.MemberData.description" class="infoWrapper">
          <div class="infoBox" v-for="(profileItem, index) in profileData" :key="index">
            <div class="infoLabel">
              {{profileItem.label}}:
            </div>
            <p class="infoText">
              {{profileItem.value}}
            </p>
          </div>
          <!--
          <div class="heightWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'height-icon.svg')" alt="Height icon" />
            <p class="height">{{ this.formattedHeight }}</p>
          </div>
          <div class="raceWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'race-icon.svg')" alt="Race icon" />
            <p class="race">
              {{
              this.MemberData.description.ethnicy.question.answerOptions[this.MemberData.description.ethnicy.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
          <div class="religionWrapper">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'religion-icon.svg')" alt="Religion icon" />
            <p class="religion">
              {{
              this.MemberData.description.religion.question.answerOptions[this.MemberData.description.religion.answer[0]-1]
              .translations[selectedLanguage].answer
              }}
            </p>
          </div>
          -->
        </div>
      </div>
      <div v-if="this.aboutMeQuestion" class="rightContainer">
        <div class="infoWrapper">
          <div class="editableContentWrapper">
                  <div class="editableQuestionWrapper">
                      <h1 class="editableQuestion" v-if="aboutMeQuestion.question">
                        {{ aboutMeQuestion.question.translations[this.$store.getters.selectedLanguage].question }}
                      </h1>
                  </div>
                  <p v-if="aboutMeQuestion.answer[0]" class="answerType2">{{ aboutMeQuestion.answer[0] }}</p>
                  <p v-else
                  class="aboutMePlaceholder"
                   v-langErr :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.memberProfile.userProfile.personalDescription.aboutPlaceholder = $event.target.innerText"
                  >
                    {{$t($i18n.locale+ '.keys.memberProfile.userProfile.personalDescription.aboutPlaceholder', {
                      username: this.MemberData.profileCard.name
                    })}}
                    <span class="iconsWrapper">
                      <img
                        class="sendComment"
                        @click="sendMessage"
                        :src="require('@/assets/images/' + $store.getters.assetsPath + 'message-green-icon.svg')"
                        alt="Message icon"
                      />
                    </span>
                  </p>
                </div>
        </div>
      </div>
    </div>
    <!--
    <div class="separatorBox"></div>
    <QuestionsAnswers />
    -->
  </div>
</template>

<script>

//import QuestionsAnswers from "./QuestionsAnswers";
import { mapGetters } from "vuex";

export default {
  name: "PersonalDescription",
  components: {
    //QuestionsAnswers
  },
  computed: {
    ...mapGetters(["MemberData","selectedLanguage","getCountry"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      if(this.getCountry == 'uk' || this.getCountry == 'us') return false;
      else return true;
    },
    formattedHeight: function(){
      if(!this.metricUnits){
        //set values to foot

        var measurement = this.MemberData.description.height.answer[0];
        var feet = parseInt(measurement);
        var fraction = measurement - feet;
        var inches = parseInt((12.0 * fraction));

        return feet + 'ft ' + inches + 'in';
      }else{
        return this.MemberData.description.height.answer[0] + "cm";
      }
    }
  },
  data() {
    return {
      language: "English",
      aboutMeQuestion: null,
      profileData: {
        height: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.height'),
          value: '',
        },
        relationship: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.relationship'),
          value: '',
        },
        bodyshape: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.bodyshape'),
          value: ''
        },
        haircolor: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.haircolor'),
          value: '',
        },
        eyecolor: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.eyecolor'),
          value: '',
        },
      }
    };
  },
  mounted() {
    console.log('this.MemberData', this.MemberData)
    this.setupProfileData();
  },
  watch: {
    MemberData: function(newVar, oldVar){
      console.log('MemberData changed!',newVar, oldVar)
      if(newVar.profile_id)  this.setupProfileData();
    }
  },
  methods: {
    setupProfileData(){
      if(this.MemberData.description.relationship){
        this.profileData.relationship.value =this.MemberData.description.relationship.question.answerOptions[this.MemberData.description.relationship.answer[0]-1].translations[this.selectedLanguage].answer
      }

      if(this.MemberData.description.bodyshape){
        this.profileData.bodyshape.value = this.MemberData.description.bodyshape.question.answerOptions[this.MemberData.description.bodyshape.answer[0]-1].translations[this.selectedLanguage].answer
      }

      if(this.MemberData.description.eyecolor){
        this.profileData.eyecolor.value = this.MemberData.description.eyecolor.question.answerOptions[this.MemberData.description.eyecolor.answer[0]-1].translations[this.selectedLanguage].answer
      }

      if(this.MemberData.description.haircolor){
        this.profileData.haircolor.value = this.MemberData.description.haircolor.question.answerOptions[this.MemberData.description.haircolor.answer[0]-1].translations[this.selectedLanguage].answer
      }

      if(this.MemberData.description.height){
        this.profileData.height.value = this.formattedHeight;
      }

      //Get question for about me
        if(this.MemberData.description.description){
          this.aboutMeQuestion = this.MemberData.description.description;
        }
    },
    sendMessage() {

      if(!this.MemberData.blockSend){
        var match = {
          id: this.MemberData.match_id,
          blockSend: false
        }
        console.log(match);
        this.$store
          .dispatch("initiateConversation", match)
          .then(res => {
            if (res == "error") {
              // show error
              //this.$store.dispatch("setErrorVisibility", true);
              this.$store.dispatch("hideContent");
            }
          });
        this.$store.dispatch("reRenderMatchesList");
      } else {
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });

        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>
