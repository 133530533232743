export default {
  toggleGalleryView(state) {
    state.galleryView = !state.galleryView;
    if (state.galleryView == true) {
      state.listView = false;
    } else {
      state.listView = true;
    }
  },
  toggleListView(state) {
    state.listView = !state.listView;
    if (state.listView == true) {
      state.galleryView = false;
    } else {
      state.galleryView = true;
    }
  },
  setMatches: (state, matches) => (state.matches = matches),
  setInitialID: (state, initialID) => (state.initialID = initialID),
  filterMatches: (state, filters) =>
    {
      console.log(filters, 'filters');
      state.activeMatchesFilters = filters;
    let _filteredMatches = state.matches.filter(
      match =>
        //match.age >= filters.ageFrom &&
        //match.age <= filters.ageTo &&
        //parseInt(match.height) >= filters.heightFrom &&
        //parseInt(match.height) <= filters.heightTo &&
        //parseInt(match.distance) <= filters.distanceFilter &&
        (parseInt(match.alternative) == 0)&&
        (filters.newFilter == 0 ? parseInt(match.new) == 0 || parseInt(match.new) == 1: parseInt(match.new) == 1) &&
        (filters.favouritesFilter == 0
          ? parseInt(match.bookmark) == 0 || parseInt(match.bookmark) == 1
          : parseInt(match.bookmark) == 1) &&
        (filters.contactFilter == 0
          ? parseInt(match.in_contact) == 0 || parseInt(match.in_contact) == 1
          : parseInt(match.in_contact) == 1) &&
        (parseInt(filters.photoFilter) == 0
          ? parseInt(match.has_photo) == 0 || parseInt(match.has_photo) == 1
          : parseInt(match.has_photo) == 1) &&
        ((
          (state.matchesSearchValue != "" && match.firstname)
          ? match.firstname
              .toLowerCase()
              .includes(state.matchesSearchValue.toLowerCase())
          : true) ||
          (
            (state.matchesSearchValue !== "" && match.profession)
            ? match.profession
                .toLowerCase()
                .includes(state.matchesSearchValue.toLowerCase())
            : true) ||
          (
            (state.matchesSearchValue != "" && match.city)
            ? match.city
                .toLowerCase()
                .includes(state.matchesSearchValue.toLowerCase())
            : true))
    );
    console.log('_filteredMatches', _filteredMatches);
    state.filteredMatches = _filteredMatches;
          //filter alternative matches
          let _alternativeMatches = state.matches.filter(
            match => (parseInt(match.alternative) == 1)
          );
          console.log('_alternativeMatches', _alternativeMatches);
          state.alternativeMatches = _alternativeMatches;

        },
  countMatches: (state, matchesQuantity) =>
    (state.matchesQuantity = matchesQuantity[0].new_matches),
  setSearchValue: (state, searchValue) =>
    (state.matchesSearchValue = searchValue),
  setBookmarks: (state, bookmarks) => (state.bookmarks = bookmarks),
  toggleBookmark: (state, match_id) =>
    state.bookmarks.forEach(item =>
      item.id == match_id
        ? parseInt(item.bookmark) == 0
          ? (item.bookmark = 1)
          : (item.bookmark = 0)
        : ""
    ),
  setDefaultFilters: (state, defaultFilters) =>
    (state.defaultFilters = defaultFilters),
  changeMatchesList: state => state.matchesList++,
  changeMatchBookmark: (state, match_id) =>
    state.matches.forEach(item =>
      item.id == match_id
        ? parseInt(item.bookmark) == 0
          ? (item.bookmark = 1)
          : (item.bookmark = 0)
        : ""
  ),
  changeMatchSmile: (state, match_id) => {
    state.matches.forEach(item => {
      if(item.id == match_id){
        (parseInt(item.smile_sent) == 0 || !item.smile_sent)
        ? (item.smile_sent = 1)
        : (item.smile_sent = 0)
      }
    });
  },
  addMatchCount: state => {
    state.matchesActionsCount++;
    // console.log(state.matchesActionsCount);
  },

  updateMatches: (state, payload) => {
    console.log('updateMatches', payload);

    var updatedMatches = [...state.matches, ...payload];

    state.matches = updatedMatches;
  },

  setActiveMatchesFilters: (state, filters) => {
    state.activeMatchesFilters = filters;
  },
  removeMatchById(state, match_id){
    console.log('removeMatchById', match_id)
    let _newMatchArray = state.matches.filter( match => {
      if(match.id != match_id) return true;
    })
    state.matches = _newMatchArray; 
  }
};
