<template>
  <div id="mobilePlans_variant_3" class="plansWrapper">
    <div class="mobilePlansWrapper">
      <div
        class="simplePlanWrapper"
        @click="selectPlan(light.id)"
        :style="[ABVariant == 2 ? {'order':2, 'margin-top':'20px'}:{'order':1, 'margin-top':'0px'}]"
      >
        <div class="planHeader green">
            <svg class="flyout" width="144" height="50" viewBox="0 0 144 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.000286399 0L0 50H104.416C106.206 50 107.5 49.5 108.934 47.9424L123.483 32.0576C124.5 31 126.21 30 128 30H144V0H0.000286399Z" fill="currentColor"/>
            </svg>
            <div class="planHeader_content">
              <div class="planHeader_price">
                <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ light.plansPrice.currencySign }}{{ light.plansPrice.visiblePrice}}.<sup>{{ light.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ light.plansPrice.visiblePrice}},<sup>{{ light.plansPrice.visiblePriceCents }}</sup>{{ light.plansPrice.currencySign }}
                    </template>
                  </span>
              </div>
              <h1>
                <span v-langErr>
                  {{ light.plansInfoHeader.titlePremium }}
                </span>
                <span class="title" v-langErr>
                  {{ light.plansInfoHeader.titleType }}
                </span>
              </h1>
            </div>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_lite_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ light.plansInfoBody.headlinePeriod }}
          </p>
          <i class="continueBtn fa fa-chevron-right"></i>
        </div>
        <div class="promoWrapper" v-if="light.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ light.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>
      <div
        class="planWrapper"
        @click="
        selectPlan(classic.id)
        "
        :style="[ABVariant == 2 ? {'order':3}:{'order':2}]"
      >
        <div class="planHeader">
          <svg class="flyout" width="144" height="50" viewBox="0 0 144 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.000286399 0L0 50H104.416C106.206 50 107.5 49.5 108.934 47.9424L123.483 32.0576C124.5 31 126.21 30 128 30H144V0H0.000286399Z" fill="currentColor"/>
            </svg>
            <div class="planHeader_content">
              <div class="planHeader_price">
                <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.visiblePrice}}.<sup>{{ classic.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ classic.plansPrice.visiblePrice}},<sup>{{ classic.plansPrice.visiblePriceCents }}</sup>{{ classic.plansPrice.currencySign }}
                    </template>
                  </span>
              </div>
              <h1>
                <span v-langErr>
                  {{ classic.plansInfoHeader.titlePremium }}
                </span>
                <span class="title" v-langErr>
                  {{ classic.plansInfoHeader.titleType }}
                </span>
              </h1>
            </div>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_classic_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ classic.plansInfoBody.headlinePeriod }}
          </p>

          <i class="continueBtn fa fa-chevron-right"></i>

        </div>
        <div class="promoWrapper" v-if="classic.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ classic.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>
      <div
        class="simplePlanWrapper most"
        @click="
        selectPlan(comfort.id)
        "
        :style="[ABVariant == 2 ? {'order':1, 'margin-top':'0px'}:{'order':3}]"
      >
        <div class="planHeader dark">
          <svg class="flyout" width="144" height="50" viewBox="0 0 144 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.000286399 0L0 50H104.416C106.206 50 107.5 49.5 108.934 47.9424L123.483 32.0576C124.5 31 126.21 30 128 30H144V0H0.000286399Z" fill="currentColor"/>
            </svg>
            <div class="planHeader_content">
              <div class="planHeader_price">
                <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.visiblePrice}}.<sup>{{ comfort.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ comfort.plansPrice.visiblePrice}},<sup>{{ comfort.plansPrice.visiblePriceCents }}</sup>{{ comfort.plansPrice.currencySign }}
                    </template>
                  </span>
              </div>
              <h1>
                <span v-langErr>
                  {{ comfort.plansInfoHeader.titlePremium }}
                </span>
                <span class="title" v-langErr>
                  {{ comfort.plansInfoHeader.titleType }}
                </span>
              </h1>
            </div>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_comfort_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ comfort.plansInfoBody.headlinePeriod }}
          </p>
          
          <i class="continueBtn fa fa-chevron-right"></i>
        </div>
        <div class="mostChosen">
          {{ $t($i18n.locale + '.keys.premiumPlans.mostChosenPlan', {
            productName: $store.getters.productName
          }) }}
        </div>
        <div class="promoWrapper" v-if="comfort.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ comfort.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>  
    </div>
    <div class="benefitsWrapper">
      <h3>{{ $t($i18n.locale).keys.premiumPlans.processPayments.premiumMembership }}</h3>
      <div class="benefitsList">
        <div class="benefitItem" v-for="(benefit, index) in benefits" :key="index">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <p class="benefitsText">
            {{ benefit }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "MobilePlans_variant1",
  props: [
    "comfort",
    "classic",
    "light",
    "selectedLanguage"
  ],
  data() {
    return {
      domain: domain,
      benefits: [
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedReading'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages'),
      ]
    };
  },
  computed: {
    ABVariant: function(){
      return 2;
    },
  },

  mounted() {

  },

  created() {


  },
  methods: {
    selectPlan(planId, priceId){
      console.log('selectPlan', planId, priceId)


      //this.$store.dispatch('setPlan', planId),
      //nextTab(priceId)
      this.$emit('selectPlan', planId);

    }
  },
};
</script>

<style lang="scss">

</style>