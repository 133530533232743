import { render, staticRenderFns } from "./MatchesFilters.vue?vue&type=template&id=03cd02ab&scoped=true"
import script from "./MatchesFilters.vue?vue&type=script&lang=js"
export * from "./MatchesFilters.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03cd02ab",
  null
  
)

export default component.exports