var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{admin: _vm.$store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editingMode: _vm.$store.getters.editable},attrs:{"id":"membershipMisc"}},[_c('div',{staticClass:"profilePhotoWrapper"},[(_vm.MemberData.profileCard.profileBackground)?_c('img',{staticClass:"profileBackground",attrs:{"src":_vm.profileBackgroundURL}}):_vm._e(),_c('div',{staticClass:"container-lg photoContainer"},[_c('div',{staticClass:"profileWrapper"},[_c('div',{staticClass:"photoWrapper"},[(_vm.profilePicture)?_c('div',{staticClass:"profilePhoto",style:({
              backgroundImage: 'url(' + _vm.profilePicture + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            })},[(!_vm.$store.getters.userRole.includes('ROLE_PREMIUM') && !_vm.MemberData.profileCard.picture_unblurred)?_c('router-link',{attrs:{"to":"/premium-plans"}},[_c('div',{staticClass:"viewButton"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.memberProfile.memberGallery.viewButton'))+" ")])]):_vm._e()],1):_vm._e(),(!_vm.profilePicture && (parseInt(_vm.profileGender) == 1))?_c('div',{staticClass:"profilePhoto",style:({
              backgroundImage:
                'url(' + require('../../../assets/images/silhouette-man.svg')
            })}):_vm._e(),(!_vm.profilePicture && (parseInt(_vm.profileGender) == 2))?_c('div',{staticClass:"profilePhoto",style:({
              backgroundImage:
                'url(' + require('../../../assets/images/silhouette-woman.svg')
            })}):_vm._e(),(!_vm.profilePicture && _vm.requestPhoto)?_c('div',{staticClass:"photoRequestButton",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendPhotoRequest.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton'))+" ")]):_vm._e(),(parseInt(_vm.MemberData.profileCard.entertainment) == 1 && _vm.showHearts)?_c('i',{staticClass:"fa fa-heart"}):_vm._e()]),_c('div',{staticClass:"userInfoWrapper"},[_c('div',{staticClass:"userInfo"},[(this.MemberData.profileCard.name)?_c('h1',[_vm._v(" "+_vm._s(this.MemberData.profileCard.name)+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.getUserAge(this.MemberData.profileCard.age))+", "+_vm._s(this.MemberData.profileCard.location)+" ")])])])]),(_vm.MemberData.profileCard.expires && !_vm.$store.getters.userRole.includes('ROLE_PREMIUM'))?_c('Countdown',{attrs:{"end-date":new Date(_vm.MemberData.profileCard.expires*1000),"timertype":'timer_box'},on:{"timer-ended":function($event){return _vm.countdownEnded()}}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }