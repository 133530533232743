<template>
  <div id="userGallery">
    <Spinner v-if="isLoading" />
  <div id="galleryUpload">
    <div class="sectionTitleWrapper">
      <h1
        class="sectionTitle"
        v-langErr
        :contenteditable="editable"
        @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.title = $event.target.innerText"
      >{{$t($i18n.locale).keys.currentUserProfile.userGallery.title}}</h1>
      <img
        v-if="editable"
        :style="{marginLeft : '20px'}"
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
        alt="Translation icon"
      />
    </div>

    <div class="galleryWrapper" :key="galleryKey">
      <div class="buttonsWrapper" v-if="imageData.length > 0">
      <button
        class="btnImages"
        @click.stop.prevent="sendImages()"
      >{{$t($i18n.locale).keys.currentUserProfile.userGallery.saveImages}}</button>
      <input
        :style="{marginLeft : '20px'}"
        v-if="editable"
        v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.saveImages"
        type="text"
      />
    </div>
      <div class="elementsWrapper">
        <div class="addPhoto galleryItem">
          <label
            class="uploadNew"
            :for="idUpload"
            v-cloak
            @drop.prevent="uploadImage($event);"
            @dragover.prevent
          >
            <!-- <span class="icon icon-uploadNew"></span> -->
            <span class="icon icon-dragPhotos"></span>
            <p
              class="userGalleryUploadNew"
            >
              {{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadNew}}
              <span class="dragInfo">
                <br>-<br>
                {{$t($i18n.locale).keys.currentUserProfile.userGallery.dragPhotosHere}}
              </span>
            </p>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadNew"
              type="text"
            />
          </label>
          <div style="display: none" class="file-upload-form">
            <input type="file" :id="idUpload" @change="uploadImage($event)" accept="image/png, image/jpg, image/jpeg" />
          </div>
          <!-- <b-modal id="crop-image" hide-footer>
            <clipper-basic ref="clipper" :src="imageToCrop" :ratio="1" :touch-create="true"></clipper-basic>
            <div class="buttonWrapper">
              <button
                class="btnImage"
                @click="cropImage()"
              >{{$t($i18n.locale).keys.generic.cropImage}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.cropImage"
                type="text"
              />
            </div>
          </b-modal> -->
          <b-modal id="failed-upload" hide-footer>
            <template v-slot:modal-title>
              {{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title}}
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.title"
                type="text"
              />
            </template>
            <div class="d-block">
              <img
                v-if="editable"
                :style="{marginLeft : '20px'}"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
              <h1
                class="message1"
                v-langErr
                :contenteditable="editable"
                @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message1}}</h1>
              <h1
                class="message2"
                v-langErr
                :contenteditable="editable"
                @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.message2}}</h1>
              <div class="btnWrapper">
                <button
                  @click="$bvModal.hide('failed-upload')"
                >{{$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing}}</button>
                <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.uploadingErrors.btnTryAgaing"
                type="text"
              />
              </div>
            </div>
          </b-modal>
          <!--
          <div class="takeNew" v-b-modal.modal-imageUpload>
            <span class="icon icon-takeNew"></span>
            <p
              class="cameraTakeNew"
            >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.takeNew}}</p>
          </div>
          <input
            :style="{marginLeft : '20px'}"
            v-if="editable"
            v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.takeNew"
            type="text"
          />
          -->
          <b-modal id="modal-imageUpload" hide-footer @hidden="resetImage()" @shown="onStart()">
            <template
              v-slot:modal-title
            >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.takeApicture}}</template>
            <code v-if="device" class="d-flex justify-content-center">{{ device.label }}</code>
            <div class="webcamWrapper">
              <vue-web-cam
                ref="webcam"
                class="webcam"
                :device-id="deviceId"
                width="100%"
                height="240"
                @cameras="onCameras"
                @error="onError"
                @camera-change="onCameraChange"
              />
              <!-- @started="onStarted" -->
              <!-- @stopped="onStopped" -->
              <!-- <figure class="figure">
                <img :src="croppedImage" class="img-responsive" />
              </figure>-->
            </div>
            <div class="chooseDeviceWrapper">
              <select class="chooseDevice" v-model="camera">
                <option>{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.selectDevice}}</option>
                <option
                  v-for="(device, index) in devices"
                  :key="device.deviceId"
                  :value="device.deviceId"
                >{{ device.label != '' ? device.label : 'Camera' + (index+1) }}</option>
              </select>
            </div>
            <div class="buttonsWrapper">
              <button
                class="btnCamera"
                @click="onCapture"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.capturePhoto}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.capturePhoto"
                type="text"
              />
              <!-- <button
                class="btnCamera"
                @click="removeCapturedPhoto"
                :disabled="!croppedImage"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.removePhoto}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.removePhoto"
                type="text"
              />
              <button
                class="btnCamera"
                @click="onStop"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.stopCamera}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.stopCamera"
                type="text"
              />
              <button
                class="btnCamera"
                @click="onStart"
              >{{$t($i18n.locale).keys.currentUserProfile.userGallery.camera.startCamera}}</button>
              <input
                :style="{marginLeft : '20px'}"
                v-if="editable"
                v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.camera.startCamera"
                type="text"
              />-->
            </div>
          </b-modal>
          <!-- <div class="importFromFacebook" @click="getFacebookPhotos()">
            <span class="icon icon-facebook"></span>
            <p class="userGalleryImportFromFacebook">{{$t($i18n.locale).keys.currentUserProfile.userGallery.importFromFacebook}}</p>
            <input
              :style="{marginLeft : '20px'}"
              v-if="editable"
              v-model="$t($i18n.locale).keys.currentUserProfile.userGallery.importFromFacebook"
              type="text"
            />
          </div>-->
        </div>
        <!--
        <div
          class="dragPhotos"
          v-cloak
          @drop.prevent="uploadImage($event);"
          @dragover.prevent
        >
          <span class="icon icon-dragPhotos"></span>
          <img
            v-if="editable"
            :style="{marginLeft : 'auto', display: 'block', marginRight: '20px'}"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
          <p
            class="userGalleryDragPhotosHere"
            v-langErr
            :contenteditable="editable"
            @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.dragPhotosHere = $event.target.innerText"
          >{{$t($i18n.locale).keys.currentUserProfile.userGallery.dragPhotosHere}}</p>
        </div>
        -->
        <div class="galleryItem imageWrapper unsaved uploaded" v-for="(image, index) in imageData" :key="'images' + index">

          <div class="wrapper">
            <img class="imageToShow" :src="image" />
            <button @click="removeFile(index)">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'close-cross-transparent.svg')" alt="Delete icon" />
            </button>
          </div>
        </div>
        <div class="galleryItem imageWrapper" v-for="(image, index) in fetchedImages" :key="componentFetchedKey + index">
          <div class="wrapper">
            <img :src="image.public_path" class="imageToShow" :class="{mainImage: (image.profile_picture && image.approved), inReview: !image.approved}" @click="chooseMainImage(image.fileId)">
            <button @click="removeFileFromBackend(image.fileId)">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'close-cross-transparent.svg')" alt="Delete icon" />
            </button>
          </div>
          <div class="imageOverlay" v-if="!image.approved">
            <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'sandclock-icon.svg')" alt="In Review icon">
            <div class="inReview"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.inReview = $event.target.innerText"
            >{{$t($i18n.locale).keys.currentUserProfile.userGallery.inReview}}</div>
            <div class="inReview2"
              v-langErr
              :contenteditable="editable"
              @blur="$t($i18n.locale).keys.currentUserProfile.userGallery.inReview2 = $event.target.innerText"
            >{{$t($i18n.locale).keys.currentUserProfile.userGallery.inReview2}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { forEach } from "lodash";
import { WebCam } from "vue-web-cam";
// import { clipperBasic } from "vuejs-clipper";
// import FbAllPhotos from "facebook-javascript-all-photos";
// import { base64StringToBlob } from 'blob-util';

export default {
  name: "UserGallery",
  components: {
    "vue-web-cam": WebCam,
    // clipperBasic
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      idUpload: "imageUpload",
      imageData: [],
      camera: null,
      deviceId: null,
      devices: [],
      capturedImage: null,
      fetchedImages: [],
      imageToCrop: "",
      croppedImage: "",
      webcamUpload: false,
      bufferData: [],
      componentFetchedKey: 0,
      deletionId: 0,
      galleryKey: 0,
      uploadActive: false,
      isLoading: true,
    };
  },
  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  mounted() {
    this.$store.dispatch('fetchImages').then( () => {
      this.fetchedImages = this.$store.getters.getImages;
      this.isLoading = false;
      //console.log(this.$store.getters.getImages, 'images')
    })
    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
    // this.$store.dispatch("fetchImages").then(res => {
    //   //this.$store.dispatch("hideLoader");
    //   if (res == "error") {
    //     //this.$store.dispatch("setErrorVisibility", true);
    //   } else {
    //     this.$store.dispatch("showContent");
    //     // this.fetchedImages = this.$store.getters.getImages.photos;
    //   }
    // });
  },

  methods: {
    /*
    getFacebookPhotos() {
      const fbAllPhotos = new FbAllPhotos();
      fbAllPhotos
        .getAlbums(5)
        .then(fullObj => {
          console.log(fullObj);
        })
        .catch(errorMsg => {
          if (errorMsg === "fbError") {
            console.log(fbAllPhotos.errorObj.message);
          } else if (errorMsg === "noAlbums") {
            console.log("No albums");
          }
        });
    },
    */
    // cropImage() {
    //   const canvas = this.$refs.clipper.clip({ wPixel: 300 });
    //   const img = canvas.toDataURL();
    //   if (this.webcamUpload) {
    //     this.croppedImage = img;
    //   }
    //   console.log(img);
    //   this.imageData.push(img);
    //   // this.$refs.webcam.stop();
    //   this.$bvModal.hide("crop-image");
    //   this.$bvModal.hide("modal-imageUpload");
    // },
    // files(file){
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       resolve(reader.result)
    //     };

    //     reader.readAsArrayBuffer(file)
    //   })
    // },
    uploadImage(event) {
      let files = event.target.files || event.dataTransfer.files;

      // if (this.imageData.length > 2) {
      //   return false;
      // }
      // console.log(this.imageData, 'img data 1')
      var readers = [];
      let formData = new FormData();
      // console.log(files, 'files');
      if (files) {
        forEach(files, async (value, index) => {
          readers[index] = new FileReader();
          formData.append("file" + index, value);
          const longFormat = value.type;
          const format = longFormat.split("/")[1];
          const formatObj = {
            format: "." + format
          };
          try {
          await new Promise((resolve, reject) => {
            
            this.$store.dispatch("sendImages", formatObj).then((response) => {

              if (this.$store.getters.getErrors == null) {

                readers[index].onload = e => {

                  const img = e.target.result;
                  this.imageData.push(img);

                  this.webcamUpload = false;
                  document.getElementById(this.idUpload).value = "";
                  this.$bvModal.hide("modal-imageUpload");

                  resolve(img);
                };
                readers[index].readAsDataURL(value);
              } else {
                this.$bvModal.show("failed-upload");
                reject(response.data.errors.message);
              }
            });
          })
        }catch(error){
          console.log(error)
        }

        });
      }
    },
    removeFile(index) {
      this.imageData.splice(index, 1);
      document.getElementById(this.idUpload).value = "";
    },
    removeFileFromBackend(id){
       console.log('aaaaa')
      this.$store.dispatch('deleteImage', id).then(() => {
        this.fetchedImages.forEach((image, index) => {
          image.fileId == id ? this.deletionId = index : ''
        })
        this.fetchedImages.splice(this.deletionId, 1);
        this.componentFetchedKey++;
      })
    },
    chooseMainImage(id){
      this.$store.dispatch("chooseMainImage", id).then(() => {
        this.$store.dispatch('fetchImages').then( () => {
          this.fetchedImages = this.$store.getters.getImages;
          this.componentFetchedKey++;
        })
      })
    },
    sendImages() {
      if(this.imageData.length == 0 ) return true;
      this.isLoading = true;

      this.bufferData = this.imageData.map(image => {
        const imageSplit = image.split(";base64,").pop();
        const buffer = Buffer.from(imageSplit, "base64");
        const options = {
          body: buffer
        };
        const returnedBuffer = {
          buffer: buffer,
          options: options
        };
        return returnedBuffer;
      });
      
      this.$store.dispatch("sendImages2", this.bufferData).then(() => {
        this.isLoading = false;
        this.$router.go();
      }, error => {
        console.log('UPLOAD ERROR');
        console.log(error);
        this.$router.go();
      });
     
    },
    onCapture() {
      this.capturedImage = this.$refs.webcam.capture();
      // this.imageToCrop = this.capturedImage;
      const format = this.capturedImage.split('/')[1].split(';')[0];


      const formatObj = {
        format: "." + format
      };
      this.$store.dispatch("sendImages", formatObj).then(() => {
        if (this.$store.getters.getErrors == null) {
              this.webcamUpload = true;
              this.imageData.push(this.capturedImage);
              this.$bvModal.hide("modal-imageUpload");

              // this.$bvModal.show("crop-image");
            } else {
              this.$bvModal.show("failed-upload");
            }
      })


      // if (this.imageData.length < 3) {
      //   this.imageData.push(this.capturedImage);
      // }
    },
    // onStarted(stream) {
    //   console.log("On Started Event", stream);
    // },
    // onStopped(stream) {
    //   console.log("On Stopped Event", stream);
    // },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      // console.log("On Camera Change Event", deviceId);
    },
    removeCapturedPhoto() {
      this.capturedImage = null;
      this.croppedImage = null;
      this.imageData.pop();
    },
    resetImage() {
      this.capturedImage = null;
      this.croppedImage = null;
    }
  }
};
</script>

<style lang="scss">
</style>
