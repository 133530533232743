export default {
  conversations: [
  ],
  messages: [],
  info: {firstName: "test", birthday: "1933-01-03", profession: "test", location: "test", id: -1},
  chatNewMessages: 0,
  chatActionsCount: 0,
  icebreaker: [],

};
