import axios from 'axios';
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {

    async checkCompleted({commit,}) {

        try {
            const resp = await axios.get(BASE_URL + `/api/personality/check_completed`, handlerEnabled);
            //localStorage.setItem("peronalityCompleted",resp.data.data.completed)
            commit("set_completed", resp.data.data.completed);
            commit('set_personalityTest', resp.data.data.personalityTest);
            commit('set_variant', resp.data.data.variant);
            commit('set_vitalQuestionsMissing', resp.data.data.vitalQuestionsMissing);
            commit('set_questionnaireQuestionsMissing', resp.data.data.questionnaireQuestionsMissing);
            return  resp.data.data
        } catch (error) {
            return 'error';
        }
    },


    lastCompleted({
        commit
    }, lastNumber) {
        return new Promise((resolve) => {
            commit('lastCompleted', lastNumber);
            resolve()
        })
    },
    async skippedQuestions({
        commit
    }) {

        const URL = `${BASE_URL}/api/personality/check_completed`;

        try {
            await axios
                .get(URL,handlerEnabled)
                .then(result => {
                    if (result.data.data.completed == true) {
                        if (result.data.data.skippedQuestions.length > 0) {
                            this.skippedArray = result.data.data.skippedQuestions;
                        } else {
                            this.skippedArray = [];
                        }
                    }
                    commit('skippedQuestions', this.skippedArray);
                });
        } catch (error) {
            return 'error';
        }

    },
    /*
    async checkAnsweredQuestionaire({
        commit
    }) {

        const URL = `${BASE_URL}/api/personality/check_completed`;


        try {
            await axios
                .get(URL, handlerEnabled)
                .then(result => {
                    console.log(result.data.data, 'check completed')
                    if(result.data.data.steps_finished > 0){
                        commit('hideFirstMessage');
                    }
                    if (result.data.data.completed == true) {
                        commit('questionnaireComplete');
                        commit('additionalQuestionsAnswered', result.data.data.additionalQuestionsShown);
                    }
                });
        } catch (error) {
            return 'error';
        }

    },
    */
   checkAnsweredQuestionaire({
    commit
    }) {

        const URL = `${BASE_URL}/api/personality/check_completed`;


        try {
             return new Promise((resolve) => {
                axios
                .get(URL, handlerEnabled)
                .then(result => {
                    console.log(result.data.data, 'check completed')
                    if(result.data.data.steps_finished > 0){
                        commit('hideFirstMessage');
                    }
                    //No additional questions required
                    result.data.data.additionalQuestionsShown = true;
                    if (result.data.data.completed == true) {
                        commit('questionnaireComplete');
                        commit('additionalQuestionsAnswered', result.data.data.additionalQuestionsShown);
                    }
                    resolve(result.data.data);
                });
            });
        } catch (error) {
            return 'error';
        }

    },
    async generateMatches(){
        const URL = `${BASE_URL}/api/personality/generate_matches`;
        try {
            await axios.get(URL, handlerEnabled);
        } catch (error) {
            console.log('generateMatches ERROR', error);
        }
    },
    loadAllSkippedQuestions({
        commit
    }) {
        commit('removeSkippedQuestion');
    },
    skippedMode({
        commit
    }) {
        commit('setSkippedMode');
    },
    async fetchAdditionalQuestions({
        commit
    }) {

        try {
            const response = await axios.get(BASE_URL + '/api/profile/random/questions/', handlerEnabled);
            let questions = response.data.data;
            questions.filter((question) => {
                question.id < 119
            });
            commit('setAdditionalQuestions', questions)
        } catch (error) {
            return 'error';
        }

    },
    async updateAdditionalQuestion({
        commit
    }, data) {

        try {
            await axios.post(BASE_URL + '/api/personality/answer', data, handlerEnabled);
            commit('answerAdditionalQuestion');

        } catch (error) {
            return 'error';
        }
        // commit('removeAdditionalQuestion', data);
    },
    loadNextAdditionalQuestion({
        commit
    }) {
        commit('removeAdditionalQuestion');
    },
    async fetchRandomNames({ commit }) {
        try {
            const response = await axios.get(BASE_URL +'/api/personality/names', handlerEnabled);
            const randomNames = response.data.data.names;

            commit('setRandomNames', randomNames);

        } catch (error) {
            return 'error';
        }
    },

    async checkFinished({ commit }) {

        try {
            const resp = await axios.get(BASE_URL + `/api/personality/check_finished`, handlerEnabled);
            //localStorage.setItem("peronalityCompleted",resp.data.data.completed)
            console.log('checkFinished', resp.data.data);
            commit("set_finished", resp.data.data.completed);
            return  resp.data.data
        } catch (error) {
            return 'error';
        }
    },
};