export default {
    set_isMobile(state, payload) {
        state.isMobile = payload;
    },
    set_isDEV(state, payload) {
        state.isDEV = payload;
    },
    set_mainLogos(state, payload) {
        console.log('set_mainLogos', state, payload);
        state.mainLogos = payload;
    },
    set_productName(state, payload) {
        console.log('set_productName', state, payload);
        state.productName = payload;
    },
    set_supportEmail(state, payload){
        state.supportEmail = payload;
    },
    set_privacyEmail(state, payload){
        state.privacyEmail = payload;
    },
    set_alerts(state, payload){
        state.alerts = payload;
    },
    set_assetsPath(state, payload){
        state.assetsPath = payload;
    },
    set_welcomeMessageSender(state, payload){
        state.welcomeMessageSender = payload;
    },
    set_siteId(state, payload){
        state.siteId = payload;
    },
    set_globalTermsAndPolicies(state,payload) {
        state.globalTermsAndPolicies = payload;
    },
    set_productURL(state, payload) {
        state.productURL = payload;
    },
    set_user(state, payload) {
        state.user = payload;
        if(state.user.roles.includes("ROLE_PREMIUM")){
            state.user.isPremium = true;
        }else{
            state.user.isPremium = false;
        }
    }
}