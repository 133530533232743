<template>
  <div class="chatMessage typeIcebreaker " :class="{notPremium: !$store.getters.userRole.includes('ROLE_PREMIUM')}">
    <div class="msgBox">

      <p class="messageText"
        :class="{ blurred: messageData.blurred }"
      >
        <img
          class="iconIBQ"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'icebreaker-green-button.svg')"
          alt="icon"
        /><br>
        {{ messageData.message }}
          <span class="premiumLinkWrapper" v-if="messageData.blurred">
            <router-link to="/premium-plans" class="premiumLink" >
              {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
            </router-link>
          </span>
        </p>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>

</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeIcebreaker",
  props: ["messageData"],
  data() {
    return {
    };
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
  },
};
</script>

<style lang="scss" scoped>



</style>
