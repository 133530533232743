<template>
  <div id="Shift4Payment">
    <form
      id="payment-form"
      class="wpwl-form wpwl-form-card"
      @submit.prevent="submitSecurionPay"
    >
      <span class="payment-errors">{{ paymentErrors }}</span>

      <div class="form-group wpwl-group">

          <input
            class="wpwl-control"
            type="tel"
            size="20"
            name="cardnumber"
            data-securionpay="number"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.cardNumber')"
            v-cardformat:formatCardNumber
            v-model="cardNumber"
            ref="_cardNumber"

          />
          <div v-if="cardErrors.cardNumber" class="error">
          <small>{{ cardErrors.cardNumber }}</small>
        </div>
      </div>

      <div class="form-group wpwl-group">

        <input
          type="tel"
          v-model="shift4Data.expireDate"
          class="wpwl-control"
          name="mm/yy"
          :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireCombined')"
          v-cardformat:formatCardExpiry
          ref="_cardExpire"

        />
        <div v-if="cardErrors.cardExpiry" class="error">
          <small>{{ cardErrors.cardExpiry }}</small>
        </div>
        <div class="form-expiration-group" style="display:none;">
          <input
            class="wpwl-control expMonth"
            type="text"
            size="2"
            data-securionpay="expMonth"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireMM')"
            maxlength="2"
            v-model="shift4Data.expireMonth"
          />
          <span class="form-spacer"> / </span>
          <input
            class="wpwl-control expYear"
            type="text"
            size="4"
            data-securionpay="expYear"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireYYYY')"
            maxlength="4"
            v-model="shift4Data.expireYear"
          />
        </div>
      </div>
      <div class="form-group wpwl-group">

          <input
            class="wpwl-control"
            type="tel"
            size="4"
            name="cid"
            data-securionpay="cvc"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.CID')"
            v-cardformat:formatCardCVC
            v-model="cardCvc"

          />
          <div v-if="cardErrors.cardCvc" class="error">
          <small>{{ cardErrors.cardCvc }}</small>
        </div>
      </div>

      <button class="wpwl-button" type="submit" :disabled="formLocked">
        {{paymentButtonText}}
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </form>    
  </div>
</template>
<script>
//Shift4 (Securion) Payment Integration

import Vue from "vue";

import { mapGetters } from "vuex";
import Shift4_helpers from "@/helpers/payments/Shift4_functions";

export default {
  name: "Shift4Payment",
    props: [
      'paymentButtonText'
    ],
    data() {
      return {
        shift4Data: {
          expireDate: '',
          expireMonth: '',
          expireYear: '',
        },
        securionFallback: false,
        retrySecurion: false,
        SECURION_PK_illicitoasis: process.env.VUE_APP_SECURION_PK_illicitoasis,
        SECURION_PK_casualxdates: process.env.VUE_APP_SECURION_PK_casualxdates,
        SECURION_PK_affaireclub: process.env.VUE_APP_SECURION_PK_affaireclub,
        SECURION_PK_fremdgehboerse: process.env.VUE_APP_SECURION_PK_fremdgehboerse,
        SECURION_PK_affaere1: process.env.VUE_APP_SECURION_PK_affaere1,
        $cardFormat: Vue.prototype.$cardFormat,
        cardNumber: null,
        cardCvc:null,
        cardErrors: {},
        formLocked: false,
        paymentErrors:null,
        countPaymentAttempt: 1,
      }
    },
    watch: {
      'shift4Data.expireDate': function(val){
        setTimeout(() => {
          if(val != this.$refs._cardExpire.value) this.shift4Data.expireDate = this.$refs._cardExpire.value;
  
          let plainValue = val.replace(/\s/g, '');
          let plainValueSplit = plainValue.split('/');
          if(plainValueSplit.length == 2){
            if(plainValueSplit[0].length == 1) this.shift4Data.expireDate = '0'+val;
          }
          if(plainValue.length > 5) this.shift4Data.expireDate = val.slice(0, -1);
          if(plainValue.length == 5) {
            this.shift4Data.expireMonth = plainValue.slice(0,2);
            this.shift4Data.expireYear = '20' + plainValue.slice(3,5);
          }else{
            this.shift4Data.expireMonth = '';
            this.shift4Data.expireYear = '';
          }
         }, 1);
      },
      getActivePlan: function(){
        this.initSecurionPay();
      }
    },
    computed: {
      ...mapGetters(["user","getActivePlan"]),
      showCardHolder: function(){
      return true;
      },
      SECURION_PK_Credorax: function(){

        if(typeof process.env.VUE_APP_SECURION_PUBLIC_KEY != 'undefined' && process.env.VUE_APP_SECURION_PUBLIC_KEY){
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: host, payment: 'sec'});
          return process.env.VUE_APP_SECURION_PUBLIC_KEY
        }else{
          //Set descriptor text
          this.$store.dispatch("setDescriptor", { brand: 'credorax', payment: 'sec'});
          return process.env.VUE_APP_SECURION_PK_UK
        }

      },
      securionPrimaryKey: function(){

        //Primary MID coming from backend

        if(this.getActivePlan.plansPrice.tariffMap){
          //Set descriptor text for primary key
          this.$store.dispatch("setDescriptor", 
          { 
            brand: 'server', 
            payment: 'sec', 
            text: this.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary.descriptor
          });
          //Check if fallback key is set 
          if(this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback){
            //Set descriptor text for fallback key
            this.$store.dispatch("setDescriptor", 
            { 
              brand: 'server', 
              payment: 'sec', 
              text: this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.descriptor,
              type: 'fallback'
            });
          }

          return this.getActivePlan.plansPrice.tariffKeys.SecurionPay.primary.key;
        }


        //Use different MID for all CH domains instead of Magnius
        /*
        if(this.getActivePlan.plansPrice.tariffIds.Magnius && domain == 'ch') {
          return this.SECURION_PK_fremdgehboerse;
        }
        */

        /*
        if(this.getActivePlan.plansPrice.tariffKeys.SecurionPay &&  host == 'affaerentreff' && domain == 'ch')
          return this.getActivePlan.plansPrice.tariffKeys.SecurionPay;
        */
       
       if(domain == 'ch'){
        //Set descriptor text
        this.$store.dispatch("setDescriptor", { brand: 'affaere1', payment: 'sec'});
        return this.SECURION_PK_affaere1;
       } 
       
       if(host == 'casualxdates'){
        //Set descriptor text
        this.$store.dispatch("setDescriptor", { brand: 'casualxdates', payment: 'sec'});
        return this.SECURION_PK_casualxdates;
       } 

        if(domain == 'com'){
          if( host == 'illicitoasis'){
            //Set descriptor text
            this.$store.dispatch("setDescriptor", { brand: 'illicitoasis', payment: 'sec'});
            return this.SECURION_PK_illicitoasis;
          } 
          else {
            return this.SECURION_PK_Credorax;
          }
        }else {
          return this.SECURION_PK_Credorax;
        }

      },
      securionFallbackKey: function(){

        if(this.getActivePlan.plansPrice.tariffMap){
          //Secondary MID coming from backend
          if(this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback){
            console.log('securionSecondaryKey', this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.key);
            return this.getActivePlan.plansPrice.tariffKeys.SecurionPay.fallback.key;
          }else{
            return '';
          }
        } else {
          return '';
        }

      },
    },
    created: function () {
      console.log('Shift4 Payment Integration loaded')
      console.log('Shift4 Payment', this.getActivePlan, this.getActivePlan.plansPrice.tariffKeys.SecurionPay)

      if(typeof Securionpay == 'undefined') this.initSecurionPay()
      else {
        //Set new Public Key
        console.log('Set new Public Key', this.securionPrimaryKey)
        SecurionPay.setPublicKey(this.securionPrimaryKey);
      } 
      
    },
    methods: {
      // INITIALIZE PAYMENT SYSTEM
      initSecurionPay() {
        
       //SEND TO HELPER FUNCTION 
       Shift4_helpers._initialze({}, this)

      },
      // FORM DATA VALIDATION
      validateSec() {
       
        //SEND TO HELPER FUNCTION 
       Shift4_helpers._validation({}, this)

      },
      //SEND PAYMENT DATA
      submitSecurionPay: function() {

        var form = document.querySelector('#payment-form');
        
        //SEND TO HELPER FUNCTION 
        Shift4_helpers._submitData({form: form}, this)
  
        //Prevent default form action 
        return false;
  
      },
      submitSecurionPayMobile: function() {
        //SEND TO HELPER FUNCTION 
        Shift4_helpers._submitData({ isMobile: true }, this)
      },
      
    },
  }

</script>