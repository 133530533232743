export default {
  set_meetings(state, payload) {
    state.meetings = payload;
  },
  set_totalMeetings(state, payload) {
    state.totalMeetings = payload;
  },
  addMeetingsCount: (state) => {
    state.meetingsActionsCount++;
  }
};
