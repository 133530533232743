
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);

const BASE_URL = process.env.VUE_APP_URL;


const Magnius_helpers = {

   _initialize(data, context){
      console.log('initMagnius');

      // Set iFrameURL to Live or DEV version
      var iFrameUrl = 'https://api.magnius.com/iframe/magnius/iframe.min.js';
      if(context.$store.getters.isDEV) iFrameUrl = 'https://payground-api.magnius.com/iframe/magnius/iframe.min.js';

      //Setup iFrame with Magnius Config
      Vue.loadScript(iFrameUrl).then((resp)=>{
        
        console.log('initMagnius script loaded', resp);
        var inputColor = window.getComputedStyle(document.querySelector('#magniusPaymentWrapper input')).getPropertyValue('color');
        const iframeConfig = {
            organisation: context.magniusOrgId,
            origin: location.origin,
            placeholder: context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.cardNumber'),
            height: "55px",
            debug: false,
            styles: {
                base: `width: 100%; box-sizing: border-box; height: 55px; padding: 0; font-size: 1.2em; color:${inputColor}; border: none;background: transparent;`,
                focus: "outline: 0;",
                error: "outline: 0;",
                placeholder: `font-size: 1em; color:rgb(117, 117, 117);`
            }
        };

        //Load iFrame
        context.magniusIframe = new CardFrame.Iframe('card-iframe', iframeConfig);
        context.magniusIframe.load();
        //Handle iFrame errors
        context.magniusIframe.on("error", function (data) { console.log("Error:", data) });
        
        //Hide loaders if present and show form
        $('.spinnerWrapper').hide();
        $('.PaymentCard').show();
      });
   },

   _validation(data, context){
      console.log('magniusValidate', data);
      //Reset error messages if present
      context.cardErrors = {};

      let valid = true;
      if (!context.$cardFormat.validateCardCVC(context.cardCvc)) {
        context.cardErrors.cardCvc = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardCVC');
        valid = false;
      }
      if  (
            !context.$cardFormat.validateCardExpiry(context.magniusData.expireDate)
            || context.magniusData.expireMonth == 0 || context.magniusData.expireYear == 0
          )
      {
        context.cardErrors.cardExpiry = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardExpiry');
        valid = false;
      }
      if(!data.is_valid){
        context.cardErrors.cardNumber = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardNumber');
        valid = false;
      }
      if(!context.magniusData.cardHolder && context.showCardHolder){
        context.cardErrors.cardHolder = context.$t(context.$i18n.locale +'.keys.premiumPlans.processPayments.errorCardHolder');
        valid = false;
      }
      return valid;
   }, 

   _tokenize(data, context){
      console.log('tokenizeMagnius')

      const onTokenzied = new Promise((resolve,reject) => {
        context.magniusIframe.on('tokenize', (data) => {
          console.log('tokenize data', data);
          if(data.token) context.magniusToken = data.token;
          resolve(true)
        });

        context.magniusIframe.on("validate", (data) => { 
            console.log("Validate:", data)
          if(!context.magniusValidate(data)) {
            console.log("Not Valid",  context)
            reject(false);
          }else {
            console.log("Valid",  context)
            context.magniusIframe.tokenize()
          }
        });

      });

      context.magniusIframe.validate();
      
      
      return onTokenzied;
   },

   async _submitData(data, context){

    if(context.magniusLocked) return false;
    context.magniusLocked = true;

    window.removeEventListener('message', context.magniusIframeListener);
    window.addEventListener('message', context.magniusIframeListener);

    var evt = data.evt;
    console.log('submitMagnius', evt)
    var form = evt.target;
    form.elements['submitButton'].disabled = true;

    await context.tokenizeMagnius().then(res => {
      console.log('tokenizeMagnius result', res);

      console.log('submitMagnius', context.magniusToken)

      var paymentData = {
        'cardToken': context.magniusToken,
        'tariffId': context.getActivePlan.plansPrice.tariffIds.Magnius,
        'cvv': parseInt(context.cardCvc),
        'expirationYear': parseInt(context.magniusData.expireYear),
        'expirationMonth': parseInt(context.magniusData.expireMonth),
        'cardHolder': context.magniusData.cardHolder,
      };
    
      if(context.isMobile){
        console.log('showSummaryMagnius send')
        context.$emit('showSummary', {data: paymentData, form: form});
        context.magniusReset();
      }else{
        Magnius_helpers._finalizePayment({paymentData: paymentData}, context)
      }

    }).catch(err => {
      console.log('tokenizeMagnius error', err);
      context.magniusLocked = false;
    })

   },

   _finalizePayment(data, context){

    if(typeof data.paymentData == 'undefined') return false;

    if(data.isSummary) $('#mobileSubmitBtn').prop('disabled', true);

    context.$axios.post(
      BASE_URL + `/api/payments/Magnius/subscribe/${context.getActivePlan.plansPrice.planId}`,
      data.paymentData
    ).then((res)=>{
      console.log('submitMagnius response', res)
      if(res.data.data.success){
        if(res.data.data.approval_url) {
          console.log('Setup magnius3DSframe', context.$refs.magnius3DSframe)
          var iframe3ds = context.$refs.magnius3DSframe;
          
          iframe3ds.src = res.data.data.approval_url;
          iframe3ds.style = 'display:block;'
        }else{
          if (res.data.data.success == true && res.data.data.token) {
            localStorage.setItem("token", res.data.data.token);
            if(res.data.data.detailsEntered == true){
              context.$router.push({ path: "/payments-success", query: { cid: context.$route.query.id } });
            } else if(res.data.data.detailsEntered == false) {
              context.$router.push({ path: "/payments-success-info", query: { cid: context.$route.query.id } });
            }
          } else {
              context.$router.push("/payments-error");
          }
        }
      }
    })
    .catch(err => {
      console.log('submitMagnius error', err);
      var errorMessage = '';
      if(err.response.data.data && err.response.data.data.error == "payment attempt not allowed"){

        //reset attempt counter
        context.countPaymentAttempt = 1;

        // Display error message
        errorMessage = context.$t(context.$i18n.locale + '.keys.premiumPlans.processPayments.errorMaxAttempts');
      }else if(err.response.data.data){
        errorMessage = err.response.data.data.error;
      }else{
        console.error(err);
      }

      if(errorMessage){
        context.paymentErrors = errorMessage;
        //$('#payment-form').find('.payment-errors').text(errorMessage);
      }

      if(!context.isMobile){
        //Reset Magnius Form 
        context.magniusReset();
      }

      return false;

    });

   },

   _iframeListener(data, context){

    var event = data.event

    if(event.data.function == "magnius3DSResponse"){

      var errorStats = ['FAILED', 'CANCELLED', 'ABANDONED', 'UNKNOWN'];
      var successStats = ['AUTHORIZED','SETTLEMENT_REQUESTED','SETTLEMENT_COMPLETED', 'PENDING', 'DECLINED'];

      if(errorStats.includes(event.data.status)){
        context.$router.push("/payments-error");
      }else if (successStats.includes(event.data.status)){
        var callbackURL = new URL(event.data.url)
        var transaction_id = callbackURL.searchParams.get('transaction_id');
        console.log('magnius3DSResponse SUCCESS', transaction_id);
        //SEND REQUEST TO API 
        var url = BASE_URL + '/api/payments/Magnius/check_completed';
        var trans_data = {
          'transactionId': transaction_id,
          'organisationId': context.magniusOrgId
        }
        context.$axios.post(url, trans_data).then( (resp) => {
          console.log('Magnius/check_status', resp)
          if (resp.data.data.success == true && resp.data.data.token) {
          localStorage.setItem("token", resp.data.data.token);
            if(resp.data.data.detailsEntered == true){
              context.$router.push({ path: "/payments-success", query: { cid: context.$route.query.id } });
            } else if(resp.data.data.detailsEntered == false) {
              context.$router.push({ path: "/payments-success-info", query: { cid: context.$route.query.id } });
            }
          } else {
              context.$router.push("/payments-error");
          }
        })
        .catch(err => {
          context.magniusReset();
          console.log('Magnius/check_status error', err);
          context.$router.push("/payments-error");
        })


      }else{
        context.$router.push("/payments-error");
      }
    }
   }
}

export default Magnius_helpers