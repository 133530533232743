<template>
  <div id="additionalQuestion">
    <div class="simpleHeader">
      <div class="container topnavWrapper">
        <div class="row">
          <div class="logoWrapper">
              <img :src="$store.getters.mainLogos.desktop" alt />
          </div>
          <!--
          <div class="questionMarkWrapper" v-if="desktopView">
            <div id="questionMark">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'QuestionMark.png" alt />
            </div>
          </div>
          -->
        </div>
      </div>
    </div>
    <div class="container mainWrapper" v-if="desktopView">
      <div class="row justify-content-center">
        <div class="questionBoxWrapper col-10" :key="componentKey">
        <h1 class="personalityAdditionalHeadline">
          {{ $t($i18n.locale + '.keys.questionnaire.additionalHeadline') }}
        </h1>
          <!--
          <div class="topWrapper">
            <div class="spinnerWrapper">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'spinner.svg')" alt="Loader" />
            </div>
            <div class="progressWrapper">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
              <div
                class="progressMessage"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.questionnaire.additionalQuestion.progressMessage =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.questionnaire.additionalQuestion
                    .progressMessage
                }}
              </div>
              <div class="rotatingTextWrapper">
                <div class="text-rotator">
                  <div class="slider">
                    <div class="mask">
                      <ul>
                        <li class="anim1">
                          <div class="quote">
                            {{ $store.getters.getRandomNames[0] }}
                            <br />{{ $store.getters.getRandomNames[1] }} <br />{{
                              $store.getters.getRandomNames[2]
                            }}
                            <br />{{ $store.getters.getRandomNames[3] }} <br />{{
                              $store.getters.getRandomNames[4]
                            }}
                          </div>
                        </li>
                        <li class="anim2">
                          <div class="quote">
                            {{ $store.getters.getRandomNames[5] }}
                            <br />{{ $store.getters.getRandomNames[6] }} <br />{{
                              $store.getters.getRandomNames[7]
                            }}
                          </div>
                        </li>
                        <li class="anim3">
                          <div class="quote">
                            {{ $store.getters.getRandomNames[8] }}
                            <br />{{ $store.getters.getRandomNames[9] }}
                          </div>
                        </li>
                        <li class="anim4">
                          <div class="quote">
                            {{ $store.getters.getRandomNames[0] }}
                            <br />{{ $store.getters.getRandomNames[1] }}
                          </div>
                        </li>
                        <li class="anim5">
                          <div class="quote">
                            {{ $store.getters.getRandomNames[2] }}
                            <br />{{ $store.getters.getRandomNames[3] }} <br />{{
                              $store.getters.getRandomNames[4]
                            }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
          <div class="questionWrapper">
            <h1 v-if="additionalQuestions" class="question">
              {{
                additionalQuestions[0].translations[
                  $store.getters.selectedLanguage
                ].question
              }}
            </h1>
            <textarea
              name="answer"
              id="answer"
              maxlength="512"
              :placeholder="
                $t($i18n.locale).keys.questionnaire.additionalQuestion.enterAnswer
              "
              v-model="answer"
            ></textarea>
          </div>
          <div class="questionBoxFooter">
            <div class="leftContainer">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
              <div class="charactersLeftMessage">
                {{ 512 - answer.length }}
                <span
                  v-langErr
                  :contenteditable="editable"
                  @blur="
                    $t($i18n.locale).keys.questionnaire.additionalQuestion =
                      $event.target.innerText
                  "
                >
                  {{
                    $t($i18n.locale).keys.questionnaire.additionalQuestion
                      .charactersLeftMessage
                  }}
                </span>
              </div>
              <div class="questionsAnsweredWrapper">
                <img
                  v-if="editable"
                  :style="{ marginLeft: '20px' }"
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                  alt="Translation icon"
                />
                <p class="questionsAnsweredMessage">
                  {{ distance }} {{ $t($i18n.locale).keys.generic.of }} 7
                  <span
                    v-langErr
                    :contenteditable="editable"
                    @blur="
                      $t(
                        $i18n.locale
                      ).keys.questionnaire.additionalQuestion.questionsAnsweredMessage =
                        $event.target.innerText
                    "
                  >
                    {{
                      $t($i18n.locale).keys.questionnaire.additionalQuestion
                        .questionsAnsweredMessage
                    }}
                  </span>
                </p>
                <div class="sliderWrapper">
                  <vue-slider
                    id="questionAnswered"
                    style="width: 60%"
                    v-model="distance"
                    :min="0"
                    :max="6"
                    :tooltip="'none'"
                    :marks="questionsMarks"
                    :adsorb="true"
                    :included="true"
                    :disabled="true"
                  ></vue-slider>
                </div>
              </div>
            </div>
            <div class="rightContainer">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
              <button
                :class="{ disabled: answer.length <= 0 }"
                class="btnSave"
                :disabled="answer.length <= 0"
                @click="
                  sendAdditionalAnswer(
                    additionalQuestions[0].id
                  )
                "
              >
                {{ $t($i18n.locale).keys.generic.save }} &
                {{ $t($i18n.locale).keys.generic.continue }}
              </button>
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.save"
                type="text"
              />
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.of"
                type="text"
              />
              <p class="continueMessage" @click="loadNextQuestion()">
                {{
                  $t($i18n.locale).keys.questionnaire.additionalQuestion
                    .continueMessage
                }}
                >>
              </p>
              <input
                v-if="editable"
                v-model="
                  $t($i18n.locale).keys.questionnaire.additionalQuestion
                    .continueMessage
                "
                type="text"
              />
            </div>
          </div>
          <div class="questionBoxBottomWrapper">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
              alt="Translation icon"
            />
            <!--
            <p
              class="calculationsMessage"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.questionnaire.additionalQuestion.calculationsMessage =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.questionnaire.additionalQuestion
                  .calculationsMessage
              }}
            </p>
            -->
            <router-link to="upload-image" class="continueMessageWrapper">
              <img
                v-if="editable"
                :style="{ marginLeft: '20px' }"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
                alt="Translation icon"
              />
              <p
                class="continueMessage"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.generic.continueBtn =
                    $event.target.innerText
                "
              >
                {{ $t($i18n.locale).keys.generic.continueBtn }} >>
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalQuestion",
  components: {},
  data() {
    return {
      componentKey: 0,
      answer: "",
      additionalQuestions: [],
      distance: "",
      desktopView: true,
      questionsMarks: {
        "0": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "1": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "2": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "3": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "4": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "5": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
        "6": {
          label: "",
          style: {
            width: "17px",
            height: "17px",
            borderRadius: "50%",
            display: "block",
            backgroundColor: "#C2C7D2",
            border: "1px solid #ffffff",
            transform: "translate(-5px, -1px)",
          },
          labelStyle: {
            color: "#000000",
          },
        },
      },
    };
  },

  created() {
    this.$store.dispatch("getEmailConfirmed").then(res =>{
      console.log('getEmailConfirmed', res);
      if(res){
        if(window.innerWidth <= 768) this.$router.push("/upload-image");
          this.$store.dispatch("generateMatches");
              this.$store.dispatch("fetchAdditionalQuestions").then((res) => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            this.additionalQuestions = this.$store.getters.getAdditionalQuestions;
            console.log(this.additionalQuestions);

            this.distance = this.additionalQuestions[0].id - 108;
            if (this.additionalQuestions[0].id >= 114) {
              this.$router.push("/upload-image");
            }
          }
        });
        this.$store.dispatch("fetchRandomNames");
      }
    });

  },
  mounted() {
    this.scrollFunction();
    this.$store.dispatch("checkCompleted");
    this.viewSizeCheck();


  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
  },
  methods: {
    scrollFunction() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    sendAdditionalAnswer(id) {
      const answerData = {
        question: id,
        answer: [this.answer],
        stepId: 107,
      };
      this.$store
        .dispatch("updateAdditionalQuestion", answerData)
        .then((res) => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {
            this.$store.dispatch("showContent");
            if (id < 114) {
              this.loadNextQuestion();
              this.answer = "";
            } else {
              this.$router.push("/upload-image");
            }
          }
        });
    },
    loadNextQuestion() {
      this.$store.dispatch("loadNextAdditionalQuestion");
      this.additionalQuestions = this.$store.getters.getAdditionalQuestions;
      this.additionalQuestions[0].id > 114
        ? this.$router.push("/upload-image")
        : "";
      this.distance++;
      this.componentKey++;
    },
    viewSizeCheck() {
      if (window.innerWidth > 768) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.text-rotator {
  width: 100%;
  height: 45px;
}

.slider {
  height: 34px;
  width: 380px;
  margin: 10px auto 0;
  overflow: visible;
  position: relative;
}

.mask {
  overflow: hidden;
  height: 34px;
  border: 1px solid #707070;
}

.slider ul {
  margin: 0;
  padding: 0;
  position: relative;
}

.slider li {
  text-align: center;
  width: 380px;
  height: 34px;
  position: absolute;
  top: -32px;
  list-style: none;
}

.slider .quote {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  width: inherit;
}
.slider li.anim1,
.slider li.anim2,
.slider li.anim3,
.slider li.anim4,
.slider li.anim5 {
  width: 100%;
}

.slider li.anim1 {
  -moz-animation: cycle 15s linear infinite;
  -webkit-animation: cycle 15s linear infinite;
  animation: cycle 15s linear infinite;
}

.slider li.anim2 {
  -moz-animation: cycle2 15s linear infinite;
  -webkit-animation: cycle2 15s linear infinite;
  animation: cycle2 15s linear infinite;
}

.slider li.anim3 {
  -moz-animation: cycle3 15s linear infinite;
  -webkit-animation: cycle3 15s linear infinite;
  animation: cycle3 15s linear infinite;
}

.slider li.anim4 {
  -moz-animation: cycle4 15s linear infinite;
  -webkit-animation: cycle4 15s linear infinite;
  animation: cycle4 15s linear infinite;
}

.slider li.anim5 {
  -moz-animation: cycle5 15s linear infinite;
  -webkit-animation: cycle5 15s linear infinite;
  animation: cycle5 15s linear infinite;
}

.slider:hover li {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes cycle {
  0% {
    top: 0px;
  }

  4% {
    top: 0px;
  }

  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  20% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  21% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  92% {
    top: -32px;
    opacity: 0;
    z-index: 0;
  }

  96% {
    top: -32px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes cycle2 {
  0% {
    top: -32px;
    opacity: 0;
  }

  16% {
    top: -32px;
    opacity: 0;
  }

  20% {
    top: 0px;
    opacity: 1;
  }

  24% {
    top: 0px;
    opacity: 1;
  }

  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  40% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  41% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle3 {
  0% {
    top: -32px;
    opacity: 0;
  }

  36% {
    top: -32px;
    opacity: 0;
  }

  40% {
    top: 0px;
    opacity: 1;
  }

  44% {
    top: 0px;
    opacity: 1;
  }

  56% {
    top: 0px;
    opacity: 1;
  }

  60% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  61% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle4 {
  0% {
    top: -32px;
    opacity: 0;
  }

  56% {
    top: -32px;
    opacity: 0;
  }

  60% {
    top: 0px;
    opacity: 1;
  }

  64% {
    top: 0px;
    opacity: 1;
  }

  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  80% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  81% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle5 {
  0% {
    top: -32px;
    opacity: 0;
  }

  76% {
    top: -32px;
    opacity: 0;
  }

  80% {
    top: 0px;
    opacity: 1;
  }

  84% {
    top: 0px;
    opacity: 1;
  }

  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  100% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }
}

@keyframes cycle {
  0% {
    top: 0px;
  }

  4% {
    top: 0px;
  }

  16% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  20% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  21% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  50% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  92% {
    top: -32px;
    opacity: 0;
    z-index: 0;
  }

  96% {
    top: -32px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes cycle2 {
  0% {
    top: -32x;
    opacity: 0;
  }

  16% {
    top: -32px;
    opacity: 0;
  }

  20% {
    top: 0px;
    opacity: 1;
  }

  24% {
    top: 0px;
    opacity: 1;
  }

  36% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  40% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  41% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle3 {
  0% {
    top: -32px;
    opacity: 0;
  }

  36% {
    top: -32px;
    opacity: 0;
  }

  40% {
    top: 0px;
    opacity: 1;
  }

  44% {
    top: 0px;
    opacity: 1;
  }

  56% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  60% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  61% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle4 {
  0% {
    top: -32px;
    opacity: 0;
  }

  56% {
    top: -32px;
    opacity: 0;
  }

  60% {
    top: 0px;
    opacity: 1;
  }

  64% {
    top: 0px;
    opacity: 1;
  }

  76% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  80% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }

  81% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }

  100% {
    top: -32px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes cycle5 {
  0% {
    top: -32px;
    opacity: 0;
  }

  76% {
    top: -32px;
    opacity: 0;
  }

  80% {
    top: 0px;
    opacity: 1;
  }

  84% {
    top: 0px;
    opacity: 1;
  }

  96% {
    top: 0px;
    opacity: 1;
    z-index: 0;
  }

  100% {
    top: 32px;
    opacity: 0;
    z-index: 0;
  }
}
</style>
