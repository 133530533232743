<template>
  <div id="questionType9">
    <h1 v-if="variant != 2" class="questionText">
      {{ questionData.question.translations[selectedLanguage].question }}
    </h1>
    <img
      v-if="editable && questionData.skippable == 1"
      :style="{ marginLeft: '20px' }"
      :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
      alt="Translation icon"
    />
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.skippableQuestionMessage =
          $event.target.innerText
      "
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >
      {{ $t($i18n.locale).keys.generic.skippableMessage }}
    </p>
    <div class="sliderWrapper">
      <vue-slider
        id="distanceFilter"
        style="width: 90%"
        v-model="distance"
        :min="sliderMin"
        :max="sliderMax"
        :tooltip="'none'"
        :marks="distanceMarks2"
        :adsorb="true"
        :included="true"
        @change="setDistanceRange()"
      ></vue-slider>
    </div>

    <GmapMap
      :center="center"
      :zoom="zoom"
      ref="mapRef"
      map-type-id="terrain"
      style="width: 100%; height: 40vh; margin: 30px 0;"
      :options="{
        streetViewControl: false,
        mapTypeControl: false
      }"
    >
      <GmapMarker
        :key="index + 'marker'"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
        :icon="{ url: require('../../assets/images/MapMarker.svg') }"
      />
      <GmapCircle
        :key="componentKey"
        :center="markers[0].position"
        :radius="mapRadius"
        :visible="true"
        :options="{
          fillColor: '#008860',
          fillOpacity: 0.3,
          strokeColor: '#008860',
          strokeWeight: 2
        }"
      ></GmapCircle>
    </GmapMap>
    <button
      v-langErr :contenteditable="editable"
      @input="
        $t($i18n.locale).keys.generic.continueBtn = $event.target.innerText
      "
      @click="
        sendAnswer();
        resendAnswer();
      "
      v-bind:class="{ 'btnContinue-disabled': distance == answerExist() }"
      :disabled="distance == answerExist"
      class="genericButton btnContinue"
    >
      {{ $t($i18n.locale).keys.generic.continueBtn }}
    </button>
    <input
      v-if="editable"
      v-model="$t($i18n.locale).keys.generic.continueBtn"
      type="text"
    />
    <img
      v-if="editable"
      :style="{ marginLeft: '20px' }"
      :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
      alt="Translation icon"
    />
    <p
      v-langErr :contenteditable="editable"
      @blur="
        $t($i18n.locale).keys.generic.answerLaterMessage =
          $event.target.innerText
      "
      v-if="questionData.question.skippable == 1"
      @click="
        emptyAnswers();
        sendAnswer();
        resendAnswer();
      "
      class="answerLaterMessage"
    >
      {{ $t($i18n.locale).keys.generic.answerLaterMessage }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["questionData"],
  name: "QuestionType9",
  data() {
    return {
      iDontMind: '',
      distance: 200,
      radius: 200000,
      centerLat: 0,
      centerLng: 0,
      city: "",
      componentKey: 0,
      answers: {},
      rangeMoved: false,
      distanceAnswer: 200,
      radiusToRender: 200,
      maxRadiusToRender: 500,
      sliderMin:50,
      sliderMax:600,
      distanceMarksKM: {
        "50": {
          label: (
            <span>
              50<br/>km
            </span>
          )
        },
        "100":{
          label: (
            <span>
              100<br/>km
            </span>
          )
        },
        "200":{
          label: (
            <span>
              200<br/>km
            </span>
          )
        },
        "500":{
          label: (
            <span>
              500<br/>km
            </span>
          )
        },
        "600": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
      distanceMarksMiles: {
        "30": {
          label: (
            <span>
              30<br/>mi
            </span>
          )
        },
        "60": {
          label: (
            <span>
              60<br/>mi
            </span>
          )
        },
        "120": {
          label: (
            <span>
              120<br/>mi
            </span>
          )
        },
        "300": {
          label: (
              <span style="margin-left:-10px">300<br/>mi</span>
          ),
        },
        "350": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
       distanceMarksAU: {
        "100": {
          label: (
            <span>
              100<br/>km
            </span>
          )
        },
        "200":{
          label: (
            <span>
              200<br/>km
            </span>
          )
        },
        "500":{
          label: (
            <span>
              500<br/>km
            </span>
          )
        },
        "1000":{
          label: (
            <span>
              1000<br/>km
            </span>
          )
        },
        "1500": {
          label: (
            <span>
              { this.$t(this.$i18n.locale).keys.questionnaire.type9.IDontMindMessage }
            </span>
          ),
        }
      },
      distanceMarks2:{},
      markers: [
        {
          id: 1,
          name: this.city,
          position: { lat: 48.1632639, lng: 11.586741999999958 }
        }
      ],
      zoom: 7,
      center: { lat: 51.2230311, lng: 6.782910000000015 },
      lockSend: true,
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    mapRadius: function(){
      if(!this.metricUnits){
        //set radius to miles
        return this.radius*1.6;
      }else{
        return this.radius;
      }

    },
    metricUnits: function(){
      if(this.$store.getters.getCountry == 'uk' || this.$store.getters.getCountry == 'us') return false;
      else return true;
    }
  },
  methods: {
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", this.answers);
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.answers,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    emptyAnswers() {
      this.answersArray = [];
    },
    loadNextQuestion() {
      this.$emit("load-next-question");
    },
    loadAnswers() {
      if (this.questionData.answered) {

        if (this.questionData.answered.answer.distance == 5000) {
          this.distance = this.sliderMax;
          this.distanceAnswer = 5000;
          this.$store.getters.getCountry.toUpperCase() == 'UK' || this.$store.getters.getCountry.toUpperCase() == 'DE' ? this.radiusToRender = 5000 : '';
          this.$store.getters.getCountry.toUpperCase() == 'CH' ? this.radiusToRender = 1500 : '';
          this.$store.getters.getCountry.toUpperCase() == 'AT' ? this.radiusToRender = 3200 : '';
          this.$store.getters.getCountry.toUpperCase() == 'AU' ? this.radiusToRender = 20000 : '';

          this.center = {
            lat: this.centerLat,
            lng: this.centerLng
          };
          this.markers[0].position.lat = this.centerLat;
          this.markers[0].position.lng = this.centerLng;
          //this.zoom = 4;
        } else {
          this.distance = this.questionData.answered.answer.distance;
          this.distanceAnswer = this.distance;
          this.radiusToRender = this.distance;
          this.center = {
            lat: this.questionData.questionData.previousQuestion.answer.answer
              .lat,
            lng: this.questionData.questionData.previousQuestion.answer.answer.lng
          };
        }

        if(this.distanceAnswer < 5000){
          this.radius = this.radiusToRender * 1000;
        } else {
          this.radius = this.maxRadiusToRender * 1000;
        }
        this.setMapZoom();
      }
    },
    answerExist() {
      if (this.questionData.answered) {
        return this.questionData.answered.answer.distance;
      } else {
        return false;
      }
    },
    setDistanceRange() {
      if (this.distance == this.sliderMax) {
        this.distanceAnswer = 5000;
        this.$store.getters.getCountry.toUpperCase() == 'UK' || this.$store.getters.getCountry.toUpperCase() == 'DE' ? this.radiusToRender = 5000 : '';
        this.$store.getters.getCountry.toUpperCase() == 'CH' ? this.radiusToRender = 1500 : '';
        this.$store.getters.getCountry.toUpperCase() == 'AT' ? this.radiusToRender = 3200 : '';
        this.$store.getters.getCountry.toUpperCase() == 'AU' ? this.radiusToRender = 2000 : '';
        this.center = {
          lat: this.centerLat,
          lng: this.centerLng
        };
        this.markers[0].position.lat = this.centerLat;
        this.markers[0].position.lng = this.centerLng;
        //this.zoom = 4;

      } else {
        this.distanceAnswer = this.distance;
        this.radiusToRender = this.distance;
        this.markers[0].position.lat = this.questionData.questionData.previousQuestion.answer.answer.lat;
        this.markers[0].position.lng = this.questionData.questionData.previousQuestion.answer.answer.lng;
        this.center = {
          lat: this.questionData.questionData.previousQuestion.answer.answer
            .lat,
          lng: this.questionData.questionData.previousQuestion.answer.answer.lng
        };
      }



        if(this.distanceAnswer < 5000){
          this.radius = this.radiusToRender * 1000;
        } else {
          this.radius = this.maxRadiusToRender * 1000;
        }
        this.setMapZoom();

      this.componentKey += 1;
      this.rangeMoved = true;
      this.answers = {
        lat: this.questionData.questionData.previousQuestion.answer.answer.lat,
        lng: this.questionData.questionData.previousQuestion.answer.answer.lng,
        distance: this.distanceAnswer
      };
    },
    getPreviousAnswerData() {

        this.answers = {
          lat: this.questionData.questionData.previousQuestion.answer.answer
            .lat,
          lng: this.questionData.questionData.previousQuestion.answer.answer
            .lng,
          distance: this.distanceAnswer
        };
        this.markers[0].position.lat = this.questionData.questionData.previousQuestion.answer.answer.lat;
        this.markers[0].position.lng = this.questionData.questionData.previousQuestion.answer.answer.lng;
        if(this.distanceAnswer == 5000){
          this.center = {
            lat: this.centerLat,
            lng: this.centerLng
          };
          this.markers[0].position.lat = this.centerLat;
          this.markers[0].position.lng = this.centerLng;
          //this.zoom = 4;
        } else {
          this.center = {
            lat: this.questionData.questionData.previousQuestion.answer.answer
              .lat,
            lng: this.questionData.questionData.previousQuestion.answer.answer.lng
          };
        }

        if(this.distanceAnswer < 5000){
          this.radius = this.radiusToRender * 1000;
        } else {
          this.radius = this.maxRadiusToRender * 1000;
        }
        this.setMapZoom();
        this.lockSend = false;

    },
    setMapZoom() {
      let actualRadius = this.radius/1000;
      console.log("actualRadius", actualRadius);
      if(actualRadius >= 2000){
          this.zoom = 3;
        }else if(actualRadius > 500 && actualRadius < 2000){
          this.zoom = 4;
        }else if(actualRadius > 200 && actualRadius <= 500){
          this.zoom = 5;
        } else if (actualRadius >= 120 && actualRadius <= 200){
          this.zoom = 6;
        } else if (actualRadius < 120 && actualRadius >= 60){
          this.zoom = 7;
        } else if (actualRadius < 60){
          this.zoom = 8;
        }
    }
  },
  mounted() {
    if(!this.metricUnits){
      //set slider to miles
      this.distanceMarks2 = this.distanceMarksMiles;
      this.distance= 120;
      this.distanceAnswer= 120;
      this.radiusToRender= 120;
      this.sliderMin=30;
      this.sliderMax=350;
      this.maxRadiusToRender = 350;
    }else if(this.$store.getters.getCountry == 'au'){
      this.distanceMarks2 = this.distanceMarksAU;
      this.distance= 200;
      this.distanceAnswer= 200;
      this.radiusToRender= 200;
      this.sliderMin=100;
      this.sliderMax=1500;
      this.maxRadiusToRender = 2000;
    }else if(this.$store.getters.getCountry == 'nz'){
      this.distanceMarks2 = this.distanceMarksKM;
      this.maxRadiusToRender = 800;
    }else if(this.$store.getters.getCountry == 'za'){
      this.distanceMarks2 = this.distanceMarksKM;
      this.maxRadiusToRender = 1000;
    }else{
      this.distanceMarks2 = this.distanceMarksKM;
      this.maxRadiusToRender = 500;
    }

    this.$refs.mapRef.$mapPromise.then(() => {

      this.loadAnswers();
      this.getPreviousAnswerData();
      if(this.$store.getters.getCountry.toUpperCase() == 'DE'){
        this.centerLat = 51.1642292;
        this.centerLng = 10.4541194;
      } else if (this.$store.getters.getCountry.toUpperCase() == 'UK'){
        this.centerLat = 54.425322;
        this.centerLng = -2.673442;
      } else if (this.$store.getters.getCountry.toUpperCase() == 'CH'){
        this.centerLat = 46.800663464;
        this.centerLng = 8.222665776;
      } else if (this.$store.getters.getCountry.toUpperCase() == 'AT'){
        this.centerLat = 47.6964719;
        this.centerLng = 13.3457347;
      } else if (this.$store.getters.getCountry.toUpperCase() == 'AU'){
        this.centerLat = -25.270787825819482;
        this.centerLng = 133.8577389520098;
      }else if (this.$store.getters.getCountry.toUpperCase() == 'PL'){
        this.centerLat = 51.95953819741848;
        this.centerLng = 19.177234005317118;
      }else if (this.$store.getters.getCountry.toUpperCase() == 'CZ'){
        this.centerLat = 49.816083003154084;
        this.centerLng = 15.469062255708012;
      }else if (this.$store.getters.getCountry.toUpperCase() == 'NZ'){
        this.centerLat = -41.446338125926836;
        this.centerLng = 173.09726875770127;
      }else if (this.$store.getters.getCountry.toUpperCase() == 'ZA'){
        this.centerLat = -28.4874690525929;
        this.centerLng = 24.93667720509233;
      }



      // this.$store.dispatch('setAdditionalTranslations').then(() => {
      //   this.iDontMind = this.$store.getters.getAdditionalTranslations.filters[0].i_dont_mind;
      //   this.componentKey9++;
      // })
    });
  }
};
</script>

<style lang="scss" scoped>
</style>
