<template>
  <div id="MemberProfile">
    <!--
    <MembershipHeader /> -->
    <MemberMisc v-if="showProfileCard" />
    <!-- <button @click="$store.dispatch('setErrorVisibility', true);">fff</button>
    <h1>{{$store.getters.getShowError}}</h1>
    <h1 v-if="$store.getters.getShowError" >klaida nutiko</h1> -->
    <div class="container-lg profile">
      <ActionsTab v-if="showProfileCard" @setProfileId="setProfileId" />
      <MemberTabs
      @clicked="onClickChildNav"
      :profileID = "profileID"
      />
      <carousel
        v-if="showCarousel"
        :per-page="1"
        :mouse-drag="false"
        :paginationEnabled="false"
        :adjustableHeight="true"
        :touchDrag="false"
        :navigateTo="[tabNumber,true]"
      >
        <slide>
          <template v-if="tabNumber == 0">
            <Profile v-if="!profileBlurred" />
            <ProfileBlurBanner v-else />
          </template>
        </slide>
        <slide>
          <Gallery v-if="tabNumber == 1" :profileId="profileId" />
        </slide>
        <slide>
          <template v-if="tabNumber == 2">
          <SexualPreferences v-if="!sexprefBlurred" :profileId="profileId"/>
          <ProfileBlurBanner v-else />
          </template>
        </slide>
        <!--
        <slide>
          <Desires v-if="tabNumber == 3" :profileId="profileId"/>
        </slide>
        -->
      </carousel>
    </div>
  </div>
</template>

<script>
// import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";
import MemberMisc from "./MemberMisc";
import MemberTabs from "./MemberTabs";
import { Carousel, Slide } from "vue-carousel";
import Profile from "@/components/membership-area/member-profile/member-profile/PersonalDescription";
import Gallery from "@/components/membership-area/member-profile/member-gallery/MemberGallery";
import SexualPreferences from "@/components/membership-area/member-profile/member-sexualpreferences/MemberSexualPreferences";
//import Desires from "@/components/membership-area/member-profile/member-desires/MemberDesires";
import ActionsTab from "./ActionsTab";
import { mapGetters } from "vuex";
import ProfileBlurBanner from "@/components/generic/ProfileBlurBanner.vue";


export default {
  name: "MemberProfile",
  components: {
    // MembershipHeader,
    MemberMisc,
    ActionsTab,
    MemberTabs,
    Carousel,
    Slide,
    Profile,
    SexualPreferences,
    Gallery,
    //Desires,
    ProfileBlurBanner
  },
  computed: {
    ...mapGetters(["MemberData", "CurrentProfileID", "PersonalityData","user"]),
    profileID: function(){
      return this.$store.getters.CurrentProfileID;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    profileBlurred: function(){
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 2;
    },
    sexprefBlurred: function(){
      if(this.$store.getters.user.isPremium) return false; 
      return this.user.settings.blurProfile & 64;
    }
  },
  data() {
    return {
      tabNumber: 0,
      showCarousel: false,
      showProfileCard: false,
      profileId: this.$route.query.id,
    };
  },
  watch: {
    $route: function(to, from){
      console.log("watch route", to , from);
      if(to.params.id != from.params.id){
        switch(to.params.id){
          case "profile":
              this.tabNumber = 0;
              break;
          case "gallery":
              this.tabNumber = 1;
              break;
          case "sexual-preferences":
              this.tabNumber = 2;
              break;
          /*
          case "desires":
              this.tabNumber = 3;
              break;
          */
          default:
              this.tabNumber = 0;
              break;
        }
      }
      if(to.query.id != from.query.id){
        this.setProfileId(to.query.id);
      }
    }
  },
  created() {
    if(this.$route.path.indexOf('member-profile/profile') != -1) this.tabNumber = 0;
    else if(this.$route.path.indexOf('member-profile/gallery') != -1) this.tabNumber = 1;
    else if(this.$route.path.indexOf('member-profile/sexual-preferences') != -1) this.tabNumber = 2;
    //else if(this.$route.path.indexOf('member-profile/desires') != -1) this.tabNumber = 3;

  },
  mounted() {
    window.scrollTo(0,0);
    this.$store.dispatch("fetchMatches");

    this.$store.dispatch("fetchMemberData", this.profileId).then(() => {
      this.$store.dispatch('hideLoader');
      this.$store.dispatch('showContent');
      this.showCarousel = true;
      this.showProfileCard = true;
      //update header counters
      this.$store.dispatch("matchesCount");
      this.$store.dispatch("visitorsCount");
    });

  },

  methods: {
    onClickChildNav(value) {
      this.tabNumber = value;
    },
    setProfileId(id){

      this.$store.dispatch('showLoader');
      this.$store.dispatch('hideContent');
      this.showCarousel = false;
      //this.showProfileCard = false;


      this.$store.dispatch("fetchMemberData", id).then(() => {
        console.log('profile id changed', id);
        this.$store.dispatch('hideLoader');
        this.$store.dispatch('showContent');
        this.profileId = id;
        this.showCarousel = true;
        this.showProfileCard = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bannerWrapper {
  display: none;
}
</style>
