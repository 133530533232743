<template>
  <div id="userTabs"
    :class="{'hidePersonality':!showPersonality}"
  >
    <router-link
      class="profile"
      :class="[
        $route.path == '/membership-area/member-profile/profile' ? 'profile-active' : ''
      ]"
      :to="'/membership-area/member-profile/profile' + profileAdd" @click.native="onClickNavigation(0)"
    >

        <div class="image"></div>
        <div class="wrapper">
          <p class="memberTabsProfile"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.memberProfile.memberTabs.profile = $event.target.innerText"
          >{{$t($i18n.locale).keys.memberProfile.memberTabs.profile}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
    </router-link>
    <router-link
      class="gallery"
      :class="[$route.path == '/membership-area/member-profile/gallery' ? 'gallery-active' : '']"
      :to="'/membership-area/member-profile/gallery' + profileAdd" @click.native="onClickNavigation(1)"
    >
        <div class="image"></div>
        <div class="wrapper">
          <p class="memberTabsGallery"
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.memberProfile.memberTabs.gallery = $event.target.innerText"
          >{{$t($i18n.locale).keys.memberProfile.memberTabs.gallery}}</p>
          <img
            v-if="editable"
            :style="{marginLeft : '20px'}"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
    </router-link>
     <router-link
      :to="'/membership-area/member-profile/sexual-preferences' + profileAdd"
      @click.native="onClickNavigation(2)"
      class="sexual-preferences"
      :class="[
        $route.path == '/membership-area/member-profile/sexual-preferences' ? 'sexual-preferences-active' : ''
      ]"
    >
      <div class="image"></div>
      <div class="wrap">
        <p  class="memberTabsSexualPreferences"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.sexualpreferences = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.memberProfile.memberTabs.sexualpreferences')}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <!--
    <router-link
      class="desires"
      :class="[
        $route.path == '/membership-area/member-profile/desires' ? 'desires-active' : ''
      ]"
      :to="'/membership-area/member-profile/desires' + profileAdd"
      @click.native="onClickNavigation(3)"
    >
      <div class="image"></div>
      <div class="wrapper">
        <p class="memberTabsDesires"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.desires = $event.target.innerText"
        >{{$t($i18n.locale +'.keys.memberProfile.memberTabs.desires')}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    -->
  </div>
</template>

<script>
export default {
  name: "MemberTabs",
  props: [
    "profileID",
    "showPersonality"
  ],
  computed: {
    profileAdd: function(){
      return (this.profileID) ? '?id=' + this.profileID : '';
    },
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {
    onClickNavigation(value) {
      this.$emit("clicked", value);
    }
  }
};
</script>

<style lang="sccs">
</style>


















