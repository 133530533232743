<template>
  <div id="questionType4">
    <h1 v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>
    <p
      v-langErr :contenteditable="editable"
      @blur="$t($i18n.locale).keys.generic.skippableMessage = $event.target.innerText"
      class="skippableQuestionMessage"
      v-if="questionData.skippable == 1"
    >{{ $t($i18n.locale).keys.generic.skippableMessage }}</p>
    <input
      v-model="answers[i-1]"
      @blur="answers[i-1] = $event.target.value"
      @keyup="answerKeyup(i,$event)"
      v-for="i in numberOfAnswers"
      :key="i"
      class="genericInput"
      type="text"
      :placeholder="$t($i18n.locale).keys.questionnaire.type3.pleaseTyperHere"
    />
    <button
      v-langErr :contenteditable="editable"
      @input="$t($i18n.locale).keys.generic.continueBtn = $event.target.innerText"
      @click="sendAnswer(); resendAnswer()"
      v-bind:class="{ 'btnContinue-disabled': !validAnswersLength || disableButton() }"

      class="genericButton btnContinue"
    >{{ $t($i18n.locale).keys.generic.continueBtn }}</button>
    <input v-if="editable" v-model="$t($i18n.locale).keys.generic.continueBtn" type="text" />
                       <img  v-if="editable"
              :style="{ marginLeft: '20px' }"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
              alt="Translation icon"
            />
    <p
      v-langErr :contenteditable="editable"
      @blur="$t($i18n.locale).keys.generic.answerLaterMessage = $event.target.innerText"
      v-if="questionData.question.skippable == 1"
      @click="emptyAnswers(); sendAnswer(1); resendAnswer(1)"
      class="answerLaterMessage"
    >{{$t($i18n.locale).keys.generic.answerLaterMessage}}</p>
  </div>
</template>
<script>
export default {
  props: ["questionData"],
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.answers = [];
      } else {
        if (!this.answers.length) {
          this.returnArray();
        }
      }
    }
  },
  name: "QuestionType4",
  data() {
    return {
      numberOfAnswers: 3,
      numberOfAnswersToAnswer: 3,
      minChars: 3,
      answers: [],
      lockSend: false,
    };
  },
  computed: {

    editable: function() {
      return this.$store.getters.editable;
  },
      selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },

    validAnswersLength() {
      // returns length of every answer
      let lengths = this.answers.map(word => {
        return word.length;
      });
      // checks if all strings contains at lest 1 char and returns if true if number of answers match
      let validFields = lengths.filter(number => number >= this.minChars);
      return validFields.length >= this.numberOfAnswersToAnswer;
    }
  },
  methods: {
    answerKeyup(i,$event){
      $($event.target).blur().focus();
    },
    sendAnswer(skipCheck) {
      if(this.lockSend) return true;
      if(!skipCheck && (!this.validAnswersLength || this.disableButton())) return false;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", this.answers);
      }
    },
    resendAnswer(skipCheck) {
      if(!skipCheck && (!this.validAnswersLength || this.disableButton())) return false;
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.answers,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    emptyAnswers(){
      this.answers = [];
    },
    returnArray() {
      if (this.questionData.answered) {
        this.answers.push(...this.questionData.answered.answer);
      }
    },
    disableButton() {
      if (this.questionData.answered) {
        if (
          JSON.stringify(this.answers) ==
          JSON.stringify([...this.questionData.answered.answer])
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        false;
      }
    }
  },
  mounted() {
    this.returnArray();
  }
};
</script>

<style lang="scss" scoped>
</style>