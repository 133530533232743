<template>
  <div id="MagniusPayment">
    <div id="magniusPaymentWrapper" >
      <form 
        method="post" 
        id="payment-form"
        class="wpwl-form wpwl-form-card magnius"
        @submit.prevent="submitMagnius"
        
      >
        <span class="payment-errors"></span>
        <div class="form-group wpwl-group">
          <div id="card-iframe" class="wpwl-control"></div>
          <div v-if="cardErrors.cardNumber" class="error">
            <small>{{ cardErrors.cardNumber }}</small>
          </div>
        </div>
        <div class="form-group wpwl-group" v-if="showCardHolder">

          <input
            class="wpwl-control"
            type="text"
            name="card-holder"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.cardHolder')"
            v-model="magniusData.cardHolder"

          />
          <div v-if="cardErrors.cardHolder" class="error">
            <small>{{ cardErrors.cardHolder }}</small>
          </div>
        </div>
        <div class="form-group wpwl-group">

          <input
            type="tel"
            v-model="magniusData.expireDate"
            class="wpwl-control"
            name="mm/yy"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.expireCombined')"
            v-cardformat:formatCardExpiry
            ref="_cardExpire"

          />
          <div v-if="cardErrors.cardExpiry" class="error">
            <small>{{ cardErrors.cardExpiry }}</small>
          </div>
        </div>
        <div class="form-group wpwl-group">

            <input
              class="wpwl-control"
              type="tel"
              size="4"
              name="cid"
              data-securionpay="cvc"
              :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.CID')"
              v-cardformat:formatCardCVC
              v-model="cardCvc"

            />
            <div v-if="cardErrors.cardCvc" class="error">
            <small>{{ cardErrors.cardCvc }}</small>
          </div>
        </div>

        <button class="wpwl-button" type="submit" name="submitButton" :disabled="magniusLocked">
          {{paymentButtonText}}
          <i class="fa fa-spinner fa-spin"></i>
        </button>
      </form>
      <iframe id="magnius3DSframe" ref="magnius3DSframe"></iframe>
    </div>
  </div>
</template>
<script>
//Magnius Payment Integration

import Vue from "vue";

import Magnius_helpers from "@/helpers/payments/Magnius_functions";
import { mapGetters } from "vuex";

export default {
    name: "MagniusPayment",
    props: [
      'paymentButtonText'
    ],
    data() {
      return {
        magniusIframe: null,
        magniusToken: null,
        magniusData: {
          expireDate: '',
          expireMonth: 0,
          expireYear: 0,
          cardHolder: null,
        },
        magniusOrgId: process.env.VUE_APP_MAGNIUS_ORG_ID,
        magniusLocked: false,
        $cardFormat: Vue.prototype.$cardFormat,
        cardNumber: null,
        cardCvc:null,
        cardErrors: {},
        paymentErrors:null,
        countPaymentAttempt: 1,
      }
    },
    watch: {
      'magniusData.expireDate': function(val){
        console.log('magniusData', val.length);
  
        let plainValue = val.replace(/\s/g, '');
        let plainValueSplit = plainValue.split('/');
  
        console.log('magniusData', plainValue, plainValueSplit);
        if(plainValueSplit.length == 2){
          if(plainValueSplit[0].length == 2) this.magniusData.expireMonth = plainValueSplit[0];
          if(plainValueSplit[1].length == 2) this.magniusData.expireYear = plainValueSplit[1];
          else if (plainValueSplit[1].length > 2) this.magniusData.expireYear = plainValueSplit[1].substr(-2);
        }
  
      },
    },
    computed: {
      ...mapGetters(["user","getActivePlan", "isMobile"]),
      showCardHolder: function(){
      return true;
    },
    },
    created: function () {
      console.log('Magnius Payment Integration loaded')
      this.initMagnius();
      //Set correct descriptor text
      this.$store.dispatch("setDescriptor", { brand: host, payment: 'mag'});
    },
    destroyed() {
      this.magniusReset();
    },
    methods: {
      initMagnius() {
        
        //SEND TO HELPER FUNCTION 
       Magnius_helpers._initialize({}, this)

      },

      magniusValidate(data){
        //SEND TO HELPER FUNCTION 
        return Magnius_helpers._validation(data, this)
      },
      
      tokenizeMagnius(){
        
        //SEND TO HELPER FUNCTION 
        return Magnius_helpers._tokenize({}, this)

      },
      async submitMagnius(evt){

        //SEND TO HELPER FUNCTION 
        Magnius_helpers._submitData({evt: evt}, this)
        
      },
      submitMagniusMobile(data){
        console.log('submitMagniusMobile', data)
        if(typeof data == 'undefined' || !data) return false;

        //SEND TO HELPER FUNCTION 
        Magnius_helpers._finalizePayment({paymentData: data}, this)

      },
      magniusIframeListener(event){
        
        //SEND TO HELPER FUNCTION 
        Magnius_helpers._iframeListener({event: event}, this)

      },
      magniusReset(){
        console.log('magniusReset');
        //Reset Magnius Form 
        if(this.magniusIframe) {
          this.magniusIframe.reset();
        }
        this.magniusData.expireDate = '';
        this.magniusData.expireMonth = 0;
        this.magniusData.expireYear = 0;
        this.magniusData.cardHolder = null;
        this.cardCvc = '';
        this.magniusLocked = false;
      },
      
    },
  }

</script>