<template>

    <div class="colors">
        <h3>Colors</h3>
        <h4>Primary</h4>
        <div class="colorGrid">
            <div v-for="i in 10" :key="'primary_'+i" class="color_wrapper">
                <div 
                    class="color_code"
                    :style="[ (i == 1) ? {'background-color': 'var(--primary-050)'} : {'background-color': 'var(--primary-' + (i-1)*100 + ')'}]"
                ></div>
                <p>
                    <span v-if="i == 1">--primary-050</span>
                    <span v-else>--primary-{{ (i-1)*100 }}</span>
                </p>
            </div>
        </div>

        <h4>Accent 1</h4>
        <div class="colorGrid">
            <div v-for="i in 10" :key="'accent1_'+i" class="color_wrapper">
                <div 
                    class="color_code"
                    :style="[ (i == 1) ? {'background-color': 'var(--accent01-050)'} : {'background-color': 'var(--accent01-' + (i-1)*100 + ')'}]"
                ></div>
                <p>
                    <span v-if="i == 1">--accent01-050</span>
                    <span v-else>--accent01-{{ (i-1)*100 }}</span>
                </p>
            </div>
        </div>

        <h4>Accent 2</h4>
        <div class="colorGrid">
            <div v-for="i in 10" :key="'accent2_'+i" class="color_wrapper">
                <div 
                    class="color_code"
                    :style="[ (i == 1) ? {'background-color': 'var(--accent02-050)'} : {'background-color': 'var(--accent02-' + (i-1)*100 + ')'}]"
                ></div>
                <p>
                    <span v-if="i == 1">--accent02-050</span>
                    <span v-else>--accent02-{{ (i-1)*100 }}</span>
                </p>
            </div>
        </div>

        <h4>Grey</h4>
        <div class="colorGrid">
            <div v-for="i in 10" :key="'grey_' + i" class="color_wrapper">
                <div 
                    class="color_code"
                    :style="[ (i == 1) ? {'background-color': 'var(--grey_50)'} : {'background-color': 'var(--grey_' + (i-1)*100 + ')'}]"
                ></div>
                <p>
                    <span v-if="i == 1">--grey_050</span>
                    <span v-else>--grey_{{ (i-1)*100 }}</span>
                </p>
            </div>
            <div v-for="i in 10" :key="'grey_cool_'+i" class="color_wrapper">
                <div 
                    class="color_code"
                    :style="[ (i == 1) ? {'background-color': 'var(--grey_cool_050)'} : {'background-color': 'var(--grey_cool_' + (i-1)*100 + ')'}]"
                ></div>
                <p>
                    <span v-if="i == 1">--grey_cool_050</span>
                    <span v-else>--grey_cool_{{ (i-1)*100 }}</span>
                </p>
            </div>
        </div>

    </div>
  
</template>

<script>

export default {

    name: "Color_styles",
    
}


</script>

<style lang="scss" scoped>

    .colors {
        .colorGrid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            justify-items: center;
            align-items: center;
            gap: 10px;
            padding: 10px 15px;
            @media screen and (max-width: 500px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            p {
                font-size: 0.7em;
                text-align: center;
                margin-top: 5px;
            }
        }
        .color_wrapper {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
        }
        .color_code {
            width: 30px;
            height:30px;
            //box-shadow: 0 0 10px rgba(0,0,0,0.15);
            border: 1px solid rgba(0,0,0,0.15);
        }
    }

</style>