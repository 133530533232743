<template>
  <div id="questionNavigation" class="variant2 ">
    <div class="content_wrapper">
      <button 
        class="prevButton"  
        @click="goBack"
        :disabled="disablePrevButton"
      >
        <i class="fa fa-chevron-left" aria-hidden="true"></i> {{ $t($i18n.locale).keys.generic.backBtn }}
      </button>
      <div class="progressbar">
        <div class="bar" v-bind:style="{ 'width': progressData.percentage + '%'  }"></div>
      </div>
      <button 
        class="nextButton"
        @click="sendAnswer"
        :disabled="nextDisabled"
      >
      {{ $t($i18n.locale).keys.generic.continue }} <i class="fa fa-chevron-right" aria-hidden="true"></i> 
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    "progressData",
    "nextDisabled",
    "prevDisabled"
  ],
  name: "QuestionNavigation",
  computed: {
    disablePrevButton: function(){
      if(this.prevDisabled || this.progressData.hideBack) return true;
      else if(this.progressData.currentQuestion == 1) return true;
      else return false;
    },
    disableNextButton: function(){
      if(this.nextDisabled || this.progressData.hideNext) return true;
      else if(this.progressData.currentQuestion == this.progressData.totalQuestions) return true;
      else return false;
    }
  },
  methods: {
    goBack() {
      console.log('goBack')
      this.$emit('goBack')
    },
    sendAnswer() {
      console.log('sendAnswer')
      this.$emit('sendAnswer');
    }
  }
  
};
</script>

<style lang="scss" scoped>
</style>