<template>
  <div id="mobilePlans_variant_2" class="plansWrapper">
            <div class="mobilePlansBg" :style="{'background-image': 'url(' + require('@/assets/images/new_payment_bg.jpg') +')'}"></div>       
            <h1 class="plansHeadline">
              {{ $t($i18n.locale).keys.premiumPlans.plansTabs.chooseMembership }}
            </h1>
            <div
              id="mobilePlan_light"
              class="mobilePlanWrapper variant2"
              :style="[{'order':2, 'margin-top':'20px'}]"
              @click="selectPlan(light.id, light.plansPrice.planId)"
            >
              <div class="mobilePlanContent">
                <p class="mobilePlanDuration">
                  {{ light.plansInfoBody.headlinePeriod }}
                </p>
                <p class="mobilePlanPrice">
                  <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ light.plansPrice.currencySign }}{{ light.plansPrice.visiblePrice}}.<sup>{{ light.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ light.plansPrice.visiblePrice}},<sup>{{ light.plansPrice.visiblePriceCents }}{{ light.plansPrice.currencySign }}</sup>
                    </template>
                    &nbsp;<span class="pmonth">{{ light.plansPrice.perMonth }}</span>
                  </span>
                  <i class="fa fa-chevron-right"></i>
                </p>
              </div>

    </div>

            <div
              id="mobilePlan_classic"
              class="mobilePlanWrapper variant2"
              :style="[{'order':3, 'margin-top':'20px'}]"
              @click="selectPlan(classic.id, classic.plansPrice.planId)"
            >
              <div class="mobilePlanContent">
                <p class="mobilePlanDuration">
                  {{ classic.plansInfoBody.headlinePeriod }}
                </p>
                <p class="mobilePlanPrice">
                  <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.visiblePrice}}.<sup>{{ classic.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ classic.plansPrice.visiblePrice}},<sup>{{ classic.plansPrice.visiblePriceCents }}{{ classic.plansPrice.currencySign }}</sup>
                    </template>
                    &nbsp;<span class="pmonth">{{ classic.plansPrice.perMonth }}</span>
                  </span>
                  <i class="fa fa-chevron-right"></i>
                </p>
              </div>

    </div>

            <div
              id="mobilePlan_comfort"
              class="mobilePlanWrapper variant2 best"
              :style="[{'order':1}]"
              @click="selectPlan(comfort.id, comfort.plansPrice.planId)"
            >
              <div class="mobilePlanBest">
                <i class="fa fa-star"></i>
                  {{ $t($i18n.locale + '.keys.premiumPlans.bestOffer') }}
                <i class="fa fa-star"></i>
              </div>
              <div class="mobilePlanContent">
                <p class="mobilePlanDuration">
                  {{ comfort.plansInfoBody.headlinePeriod }}
                </p>
                <p class="mobilePlanPrice">
                  <span >
                    <template v-if="selectedLanguage == 'en'">
                      {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.visiblePrice}}.<sup>{{ comfort.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                    </template>
                    <template v-else>
                      {{ comfort.plansPrice.visiblePrice}},<sup>{{ comfort.plansPrice.visiblePriceCents }}{{ comfort.plansPrice.currencySign }}</sup>
                    </template>
                    &nbsp;<span class="pmonth">{{ comfort.plansPrice.perMonth }}</span>
                  </span>
                  <i class="fa fa-chevron-right"></i>
                </p>
              </div>

            </div>
          </div>
</template>

<script>


export default {
  name: "MobilePlans_variant2",
  props: [
    "comfort",
    "classic",
    "light",
    "selectedLanguage"
  ],
  data() {
    return {

    };
  },


  mounted() {

  },

  created() {


  },
  methods: {
    selectPlan(planId, priceId){
      console.log('selectPlan', planId, priceId)


      //this.$store.dispatch('setPlan', planId),
      //nextTab(priceId)
      this.$emit('selectPlan', planId);

    }
  },
};
</script>

<style lang="scss">

</style>