export default {
  setProfileID(state, payload) {
    state.currentProfileID = payload;
  },
  setMemberData(state, payload) {
    state.memberData = payload;
  },
  update_likes_comments(state, payload) {
    state.likes_comments = payload;
  },
  setGalleryData(state, payload) {
    state.galleryData = payload;
  },
  setInterestsData(state, payload) {
    state.interestsData = payload;
  },
  setPersonalityData(state, payload) {
    state.personalityData = payload;
  },

};
