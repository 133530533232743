<template>
  <div class="chatMessage typeMessage htmlMessage">
    <div class="msgBox" >
      <p
      class="messageText"
      v-html="messageData.message"
      ></p>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>

</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeMessage",
  props: ["messageData"],
  data() {
    return {
    };
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
  },
  mounted() {
    setTimeout(()=>{
      document.querySelector('ul.noInput').scrollTo(0,0);
    },500);

  },
};
</script>

<style lang="scss" scoped>



</style>
