export default {
  isMobile: state => state.isMobile,
  isDEV: state => state.isDEV,
  mainLogos: state => state.mainLogos,
  productName: state => state.productName,
  supportEmail: state => state.supportEmail,
  privacyEmail: state => state.privacyEmail,
  alerts: state => state.alerts,
  assetsPath: state => state.assetsPath,
  welcomeMessageSender: state => state.welcomeMessageSender,
  siteId: state => state.siteId,
  globalTermsAndPolicies: state => state.globalTermsAndPolicies,
  productURL: state => state.productURL,
  user: state => state.user,
};