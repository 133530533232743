<template>
  <div 
    class="chatMessage typeMessage"
    :class="{'pending' : messageData.status == 4}"
  >
    <div class="msgBox" >
      <p class="pendingInfo" v-if="messageData.status == 4">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        {{ $t($i18n.locale+'.keys.membershipChat.messagePending.infoText_1') }}
         <a @click.prevent="sendToPremium" href="">{{ $t($i18n.locale+'.keys.membershipChat.messagePending.linkToPremium') }}</a> 
         {{ $t($i18n.locale+'.keys.membershipChat.messagePending.infoText_2') }}
      </p>
      <p
      :class="{ blurred: messageData.blurred }"
      class="messageText"
      >{{ messageData.message }}
        <span class="premiumLinkWrapper" v-if="messageData.blurred">
          <router-link to="/premium-plans" class="premiumLink" >
            {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
          </router-link>
        </span>
      </p>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>

</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeMessage",
  props: ["messageData"],
  data() {
    return {
    };
  },
  methods: {
    sendToPremium(){
      location.href="/premium-plans";
    },
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
     checkPremium(){
      if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')){
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Clicked Blurred Message',
          label: 'Comment'
        });

        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }else return true;
    },
  },
};
</script>

<style lang="scss" scoped>



</style>
