export default {
  checkErrors(state, errors){
    state.errors = errors;
  },
  setImagesChanges(state, payload){
    state.imageInfo.push(payload);
  },
  removeImage(state, id){
    state.imageInfo.slice(id, 1);
  },
  setImages(state, payload){
    state.images = payload
  },
  changeProfileCardPicture(state){
    state.profileCardPictureChange++;
  }
};
