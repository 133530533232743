<template>
  <div id="userTabs">
    <router-link
      to="/membership-area/user-profile/profile"
      @click.native="onClickNavigation(0)"
      class="profile"
      :class="[$route.path == '/membership-area/user-profile/profile' ? 'profile-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p class="memberTabsProfile"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.profile = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.profile}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <router-link
      to="/membership-area/user-profile/gallery"
      @click.native="onClickNavigation(1)"
      class="gallery"
      :class="[$route.path == '/membership-area/user-profile/gallery' ? 'gallery-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p lass="memberTabsGallery"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.gallery = $event.target.innerText"
        >{{$t($i18n.locale).keys.memberProfile.memberTabs.gallery}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <router-link
      to="/membership-area/user-profile/sexual-preferences"
      @click.native="onClickNavigation(2)"
      class="sexual-preferences"
      :class="[
        $route.path == '/membership-area/user-profile/sexual-preferences' ? 'sexual-preferences-active' : ''
      ]"
    >
      <div class="image"></div>
      <div class="wrap">
        <p  class="memberTabsSexualPreferences"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.sexualpreferences = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.memberProfile.memberTabs.sexualpreferences')}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    <!-- 
    <router-link
      to="/membership-area/user-profile/desires"
      @click.native="onClickNavigation(3)"
      class="desires"
      :class="[$route.path == '/membership-area/user-profile/desires' ? 'desires-active' : '']"
    >
      <div class="image"></div>
      <div class="wrap">
        <p lass="memberTabsDesires"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.memberProfile.memberTabs.desires = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.memberProfile.memberTabs.desires')}}</p>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
    </router-link>
    -->
  </div>
</template>

<script>
export default {
  name: "UserTabs",
  methods: {
    onClickNavigation(value) {
      this.$emit("clicked", value);
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  }
};
</script>

<style lang="sccs">
</style>
