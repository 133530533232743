export default {
  setUserSexualPreferences(state, payload){
    console.log('setUserSexualPreferences', payload);

    state.userSexualPreferences = payload.map(e => {
      console.log(e);
      e.picture = require(`@/assets/images/sexpref/${e.key}.png`);
      console.log(e);
      return e;
    });
  },
  setUserDesires(state, payload){

    console.log('setUserDesires', payload);
    state.userDesires = payload.map(e => {
      console.log(e);
      try{
      e.picture = require(`@/assets/images/desires/${e.key}.png`);
      }catch(error){
        //alert(error);
        e.picture = '';
      }
      console.log(e);
      return e;
    });
  },
};
