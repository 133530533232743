
<template>
  <div id="questionType7" class="questionComponent">
    
    <h1 v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>

    <div :class="{'chatStyleAnswer' : variant == 2}">
      <div class="answerWrapper">
        <p class="additionalMessage" >
          <span v-if="country.includes('us')">
            {{$t($i18n.locale +'.keys.questionnaire.type7.additionalInfoZIP' )}}<br>
          </span>

          {{$t($i18n.locale +'.keys.questionnaire.type7.cityVisibilityMessage' )}}
        </p>
        <div class="locationWrapper">
          <div class="locationInputWrapper" :class="{ plusError: !answerChosen && variant != 2 }">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
              alt="Translation icon"
            />
            <p
              class="countryErrorMessage"
              v-if="showError"
            ><span
            v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.questionnaire.type7.onlyCitiesFromYourCountry =$event.target.innerText"
            >{{$t($i18n.locale).keys.questionnaire.type7.onlyCitiesFromYourCountry}} </span><span>({{$store.getters.getCountry.toUpperCase()}})</span><span    v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.questionnaire.type7.areAvailable =$event.target.innerText"> {{$t($i18n.locale).keys.questionnaire.type7.areAvailable}}</span></p>
            <vue-google-autocomplete
              v-if="showInput"
              :key="autocompleteKey"
              id="map"
              class="genericInput"
              ref="userLocation"
              :class="{ error: showError }"
              :placeholder="$t($i18n.locale).keys.questionnaire.type7.enterLocation"
              v-on:placechanged="getAddressData"
              @keyup="addressChange"
              :country="country"
              :types="types"
              :value="address"
              v-on:no-results-found="noResults"
              @focus="focusInput"
              :enable-geolocation=true
              :inputChange="addressChange"
            ></vue-google-autocomplete>
          </div>
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
          <!-- <button
            v-langErr :contenteditable="editable"
            @blur="$t($i18n.locale).keys.questionnaire.type7.locateMeMessage = $event.target.innerText"
            @click="reassignMarker()"
            class="genericButton btnLocate"
          >{{$t($i18n.locale).keys.questionnaire.type7.locateMeMessage}}</button>
          <input
            v-if="editable"
            v-model="$t($i18n.locale).keys.questionnaire.type7.locateMeMessage"
            type="text"
          /> -->
        </div>
        <GmapMap
          v-if="showMap"
          :center="center"
          :zoom="zoom"
          ref="map"
          map-type-id="terrain"
          class="mapStyle"
          style=""
          :key="componentKey"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
          }"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
            :icon="{ url: require('../../assets/images/MapMarker.svg')}"
          />
        </GmapMap> 
      </div>
    </div>

    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="!answerChosen"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />

    <button
      v-else
      v-langErr 
      @click="sendAnswer();resendAnswer()"
      v-bind:class="{ 'btnContinue-disabled': !answerChosen }"
      :disabled="!answerChosen"
      class="genericButton btnContinue"
    >{{$t($i18n.locale).keys.generic.continueBtn}}</button>

  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete, QuestionNavigation },
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType7",
  data() {
    return {
      address: "",
      city: "",
      markers: [
        {
          id: 1,
          name: this.city,
          position: { lat: 48.1632639, lng: 11.586741999999958 }
        }
      ],
      zoom: 10,
      center: { lat: 48.1632639, lng: 11.586741999999958 },
      componentKey: 0,
      answers: {},
      answerChosen: false,
      showError: false,
      country: "",
      lockSend: false,
      inputTimeout: null,
      autocompleteKey: 1,
      showMap: false,
      showInput: false,
    };
  },
  methods: {
    noResults: function(){
      console.log('NO RESULTS',$('.pac-container .pac-item').length);
      this.showError = true;
    },
    addressChange: function($event){
        console.log('addressChange', $event);
        console.log($event.target.value);
        const value = $event.target.value
        const skipKeys = [13, 38, 40]

        window.clearTimeout(this.inputTimeout)

        if(value != '' && !skipKeys.includes($event.keyCode)){
          this.$nextTick(()=>{
            this.inputTimeout = setTimeout(() => {
              //console.log('autocomplete visible TO',$('.pac-container').is(':visible'),$('.pac-container .pac-item').length)
              if(!$('.pac-container').is(':visible')){
                this.showError = true;
              }else{
                console.log('addressChange - showError')
                this.showError = false;
              }
            }, 500);

          })

        }else {
          this.showError = false;
        }
    },
    getAddressData: function(addressData, placeResult) {
     console.log(placeResult, 'place results');
     console.log(addressData, 'addressData');
      this.address = addressData;
      if(!this.address.locality && !placeResult.name){
        this.$refs.userLocation.clear();
        $('#map').val('');
        this.answerChosen = false;
        return false;
      }
      this.$refs.userLocation.blur();
      this.answers = {
        country: this.address.country,
        city: (this.address.locality) ? this.address.locality : placeResult.name,
        lat: this.address.latitude,
        lng: this.address.longitude,
        zip: this.address.postal_code,
      };
      // console.log(placeResult['address_components'], 'short');

      let returnedData = placeResult['address_components'];
      let countryData = returnedData.filter(item =>
        item.types[0] == 'country' || item.types[1] == 'country'
      )

      countryData[0]['short_name'] == 'GB' ? countryData[0]['short_name'] = 'UK' : '';
      if(countryData[0]['short_name'] == this.$store.getters.getCountry.toUpperCase()) {
        this.answerChosen = true;
      } else {
        this.showError = true;
        this.answerChosen = false;
      }

      //GMap props
      this.markers[0].position.lat = this.address.latitude;
      this.markers[0].position.lng = this.address.longitude;
      this.componentKey += 1;
      this.center["lat"] = this.address.latitude;
      this.center["lng"] = this.address.longitude;
      this.zoom = 15;
      //this.showMap = true;
    },
    reassignMarker() {
      // console.log("getting location");
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: 2000
      }).then(coordinates => {
        this.markers[0].position.lat = coordinates.lat;
        this.markers[0].position.lng = coordinates.lng;
        this.componentKey += 1;
        this.center["lat"] = coordinates.lat;
        this.center["lng"] = coordinates.lng;
        this.zoom = 10;
        var latLngObj = {
          lat: coordinates.lat,
          lng: coordinates.lng
        };
        this.$geocoder.send(latLngObj, response => {
          this.answers = {
            country: response.results[0].address_components[5].long_name,
            city: response.results[0].address_components[2].long_name,
            lat: coordinates.lat,
            lng: coordinates.lng
          };
          console.log(response.results[0].address_components, 'locate me')
          // const indexNumber = response.results[0].address_components.length - 1;
          // if(response.results[0].address_components[indexNumber] != undefined){
            if (response.results[0].address_components[5].short_name == this.$store.getters.getCountry.toUpperCase()) {
              this.answerChosen = true;
            } else {
              this.answerChosen = false;
            }
          // }
        });
      });
    },
    sendAnswer() {
      if(this.lockSend) return true;
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", this.answers);
      }
      else if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.answers,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    resendAnswer() {
      if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: this.answers,
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.answers = this.questionData.answered.answer;
        this.markers[0].position.lat = this.answers.lat;
        this.markers[0].position.lng = this.answers.lng;
        this.center["lat"] = this.answers.lat;
        this.center["lng"] = this.answers.lng;
        this.answers.locality = this.answers.city;

        this.address = `${this.answers.city}, ${this.answers.country}`;
      }
    },
    focusInput(){
      console.log('focus Input');
      this.$refs.userLocation.clear();
    }
  },
  mounted() {

    this.loadAnswers();
    // this.reassignMarker();
    this.country = [this.$store.getters.getCountry];

    console.log('GOOGLE', window.google)
    if(window.google) {
      this.showInput = true;
      console.log('GEOLOCATE')
      this.$nextTick(()=>{
            this.$refs.userLocation.geolocate();   
          })
    }else{
      var i = setInterval(() => {
        console.log('setInterval - GOOGLE', window.google)
        if(window.google) {
          this.showInput = true;
          console.log('GEOLOCATE')
          this.$nextTick(()=>{
            this.$refs.userLocation.geolocate();   
          })
          
          clearInterval(i);
        }
      }, 500)
    }
       
   

    // console.log(this.country, 'country')
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    types: function() {

       if(this.country.includes('us')){
        //this.autocompleteKey++;
        return '(regions)';
       } 
       else return '(cities)';
    }
  }
};
</script>

<style lang="scss" scoped>

 .mapStyle {
    width: 100%; 
    height: 40vh; 
    margin: 30px 0;
    @media (max-width: 960px) { 
        height:25vh;
    }
 }
</style>
