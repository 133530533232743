<template >
  <div id="userSexualPreferences">
    <Spinner v-if="isLoading" />
    <div>
      <div class="sectionTitleWrapper">
        <h1
          class="sectionTitle"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.currentUserProfile.userSexualPreferences.title = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.currentUserProfile.userSexualPreferences.title')}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="@/assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="itemsWrapper">
        <div class="itemWrapper" v-for="item in userSexualPreferences" :key="item.key">
          <div class="item"
          :class="{
            'active':item.status
          }"
          @click="setPreferences(item)">
            <div class="itemStatus" v-if="item.status">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
            </div>
            <div class="itemPicture">
              <img :src='item.picture' alt="" height="100px">
            </div>
            <div class="itemName">
              <span>{{item.name}}</span>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "UserSexualPreferences",
  data() {
    return {
      isLoading: true,
      userSexualPreferences: [],
      userSexualPreferences_active: []
    };
  },
  computed: {
    ...mapGetters({
     // userSexualPreferences : 'getUserSexualPreferences'
      }),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {

    this.$store.dispatch("fetchUserSexualPreferences").then(() => {
        console.log('fetchUserSexualPreferences');
        this.userSexualPreferences = this.$store.getters.getUserSexualPreferences

        this.userSexualPreferences_active = this.$store.getters.getUserSexualPreferences.filter(e => {
          if(e.status) return e;
        });

        //Set all to active if none is active
        if(this.userSexualPreferences_active.length == 0){
          this.userSexualPreferences = this.$store.getters.getUserSexualPreferences.map(e => {
            e.status = 1
            return e;
          });
        }

        setTimeout(() => {
          this.isLoading = false;
        }, 100);

    });
  },
  methods: {
    setPreferences(item){
      console.log("setPreferences", item);

      item.status = !item.status;
      this.sendPreferences();
    },
    sendPreferences(){
      //'/api/profile/sexual_preferences'
      var selectedIds = [];
      this.userSexualPreferences.forEach(e => {
        if(e.status) selectedIds.push(e.id);
      });
      console.log('sendPrefrences', selectedIds);
      var data = {
        preferences: selectedIds
      }

      this.$store.dispatch("sendPreferences", data);

    },

  }
};
</script>
