
  export default function user_age(item) {
      
      const today = new Date();
      const birthDate = new Date(item.replace(/ /g, "T")); //iOS Safari needs this format
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    
      return  age
  }