import axios from 'axios'
// import  from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
const handlerEnabled= {timeout: false};
import router from "../../../../src/router";
export default {


    async getEmailConfirmed({
        commit
    }) {
        try {
            return new Promise( resolve => {

            axios.get(BASE_URL + `/api/email/check_confirmed/`).then(resp =>{
                commit("set_emailConfirmed", resp.data.data);
                console.log('getEmailConfirmed');
                console.log(resp.data);
                let emailConfirmed = resp.data.data;
                //return true; //Hotfix to skip email confirm check completely until solution is built in
                if(emailConfirmed == true) {
                    console.log('emailConfirmed true');
                    resolve(true);
                    return true;
    
                }
                if(emailConfirmed == false && router.currentRoute.path != "/confirm-email"){
                    console.log('ROUTER', router);
                    router.push("/confirm-email");
                }
            })

        });
            /*
            if(emailConfirmed == false){
            router.beforeEach((to, from, next) => {
                     console.log(to, 'router to');
                if(emailConfirmed !== true && to.matched[0].path == '/membership-area'
                && to.matched[0].path == '/upload-image'){
                    next({name: 'ConfirmEmail'})
                    return true
                }
                else {
                   next();
                   return false
                }

              });
              return false;
            }
            */
    } catch (error) {
            // return "error";
        }
    },

    setLogoutTimer({
        dispatch
    }, expirationTime) {
        // console.log('logout timer was set')
        setTimeout(() => {
            dispatch('logout')
            // console.log('logout happened')
        }, expirationTime * 1000)
    },
    // login({ commit, dispatch }, user) {
    login({
        commit
    }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios({
                    url: `${BASE_URL}` + '/auth/login',
                    data: user,
                    method: 'POST'
                })
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user
                    localStorage.setItem('token', token)
                    // Add the following line:
                    axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token, user)
                    resolve(resp)
                })
                // dispatch('setLogoutTimer', 15)
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },



    register({
        commit
    }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios({
                    url: BASE_URL + '/auth/register',
                    data: user,
                    method: 'POST'
                })
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user
                    localStorage.setItem('token', token)
                    axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token, user)
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error', err)
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    logout({
        commit
    }) {
        return new Promise((resolve) => {
            // console.log('logged out')
            commit('auth_logout')
            localStorage.removeItem('token')
            localStorage.removeItem("emailConfirmed")
            delete axios.defaults.headers.common['Authorization']
            resolve()

        })
    },
    tokenAuth({
        commit
    }, token) {
        return new Promise((resolve) => {
            const user = {};
            localStorage.setItem('token', token);
            commit('auth_success', token, user);
            resolve()
        })
    },
    setRole({
        commit
    }, role) {
        commit('set_role', role);
    },
    setAuthUser({
        commit
    }, user) {
        commit('set_auth_user', user);
    },
    setEmailConfirmed({
        commit
    }, emailConfirmed) {
        commit('set_emailConfirmed', emailConfirmed);
    },
    setLocale({
        commit
    }, locale) {
        commit('set_locale', locale);
    },
    setCountry({
        commit
    }, country) {
        commit('set_country', country);
    },
    async setAdditionalTranslations({
        commit,
        getters
    }) {
        const response = await axios.get(BASE_URL + `/translations/additional/${getters.selectedLanguage}`);
        commit('setAdditionalTranslations', response.data.data);
    },
    async resendConfirmationEmail({commit}){
        await axios.get(BASE_URL + '/api/email/resend/', handlerEnabled);
        console.log(commit)
    }

}