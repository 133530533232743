<template>
  <div id="SEPAPayment">
    <form
      action=""
      method="post"
      id="payment-form-sepa"
      class="wpwl-form wpwl-form-sepa"
      @submit.prevent="submitSEPA"
    >
      <span class="payment-errors">{{ paymentErrors }}</span>

      <div class="form-group wpwl-group">
          <input
            class="wpwl-control"
            type="text"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.SEPA_IBAN')"
            v-model="SEPA_IBAN_display"
            required=""
          />
          <div v-if="SEPA_errors.SEPA_IBAN" class="error">
            <small>{{ SEPA_errors.SEPA_IBAN }}</small>
          </div>
      </div>
      <div class="form-group wpwl-group">
          <input
            class="wpwl-control"
            type="text"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.SEPA_firstname')"
            v-model="SEPA_firstname"
            required=""
          />
          <div v-if="SEPA_errors.SEPA_firstname" class="error">
            <small>{{ SEPA_errors.SEPA_firstname }}</small>
          </div>
      </div>
      <div class="form-group wpwl-group">
          <input
            class="wpwl-control"
            type="text"
            :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.SEPA_lastname')"
            v-model="SEPA_lastname"
            required=""
          />
          <div v-if="SEPA_errors.SEPA_lastname" class="error">
            <small>{{ SEPA_errors.SEPA_lastname }}</small>
          </div>
      </div>

      <button class="wpwl-button" type="submit" :disabled="formLocked">
        {{paymentButtonText}}
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </form>
  </div>
</template>

<script>
//SEPA Payment Integration

import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
import { mapGetters } from "vuex";

import SEPA_helpers from "@/helpers/payments/SEPA_functions";

Vue.use(LoadScript);

export default {
    name: "SEPAPayment",
    props: [
      'paymentButtonText'
    ],
    data() {
      return {
        SEPA_IBAN: null,
        SEPA_IBAN_display: null,
        SEPA_firstname:'',
        SEPA_lastname:'',
        SEPA_errors: {
          hasErrors: false,
          SEPA_IBAN: null,
          SEPA_lastname: null,
          SEPA_firstname: null,
        },
        SEPA_data: {},
        iban_validator: require('iban'),
        paymentErrors: null,
        formLocked: false,
        countPaymentAttempt: 1,
      }
    },
    watch: {
      SEPA_IBAN_display: function(val){
        this.SEPA_IBAN_display = this.iban_validator.printFormat(val);
        this.SEPA_IBAN = this.iban_validator.electronicFormat(val);
        if(!this.iban_validator.isValid(val)){
          this.SEPA_errors.SEPA_IBAN = this.$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.errorSepaIBAN')
        }else{
          this.SEPA_errors.SEPA_IBAN = null;
        }
  
      },
    },
    computed: {
      ...mapGetters(["user","getActivePlan","isMobile"]),
      
    },
    created: function () {
      console.log('SEPA Payment Integration loaded')

      //Set correct descriptor text
      this.$store.dispatch("setDescriptor", { brand: host, payment: 'sepa'});
    },
    methods: {
      sendSEPAData(data){
          //SEND TO HELPER FUNCTION
          SEPA_helpers._sendData(data,this)
      },
      submitSEPA(){
        
        //SEND TO HELPER FUNCTION
        SEPA_helpers._submitData({},this)
  
      },
      submitSEPAMobile: function(){

        this.sendSEPAData(this.sepa_form_data);
  
      },
      validateSEPA(){
        
       //SEND TO HELPER FUNCTION
       return SEPA_helpers._validation({},this)
  
      },
    },
  }

</script>