// issues with setting config.handlerEnabled {handlerEnabled:true}
// instead temporary solution is to use {timeout: false}

import axios from 'axios';
import store from "./store/index.js";
import jwt from "jsonwebtoken";
// import router from "../src/router";

const $axios = axios.create({

});
axios.defaults.timeout = null;

const token = localStorage.getItem('token')
if (token) {
  $axios.defaults.headers.common['Authorization'] = token
}


axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token");
    if (jwt.decode(token) == null) {
      localStorage.removeItem("token");
    }

    if (token != undefined) {
      let decoded = jwt.decode(token, {
        complete: true
      });
      let expires = null;
      if (decoded) {
        store.dispatch('setRole', decoded.payload.roles);
        //store.dispatch('setLocale', decoded.payload.locale);
        store.dispatch('setCountry', decoded.payload.country);
        store.dispatch('setAuthUser', decoded.payload);
        // console.log(decoded.payload.country, 'country')
        expires = decoded.payload.exp;




        if (Date.now() >= expires * 1000) {
          config.headers["Authorization"] = null;
          localStorage.removeItem("token");
          store.dispatch('logout');
        } else {
          // console.log(config.url.split('/')[2], 'config')
          if(config.url.split('/')[2] != 'bigcake-pictures3.s3.eu-central-1.amazonaws.com'){
            config.headers["Authorization"] = `Bearer ${token}`;
          }
        }
      }
    }
    return config;
  },

  error => {
    return Promise.reject(error);
  }
);




export default $axios;