<template>
  <div id="welcomeMessage" >
    <div class="welcomeMessageWrapper">
      <div class="mainContainerWrapper">
        <div class="logoWrapper">
          <img :src="$store.getters.mainLogos.desktop" alt="Logo" />
        </div>
        <div class="mainContainer">
          <div class="titleWrapper">
            <div
              class="title"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.questionnaire.additionalQuestion.welcome =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.questionnaire.additionalQuestion.welcome
              }}
            </div>
          </div>
          <div class="mainTextWrapper">
            <div
              class="mainText"
              v-langErr
              :contenteditable="editable"
              @blur="
                $t(
                  $i18n.locale
                ).keys.questionnaire.additionalQuestion.weAreExcited =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.questionnaire.additionalQuestion
                  .weAreExcited
              }}
            </div>
          </div>
          <div class="buttonWrapper">
            <router-link to="/personality-test">
              <button class="btnContinue" v-langErr :contenteditable="editable">
                {{ $t($i18n.locale).keys.generic.continueBtn }}
              </button>
              <input
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.continueBtn"
                type="text"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeMessage",
  components: {},
  data() {
    return {};
  },
  mounted(){
    this.scrollFunction();
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
  },
  methods: {
    scrollFunction() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
  }
};
</script>
