<template>
  <div id="notFoundPage">
    <div class="container-main">
      <div class="containerNotFound">
        <div class="messageContainer">
          <h1 class="notFoundTitle">Oops!</h1>
          <p class="notFoundMessage">404 - page not found</p>
          <p class="pageUnavailableMessage">Looks like this page is unavailable</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>