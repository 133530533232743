import axios from "axios";
import Vue from 'vue';
// import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};
export default {
    async fetchProfileData({
        commit
    }) {

        try {
            const response = await axios.get(BASE_URL + '/api/profile/view', handlerEnabled);
            commit('setPersonalDescription', response.data.data.description);
            commit('setProfileCard', response.data.data.profileCard);
            commit('setMainAnswers', response.data.data.answers);
            commit('setAnsweredRandomQuestions', response.data.data.answersRandom);
        } catch (error) {
            return 'error';
        }

    },
    async updatePersonalDescription({
        commit
    }, data) {
        const response = await axios.put(BASE_URL + '/api/personality/data/update', data,handlerEnabled);
        try {
            if (response) {
                Vue.gtm.trackEvent({
                    event: 'Settings',
                    category: 'Settings',
                    action: 'Profile Updated',
                    label: 'Description Updated'
                });
                commit('descriptionUpdated');
                throw 'success';
            } else {
                throw 'error'
            }
        } catch (res) {
            return res;
        }
    },
    async updateLanguage({
        commit
    }, data) {
        const response = await axios.put(BASE_URL + '/api/profile/language/update', data,handlerEnabled);
        try {
            if (response) {
                commit('languageUpdated');
                throw 'success';
            } else {
                throw 'error'
            }
        } catch (res) {
            return res;
        }
    },
    async fetchRandomQuestions({
        commit
    }) {
        const response = await axios.get(BASE_URL + '/api/profile/random/questions/',handlerEnabled);
        try {
            commit('setRandomQuestions', response.data.data)
            console.log(response.data.data, 'random')
            throw 'success'
        } catch (error) {
            return 'error';
        }
    },
    removeChosenQuestion({
        commit
    }, id) {
        commit('removeAnsweredChosenQuestion', id);
    },
    async updateRandomQuestion({
        commit
    }, data) {

        try {
            await axios.post(BASE_URL + '/api/personality/answer', data,handlerEnabled);
            Vue.gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Profile Updated',
                label: 'Random Question Answered'
            });
            commit('randomQuestionsUpdated', data);
        } catch (error) {
            return 'error';
        }
    },
    setJobStatus({
        commit
    }) {
        commit('setJobStatus');
    },
    async setBackground({commit}, id){
        await axios.put(BASE_URL + `/api/profile/background_set/${id}`, handlerEnabled);
        console.log(commit)
    }
}