  <template lang="html">
    <div class="countdownComponent">
        <div v-if="isEnded" >
        Ended!
        </div>
    
        <div v-else>
            <div v-if="timertype == 'counter_only'">
                {{ hours }}:{{ minutes }}:{{ seconds }}
            </div>
            <div v-else-if="timertype == 'inline_timer'">
              <div class="expireCounter">
                <i class="fa fa-hourglass" aria-hidden="true"></i>&nbsp;
                {{ hours }}:{{ minutes }}:{{ seconds }}
              </div>
            </div>
            <div v-else-if="timertype == 'timer_box'">
              <div class="expireBox">
                <p>
                  {{$t($i18n.locale+'.keys.expirationBox.expirationInfoText', {'expire_time_span': expire_time_span})  }}
                </p>
                <div class="expireCounter">
                  <i class="fa fa-hourglass" aria-hidden="true"></i>&nbsp;
                  {{ hours }}:{{ minutes }}:{{ seconds }}
                </div>
                <div class="btnWrapper">
                  <button class="btnPremium" @click="sendToPremium">
                    <span>
                      {{$t($i18n.locale+'.keys.expirationBox.expirationPremiumButton')  }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <!-- 
            <div class="countdownCircle">
              <svg>
                <circle cx="30" cy="30" r="25" ref="circle"></circle>
              </svg>
            </div>
            -->
        <!--
        <div>Days: {{ days }}</div>
        <div>Hours: {{ hours }}</div>
        <div>Minutes: {{ minutes }}</div>
        <div>Seconds: {{ seconds }}</div>
        -->
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      endDate: {
        type: Date // Date.parse(this.endDate)
      },
      timertype: {
        default: 'counter_only'
      }
    },
  
    data () { 
      return {
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
        isEnded: null
      }
    },
  watch: {
    hours: function(val){
        var newVal = val.toString()
        if(newVal.length < 2) {
            this.hours = '0' + newVal;
        }
    },
    minutes: function(val){
        var newVal = val.toString()
        if(newVal.length < 2) {
            this.minutes = '0' + newVal;
        }
    },
    seconds: function(val){
        var newVal = val.toString()
        if(newVal.length < 2) {
            this.seconds = '0' + newVal;
        }
    }
  },
  computed: {
    expire_time_span() {
      if(this.$store.getters.selectedLanguage == 'de'){
        if(this.$store.getters.user.settings.countdownHours){
          return this.$store.getters.user.settings.countdownHours;
        }else{
          //Default Fallback
          return '24';
        }
        
      }else{
        if(this.$store.getters.user.settings.countdownHours){
          return this.$store.getters.user.settings.countdownHours;
        }else{
          //Default Fallback
          return '24';
        }
      }
    }

  },
    methods: {
      sendToPremium(){
      this.$router.push(`/premium-plans`);
      },
      timerEnded(){
        console.log('timerEnded')
        this.$emit('timer-ended', true)
      },
      updateRemaining (distance) {
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)

        //Circular countdown clock
        /*
        var initialOffset = 157;
        var startDate = (this.endDate - (86400000));
        var currentTime = new Date()
        var totalTimeRange = this.endDate - startDate;
        var timeElapsed = currentTime - startDate;
        var percentageElapsed = (timeElapsed / totalTimeRange) * 100;
        
        var offset = initialOffset - ((timeElapsed / totalTimeRange) * initialOffset)
        console.log('updateRemaining', this.$refs.circle, percentageElapsed, offset )
        this.$refs.circle.style.strokeDashoffset = offset;
        */
      },
      
      tick () {
        const currentTime = new Date()
        const distance = Math.max(this.endDate - currentTime, 0)
        this.updateRemaining(distance)
        
        if (distance === 0) {
          clearInterval(this.timer)
          this.isEnded = true
          this.$emit('timer-ended', true)
        }
      }
    },
    
    mounted () {
      this.tick()
      this.timer = setInterval(this.tick.bind(this), 1000)
      //this.$refs.circle.style.strokeDashoffset = 157;
    },
    
    destroy () {
      clearInterval(this.timer)
    }
  }
  </script>
  <style lang="scss" scoped>
  .countdownCircle {
    svg {
      transform: rotate(-90deg);
      width: 60px;
      height: 60px;
      circle {
        fill: transparent;
        stroke: red;
        stroke-width: 3px;
        stroke-dasharray: 157;
        stroke-dashoffset: 1;
      }
    }
  }
</style>