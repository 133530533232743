<template>
  <div id="mobilePlans_variant_1" class="plansWrapper">
    <div class="mobilePlansWrapper">
      <div
        class="simplePlanWrapper"
        @click="selectPlan(light.id)"
        :style="[ABVariant == 2 ? {'order':2, 'margin-top':'20px'}:{'order':1, 'margin-top':'0px'}]"
      >
        <div class="planHeader green">
          <h1>
            <span v-langErr>
              {{ light.plansInfoHeader.titlePremium }}
            </span>
            <span class="title" v-langErr>
              {{ light.plansInfoHeader.titleType }}
            </span>
          </h1>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_lite_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ light.plansInfoBody.headlinePeriod }}
          </p>

          <div class="plansPrice" :class="{'leadCurrency':(selectedLanguage == 'en')}">
            <!--
            <p class="oldPrice" v-if="light.plansPrice.discount">
              {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
              <span class="strike">
                <template v-if="selectedLanguage == 'en'">
                  {{ light.plansPrice.currencySign }}{{ light.plansPrice.insteadOfPrice }}.{{ light.plansPrice.insteadOfPriceCents }}
                </template>
                <template v-else>
                  {{ light.plansPrice.insteadOfPrice }},{{ light.plansPrice.insteadOfPriceCents }}{{ light.plansPrice.currencySign }}
                </template>
              </span>
            </p>
            <p v-else></p>
            -->
            <h1>
              <span >
                <template v-if="selectedLanguage == 'en'">
                  {{ light.plansPrice.currencySign }}{{ light.plansPrice.visiblePrice}}.<sup>{{ light.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                </template>
                <template v-else>
                  {{ light.plansPrice.visiblePrice}},<sup>{{ light.plansPrice.visiblePriceCents }}</sup>{{ light.plansPrice.currencySign }}
                </template>
              </span>
            </h1>
          </div>
          <i class="continueBtn fa fa-chevron-right"></i>
        </div>
        <div class="promoWrapper" v-if="light.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ light.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>
      <div
        class="planWrapper"
        @click="
        selectPlan(classic.id)
        "
        :style="[ABVariant == 2 ? {'order':3}:{'order':2}]"
      >
        <div class="planHeader">
          <h1>
            <span v-langErr>
              {{ classic.plansInfoHeader.titlePremium }}
            </span>
            <span class="title" v-langErr>
              {{ classic.plansInfoHeader.titleType }}
            </span>
          </h1>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_classic_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ classic.plansInfoBody.headlinePeriod }}
          </p>

          <div class="plansPrice"  :class="{'leadCurrency':(selectedLanguage == 'en')}">
            <!--
            <p class="oldPrice" v-if="classic.plansPrice.discount">
              {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
              <span class="strike">
                <template v-if="selectedLanguage == 'en'">
                  {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.insteadOfPrice }}.{{ classic.plansPrice.insteadOfPriceCents }}
                </template>
                <template v-else>
                  {{ classic.plansPrice.insteadOfPrice }},{{ classic.plansPrice.insteadOfPriceCents }}{{ classic.plansPrice.currencySign }}
                </template>
              </span>
            </p>
            <p v-else></p>
            -->
            <h1>
            <span >
              <template v-if="selectedLanguage == 'en'">
                {{ classic.plansPrice.currencySign }}{{ classic.plansPrice.visiblePrice}}.<sup>{{ classic.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
              </template>
              <template v-else>
                {{ classic.plansPrice.visiblePrice}},<sup>{{ classic.plansPrice.visiblePriceCents }}</sup>{{ classic.plansPrice.currencySign }}
              </template>
            </span>
            </h1>
          </div>
          <i class="continueBtn fa fa-chevron-right"></i>

        </div>
        <div class="promoWrapper" v-if="classic.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ classic.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>
      <div
        class="simplePlanWrapper most"
        @click="
        selectPlan(comfort.id)
        "
        :style="[ABVariant == 2 ? {'order':1, 'margin-top':'0px'}:{'order':3}]"
      >
        <div class="planHeader dark">
          <h1>
            <span v-langErr>
              {{ comfort.plansInfoHeader.titlePremium }}
            </span>
            <span class="title" v-langErr>
              {{ comfort.plansInfoHeader.titleType }}
            </span>
          </h1>
        </div>
        <div class="planBody">
          <div class="plansImage">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'plan_comfort_mobile.jpg')" alt="">
          </div>
          <p class="planDuration" v-langErr>
            {{ comfort.plansInfoBody.headlinePeriod }}
          </p>
          <div class="plansPrice"  :class="{'leadCurrency':(selectedLanguage == 'en')}">
            <!--
            <p class="oldPrice" v-if="comfort.plansPrice.discount">
              {{ $t($i18n.locale +'.keys.premiumPlans.processPayments.oldPrice')}}
              <span class="strike">
                <template v-if="selectedLanguage == 'en'">
                  {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.insteadOfPrice }}.{{ comfort.plansPrice.insteadOfPriceCents }}
                </template>
                <template v-else>
                  {{ comfort.plansPrice.insteadOfPrice }},{{ comfort.plansPrice.insteadOfPriceCents }}{{ comfort.plansPrice.currencySign }}
                </template>
              </span>
            </p>
            <p v-else></p>
            -->
            <h1>
              <span >
                <template v-if="selectedLanguage == 'en'">
                  {{ comfort.plansPrice.currencySign }}{{ comfort.plansPrice.visiblePrice}}.<sup>{{ comfort.plansPrice.visiblePriceCents }}&nbsp;&nbsp;</sup>
                </template>
                <template v-else>
                  {{ comfort.plansPrice.visiblePrice}},<sup>{{ comfort.plansPrice.visiblePriceCents }}</sup>{{ comfort.plansPrice.currencySign }}
                </template>
              </span>
            </h1>
          </div>
          <i class="continueBtn fa fa-chevron-right"></i>
        </div>
        <div class="mostChosen">
          {{ $t($i18n.locale + '.keys.premiumPlans.mostChosenPlan', {
            productName: $store.getters.productName
          }) }}
        </div>
        <div class="promoWrapper" v-if="comfort.plansPrice.discount">
          <div class="promoRibbon">
            <span class="ribbonContent">-{{ comfort.plansPrice.discount }}%</span>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>


export default {
  name: "MobilePlans_variant1",
  props: [
    "comfort",
    "classic",
    "light",
    "selectedLanguage"
  ],
  data() {
    return {
      domain: domain
    };
  },
  computed: {
    ABVariant: function(){
      return 2;
    },
  },

  mounted() {

  },

  created() {


  },
  methods: {
    selectPlan(planId, priceId){
      console.log('selectPlan', planId, priceId)


      //this.$store.dispatch('setPlan', planId),
      //nextTab(priceId)
      this.$emit('selectPlan', planId);

    }
  },
};
</script>

<style lang="scss">

</style>