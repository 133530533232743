var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionComponent",attrs:{"id":"questionType1"}},[(_vm.variant != 2)?_c('h1',{staticClass:"questionText",domProps:{"innerHTML":_vm._s(_vm.questionData.question.translations[_vm.selectedLanguage].question)}}):_vm._e(),_c('div',{class:{'chatStyleAnswer' : _vm.variant == 2}},[_c('div',{staticClass:"answerWrapper"},[(_vm.questionData.question.id == 104)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"additionalMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.questionnaire.type1.additionalMessage', {last_answer: _vm.last_answer}))+" ")]):_vm._e(),(_vm.questionData.question.id == 105)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"additionalMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.additionalMessage2)+" ")]):_vm._e(),(_vm.questionData.skippable == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"skippableQuestionMessage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.skippableQuestionMessage)+" ")]):_vm._e(),(!_vm.sliderLayout)?_c('div',{staticClass:"choices"},_vm._l((_vm.questionData.question.answerOptions),function(item,i){return _c('button',{key:i,staticClass:"genericButton btnAnswer orange-button",class:[
            { genericBtnSelected: _vm.answer === i + 1 },
            { genericBtnSelected: _vm.addAnswerClass(i + 1) && _vm.removeSelected },
            (item.translations[_vm.selectedLanguage].answer.length > 15) &&
            (item.translations[_vm.selectedLanguage].answer.length <= 65)
              ? 'buttonWider'
              : '',
              item.translations[_vm.selectedLanguage].answer.length > 65 ? 'buttonWidest' : ''
          ],attrs:{"data-toggle":"modal","data-target":"#38-quest-modal","type":"button","data-dismiss":"modal"},on:{"click":function($event){_vm.sendAnswer(i + 1);
            _vm.modalLoad();
            _vm.modalLoad2();
            _vm.answerSelected(i + 1);
            _vm.resendAnswer(i + 1);
            _vm.removeSelected = false;}}},[_vm._v(" "+_vm._s(item.translations[_vm.selectedLanguage].answer)+" ")])}),0):_c('div',{staticClass:"importanceSlider"},[_c('ul',_vm._l((_vm.questionData.question.answerOptions),function(item,i){return _c('li',{key:i,class:[
              { active: _vm.answer === i + 1 },
              { active: _vm.addAnswerClass(i + 1) && _vm.removeSelected }
            ],attrs:{"data-toggle":"modal","data-target":"#38-quest-modal"},on:{"click":function($event){_vm.sendAnswer(i + 1);
              _vm.modalLoad();
              _vm.modalLoad2();
              _vm.answerSelected(i + 1);
              _vm.resendAnswer(i + 1);
              _vm.removeSelected = false;}}},[((i+1) == 1 || (i+1) == 4 || (i+1) == 7)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.translations[_vm.selectedLanguage].answer)+" ")]):_vm._e()])}),0)])])]),(_vm.variant == 2)?_c('QuestionNavigation',{attrs:{"progressData":{
      ..._vm.navData,
      currentQuestion: _vm.questionData.question.orderId,
    },"nextDisabled":!_vm.answer,"prevDisabled":false},on:{"sendAnswer":_vm.sendSelectedAnswer,"goBack":function($event){return _vm.$emit('goBack')}}}):_vm._e(),_c('b-modal',{staticClass:"modal fade",attrs:{"id":"38-quest-modal","tabindex":"-1","role":"dialog","aria-labelledby":"38-quest-modal-label","data-color":"orange","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"logoWrapper"},[_c('img',{attrs:{"src":_vm.$store.getters.mainLogos.desktop,"alt":"Logo image"}})]),(_vm.modalContentID == 38)?_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-container"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"modal1Headline",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal1.headline =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal1.headline)+" ")]),_c('div',{staticClass:"modal-message"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal1.paragraph =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal1.paragraph)+" ")])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-default btn-close modal-button",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){_vm.removeModal();
              _vm.removeModal2();
              _vm.loadNext();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" ")])])]):_vm._e(),(_vm.modalContentID == 80)?_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-container"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('h1',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"modal1Headline",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal2.headline =
                $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal2.headline)+" ")]),_c('div',{staticClass:"modal-message"},[_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal2.paragraph =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.type1.modal2.paragraph)+" ")])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"btn btn-default btn-close modal-button",attrs:{"contenteditable":_vm.editable,"type":"button","data-dismiss":"modal"},on:{"input":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn =
                $event.target.innerText},"click":function($event){_vm.removeModal();
              _vm.removeModal2();
              _vm.loadNext();}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn),expression:"$t($i18n.locale).keys.generic.continueBtn"}],attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "continueBtn", $event.target.value)}}}):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"modal-backdrop-transparent modal-transition",attrs:{"id":"modal-backdrop"},on:{"click":function($event){_vm.removeModal(); _vm.removeModal2()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }