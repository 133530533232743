export default {
    change_editable(state) {
        state.editable = !state.editable
    },
    locales_default(state) {
        state.avaibableLanguages = ['en']
    },
    selected_language(state, payload) {
        state.selectedLanguage = payload
    },
    locale_translations(state, payload) {
        state.translationKeys = payload
    },
    load_locales(state, payload){
        const languages = payload.map(x => x.locale);
        state.avaibableLanguages = languages
            }
}

