import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import PersonalityTest from "./components/personality/PersonalityTest.vue";
import MainPanel from "./components/membership-area/MainPanel.vue";
import MembershipHome from "./components/membership-area/MembershipHome.vue";
import Login from "./components/auth/Login.vue";
import Logout from "./components/auth/Logout.vue";
import Register from "./components/auth/Register.vue";
import MagicLink from "./components/auth/MagicLink.vue";
import ResetPassword from "./components/auth/ResetPass.vue";
import ChangeLanguage from "./components/ChangeLanguage.vue";
import MembershipChat from "./components/membership-area/membership-chat/MembersipChat.vue";
import MembershipMatchesList from "./components/membership-area/membership-matches/MembershipMatchesList.vue";
import MembershipVisitorsList from "./components/membership-area/membership-visitors/MembershipVisitorsList.vue";
import MembershipMeetingsMain from "./components/membership-area/membership-meetings/MembershipMeetingsMain.vue";
import UserProfile from "./components/membership-area/membership-profile/UserProfile.vue";
import MemberProfileWrapper from "./components/membership-area/member-profile/MemberProfileWrapper.vue";
import PremiumPlans from "./components/membership-area/premium-plans/PremiumPlans.vue";
import PaymentsError from "./components/membership-area/premium-plans/PaymentsError.vue";
//import PaymentsSuccess from "./components/membership-area/premium-plans/PaymentsSuccess.vue";
import PaymentsSuccessInfo from "./components/membership-area/premium-plans/PaymentsSuccessInfo.vue";
import CompletePayment from "./components/membership-area/premium-plans/CompletePayment.vue";

import MatchesPreferences from "./components/membership-area/matches-preferences/MatchesPreferences.vue";
import UserAccount from "./components/membership-area/my-account/UserAccount.vue";

import NotFound from "./views/404.vue";

import UploadImage from "./components/personality/additional-questions/UploadImage.vue";
import AdditionalQuestion from "./components/personality/additional-questions/AdditionalQuestion.vue";
import CompletionMessage from "./components/personality/additional-questions/CompletionMessage.vue";
import WelcomeMessage from "./components/personality/additional-questions/WelcomeMessage.vue";
import ConfirmEmail from "./components/generic/ConfirmEmail";

import Styleguide from "./components/_styleguide/Styleguide.vue";




const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};


const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;

  }
  // next('/login')
  window.location.href = 'https://' + host +'.'+ store.getters.getCountryDomainLanding
  //window.location.href = `${process.env.VUE_APP_LANDING_URL}${store.getters.getCountryDomainLanding}`;
  return;
};

const DEVonly = (to, from, next) => {
  if (store.getters.isDEV) {
    next();
    return;
  }
  next("/");
};

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      path: "/",
      beforeEnter: ifAuthenticated,
      redirect: "/membership-area/home",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/personality-test/:questionId?",
      name: "PersonalityTest",
      component: PersonalityTest,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout
    },
    {
      path: "/magicLink/:token",
      name: "magicLink",
      component: MagicLink
    },
    {
      path: "/resetPassword/:token",
      name: "resetPassword",
      component: ResetPassword
    },
    {
      path: "/completePayment/",
      name: "completePayment",
      component: CompletePayment
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/language",
      name: "ChangeLanguage",
      component: ChangeLanguage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/membership-area",
      redirect: "/membership-area/home",
      beforeEnter: ifAuthenticated,
      name: "MainPanel",
      component: MainPanel,
      children: [{
          // UserProfile will be rendered inside User's <router-view>
          // when /user/:id/profile is matched
          path: "home",
          component: MembershipHome
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "messages/:id?",
          component: MembershipChat
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "matches",
          component: MembershipMatchesList
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "visitors",
          component: MembershipVisitorsList
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "meetings",
          component: MembershipMeetingsMain
        },

        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "matches-preferences",
          component: MatchesPreferences
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "user-profile/:id",
          component: UserProfile
        },
        {
          path: "member-profile/:id",
          component: MemberProfileWrapper
        },
        {
          path: "my-account",
          component: UserAccount
        },
        {
          path: "*",
          component: NotFound
        }
      ]
    },
    {
      path: "/premium-plans",
      name: "PremiumPlans",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/premium-plans/:purchase",
      name: "PremiumPlansPurchase",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    //D6
    {
      path: "/premium-plans/:select",
      name: "PremiumPlansSelectMethod",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/premium-plans/:summary",
      name: "PremiumPlansSummary",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/premium-plans/plans",
      name: "PremiumPlansPlans",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/premium-plans/form",
      name: "PremiumPlansForm",
      component: PremiumPlans,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    // /D6
    {
      path: "/payments-error",
      name: "PaymentsError",
      component: PaymentsError,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/payments-success",
      name: "PaymentsSuccess",
      component: PaymentsSuccessInfo,
      props: {showInfo: false},
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/payments-success-info",
      name: "PaymentsSuccessInfo",
      component: PaymentsSuccessInfo,
      props: {showInfo: true},
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/upload-image",
      name: "UploadImage",
      component: UploadImage,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/additional-question",
      name: "AdditionalQuestion",
      component: AdditionalQuestion,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/completion-message",
      name: "CompletionMessage",
      component: CompletionMessage,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/welcome-message",
      name: "WelcomeMessage",
      component: WelcomeMessage,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/confirm-email",
      name: "ConfirmEmail",
      component: ConfirmEmail,
      beforeEnter: ifAuthenticated,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/styleguide",
      name: "Styleguide",
      component: Styleguide,
      beforeEnter: DEVonly
    },
    {
      path: "*",
      component: NotFound
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  console.log('routing beforeEach');
  console.log('to', to);
  console.log('from', from);
  console.log('next', next);

  //Skip guards for routes without authentification
  if(
    to.path.indexOf("/magicLink") != -1 ||
    to.path.indexOf("/resetPassword") != -1
  ){
    if(to.path.indexOf("/magicLink") != -1){
      localStorage.removeItem("token");
      localStorage.setItem('token', to.params.token);
    }
    next();
    return true;
  }

  //Skip guards if navigation is only inside payment tabs
  //On payment pages keep url parameters from origin page if not present
  if(from.path != to.path && from.path.indexOf('/premium-plans') != -1 && to.path.indexOf('/premium-plans') != -1){
    console.log('IF INDSIDE PREMIUM');
    if(!hasQueryParams(to) && hasQueryParams(from)){
    let toWithQuery = Object.assign({}, to, {query: from.query});
    router.push(toWithQuery);
      return true;
    }else{
      next();
      return true;
    }
  }

  //Navigation inside messages
  if(from.path.indexOf('/membership-area/messages') != -1 && to.path.indexOf('/membership-area/messages') != -1){
   next();
   return true;
  }

  //Navigation inside matches
  if(from.path.indexOf('/membership-area/matches') != -1 && to.path.indexOf('/membership-area/matches') != -1){
    next();
    return true;
   }

  //MAIN NAVIGATION GUARD FOR ALL ROUTES
  store.dispatch("checkAnsweredQuestionaire").then( (res) => {
    //Check for personality test status
    console.log("checkAnsweredQuestionaire", res);
    let questionDone = res.completed;
    console.log('questionDone', questionDone);
    //confirmFirst check
    if(res.confirmFirst) {
      store.dispatch("getEmailConfirmed")
    }

    //If personality test is not finished but tried to access members area > redirect to personality test
    if (questionDone !== true && to.matched[0].path == '/membership-area') {
      if(res.confirmFirst) {
        store.dispatch("getEmailConfirmed")
      }else {
        next({
          name: 'PersonalityTest'
        });
      }
      return true;
    }
    //If user didn't visit additional question but tried to access members area > redirect to additional question
    else if(!res.additionalQuestionsShown && to.path.indexOf("/membership-area") != -1){
      next('/additional-question');
      return true;
    }
    //If user didn't see welcome message but tried to access members area > redirect to welcome message
    else if(
      !res.finishedRegistration &&
      to.path.indexOf("/membership-area") != -1 &&
      to.fullPath.indexOf("/membership-area/matches?reg=1") == -1
    ){
      next('/membership-area/matches?reg=1');
      return true;
    }
    //If user didn't save contact data after buying premium > redirect to confirm page
    else if(res.contactDataRequired && to.path.indexOf("/payments-success-info") == -1){
      next('/payments-success-info');
      return true;
    }
    //Navigation guards for members area and payment sites
    else if(to.path.indexOf("/membership-area") != -1 || to.path.indexOf("/premium-plans") != -1){
      //Check if email address is confirmed - store action will redirect to email confirm site if not
      store.dispatch("getEmailConfirmed").then((res)=>{
        console.log('getEmailConfirmed', res);
        if(res == true){
          //Set up variables for profile paths (should be handled differently)
          const path = "/membership-area/member-profile/";
          const altpath = "/membership-area/user-profile/";
          const gallery = path + 'gallery';
          const profile = path + 'profile';
          //const personality = path + 'personality';
          //const desires = path + 'desires';
          const sexpref = path + 'sexual-preferences';
          const usrgallery = altpath + 'gallery';
          const usrprofile = altpath + 'profile';
          //const usrpersonality = altpath + 'personality';
          //const usrdesires = altpath + 'desires';
          const usrsexpref = altpath + 'sexual-preferences';
          //If navigation to a profile subsite

          if (
            to.path == gallery || to.path == profile || to.path == sexpref /*|| to.path == desires */ ||
            to.path == usrgallery || to.path == usrprofile || to.path == usrsexpref /*|| to.path == usrdesires */
          ) {
            console.log('IF INSIDE PROFILE');
            next();
          }
          //Redirect payment subsites to first page for direct requests
          else if(
            (
              to.path.indexOf("/premium-plans/purchase") != -1 ||
              to.path.indexOf("/premium-plans/form") != -1 ||
              to.path.indexOf("/premium-plans/select") != -1 ||
              to.path.indexOf("/premium-plans/summary") != -1
            ) && from.path == '/'
          ){
            console.log('PREMIUM REDIRECT');
          next("/premium-plans/plans?direct=1");
          }
          //Default navigation guard for all pages that didn't match any other criteria
          else {
            console.log('FINAL ELSE');
            store.dispatch('hideContent');
            store.dispatch('showLoader');
            store.dispatch('setErrorVisibility', false);
            next();
          }
        }
      });

    }
    //Navigation guard for pages outside members area
    else{
      console.log('OUTSIDE MEMBERSAREA');
      store.dispatch('hideContent');
      store.dispatch('showLoader');
      store.dispatch('setErrorVisibility', false);
      next();
    }
  });
});


router.afterEach(()=>{
  //Custom FB tracking
  if(typeof window.fbq != "undefined"){
    //console.log('NO interval FBQ');
    window.fbq('track', 'PageView');

  }else{
    var FBQInterval = setInterval(() => {
      //console.log('inter FBQ');
      if(typeof window.fbq != "undefined"){
        window.fbq('track', 'PageView');
        //console.log('clear FBQ');
        clearInterval(FBQInterval);
      }
    }, 100);
    setTimeout(()=>{clearInterval(FBQInterval);}, 10000);
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}


export default router;