<template>
  <div id="errorPage">
    <div class="container-main">
      <div class="containerNotFound">
        <div class="messageContainer">
          <h1
            class="errorTitle"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.generic.errorTitle = $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.generic.errorTitle }}
          </h1>
          <p
            class="errorOccuredMessage"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.generic.errorOccuredMessage =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.generic.errorOccuredMessage }}
          </p>
          <p
            class="noDataReceivedMessage"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.generic.noDataReceivedMessage =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.generic.noDataReceivedMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
  },
};
</script>
