export default {
  setPersonalDescription(state, payload){
    state.personalDescription = payload;
  },
  setProfileCard(state, payload){
    state.profileCard = payload;
  },
  setMainAnswers(state, payload){
    state.mainAnswers = payload;
  },
  descriptionUpdated(state){
    state.descriptionUpdated = true;
  },
  languageUpdated(state){
    state.languageUpdated = true;
  },
  setRandomQuestions(state, payload){
    state.randomQuestions = payload;
  },
  removeAnsweredChosenQuestion(state, id){
    let i;
    state.randomQuestions.forEach((question, index) => {
      if(question.id == id){
        i = index;
      }
    })
    state.randomQuestions.splice(i, 1);
  },
  setAnsweredRandomQuestions(state, payload){
    state.randomAnsweredQuestions = payload;
  },
  randomQuestionsUpdated(state, data){
    const question = {
      question: data.answer
    }; 
    state.randomAnsweredQuestions.push(question);
  },
  setJobStatus(state){
    state.profileCard.job.status = !state.profileCard.job.status;
  }

};
