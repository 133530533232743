<template>
  <div>
    <form class="login" @submit.prevent="login">
      <h1>TESTING Sign in</h1>
      <label>Email</label>
      <input required v-model="email" type="email" placeholder="Name" />
      <label>Password</label>
      <input required v-model="password" type="password" placeholder="Password" />
      <hr />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
// import axios from 'axios';
export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {

    login() {
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
              this.$store.dispatch("checkCompleted");
              this.$store.dispatch("getEmailConfirmed");
          
            this.$store.dispatch("checkAnsweredQuestionaire").then(res => {
            
              if (res == "error") {
                console.log('error');
              } else {
                if (this.$store.getters.getCompletedQuestionnaire && this.$store.getters.getAdditionalQuestionsAnswered == null) {
                  this.$router.push("completion-message");
                  console.log(this.$store.getters.getAdditionalQuestionsAnswered)
                } else if (this.$store.getters.getCompletedQuestionnaire && this.$store.getters.getAdditionalQuestionsAnswered != null) {
                  this.$router.push("/membership-area/home");
                } else {
                  if(!this.$store.getters.hideFirstMessage)
                  {
                    this.$router.push("/welcome-message");
                  }
                  else {
                    this.$router.push("/personality-test");
                  }
                }
              }
            });
          })
          .catch(err => console.log(err));
    }
  }
};
</script>