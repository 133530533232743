<template>
  <div class="chatMessage typeComment ">
    <div class="msgBox" >
      <div

      class="commentedItemBox"
      >
        <h3
        :class="{ blurred: messageData.blurred }"
        class="messageHeadline"
        >
          <img
            class="message-icon"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Message_White-icon.svg')"
            alt=""
          />{{ messageData.message }}
          <span class="premiumLinkWrapper" v-if="messageData.blurred">
            <router-link to="/premium-plans" class="premiumLink" >
              {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
            </router-link>
          </span>
        </h3>
        <div class="eventInfo">
          <p class="eventHeadline">
            {{ messageData.quote_question }}
          </p>
          <p v-if="showMore" class="eventText">
            <span v-if="typeof messageData.quote_text == 'array'">
            </span>
            <span v-else>
            {{ messageData.quote_text }}
            </span>
          </p>
          <div class="buttonContainer">
            <img
              v-if="editable"
              :style="{ marginLeft: '20px' }"
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
              alt="Translation icon"
            />
            <button
              v-if="!showMore"
              @click="showMore = !showMore"
              class="showMoreButton chatFontSize"
              v-langErr :contenteditable="editable"
              @input="
                $t($i18n.locale).keys.membershipChat.buttons.showMore =
                  $event.target.innerText
              "
            >
              <!-- Show more -->
              {{ $t($i18n.locale).keys.membershipChat.buttons.showMore }}
            </button>
            <input
              v-if="editable && !showMore"
              v-model="$t($i18n.locale).keys.membershipChat.buttons.showMore"
              type="text"
            />
            <button
              v-if="showMore"
              @click="showMore = !showMore"
              class="showMoreButton"
              v-langErr :contenteditable="editable"
              @input="
                $t($i18n.locale).keys.membershipChat.buttons.showMore =
                  $event.target.innerText
              "
            >
              <!-- Show less -->
              {{ $t($i18n.locale).keys.membershipChat.buttons.showLess }}
            </button>
            <input
              v-if="editable && showMore"
              v-model="$t($i18n.locale).keys.membershipChat.buttons.showLess"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>
</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeComment",
  props: ["messageData"],
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  watch: {
    messageData: {
      immediate: true,
      handler () {
      //console.log('messageData Watch-------------------', newVal);
      //console.log('messageData Watch-------------------', oldVal);
      }
    }
  },
  data() {
    return {
      showMore: false
    };
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
    checkPremium(){
      if(!this.$store.getters.userRole.includes('ROLE_PREMIUM')){
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Clicked Blurred Message',
          label: 'Comment'
        });

        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }else return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
