var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"membershipMatches"}},[_c('header',{staticClass:"matchesHeader"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"matchesTitle",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.matches =
          $event.target.innerText}}},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'puzzle-solidwhite-icon.svg'),"alt":"Matches icon"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.matches)+" ")]),_c('div',{staticClass:"matchesDataWrapper"},[_c('div',{staticClass:"matchesQuantity"},[_vm._v(_vm._s(_vm.$store.getters.matchesQuantity))])])]),_c('div',{staticClass:"matchesImagesWrapper"},_vm._l((_vm.$store.getters.getHomeMatches),function(match){return _c('div',{key:match.id + 'match',staticClass:"image1Wrapper",on:{"click":function($event){return _vm.$router.push(`member-profile/profile?id=${match.profile_id}`);}}},[(match.public_path)?_c('img',{staticClass:"matchesImage1",attrs:{"src":`${match.public_path}`}}):_vm._e(),(!match.public_path && (parseInt(match.gender) == 1))?_c('img',{staticClass:"matchesImage1",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg'),"alt":""}}):_vm._e(),(!match.public_path && (parseInt(match.gender) == 2))?_c('img',{staticClass:"matchesImage1",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg'),"alt":""}}):_vm._e(),_c('div',{staticClass:"imageOverlay",class:[match.new == 1 ? 'new' : '']},[(match.new == 1)?_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"newMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.new =
              $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.new)+" ")]):_vm._e(),(match.firstname)?_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"matchInfo"},[_c('span',{staticClass:"matchName"},[_vm._v(_vm._s(match.firstname))]),_c('span',{staticClass:"matchAge"},[_vm._v(", "+_vm._s(match.age))])])]):_vm._e()])])}),0),_c('div',{staticClass:"membershipButtonWrapper"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('router-link',{attrs:{"to":"/membership-area/matches"}},[_c('button',{staticClass:"membershipButton"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches)+" ")])]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
        _vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches
      ),expression:"\n        $t($i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches\n      "}],attrs:{"type":"text"},domProps:{"value":(
        _vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches.seeAllMatches
      )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipMatches, "seeAllMatches", $event.target.value)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }