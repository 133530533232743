<template>
  <div id="questionType5" class="questionComponent">
   
    <h1 v-if="variant != 2" class="questionText">
      {{ questionData.question.translations[selectedLanguage].question }}
    </h1>
    
    <div :class="{'chatStyleAnswer' : variant == 2}">
      <div class="answerWrapper">
        <p
          v-langErr
          class="additionalMessage"
          v-if="questionData.question.id == 1"
        >
          {{ $t($i18n.locale).keys.questionnaire.type5.dobMessage }}
        </p>
        <p
          v-langErr
          class="skippableQuestionMessage"
          v-if="questionData.skippable == 1"
        >
          {{ $t($i18n.locale).keys.generic.skippableQuestionMessage }}
        </p>
        <div class="dateField" v-if="!showInputDatefield">
          <select v-model="answer.day" class="genericInput">
            <option value="Day" disabled selected>{{
              $t($i18n.locale).keys.generic.day
            }}</option>
            <option :value="i + 1" v-for="(day, i) in 31" :key="i">{{
              i + 1
            }}</option>
          </select>
          <select v-model="answer.month" :placeholder="$t($i18n.locale).keys.questionnaire.type5.month"  class="genericInput">
            <option value="Month" disabled selected>{{
              $t($i18n.locale).keys.generic.month
            }}</option>
            <option
              :value="i + 1"
              :placeholder="$t($i18n.locale).keys.questionnaire.type5.month"
              v-for="(month, i) in months"
              :key="i"
              >{{$t($i18n.locale).keys.months[month]  }}</option
            >
          </select>
          <select v-model="answer.year" :placeholder="$t($i18n.locale).keys.questionnaire.type5.yonth"  class="genericInput">
            <option value="Year" disabled selected>{{
              $t($i18n.locale).keys.generic.year
            }}</option>
            <option v-for="i in 82" v-bind:value="(minYear+1) - i" :key="i">{{
              (minYear+1) - i
            }}</option>
          </select>
        </div>
        <div class="dateInput" v-if="showInputDatefield">
            <input
              :placeholder="inputPlaceholder"
              class="genericInput"
              v-model="answerDateInput"
              type="tel"
              minlength="10"
              maxlength="10"
              onPaste="return false"
              @input="handleDateInput"
            />
          <p v-if="answerInputError" class="dateInputError">
            {{ answerInputError }}
          </p>
        </div>
        <p
          class="additionalMessage"
        >{{$t($i18n.locale+'.keys.questionnaire.type5.additionalMessage')}}</p>
      </div>
    </div>
    
    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled="!validDateFormat"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />
    
    <button
      v-else
      v-langErr
      v-bind:class="{ 'btnContinue-disabled': !validDateFormat }"
      :disabled="!validDateFormat"
      @click="sendAnswer"
      class="genericButton btnContinue"
    >
      {{ $t($i18n.locale).keys.generic.continueBtn }}
    </button>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  components: { QuestionNavigation },
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType5",
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      answer: { day: "Day", year: "Year", month: "Month" },
      lockSend: false,
      answerDateInput: '',
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },

    validDateFormat() {

      if(!this.showInputDatefield) {
        return (
          new Date(this.answer.year, this.answer.month, this.answer.day) !=
          "Invalid Date"
        );
      }else{
        var validation = this.validateBirthdate(this.answerDateInput, this.dateFormatByCountry)
        console.log("validDateFormat", validation)
        return validation.success;

      }
     
    },
    answerInputError() {
      var validation = this.validateBirthdate(this.answerDateInput, this.dateFormatByCountry)
      if(validation.error) return this.$t(this.$i18n.locale+'.keys.questionnaire.type5.errorMinAge', {minAge: this.minAge});
      else return '';
    },
    minAge(){
      return 18;
    },
    minYear() {
      return (new Date().getFullYear() - this.minAge);
    },
    showInputDatefield: function() {
      return true;
    },
    dateFormatByCountry: function() {
     
      if(['de', 'at', 'ch', 'cz', 'pl'].includes(domain)){
        return 'dd.mm.yyyy';
      }else{
        return 'yyyy/mm/dd';
      }
    },
    inputPlaceholder: function() {
      if(this.dateFormatByCountry == 'dd.mm.yyyy' && this.selectedLanguage == 'de'){
        return 'tt.mm.jjjj';
      }else if(this.dateFormatByCountry == 'dd.mm.yyyy'){
        return 'dd.mm.yyyy';
      }else{
        return 'yyyy/mm/dd';
      }
    }

  },
  mounted() {
    this.loadDate();
  },
  
  methods: {
    dateFormated() {
      var formatedDate, dateArray, month, day;

      if(this.showInputDatefield){
        console.log("dateFormatted", this.answerDateInput)

        if(this.dateFormatByCountry == 'dd.mm.yyyy'){
          dateArray = this.answerDateInput.split('.')
          formatedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;  
        }else{
          dateArray = this.answerDateInput.split('/')
          formatedDate = `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;  
        }

        console.log("formatedDate",formatedDate)

      }else{
        month = this.answer.month < 10 ? '0' + this.answer.month : this.answer.month;
        day = this.answer.day < 10 ? '0' + this.answer.day : this.answer.day;
        formatedDate = `${this.answer.year}-${month}-${day}`;
      }
      return formatedDate;
    },

    sendAnswer() {
      if(this.lockSend) return true;

      var answerDate;
      answerDate = this.dateFormated();
     
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [answerDate]);
        this.lockSend = false;
      }
      else if (this.questionData.answered) {
        this.$emit("put-answer", {
          answered: [answerDate],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    loadDate() {
      if (this.questionData.answered) {
        var date = this.questionData.answered.answer[0];
        var dateArray = date.split("-");
        let year = dateArray[0];
        let month = parseInt(dateArray[1]);
        let day = parseInt(dateArray[2]);
        this.answer = { day: day, year: year, month: month };

        if(this.dateFormatByCountry == 'dd.mm.yyyy') this.answerDateInput = `${(dateArray[2])}.${dateArray[1]}.${year}`;
        else this.answerDateInput = `${year}/${dateArray[1]}/${dateArray[2]}`;
      }
    },
    handleDateInput(e){
      console.log("handleDateInput", e.target.value, e.inputType, e)
      if(e.inputType == 'deleteContentBackward') return true;

      var val = e.target.value.substring(0, e.target.selectionStart)

      var output = this.handleTyping(this.removeDateMask(val), this.dateFormatByCountry);

      console.log("handleTyping", output)

      this.answerDateInput = this.applyDateMask(output, this.dateFormatByCountry);

      return false;
      
    },

    //TEST METHODS

    handleTyping(e, t) {

      switch (t.replace(/[-/.]/gi, '')) {
      case 'yyyymmdd':
          return this.handleDateFormatAutocompleteYYYYMMDD(e);
      case 'ddmmyyyy':
          return this.handleDateFormatAutocompleteDDMMYYYY(e);
      default:
          return e
      }
    },
    applyDateMask(e, t) {
    for (var i = t.match('[-/.]')[0], r = [], n = 0; n < e.length; n++)
        r.push(e[n]),
        t.startsWith('yyyy') && (3 !== n && 5 !== n || r.push(i)),
        (t.startsWith('dd') || t.startsWith('mm')) && (1 !== n && 3 !== n || r.push(i));
    return r.join('')
    },
    removeDateMask(e) {
    return e.replace(/\D/g, '')
    },
    handleDateFormatAutocompleteDDMMYYYY (e) {

      console.log('handleDateFormatAutocompleteYYYYMMDD',e);

        var t, i, r, n, o, a, s, c, l;

        return 1 === e.length && 
        (
          t = e.charAt(0),
          console.log('t', t),
          3 < parseInt(t, 10) && (e = '0'.concat(e))
        ),

        2 === e.length && 
        (
          i = e.substring(0, 2),
          console.log('i', i),
          r = e.charAt(1),
          console.log('r', r),
          31 < parseInt(i, 10) && 
          (
            e = parseInt(r, 10) < 2 ? '0'.concat(e.charAt(0), e.charAt(1)) : '0'.concat(e.charAt(0), '0', e.charAt(1))
          )
        ),

        3 === e.length && 
        (
          o = e.charAt(2),
          console.log('o', o),
          1 < parseInt(o, 10) && (e = e.slice(0, -1).concat('0', o))
        ),
        
        4 === e.length && 
        (
          n = e.substring(2, 4),
          console.log('n', n),
          12 < parseInt(n, 10) && (
            o = e.charAt(2),
            console.log('o', o),
            a = e.charAt(3),
            console.log('a', a),
            e = e.slice(0, -2).concat('0', o),
            console.log('e', e),
            9 === parseInt(a, 10) && (e = e.concat(a)),
            parseInt(a, 10) < 9 && (e = e.concat('19', a))
          )
        ),
        
        5 === e.length &&
        (
          s = e.charAt(4),
          console.log('s', s),
          0 === parseInt(s, 10) && (e = e.slice(0, -1).concat('20', s)),
          2 < parseInt(s, 10) && (e = e.slice(0, -1).concat('19', s))
        ),
        
        6 === e.length && 
        (
          c = e.substring(4, 6),
          console.log('c', c),
          l = e.charAt(5),
          console.log('l', l),
          20 < parseInt(c, 10) && (e = e.slice(0, -2).concat('200', l)),
          parseInt(c, 10) < 19 && (e = e.slice(0, -2).concat('19', l))
        ),
        e
    }, handleDateFormatAutocompleteYYYYMMDD (e) {

      console.log('handleDateFormatAutocompleteYYYYMMDD',e);

        var t, i, r, n, o, a, s;
        return 1 === e.length && (t = e.charAt(0),
        0 === parseInt(t, 10) && (e = e.slice(0, -1).concat('20', t)),
        2 < parseInt(t, 10) && (e = e.slice(0, -1).concat('19', t))),
        2 === e.length && (i = e.substring(0, 2),
        r = e.charAt(1),
        20 < parseInt(i, 10) && (e = e.slice(0, -2).concat('200', r)),
        parseInt(i, 10) < 19 && (e = e.slice(0, -2).concat('19', r))),
        5 === e.length && (o = e.charAt(4),
        1 < parseInt(o, 10) && (e = e.slice(0, -1).concat('0', o))),
        6 === e.length && (n = e.substring(4, 6),
        12 < parseInt(n, 10) && (o = e.charAt(4),
        a = e.charAt(5),
        e = e.slice(0, -2).concat('0', o),
        parseInt(a, 10) <= 3 && (e = e.concat(a)),
        3 < parseInt(a, 10) && (e = e.concat('0', a)))),
        7 === e.length && (s = e.charAt(6),
        3 < parseInt(s, 10) && (e = e.slice(0, -1).concat('0', s))),
        e
    },
    validateBirthdate(e, t) {
    if (10 !== e.length)
        return {
            success: !1
        };
    var i = t.match('[-/.]')[0]
      , r = e.split(i).map(function(e) {
        return parseInt(e, 10)
    })
      , n = t.split(i)
      , o = r[n.indexOf('dd')]
      , a = r[n.indexOf('mm')]
      , s = r[n.indexOf('yyyy')];
    if (r) {
        var c = new Date(s,a - 1,o);
        if (o < 1 || 31 < o)
            return {
                success: !1,
                error: 'invalidDate'
            };
        if (a < 1 || 12 < a)
            return {
                success: !1,
                error: 'invalidDate'
            };
        var l = (new Date).setFullYear(this.minYear)
          , p = (new Date).setFullYear((new Date).getFullYear() - 90)
          , d = new Date(p).getFullYear()
          , u = new Date(l).getFullYear();
        if (s < d || u < s) {
            if (s < d)
                return {
                    success: !1,
                    error: 'invalidMinYear'
                };
            if (u < s)
                return {
                    success: !1,
                    error: 'invalidAge'
                }
        } else if (s === u) {
            var h = new Date;
            if (a > h.getMonth() + 1 || a === h.getMonth() + 1 && o > h.getDate())
                return {
                    success: !1,
                    error: 'invalidAge'
                }
        }
        if (c.getDate() === o && c.getMonth() === a - 1 && c.getFullYear() === s)
            return {
                success: !0
            }
    }
    return {
        success: !1,
        error: 'invalidDate'
    }
  }

  },
};
</script>
<style lang="scss" scoped></style>
