<template>
  <div v-if="showBar" id="ActionTab" :key="componentKey">
    <span v-if="!isFirst" @click="loadPrevious" class="profileButton buttonBack">&#60;</span>
    <button @click="sendMessage" class="sendMessage">
      <img
        :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope-fixed-white.svg')"
        alt="Like icon"
      />
      <span>
      {{ $t($i18n.locale).keys.memberProfile.actionTab.sendMessage }}
      </span>
    </button>
          <input
          v-if="editable"
          v-model="
            $t($i18n.locale).keys.memberProfile.actionTab.sendMessage
          "
          type="text"
        />
    <template>
      <span v-if="showFunctionButtons">
        <!--
        <img
          v-if="MemberData.icebreaker_question"
          @click="showIBQ($event)"
          class="openIBQ"
          v-b-tooltip.hover
          :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'icebreaker-green-button.svg')"
          alt="Like icon"
        />
        -->
        <div 
          class="actionButton sendSmile"
          :class="{
            sent: MemberData.smile_sent == 1,
            loading: smileLoading
          }"
          @click="sendSmile"
          v-b-tooltip.hover
          :title="MemberData.smile_sent == 1 ? '': $t($i18n.locale + '.keys.tooltips.Smile_button')"
          ref="smileButton"
        >
          <img
            v-show="MemberData.smile_sent == 1"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'smile-grey-button.svg')"
            alt="Like icon"
          />
          <img
            v-show="!MemberData.smile_sent"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'smile-green-button.svg')"
            alt="Like icon"
          />
        </div>
        
        <div 
          class="actionButton makeFavorite"
          @click="toggleBookmark"
          v-b-tooltip.hover
          :title="
          MemberData.bookmark ? 
          $t($i18n.locale + '.keys.tooltips.FavRemove_button') : $t($i18n.locale + '.keys.tooltips.Fav_button')
          "
          ref="favoriteButton"
        >
          <img
            v-show="MemberData.bookmark == 1"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'favourite-selected-button.svg')"
            alt="Favorite icon"
          />
          <img
            v-show="!MemberData.bookmark"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'favourite-green-button.svg')"
            alt="Favorite icon"
          />
        </div>

        <img
          @click="removeMatch"
          class="deleteMessage"
          v-b-tooltip.hover
          :title="$t($i18n.locale + '.keys.tooltips.Del_button')"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'delete-grey-button.svg')"
          alt="Delete icon"
        />
      </span>

      <div
        v-if="!MemberData.reported"
        @click="showReport"
        class="reportUserBtn"
        v-b-tooltip.hover
        :title="$t($i18n.locale + '.keys.tooltips.Report_button')"
      >
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>

    </template>

    <span v-if="!isLast" @click="loadNext" class="profileButton buttonNext">&#62;</span>
<!--
<ModalIcebreakerQuestions ref="modalIBQ" />
-->

    <ModalUserReport ref="modalUserReport" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

//import ModalIcebreakerQuestions from "@/components/membership-area/ModalIcebreakerQuestions.vue";

import ModalUserReport from "@/components/membership-area/member-profile/ModalUserReport.vue";

export default {
  name: "ActionsTab",
components: {
  //ModalIcebreakerQuestions
  ModalUserReport
},
  computed: {
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    filteredIDs() {
      return this.allMatches.map(a => Number(a.matched_user_id));
    },
    filteredMatchIDs() {
      return this.allMatches.map(a => Number(a.id));
    },
    lastID() {
      return this.filteredIDs[this.filteredIDs.length -1];
    },
    firstID() {
      return this.filteredIDs[0];
    },
    isFirst() {
      if(this.currentID == this.firstID) return true;
      else return false;
    },
    isLast() {
      if(this.currentID == this.lastID) return true;
      else return false;
    },
    ...mapGetters(["allMatches", "MemberData", "user"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    userIdFromArray() {
      //debugger;
      console.log("userIdFromArray",this.filteredIDs, this.currentID);
      return this.filteredIDs.indexOf(this.currentID);
    },
    matchIdFromArray() {
      return this.filteredMatchIDs[this.userIdFromArray];
    }
  },
  data() {
    return {
      showBar: true,
      showFunctionButtons: false,
      componentKey: 0,
      currentID: parseInt(this.$route.query.id),
      profileLoaded: false,
      myMatches: null,
      ibqLoader: false,
      selectedIBQId: '',
      currentIBQ: [],
      smileLoading: false,
    };
  },
 updated() {
      if (Number.isNaN(Number(this.$route.query.id)) || this.$route.query.id == undefined || this.$route.query.id == null)  {
      this.$router.push("/membership-area/home");
    }
  },
  created() {
      if (Number.isNaN(Number(this.$route.query.id)) || this.$route.query.id == undefined || this.$route.query.id == null)  {
      this.$router.push("/membership-area/home");
    }
    this.showFunctionButtons = true;
    //this.getProfile(this.$route.query.id);
    setTimeout(() => {
      this.showBar = true;
    }, 100);
  },
  mounted() {},
  methods: {
    ...mapActions(["matchesDeleteConversation"]),
    showReport($event){
      //Default Tooltip delay is 50ms
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')  
      }, 55);
      
      this.$refs.modalUserReport.show(this.currentID, $event.currentTarget);
    },
    showIBQ($event){
      this.$refs.modalIBQ.show(this.currentID, $event.currentTarget);
    },
    removeMatch() {

      this.$bvModal.msgBoxConfirm(this.$t(this.$i18n.locale + '.keys.memberProfile.actionTab.deleteMatchInfo'), {
          title: this.$t(this.$i18n.locale).keys.memberProfile.actionTab.deleteMatchTitle,
          //buttonSize: 'sm',
          okVariant: 'danger',
          modalClass: 'error-dialog',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          okTitle: this.$t(this.$i18n.locale + '.keys.membershipChat.confirm.btnYes'),
          cancelTitle: this.$t(this.$i18n.locale + '.keys.membershipChat.confirm.btnNo'),
        })
          .then(value => {
            //console.log('DELETE', value);
            if(value){
              this.matchesDeleteConversation(this.currentID);
              if(this.isLast) this.loadPrevious();
              else this.loadNext();
            }

          })
          .catch(err => {
            // An error occurred
            console.log('DELETE err', err);
          })

    },
    showActionButtons() {
      this.profileLoaded = true;
    },

    toggleBookmark() {
      
      this.$refs.favoriteButton.classList.add('loading');
      this.$store.dispatch("toggleBookmark", this.MemberData.match_id).then(res => {
        this.$refs.favoriteButton.classList.remove('loading');
        console.log('toggleBookmark res', res)
        //this.$refs.smileButton.classList.remove('loading');
        this.updateMemberData();
      });
      
    },
    sendMessage() {

      if(this.MemberData.blockSend){
        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });
      }
        var match = {
          id: this.MemberData.match_id,
          blockSend: this.MemberData.blockSend
        }
        this.$store
          .dispatch("initiateConversation", match)
          .then(res => {
            if (res == "error") {
              // show error
              //this.$store.dispatch("setErrorVisibility", true);
              this.$store.dispatch("hideContent");
            }
          });
        this.$store.dispatch("reRenderMatchesList");
        
    },
    sendSmile() {
      //if(true){
        if(this.MemberData.smile_sent == 1 || this.smileLoading) return false;
        this.smileLoading = true;
        this.$store.dispatch("sendSmile", this.MemberData.match_id).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            this.$store.dispatch("hideContent");
            this.smileLoading = false;
          } else {
            this.updateMemberData();
          }
        });
            /*
            this.$store.dispatch("fetchMatches").then(res => {
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
                this.$store.dispatch("hideContent");
              } else {
                this.$store.dispatch("getConversations");
              }
            });
          }
          this.$store.dispatch("reRenderMatchesList");
          this.componentKey++;
        });
        */
      /*
      } else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Smile'
        });

        localStorage.setItem("premium", 'false');
        location.href="/premium-plans";
      }
      */
    },
    initiateCorrectNumbers() {
      this.currentID = Number(this.currentID);
      this.showFunctionButtons = true;
    },
    loadNext() {
      /*
      if (this.currentID < this.lastID) {
        this.currentID = Number(this.currentID) + 1;
        this.getProfile(
          this.filteredIDs[this.filteredIDs.indexOf(this.currentID)]
        );
      }*/
      console.log('loadNext', this.currentID);
      if(this.currentID != this.lastID){
        this.currentID = this.filteredIDs[this.userIdFromArray+1];
        console.log('loadNext', this.currentID);
        this.getProfile(this.currentID);
      }
    },
    loadPrevious() {
      if(this.currentID != this.firstID){
        this.currentID = this.filteredIDs[this.userIdFromArray-1];
        console.log('loadPrevious', this.currentID);
        this.getProfile(this.currentID);
      }
    },
    getProfile(profileID) {
      console.log('getProfile', profileID, this.currentID);
      //this.$store.dispatch('showLoader');
      //this.$store.dispatch('hideContent');

      //this.setQueryStringParameter('id', this.currentID);
      this.$router.push({query: {id: this.currentID}});
      //this.$emit("setProfileId", this.currentID);
      this.initiateCorrectNumbers();

      return true;
      /*
      this.$store.dispatch("fetchMemberData", profileID).then(res => {
        //this.$store.dispatch("hideLoader");
        if (res == "error") {
          // show error
          //this.$store.dispatch("setErrorVisibility", true);
        } else {

        }
      });
      this.$store.dispatch("fetchInterestsData", profileID);
      this.$store.dispatch("fetchPersonalityData", {
          profileID: profileID,
          locale: this.selectedLanguage
        });
      this.$store.dispatch("fetchGalleryData", profileID);
      */
    },
    setQueryStringParameter: function (name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
    },
    updateMemberData() {
      this.$store.dispatch("fetchMemberData", {memberID: this.MemberData.profile_id, reset: true}).then(res => {
        console.log('sendSmile fetchMemberData', res)
        this.componentKey++;
        this.smileLoading = false;
      });
    },
  }
};
</script>
<style lang="scss" scoped>
</style>
