<template>
  <div id="personalDetails" class="preferencesBox">
    <div class="personalDetailsWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          class="title"
          v-langErr
          :contenteditable="editable"
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.personalDetails =
              $event.target.innerText
          "
        >
          {{
            $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
              .personalDetails
          }}
        </h1>
      </div>
      <div class="detailsWrapper">
        <div class="detailWrapper" v-if="!mobileView">
          <!-- <div class="secureCodeWrapper">
            <p class="secureCodeText">
              <span>Secure Code: </span>
              <span class="code">SUS_168798</span>
            </p>
          </div>-->
        </div>
        <div class="detailWrapper">
          <!-- start -->
          <form @submit.prevent="validateEmail()">
            <div class="detailsTextWrapper">
              <h3
                class="detailsText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.email =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .email
                }}
              </h3>
            </div>
            <div :key="componentKeyEmail" class="detailsInputWrapper">
              <div class="genericInput">{{ email }}</div>
            </div>
            <div class="detailsInputWrapper">
              <p
                v-if="newEmailEmptyError"
                v-langErr
                class="emptyErrorText"
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.enterEmail =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .enterEmail
                }}
              </p>

              <p
                v-if="newEmailBadFormatError"
                v-langErr
                class="formatErrorText"
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.enterValidEmail =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .enterValidEmail
                }}
              </p>

              <p
                v-if="sameAsOldError"
                v-langErr
                class="sameAsOldError"
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.emailShouldMatch =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .emailShouldMatch
                }}
              </p>
              <p
                v-if="$store.getters.getEmail"
                class="emailExistsError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.alreadyExist =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .alreadyExist
                }}
              </p>
              <input
                type="email"
                class="genericInput"
                :class="{
                  emptyError: newEmailEmptyError,
                  emptyError: newEmailBadFormatError,
                  emptyError: sameAsOldError,
                  emptyError: $store.getters.getEmail,
                }"
                v-model="newEmail"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .newEmailAddress
                "
              />
            </div>
            <div class="detailsInputWrapper">
              <p
                v-if="repeatedEmailEmptyError"
                class="emptyErrorText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.enterEmail =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .enterEmail
                }}
              </p>
              <p
                v-if="repeatedEmailBadFormatError"
                class="formatErrorText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.enterValidEmail =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .enterValidEmail
                }}
              </p>
              <p
                v-if="notSameEmailsError"
                class="sameEmailError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.repeatEmail =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .repeatEmail
                }}
              </p>
              <p
                v-if="sameAsOldError"
                class="sameAsOldError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.emailShouldMatch =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .emailShouldMatch
                }}
              </p>
              <p
                v-if="$store.getters.getEmail"
                class="emailExistsError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.alreadyExist =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .alreadyExist
                }}
              </p>
              <input
                type="email"
                class="genericInput"
                :class="{
                  emptyError: repeatedEmailEmptyError,
                  emptyError: repeatedEmailBadFormatError,
                  emptyError: notSameEmailsError,
                  emptyError: sameAsOldError,
                  emptyError: $store.getters.getEmail,
                }"
                v-model="repeatedEmail"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .confirmEmail
                "
              />
            </div>
          </form>
            <div class="buttonWrapper">
              <button @click="validateEmail()" class="btnSave">
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .saveChnages
                }}
              </button>
              <input
                v-if="editable"
                v-model="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .saveChnages
                "
                type="text"
              />
            </div>
          <!-- end -->
        </div>
        <div class="detailWrapper">
          <!-- start -->
          <form @submit.prevent="changePassword()">
            <div class="detailsTextWrapper">
              <h3
                class="detailsText"
                v-langErr
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .password
                }}
              </h3>
            </div>
            <div class="detailsInputWrapper">
              <p v-if="oldPasswordError !== ''" class="passwordErrorMessage">
                {{ oldPasswordError }}
              </p>
              
              <input
                :class="{ emptyError: oldPasswordError !== '' }"
                class="genericInput"
                v-model="oldPassword"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .oldPassword
                "
                type="password"
                @input="resetPasswortErrors"
              />
            </div>
            <div class="detailsInputWrapper">
              <p v-if="newPasswordError !== ''" class="passwordErrorMessage">
                {{ newPasswordError }}
              </p>
              <p class="passwordErrorMessage" v-if="!$v.newPassword.minLength">
                {{ $t($i18n.locale).keys.generic.minNewPassword }}
                {{ $v.newPassword.$params.minLength.min }}
                {{ $t($i18n.locale).keys.generic.letters }}
              </p>
              <input
                :class="{ emptyError: newPasswordError !== '' }"
                class="genericInput"
                v-model.trim="$v.newPassword.$model"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .newPassword
                "
                type="password"
                @input="resetPasswortErrors"
              />
            </div>
            <div class="detailsInputWrapper">
              <p
                v-if="confrimPasswordError !== ''"
                class="passwordErrorMessage"
              >
                {{ confrimPasswordError }}
              </p>

              <p
                class="passwordErrorMessage"
                v-if="
                  !$v.repeatNewPassword.sameAsPassword &&
                    $v.repeatNewPassword.$dirty
                "
              >
                {{ $t($i18n.locale).keys.generic.passwordIndentical }}
              </p>
              <input
                :class="{ emptyError: confrimPasswordError !== '' }"
                class="genericInput"
                v-model.trim="$v.repeatNewPassword.$model"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .repeatPassword
                "
                type="password"
                @input="resetPasswortErrors"
              />
            </div>
          </form>
            <div class="buttonWrapper">
              <button @click="changePassword()" class="btnSave">
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .saveChnages
                }}
              </button>
              <input
                v-if="editable"
                v-model="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .saveChnages
                "
                type="text"
              />
            </div>
          <!-- end -->
        </div>
        <div class="detailWrapper" :key="componentAddressKey">
          <form @submit.prevent="changeAddress()">
            <div class="detailsTextWrapper">
              <h3
                class="detailsText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.address =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .address
                }}
              </h3>
            </div>
            <div class="detailsInputsWrapper">
              <p
                v-if="addressError"
                class="emptyError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.blankFieldsError =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .blankFieldsError
                }}
              </p>
              <p
                v-if="badFormatAddress"
                class="badFormatError"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.badFormatError =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .badFormatError
                }}
              </p>
              <div class="addressFirstLine">
                <input
                  :class="{ error: addressError || firstnameError }"
                  class="genericInputSmall"
                  v-model="firstname"
                  :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.firstName')"
                />
                <input
                  :class="{ error: addressError || lastnameError }"
                  class="genericInputSmall"
                  v-model="lastname"
                  :placeholder="$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.lastName')"
                />
              </div>
              <div class="addressFirstLine">
                <input
                  :class="{ error: addressError || roadFormatError }"
                  class="genericInputMedium"
                  v-model="road"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.road
                  "
                />
                <input
                  :class="{ error: addressError || numberFormatError }"
                  v-if="!mobileView"
                  class="genericInputXsmall"
                  v-model="number"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.number
                  "
                />
              </div>
              <div class="addressSecondLine">
                <input
                  :class="{ error: addressError || numberFormatError }"
                  v-if="mobileView"
                  class="genericInputXsmall number"
                  v-model="number"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.number
                  "
                />
                <input
                  :class="{ error: addressError || postalCodeFormatError }"
                  class="genericInputXsmall postal"
                  v-model="postalCode"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.postalCode
                  "
                />
                <input
                  :class="{ error: addressError || cityFormatError }"
                  class="cityDropdown genericInputXsmall "
                  v-model="city"
                  :disabled="true"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.city
                  "
                />
                <input
                  :class="{ error: addressError || countryFormatError }"
                  class="countryDropdown genericInputXsmall "
                  v-model="country"
                  :disabled="true"
                  :placeholder="
                    $t($i18n.locale).keys.membershipArea.myAccount
                      .personalDetails.country
                  "
                />
              </div>
            </div>
          </form>
        </div>
        <div class="detailWrapper">
          <form @submit.prevent="changeAddress()">
            <div class="detailsTextWrapper">
              <h3
                class="detailsText"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.phone =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .phone
                }}
              </h3>
            </div>
            <div class="detailsInputWrapper">
              <input
                class="genericInput"
                :class="{ error: phoneNumberError }"
                v-model="phoneNumber"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .enterYourNumber
                "
                type="tel"
              />
            </div>
          </form>
        </div>
        <div class="buttonWrapper">
          <button @click="changeAddress()" class="btnSave">
            {{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .saveChnages
            }}
          </button>
          <input
            v-if="editable"
            v-model="
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .saveChnages
            "
            type="text"
          />
        </div>
      </div>
      <div class="deleteAccountWrapper" v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')">
        <p
          class="title"
          :contenteditable="editable"
          v-langErr
          @blur="
            $t(
              $i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.deleteAccount =
              $event.target.innerText
          "
        >
          {{
            $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
              .deleteAccount
          }}
        </p>
        <p class="infoText" v-if="!this.$store.getters.userRole.includes('ROLE_PREMIUM')">

          <span
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.allOfthe =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .allOfthe
            }}</span
          >&nbsp;<span
            class="here"
            @click="$bvModal.show('bv-modal-deletion-reasons')"
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.here =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .here
            }}</span
          >

          <!--
          <a
            class="here"
            :href="supportLink"
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.contactSupport =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .contactSupport
            }}
          </a>
          -->
        </p>
        <p class="infoText" v-else>
          <a
            class="here"
            :href="supportLink"
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.contactSupport =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .contactSupport
            }}
          </a>
        </p>
        <b-modal id="bv-modal-deletion-reasons" hide-footer>
          <template
            v-slot:modal-title
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.deleteTheAccount =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .deleteTheAccount
            }}</template
          >
          <div class="d-block text-center">
            <p
              class="listTitle"
              :contenteditable="editable"
              v-langErr
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.pleaseTell =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale+'.keys.membershipArea.myAccount.personalDetails.pleaseTell',{productName: $store.getters.productName,})
              }}:
            </p>
            <div class="reasonsWrapper">
              <label
                v-for="reason in deletionReasons"
                :key="reason.id + 'reason'"
                class="reasonItem"
                @click="chooseReason($event), setChosenReasonID(reason.id)"
              >
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p class="reasonText">{{ reason.text }}</p>
              </label>
              <!-- <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.insufficientPartner =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.insufficientPartner}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.dubiousObscene =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.dubiousObscene}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.iDidNotLike =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.iDidNotLike}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.theFunctiomnality =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.theFunctiomnality}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.techIssues =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.techIssues}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.haveChosen =$event.target.innerText"
                >{{$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.haveChosen}}</p>
              </label>
              <label class="reasonItem" @click="chooseReason">
                <input type="checkbox" class="input" />
                <span class="checkmark"></span>
                <p
                  class="reasonText"
                  :contenteditable="editable"
                  @blur="$t($i18n.locale).keys.membershipArea.myAccount.personalDetails.manyEmails =$event.target.innerText"
                >{{$t($i18n.locale+'.keys.membershipArea.myAccount.personalDetails.manyEmails',{productName: $store.getters.productName,})}}</p>
              </label>-->
            </div>
            <p
              class="suggestionsTitle"
              :contenteditable="editable"
              v-langErr
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.doYouHave =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale+'.keys.membershipArea.myAccount.personalDetails.doYouHave',{productName: $store.getters.productName,})
              }}
            </p>
            <textarea
              :placeholder="
                $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .typeHere
              "
            ></textarea>
            <div class="buttonWrapper">
              <button
                class="btnCancel"
                @click="$bvModal.hide('bv-modal-deletion-reasons')"
              >
                {{ $t($i18n.locale).keys.generic.cancelBtn }}
              </button>
              <input
                :style="{ marginLeft: '20px' }"
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.cancelBtn"
                type="text"
              />
              <button
                class="btnSave"
                :disabled="!reasonChosen"
                @click="
                  $bvModal.hide('bv-modal-deletion-reasons');
                  $bvModal.show('bv-modal-deletion-confirmation');
                "
              >
                {{ $t($i18n.locale).keys.generic.saveBtn }}
              </button>
              <input
                :style="{ marginLeft: '20px' }"
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.saveBtn"
                type="text"
              />
            </div>
          </div>
        </b-modal>
        <b-modal id="bv-modal-deletion-confirmation" hide-footer>
          <template
            v-slot:modal-title
            :contenteditable="editable"
            v-langErr
            @blur="
              $t(
                $i18n.locale
              ).keys.membershipArea.myAccount.personalDetails.deleteMyAccount =
                $event.target.innerText
            "
            >{{
              $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                .deleteMyAccount
            }}</template
          >
          <div class="d-block text-center">
            <h3
              class="deletionTitle"
              :contenteditable="editable"
              v-langErr
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.areYouAccount =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .areYouAccount
              }}
            </h3>
            <div class="confirmationWrapper">
              <p
                class="confirmationText"
                :contenteditable="editable"
                v-langErr
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.membershipArea.myAccount.personalDetails.confirmPassword =
                    $event.target.innerText
                "
              >
                {{
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .confirmPassword
                }}:
              </p>
              <p
                v-if="this.$store.getters.getAccountDeleteError !== ''"
                class="incorrecPasswordMessage"
              >
                {{ this.$store.getters.getAccountDeleteError }}
              </p>
              <input
                :class="{
                  error: this.$store.getters.getAccountDeleteError !== '',
                }"
                class="confirmationPassword"
                v-model="confirmationPassword"
                :placeholder="
                  $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                    .password
                "
                type="password"
              />
            </div>
            <p
              class="infoMessage"
              :contenteditable="editable"
              v-langErr
              @blur="
                $t(
                  $i18n.locale
                ).keys.membershipArea.myAccount.personalDetails.deletingYourAccount =
                  $event.target.innerText
              "
            >
              {{
                $t($i18n.locale).keys.membershipArea.myAccount.personalDetails
                  .deletingYourAccount
              }}
            </p>
            <div class="buttonWrapper">
              <button
                class="btnCancel"
                @click="$bvModal.hide('bv-modal-deletion-confirmation')"
              >
                {{ $t($i18n.locale).keys.generic.cancelBtn }}
              </button>
              <input
                :style="{ marginLeft: '20px' }"
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.cancelBtn"
                type="text"
              />
              <button class="btnSave" @click="deleteAccount()">
                {{ $t($i18n.locale).keys.generic.saveBtn }}
              </button>
              <input
                :style="{ marginLeft: '20px' }"
                v-if="editable"
                v-model="$t($i18n.locale).keys.generic.saveBtn"
                type="text"
              />
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "PersonalDetails",
  validations: {
    newPassword: {
      required,
      minLength: minLength(6),
    },
    repeatNewPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  data() {
    return {
      mobileView: false,
      confirmationPassword: "",
      repeatedEmail: "",
      newEmail: "",
      firstname: "",
      lastname: "",
      road: "",
      postalCode: "",
      city: "",
      country: "",
      phoneNumber: "",
      number: "",
      email: "",
      newEmailEmptyError: false,
      repeatedEmailEmptyError: false,
      newEmailBadFormatError: false,
      repeatedEmailBadFormatError: false,
      newNewEmail: "",
      newRepeatedEmail: "",
      notSameEmailsError: false,
      componentKeyEmail: 0,
      sameAsOldError: false,
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      newPasswordError: "",
      oldPasswordError: "",
      confrimPasswordError: "",
      componentPasswordKey: 0,
      addressError: false,
      componentAddressKey: 0,
      deletionReasons: [],
      deletionReasonID: 0,
      reasonChosen: false,
      badFormatAddress: false,
      lastnameError: false,
      firstnameError: false,
      roadFormatError: false,
      numberFormatError: false,
      postalCodeFormatError: false,
      countryFormatError: false,
      cityFormatError: false,
      phoneNumberError: false,
      domain: "en",
      germanDomains: ["de", "at", "ch"],
      englishDomains: ["com", "uk"],
      otherDomains: ["cz", "pl", "fr"],
    };
  },
  computed: {
    ...mapGetters(["getClientSettings"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    supportLink: function () {

      return `mailto:${this.$store.getters.supportEmail}`;

      /*
      var link ='https://support.50slove.com/hc/';
      if(this.domain == 'en'){
        link = link + 'en-001';
      } else if (this.domain == "cz") {
        link = link + 'cs';
      }else{
        link = link + this.domain;
      }

      return link;
      */
    }
  },
   watch:{
    getClientSettings: function(newVal){
      if(!_.isEmpty(newVal)){
         this.setPersonalDetails();
      }
    }
  },

  mounted() {
    this.windowWidth();
    this.defaultDomain();
    this.setPersonalDetails();
    this.$store.dispatch("getDeletionReasons").then(() => {
      this.deletionReasons = this.$store.getters.getDeletionReasons;
    });
  },
  methods: {
    resetPasswortErrors() {
        this.oldPasswordError = "";
        this.newPasswordError = "";
        this.confrimPasswordError = "";
    },
    setPersonalDetails(){
      this.email = this.$store.getters.getClientSettings.personal_details.mail;

      this.$store.getters.getClientSettings.personal_details.address.firstname ==
      null
        ? (this.firstname = "")
        : (this.firstname = this.$store.getters.getClientSettings.personal_details.address.firstname);

      this.$store.getters.getClientSettings.personal_details.address.lastname ==
      null
        ? (this.lastname = "")
        : (this.lastname = this.$store.getters.getClientSettings.personal_details.address.lastname);

      this.$store.getters.getClientSettings.personal_details.address.road ==
      null
        ? (this.road = "")
        : (this.road = this.$store.getters.getClientSettings.personal_details.address.road);
      this.$store.getters.getClientSettings.personal_details.address.number ==
      null
        ? (this.number = "")
        : (this.number = this.$store.getters.getClientSettings.personal_details.address.number);
      this.$store.getters.getClientSettings.personal_details.address
        .postal_code == null
        ? (this.postalCode = "")
        : (this.postalCode = this.$store.getters.getClientSettings.personal_details.address.postal_code);
      this.$store.getters.getClientSettings.personal_details.address.country ==
      null
        ? (this.country = "")
        : (this.country = this.$store.getters.getClientSettings.personal_details.address.country);
      this.$store.getters.getClientSettings.personal_details.address.city ==
      null
        ? (this.city = "")
        : (this.city = this.$store.getters.getClientSettings.personal_details.address.city);
      this.$store.getters.getClientSettings.personal_details.address.phone ==
      null
        ? (this.phoneNumber = "")
        : (this.phoneNumber = this.$store.getters.getClientSettings.personal_details.address.phone);
    },
    defaultDomain: function() {
      let url = window.location.origin.split(".");
      let domain = url[url.length - 1];

      if (this.germanDomains.includes(domain)) {
        return (this.domain = "de");
      } else if (this.englishDomains.includes(domain)) {
        return (this.domain = "en");
      } else if (this.otherDomains.includes(domain)) {
        return (this.domain = domain);
      } else {
        return (this.domain = "en");
      }
    },
    windowWidth() {
      if (window.innerWidth < 576) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    chooseReason(event) {
      this.reasonChosen = true;
      document.querySelectorAll(".reasonItem").forEach((item) => {
        item.firstChild.checked = false;
        console.log(item.firstChild.checked, "checked");
      });
      event.target.checked = true;
      console.log("aaa");
    },
    validateEmail() {
      // eslint-disable-next-line
      const regEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      if (this.newEmail !== "") {
        if (this.newEmail.match(regEx)) {
          if (
            this.newEmail == this.repeatedEmail &&
            this.newEmail !== this.email
          ) {
            this.newNewEmail = this.newEmail;
          } else if (
            this.newEmail == this.repeatedEmail &&
            this.newEmail == this.email
          ) {
            this.sameAsOldError = true;
          } else {
            this.notSameEmailsError = true;
          }
        } else {
          this.newEmailBadFormatError = true;
        }
      } else {
        this.newEmailEmptyError = true;
      }
      if (this.repeatedEmail !== "") {
        if (this.repeatedEmail.match(regEx)) {
          if (
            this.newEmail == this.repeatedEmail &&
            this.repeatedEmail !== this.email
          ) {
            this.newRepeatedEmail = this.repeatedEmail;
          } else if (
            this.newEmail == this.repeatedEmail &&
            this.repeatedEmail == this.email
          ) {
            this.sameAsOldError = true;
          } else {
            this.notSameEmailsError = true;
          }
        } else {
          this.repeatedEmailBadFormatError = true;
        }
      } else {
        this.repeatedEmailEmptyError = true;
      }
      if (this.newRepeatedEmail !== "" && this.newNewEmail !== "") {
        let newEmail = {
          email: this.newNewEmail,
          confirmEmail: this.newRepeatedEmail,
        };

        this.$store.dispatch("updateEmail", newEmail).then(() => {
          this.$store.getters.getNewEmail == true
            ? (this.email = this.newNewEmail)
            : "";
        });
        this.newEmail = "";
        this.repeatedEmail = "";
      }
    },
    changePassword() {
      const changedPassword = {
        oldPassword: this.oldPassword,
        password: this.newPassword,
        passwordConfirm: this.repeatNewPassword,
      };

      if(changedPassword.password != changedPassword.passwordConfirm) {
        this.repeatNewPassword = '';
        this.newPassword = '';

        this.newPasswordError =  this.$t(this.$i18n.locale).keys.generic.passwordIndentical;
        return false;
      }

      this.$store.dispatch("updatePassword", changedPassword).then(() => {
        this.oldPassword = "";
        this.newPassword = "";
        this.repeatNewPassword = "";
        this.oldPasswordError = "";
        this.newPasswordError = "";
        this.confrimPasswordError = "";
        if (Object.keys(this.$store.getters.getEmailErrors).length !== 0) {
          if (
            this.$store.getters.getEmailErrors.old_password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.valueNotBlank &&
            this.$store.getters.getEmailErrors.password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError &&
            this.$store.getters.getEmailErrors.password_confirm ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError
          ) {
            this.oldPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.oldPasswordError;
            this.newPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
            this.confrimPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
          } else if (
            this.$store.getters.getEmailErrors.old_password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.valueNotBlank &&
            this.$store.getters.getEmailErrors.password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError
          ) {
            this.oldPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.oldPasswordError;
            this.newPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
          } else if (
            this.$store.getters.getEmailErrors.password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError &&
            this.$store.getters.getEmailErrors.password_confirm ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError
          ) {
            this.newPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
            this.confrimPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
          } else if (
            this.$store.getters.getEmailErrors.old_password ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.valueNotBlank &&
            this.$store.getters.getEmailErrors.password_confirm ==
              this.$t(this.$i18n.locale).keys.membershipArea.myAccount
                .personalDetails.newPasswordError
          ) {
            this.oldPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.oldPasswordError;
            this.confrimPasswordError = this.$t(
              this.$i18n.locale
            ).keys.membershipArea.myAccount.personalDetails.newPasswordError;
          } else if (this.$store.getters.getEmailErrors.old_password !== "") {
            this.oldPasswordError = this.$store.getters.getEmailErrors.old_password;
          } else if (this.$store.getters.getEmailErrors.password !== "") {
            this.newPasswordError = this.$store.getters.getEmailErrors.password;
          } else if (
            this.$store.getters.getEmailErrors.password_confirm !== ""
          ) {
            this.confrimPasswordError = this.$store.getters.getEmailErrors.password_confirm;
          }
        } else {
          this.oldPassword = "";
          this.newPassword = "";
          this.repeatNewPassword = "";
        }
      });
    },
    changeAddress() {
      this.addressError = false;
      this.badFormatAddress = false;

      const lettersNumbers = /^[0-9a-zA-Z -]+$/;
      const letters = /[a-zA-Z ]+/g;
      const numbers = /^[-+]?[0-9]+$/;
      const phone = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      if (
        (
          !this.firstname.match(letters) ||
          !this.lastname.match(letters) ||
          !this.road.match(lettersNumbers) ||
          !this.number.match(lettersNumbers) ||
          !this.postalCode.match(lettersNumbers) ||
          !this.country.match(letters) ||
          !this.city.match(letters) ||
          !this.phoneNumber.match(phone)) &&
          this.firstname != "" &&
          this.lastname != "" &&
          this.road != "" &&
          this.number != "" &&
          this.postalCode != "" &&
          this.country != "" &&
          this.city != "" &&
          this.phoneNumber != ""
      ) {
        this.badFormatAddress = true;
        !this.firstname.match(letters) ? (this.firstnameError = true) : "";
        !this.lastname.match(letters) ? (this.lastnameError = true) : "";
        !this.road.match(lettersNumbers) ? (this.roadFormatError = true) : "";
        !this.number.match(numbers) ? (this.numberFormatError = true) : "";
        !this.postalCode.match(lettersNumbers)
          ? (this.postalCodeFormatError = true)
          : "";
        !this.country.match(letters) ? (this.countryFormatError = true) : "";
        !this.city.match(letters) ? (this.cityFormatError = true) : "";
        !this.phoneNumber.match(phone) ? (this.phoneNumberError = true) : "";
      } else if (
        this.firstname == "" ||
        this.lastname == "" ||
        this.road == "" ||
        this.number == "" ||
        this.postalCode == "" ||
        this.country == "" ||
        this.city == ""
        // ||      this.phoneNumber == ""
      ) {
        this.addressError = true;
      } else {
        const newAddress = {
          firstname: this.firstname,
          lastname: this.lastname,
          road: this.road,
          number: this.number,
          postalCode: this.postalCode,
          country: this.country,
          city: this.city,
          phone: this.phoneNumber,
        };
        this.$store.dispatch("updateAddress", newAddress).then(() => {
          this.addressError = false;
        });
      }
    },
    setChosenReasonID(id) {
      this.deletionReasonID = id;
    },
    deleteAccount() {
      const deletionInfo = {
        password: this.confirmationPassword,
        reason: this.deletionReasonID,
      };
      this.$store.dispatch("deleteAccount", deletionInfo).then(() => {

        console.log("deleteAccount", this.$store.getters.getAccountDeleteError)

        if (!this.$store.getters.getAccountDeleteError) {

          this.$gtm.trackEvent({
            event: 'Settings',
            category: 'Settings',
            action: 'Delete Account',
            label: 'Reason: ' + this.deletionReasonID
          });

          window.location.href = 'https://' + host +'.'+ this.$store.getters.getCountryDomainLanding

        }
      });
    },
  },
};
</script>
