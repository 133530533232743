<template>
  <div id="personalDescription">
    <Spinner v-if="isLoading" />
    <div class="sectionBox">
      <div class="sectionTitleWrapper">
        <h1
          class="sectionTitle"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.title = $event.target.innerText"
        >{{$t($i18n.locale).keys.currentUserProfile.userProfile.personalDescription.title}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
      <div class="sectionItemsWrapper">
        <div class="leftContainer">
          <div class="infoWrapper">
            <div class="infoBox" v-for="profileItem in profileData" :key="profileItem.id">
              <div class="infoLabel">{{profileItem.label}}:</div>
              <p class="infoText">{{profileItem.value}}</p>
              <img
                v-if="profileItem.editable"
                @click="toggleEdit(profileItem)"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                alt="Edit button"
              />
              <img
                v-else
                @click="toggleEdit(profileItem)"
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button-grey.svg')"
                alt="Edit button"
                v-b-tooltip.hover
                :title="profileItem.tooltip"
              />
              <div v-if="profileItem.showEdit && profileItem.editable" class="editItemsWrapper">
                <div id="" class="itemsListWrapper" v-if="profileItem.answers">
                  <label
                    v-for="(option, index) in profileItem.answers"
                    :key="index"
                    @click="profileItem.saveData = index+1"
                    class="item"
                  >
                    <input
                      :name="`radio_${profileItem.id}`"
                      :checked="profileItem.saveData-1 == index"
                      type="radio"
                      class="input"
                    />
                    <span class="checkmark"></span>
                    <p class="itemName">{{option}}</p>
                  </label>
                </div>
                <div v-else style="display:block; width:100%;">
                  <div class="sliderWrapper" >
                    <vue-slider
                      id="heightFilter"
                      style="width: 100%"
                      v-model="profileItem.saveData"
                      :min="sliderProps.min"
                      :max="sliderProps.max"
                      :tooltip="'always'"
                      :tooltip-placement="'top'"
                      :enable-cross="false"
                      :tooltip-formatter="sliderProps.formatter"
                      :interval="sliderProps.interval"
                    ></vue-slider>
                  </div>
                </div>
                <div class="buttonWrapper">
                  <button
                    @click="profileItem.showEdit = !profileItem.showEdit"
                    class="btnCancel"
                  >{{$t($i18n.locale).keys.generic.cancelBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="saveNewValue(profileItem)"
                    class="btnSave"
                  >{{$t($i18n.locale).keys.generic.saveBtn}}</button>
                  <input
                    :style="{marginLeft : '20px'}"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightContainer">
          <div class="infoWrapper" >
            <div id="aboutMeQuestion" class="mainContainer" v-if="aboutMeQuestion">
              <div
                class="editableContentWrapper"
                :class="{ editBorder: editAboutMe }"
              >
                <div class="editableQuestionWrapper">
                  <h1 class="editableQuestion" v-if="aboutMeQuestion.question">
                    {{ aboutMeQuestion.question.translations[this.$store.getters.selectedLanguage].question }}
                  </h1>
                  <img
                    v-if="!editAboutMe && aboutMeQuestion.status"
                    @click="editAboutMe = !editAboutMe"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'edit-button.svg')"
                    alt="Edit icon"
                  />
                  <img
                    v-if="!aboutMeQuestion.status"
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'review-icon.svg')"
                    alt="Review icon"
                  />
                </div>
                <p
                  class="editableAnswer"
                  :class="{ inReview: !aboutMeQuestion.status }"
                  :contenteditable="editAboutMe"
                  v-if="aboutMeQuestion.answer && !editAboutMe"

                >
                  {{ aboutMeQuestion.answer[0] }}
                </p>
                <textarea
                  id="aboutMeInput"
                  v-if="aboutMeQuestion.answer && editAboutMe"
                  v-model="aboutMeQuestion.answer[0]"
                >

                </textarea>
                <div v-if="editAboutMe" class="buttonWrapper">
                  <button
                    @click="
                      editAboutMe = !editAboutMe;
                      aboutMeQuestion.answer[0] = aboutMeSavedAnswer;
                    "
                    class="btnCancel"
                  >
                    {{ $t($i18n.locale).keys.generic.cancelBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.cancelBtn"
                    type="text"
                  />
                  <button
                    @click="
                      editAboutMe = !editAboutMe;
                      sendUpdatedRandomAnswer(
                        aboutMeQuestion.id,
                        aboutMeQuestion.answer
                      );
                      aboutMeSavedAnswer = aboutMeQuestion.answer[0];
                    "
                    class="btnSave"
                  >
                    {{ $t($i18n.locale).keys.generic.saveBtn }}
                  </button>
                  <input
                    :style="{ marginLeft: '20px' }"
                    v-if="editable"
                    v-model="$t($i18n.locale).keys.generic.saveBtn"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="sectionBox">
      <QuestionsAnswers />
    </div>
    -->
  </div>
</template>

<script>
//import QuestionsAnswers from "./QuestionsAnswers";

// import { VueSuggestion } from "vue-suggestion";
import {mapGetters} from "vuex";

export default {
  name: "PersonalDescription",
  components: {
    //QuestionsAnswers,
    // VueSuggestion
  },
  data() {
    return {
      hover: false,
      componentKey2: 0,
      itemsLimit: false,
      item: {},
      items: [],
      selectedItems: [],
      componentKey: 0,
      showEditRace: false,
      showEditReligion: false,
      showEditSmoking: false,
      showEditEducation: false,
      showEditLanguage: false,
      heightValue: "",
      raceArray: [],
      raceValue: "",
      raceEditValueID: 1,
      chosenRaceID: 1,
      religionArray: [],
      religionValue: "",
      religionEditValueID: 1,
      chosenReligionID: 1,
      smokingArray: [],
      smokingValue: "",
      smokingEditValueID: 1,
      chosenSmokingID: 1,
      educationArray: [],
      educationValue: "",
      educationEditValueID: 1,
      chosenEducationID: 1,
      languagesArray: [],
      languagesElements: "",
      fetchedLanguages: [],
      languageModel: {},
      show: false,
      isLoading:true,
      aboutMeQuestion: {},
      editAboutMe: false,
      aboutMeSavedAnswer: '',
      profileData: {
        height: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.height'),
          value: '',
          tooltip: this.$t(this.$i18n.locale).keys.currentUserProfile.userProfile.personalDescription.changeHeightText,
          showEdit: false,
          editable: true
        },
        relationship: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.relationship'),
          value: '',
          tooltip: null,
          showEdit: false,
          editable: true,
        },
        bodyshape: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.bodyshape'),
          value: '',
          tooltip: null,
          showEdit: false,
          editable: true,
        },
        haircolor: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.haircolor'),
          value: '',
          tooltip: null,
          showEdit: false,
          editable: true,
        },
        eyecolor: {
          label: this.$t(this.$i18n.locale + '.keys.currentUserProfile.userProfile.personalDescription.eyecolor'),
          value: '',
          tooltip: null,
          showEdit: false,
          editable: true,
        },
      },
      sliderProps: {
        interval: 1,
        min: 140,
        max: 200,
        formatter: "{value}cm",
      }
    };
  },
  computed: {
    ...mapGetters(["getCountry", "getPersonalDescription"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      if(this.getCountry == 'uk' || this.getCountry == 'us') return false;
      else return true;
    }
  },
  created(){

  },
  mounted() {
        //set slider to foot
        if(!this.metricUnits){
          this.sliderProps.interval = 0.01;
          this.sliderProps.min = 4.50;
          this.sliderProps.max = 6.60;
          this.sliderProps.formatter = v => {
            var measurement = v;
            var feet = parseInt(measurement);
            var fraction = measurement - feet;
            var inches = parseInt((12.0 * fraction));
            return feet + 'ft ' + inches + 'in';
          }
        }


    // this.$store.dispatch("showLoader");
    // this.$store.dispatch("setErrorVisibility", false);
    // this.$store.dispatch("hideContent");
    this.setProfileData();
return true;

  },
  methods: {
    calcFeet(measurement){
      var feet = parseInt(measurement);
      var fraction = measurement - feet;
      var inches = parseInt((12.0 * fraction));
      return feet + 'ft ' + inches + 'in';
    },
    toggleEdit(el){
      console.log(el, this.profileData);

      //Hide all other edit boxes
      /*
      Object.entries(this.profileData).forEach(item => {
        console.log('toggleEdit', item[1], el);
        if(el != item[1]) item[1].showEdit = false
      })
        */

      el.showEdit = !el.showEdit;
    },
    saveNewValue(el) {
      console.log(el);
      if(!el.id){
        this.sendUnansweredQuestion(el.question.id, el.saveData)
        return false;
      }
      const data = {
        customerAnswerId: el.id,
        answer: [el.saveData]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        console.log("updatePersonalDescription", res)
        if (res == "error") {
          // show error
        }else{
          if(el.answers) el.value = el.answers[el.saveData-1];
          else{
            if(!this.metricUnits){
              el.value = this.calcFeet(el.saveData);
            }else{
              el.value = el.saveData + 'cm';
            }

            el.answerValue = el.saveData;
          }
          el.showEdit = false;
        }
      });
      //send value to server
    },
    onInputAboutMe(e){
      this.aboutMeQuestion.answer[0] = e.target.innerHTML;
    },
    sendUpdatedRandomAnswer(updatedQuestionID, updatedAnswer) {
      console.log("sendUpdatedRandomAnswer",updatedQuestionID , updatedAnswer);
      if(updatedQuestionID){
        const data = {
          customerAnswerId: updatedQuestionID,
          answer: updatedAnswer
        };
        this.$store.dispatch("updatePersonalDescription", data).then(res => {
          if (res == "error") {
            // show error
          }
        });
      }else{
        const answerData = {
          question: this.aboutMeQuestion.question.id,
          answer: updatedAnswer,
          stepId: 107
        };
        this.$store.dispatch("updateRandomQuestion", answerData).then(res => {
          if (res == "error") {
            // show error
          } 
        });
      }
    },
    
    sendUnansweredQuestion(id, answer){
      const answerData = {
          question: id,
          answer: [answer],
          stepId: 107
        };
        this.$store.dispatch("updateRandomQuestion", answerData).then(res => {
          console.log('updateRandomQuestion', res)
          this.$store.dispatch("fetchProfileData").then(()=>{
            this.setProfileData();
            Object.entries(this.profileData).forEach(item => {
              item[1].showEdit = false
            })
          });

        });
    },
  
    setProfileData(){

      //Set up height
      if(this.getPersonalDescription.height.answer[0] == 0) {
         this.profileData.height.value = this.$t(this.$i18n.locale).keys.questionnaire.just_ask;
         this.getPersonalDescription.height.answer[0] = this.sliderProps.min;
      }else{
        if(!this.metricUnits){
          //set values to foot

          var measurement = this.getPersonalDescription.height.answer[0];

          this.heightValue = this.calcFeet(measurement);
          this.profileData.height.value = this.calcFeet(measurement);
        }else{
          this.heightValue = this.getPersonalDescription.height.answer[0] + "cm";
          this.profileData.height.value = this.getPersonalDescription.height.answer[0] + "cm";
        }
      }
      this.profileData.height.saveData = this.getPersonalDescription.height.answer[0];
      this.profileData.height.id = this.getPersonalDescription.height.id;
      this.profileData.height.answerValue = this.getPersonalDescription.height.answer[0];
      if(this.getPersonalDescription.height.question) this.profileData.height.question = this.getPersonalDescription.height.question;

        //Set up eyecolor
        if(this.getPersonalDescription.eyecolor){
          //console.log(this.getPersonalDescription.eyecolor);
          this.profileData.eyecolor.answers = this.getPersonalDescription.eyecolor.question.answerOptions.map(e => {
            //console.log('e', e, this.$store.getters.selectedLanguage);
            return e.translations[this.$store.getters.selectedLanguage].answer;
          });

          this.profileData.eyecolor.value = 
            (this.getPersonalDescription.eyecolor.answer) ? 
            this.profileData.eyecolor.answers[this.getPersonalDescription.eyecolor.answer[0]-1] : '';

          this.profileData.eyecolor.saveData = 
            (this.getPersonalDescription.eyecolor.answer) ? 
            this.getPersonalDescription.eyecolor.answer[0] : '';

          this.profileData.eyecolor.id = this.getPersonalDescription.eyecolor.id;
          if(this.getPersonalDescription.eyecolor.question) this.profileData.eyecolor.question = this.getPersonalDescription.eyecolor.question;

        }
        //Set up bodyshape
        if(this.getPersonalDescription.bodyshape){
          //console.log(this.getPersonalDescription.bodyshape);
          this.profileData.bodyshape.answers = this.getPersonalDescription.bodyshape.question.answerOptions.map(e => {
            //console.log('e', e, this.$store.getters.selectedLanguage);
            return e.translations[this.$store.getters.selectedLanguage].answer;
          });

          this.profileData.bodyshape.value = 
            (this.getPersonalDescription.bodyshape.answer) ? 
            this.profileData.bodyshape.answers[this.getPersonalDescription.bodyshape.answer[0]-1] : '';

          this.profileData.bodyshape.saveData = 
            (this.getPersonalDescription.bodyshape.answer) ? 
            this.getPersonalDescription.bodyshape.answer[0] : '';
          
            this.profileData.bodyshape.id = this.getPersonalDescription.bodyshape.id;
            if(this.getPersonalDescription.bodyshape.question) this.profileData.bodyshape.question = this.getPersonalDescription.bodyshape.question;
        }
        //Set up relationship
        if(this.getPersonalDescription.relationship){
          //console.log(this.getPersonalDescription.relationship);
          this.profileData.relationship.answers = this.getPersonalDescription.relationship.question.answerOptions.map(e => {
            //console.log('e', e, this.$store.getters.selectedLanguage);
            return e.translations[this.$store.getters.selectedLanguage].answer;
          });
          this.profileData.relationship.value = 
            (this.getPersonalDescription.relationship.answer) ?
            this.profileData.relationship.answers[this.getPersonalDescription.relationship.answer[0]-1] : '';
          
          this.profileData.relationship.saveData = 
            (this.getPersonalDescription.relationship.answer) ?
            this.getPersonalDescription.relationship.answer[0] : '';
          
          this.profileData.relationship.id = this.getPersonalDescription.relationship.id;
          if(this.getPersonalDescription.relationship.question) this.profileData.relationship.question = this.getPersonalDescription.relationship.question;
        }
        //Set up haircolor
        if(this.getPersonalDescription.haircolor){
          //console.log(this.getPersonalDescription.haircolor);
          this.profileData.haircolor.answers = this.getPersonalDescription.haircolor.question.answerOptions.map(e => {
            //console.log('e', e, this.$store.getters.selectedLanguage);
            return e.translations[this.$store.getters.selectedLanguage].answer;
          });
          this.profileData.haircolor.value = 
            (this.getPersonalDescription.haircolor.answer) ?
            this.profileData.haircolor.answers[this.getPersonalDescription.haircolor.answer[0]-1] : '';

          this.profileData.haircolor.saveData = 
            (this.getPersonalDescription.haircolor.answer) ?
            this.getPersonalDescription.haircolor.answer[0] : '';

          this.profileData.haircolor.id = this.getPersonalDescription.haircolor.id;
          if(this.getPersonalDescription.haircolor.question) this.profileData.haircolor.question = this.getPersonalDescription.haircolor.question;
        }

        //Get question for about me
        if(this.getPersonalDescription.description){
          this.aboutMeQuestion = this.getPersonalDescription.description;
          console.log("getRandomAnsweredQuestions", this.aboutMeQuestion);
          //this.aboutMeSavedAnswer = this.aboutMeQuestion.answer[0];
        }
        this.isLoading = false;
    },
    resetLanguages() {
      this.selectedItems = [];
      for (let i = 0; i < this.selectedItemsOne.length; i++) {
        this.selectedItems.push({ id: i, name: this.selectedItemsOne[i] });
      }
      this.componentKey++;
    },
    sendLanguages() {
      let selectedItemsLanguages = [];
      this.selectedItems.forEach(language => {
        selectedItemsLanguages.push(language.name);
      });
      const data = {
        langs: [...selectedItemsLanguages]
      };
      this.$store.dispatch("updateLanguage", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
      // send this.selectedItems to server
    },
    removeItem(index) {
      if(this.selectedItems.length > 1){
        this.selectedItems.splice(index, 1);
        if (this.selectedItems.length < 10) {
          this.itemsLimit = false;
          this.componentKey2++;
        }
        this.componentKey++;
      }
    },
    itemSelected(item) {
      this.item = item;
      if (this.selectedItems.length < 10) {
        this.selectedItems.push(this.item);
      } else {
        this.itemsLimit = true;
        this.componentKey2++;
      }
      this.componentKey++;
      this.item = "";
    },
    setLabel(item) {
      console.log(item);
      return "";
    },

    setEditRace() {
      setTimeout(() => {
        document.getElementById(
          `race${this.raceEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseRace(event) {
      document.querySelectorAll("#editRace .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenRaceID = parseInt(event.target.parentElement.id.substr(4)) + 1;
    },
    newRaceValue() {
      this.raceValue = this.raceArray[this.chosenRaceID - 1];
      this.raceEditValueID = parseInt(this.chosenRaceID - 1);

      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.ethnicy.id,
        answer: [this.chosenRaceID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
      //send value to server
    },
    setEditReligion() {
      setTimeout(() => {
        document.getElementById(
          `religion${this.religionEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseReligion(event) {
      document.querySelectorAll("#editReligion .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenReligionID =
        parseInt(event.target.parentElement.id.substr(8)) + 1;
    },
    newReligionValue() {
      this.religionValue = this.religionArray[this.chosenReligionID - 1];
      this.religionEditValueID = parseInt(this.chosenReligionID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.religion
          .id,
        answer: [this.chosenReligionID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    setEditSmoking() {
      setTimeout(() => {
        document.getElementById(
          `smoking${this.smokingEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseSmoking(event) {
      document.querySelectorAll("#editSmoking .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenSmokingID =
        parseInt(event.target.parentElement.id.substr(7)) + 1;
    },
    newSmokingValue() {
      this.smokingValue = this.smokingArray[this.chosenSmokingID - 1];
      this.smokingEditValueID = parseInt(this.chosenSmokingID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.smoking.id,
        answer: [this.chosenSmokingID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    },
    setEditEducation() {
      setTimeout(() => {
        document.getElementById(
          `education${this.educationEditValueID}`
        ).firstChild.checked = true;
      }, 100);
    },
    chooseEduation(event) {
      document.querySelectorAll("#editEducation .item").forEach(item => {
        item.firstChild.checked = false;
      });
      event.target.checked = true;
      this.chosenEducationID =
        parseInt(event.target.parentElement.id.substr(9)) + 1;
    },
    newEducationValue() {
      this.educationValue = this.educationArray[this.chosenEducationID - 1];
      this.educationEditValueID = parseInt(this.chosenEducationID) - 1;
      //send value to server
      const data = {
        customerAnswerId: this.$store.getters.getPersonalDescription.degree.id,
        answer: [this.chosenEducationID]
      };
      this.$store.dispatch("updatePersonalDescription", data).then(res => {
        if (res == "error") {
          // show error
        }
      });
    }
  }
};
</script>

