<template>
  <div>
    <h4>Register</h4>
    <form @submit.prevent="register">
      <label for="email" >E-Mail Address</label>
      <div>
          <input id="email" type="email" v-model="email" required>
      </div>


      <label for="gender" >gender</label>
      <div>
          <input id="gender" type="text" v-model="gender" required>
      </div>

      <label for="password">Password</label>
      <div>
          <input id="password" type="password" v-model="password" required>
      </div>

      <label for="password-confirm">Confirm Password</label>
      <div>
          <input id="password-confirm" type="password" v-model="password_confirmation" required>
      </div>

      <div>
          <button type="submit">Register</button>
      </div>
    </form>
    payments
    <!-- <form action="https://processingpartners.docs.oppwa.com/tutorials/integration-guide" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      gender: "",
      password: "",
      password_confirmation: "",
    //   payments: {"data": {
    //     "script_url": "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=E3B87C16EBBCD73B799DBF2DCA7C79C3.uat01-vm-tx04",
    //     "brands": "VISA MASTER AMEX"
    // },
    // "errors": null
    // }
    }
  },

  methods: {
    register() {
      let data = {
        email: this.email,
        gender: this.gender,
        password: this.password,
        country: 'de',
        locale: 'de'

      };
      this.$store
        .dispatch("register", data)
        .then(() => this.$router.push("/"))
        .catch(err => console.log(err));
    }
  }
};
</script>