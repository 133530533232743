<template>
  <div v-if="meetingData" id="SendMobileMessage">
    <div class="container-mobile">
      <!--
      <div class="chatHeaderMobile">
        <img
          @click="returnChat"
          class="chatBackBtn"
          src="../../../assets/images/Arrow-white-left.svg"
          alt=""
        />

           <div
            v-if="meetingData.left_card.public_path"
            class="chatImage"
            v-bind:style="{
              backgroundImage: 'url(' + meetingData.left_card.public_path + ')',
              backgroundRepeat: 'no-repeat'
            }"
          ></div>
          <div
            v-if="!meetingData.left_card.public_path && (parseInt(meetingData.left_card.gender) == 1)"
            class="chatImage"
            :style="{
              backgroundImage:
                'url(' + require('../../../assets/images/silhouette-man.svg')
            }"
          ></div>
          <div
            v-if="!meetingData.left_card.public_path && (parseInt(meetingData.left_card.gender) == 2)"
            class="chatImage"
            :style="{
              backgroundImage:
                'url(' + require('../../../assets/images/silhouette-woman.svg')
            }"
          ></div>
        <h1 class="meetInfo">  {{ meetingData.left_card.name }},
                  {{ getUserAge(meetingData.left_card.age.date) }}</h1>
      </div>
      -->
      <!-- works -->
      <form class="messageBox-form" @submit.prevent="sendMessage(buffer)">
        <input
          v-on:keyup.enter="send"
          ref="textarea"
          type="text"
          :placeholder="$t($i18n.locale).keys.membershipChat.typeHere"
          class="messageBox"
          v-model="buffer"
        />
        <button
          class="emoji-trigger showEmoji"
          :class="{ triggered: showEmojiPicker }"
          @click.stop.prevent="toggleEmojiPicker"
          id="emoji-button"
        ></button>
        <div @click="sendMessage(buffer)" class="sendMessage"></div>
        <picker
          :style="{ position: 'relative', bottom: '470px', left: '6px' }"
          v-show="showEmojiPicker"
          title="Pick your emoji..."
          emoji="point_up"
          @select="addEmoji"
          v-if="loadPicker"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue";
import getUserAge from "../../../helpers/userAge";

export default {
  name: "SendMobileMessage",
    props: ["meetingData"],
  components: { Picker },
  methods: {
        getUserAge(birthday) {
      return getUserAge(birthday);
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
      this.loadPicker = true;
    },
    addEmoji(emoji) {
      const textarea = this.$refs.textarea;
      const cursorPosition = textarea.selectionEnd;
      const start = this.buffer.substring(0, textarea.selectionStart);
      const end = this.buffer.substring(textarea.selectionStart);
      this.buffer = start + emoji.native + end;
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + emoji.native.length;
      });
    },
    sendMessage(messageData) {
      if(this.buffer == '') return true;
      this.$emit("sendMessage", messageData);
      this.showEmojiPicker = false;
      this.buffer =  '';
    },
    returnChat() {
      this.$emit("changeChat");
    }
  },
  data() {
    return {
      showEmojiPicker: false,
      buffer: "",
      loadPicker: false
    };
  }
};
</script>

<style lang="scss" scoped>
</style>
