<template>
  <div id="visitorsList">
    <div
      v-if="$store.getters.allFilteredVisitors.length"
      class="visitorsWrapper"
      v-bind:class="[
        $store.getters.galleryViewVisitors ? 'galleryView' : '',
        $store.getters.listViewVisitors ? 'listView' : '',
        isMobile ? 'mobileView' : ''
      ]"
    >
      <div
        class="visitorWrapper"
        :class="{
          onlineUser: visitor.online
        }"
        v-for="(visitor, i) in filteredVisitorsList"
        :key="i + visitor.id + 'list'"
        @click.stop="goToProfile(visitor)"
      >
        <div class="imageWrapper">
          <img
            v-if="visitor.public_path"
            :src="visitor.public_path"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="visitorsImage"
          />
          <div
            class="imageOverlay"
            v-bind:class="[visitor.new == 1 ? 'new' : '']"
          >
            <p
              class="newMessage"
              v-if="visitor.new == 1"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipVisitors.visitorsList.new =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.membershipVisitors.visitorsList.new }}
            </p>
          </div>
          <div
            v-if="!visitor.public_path && visitor.photo_request"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest(visitor.matched_user_id, $event)"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
          </div>
          <Countdown
            v-if="visitor.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
            :end-date="new Date(visitor.expires*1000)" 
            @timer-ended="countdownEnded(visitor)"
            :timertype="'inline_timer'"
          />
        </div>
        <div class="infoWrapper">
          <div class="nameAgeWrapper">
            <!--
            <img v-if="parseInt(visitor.entertainment) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart icon">
            -->
            <div class="nameAge">
              <span class="username">{{ visitor.firstname }}</span>, {{ getAge(visitor.birthday) }}
            </div>
          </div>
          <div class="basicInfoWrapper">
            <!--
            <div class="workWrapper">
              <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'job-icon.svg')" alt="Job icon" />
              <p class="workText">{{ visitor.profession }}</p>
            </div>
            -->
            <div class="locationWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'house-icon.svg')"
                alt="Location icon"
              />
              <p class="locationText">{{ visitor.city }}</p>
            </div>
            <!--
            <div class="visitorWrapper">
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidblue-icon.svg')"
                alt="visitors icon"
              />
              <p class="visitorsPercentage">{{ visitor.accuracy }}</p>
            </div>
            -->
          </div>
          <div class="actionsWrapper">
            <!--
            <button
              class="sendMessageButton"
              @click="initiateConversation(visitor)"
            >
              <img
                :src="require('@/assets/images/' + $store.getters.assetsPath + 'envelope-white-icon.svg')"
                alt="Envelope icon"
              />
              {{ $t($i18n.locale).keys.membershipVisitors.visitorsList.sendMessage }}
            </button>
            -->
            <div 
              @click="initiateConversation(visitor)" 
              class="iconChat icons"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.membershipVisitors.visitorsList.sendMessage')"
              ></div>
            <!--
            <div
              v-if="visitor.icebreaker_question"
              class="iconIBQ icons"
              @click="showIBQ(visitor.matched_user_id, $event)"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
            ></div>
            -->
            <div
              @click.stop="smileSent(visitor, $event)"
              :id="'visitor'+visitor.id"
              :class="{ 
                smileSent: visitor.smile_sent == 1,
                loading: smileLoading 
              }"
              class="iconSmile icons"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.Smile_button')"
            ></div>
            <div
              @click.stop="toggleBookmark(visitor, $event)"
              :id="'bookmark'+visitor.id"
              :class="[visitor.bookmark == 1 ? 'added' : '']"
              class="iconFavourites icons"
              v-b-tooltip.hover
              :title="[visitor.bookmark == 1 ? $t($i18n.locale + '.keys.tooltips.FavRemove_button') : $t($i18n.locale + '.keys.tooltips.Fav_button')]"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="visitorWrapperGallery"
        :class="{
          onlineUser: visitor.online
        }"
        v-for="(visitor, i) in filteredVisitorsGallery"
        :key="i + visitor.id + 'gallery'"
        @click.stop="goToProfile(visitor)"
      >
        <div class="imageWrapper">
          <img
            v-if="visitor.public_path"
            :src="visitor.public_path"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="visitorsImage"
          />
          <div
            class="imageOverlay"
            v-bind:class="[visitor.new == 1 ? 'new' : '']"
          >
            <p
              class="newMessage"
              v-if="visitor.new == 1"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipVisitors.visitorsList.new =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.membershipVisitors.visitorsList.new }}
            </p>
            <Countdown
              v-if="visitor.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
              :end-date="new Date(visitor.expires*1000)" 
              @timer-ended="countdownEnded(visitor)"
              :timertype="'inline_timer'"
            />
            <div class="bottomTextWrapper">
              <div class="nameWrapper">
                <div class="nameAgeText">
                  {{ visitor.firstname.length > 15 ? visitor.firstname.substring(0, 14).concat('...') : visitor.firstname }}
                </div>
              </div>
              <div class="wrapper">
                <div class="nameAgeTextWrapper">
                  <!--
                  <img v-if="parseInt(visitor.entertainment) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart icon">
                  -->
                  <div class="nameAgeText">
                    {{ getAge(visitor.birthday) }}, {{ visitor.city }}
                  </div>
                </div>
                <!--
                <div class="visitoredPercentageWrapper">
                  <img
                    :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidwhite-icon.svg')"
                    alt="visitors icon"
                  />
                  <p class="visitoredPercentage">{{ visitor.accuracy }}</p>
                </div>
                -->
              </div>
            </div>
            <div class="overlayWrapper">
              <div class="darkOverlay"></div>
              <div class="basicInfoWrapper">
                <div class="nameAgeTextWrapper">
                  <!--
                  <img v-if="parseInt(visitor.entertainment) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart green">
                  -->
                  <div class="nameText">
                    {{ visitor.firstname }}
                  </div>
                </div>
                <div class="locationAgeText">{{ getAge(visitor.birthday) }}, {{ visitor.city }}</div>
                <div class="jobText">{{ visitor.profession }}</div>
                <div
                  v-if="!visitor.public_path && visitor.photo_request"
                  class="photoRequestButton"
                  @click.stop.prevent = "sendPhotoRequest(visitor.matched_user_id, $event)"
                  >
                  {{
                    $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
                  }}
                </div>
              </div>
              <!--
              <div class="visitoredPercentageWrapper">
                <img
                  :src="require('@/assets/images/' + $store.getters.assetsPath + 'puzzle-solidwhite-icon.svg')"
                  alt="visitors icon"
                />
                <p class="visitoredPercentage">{{ visitor.accuracy }}</p>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="visitorWrapperMobile"
        :class="{
          onlineUser: visitor.online
        }"
        v-for="(visitor, i) in filteredVisitorsMobile"
        :key="i + visitor.id + 'mobile'"
        @click.stop="goToProfile(visitor)"
      >
        <div class="imageWrapper">
          <img
            v-if="visitor.public_path"
            :src="visitor.public_path"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 1)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
            class="visitorsImage"
          />
          <img
            v-if="!visitor.public_path && (parseInt(visitor.gender) == 2)"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
            class="visitorsImage"
          />
          <div
            class="imageOverlay"
            v-bind:class="[visitor.new == 1 ? 'new' : '']"
          >
            <p
              class="newMessage"
              v-if="visitor.new == 1"
              v-langErr :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.membershipVisitors.visitorsList.new =
                  $event.target.innerText
              "
            >
              {{ $t($i18n.locale).keys.membershipVisitors.visitorsList.new }}
            </p>
          </div>
          <div
            v-if="!visitor.public_path && visitor.photo_request"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest(visitor.matched_user_id, $event)"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
          </div>
          <Countdown
            v-if="visitor.expires  && !$store.getters.userRole.includes('ROLE_PREMIUM')"
            :end-date="new Date(visitor.expires*1000)" 
            @timer-ended="countdownEnded(visitor)"
            :timertype="'inline_timer'"
          />
        </div>
        <div class="infoWrapper">
          <div class="nameAgeText">
            <!--
            <img v-if="parseInt(visitor.entertainment) == 1" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart icon">
            -->
            <span class="visitorsUsername">{{ visitor.firstname > 8 ? visitor.firstname.substring(0, 7).concat('...') : visitor.firstname}}</span>, {{ getAge(visitor.birthday) }}
          </div>
          <div class="locationText">{{ visitor.city }}</div>
          <div class="iconsWrapper">
            <!--
            <div
              @click="initiateConversation(visitor)"
              class="iconChat"
            ></div>
            -->
            <!--
            <div v-if="visitor.icebreaker_question" @click="showIBQ(visitor.matched_user_id, $event)" class="iconIBQ"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.IBQ_button')"
             ></div>
            -->
            <!--
            <div
              v-on="
                visitor.smile_sent == null || visitor.smile_sent == 0
                  ? { click: () => (smileSent(visitor), addClass(visitor.id)) }
                  : {}
              "
              :id="'visitor'+visitor.id"
              :class="{ smileSent: visitor.smile_sent == 1 }"
              class="iconSmile"
              v-b-tooltip.hover
              :title="$t($i18n.locale + '.keys.tooltips.Smile_button')"
            ></div>
            -->
            <div
              @click.stop.prevent="toggleBookmark(visitor, $event)"
              :id="'bookmark'+visitor.id"
              :class="[visitor.bookmark == 1 ? 'added' : '']"
              class="iconFavourites"
              v-b-tooltip.hover
              :title="[visitor.bookmark == 1 ? $t($i18n.locale + '.keys.tooltips.FavRemove_button') : $t($i18n.locale + '.keys.tooltips.Fav_button')]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <ModalIcebreakerQuestions ref="modalIBQ" />
    -->
  </div>

</template>

<script>
//import ModalIcebreakerQuestions from "@/components/membership-area/ModalIcebreakerQuestions.vue";
import Countdown from "@/components/generic/Countdown.vue";

import { mapGetters } from "vuex";

export default {
  name: "VisitorsList",
  components: {
    Countdown
    //ModalIcebreakerQuestions
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      filteredVisitorsList: [],
      filteredVisitorsGallery: [],
      filteredVisitorsMobile: [],
      listItems: 0,
      galleryItems: 0,
      mobileItems: 0,
      visitorsLoading: false,
      visitorsLoadData: {
        offset: 0,
        limit: 10
      },
      smileLoading: false
    };
  },
  watch: {
    allFilteredVisitors: function() {
      this.setFilteredVisitors();
      $(window).trigger('scroll');
    }
  },
  mounted() {
    this.setFilteredVisitors();
    //this.scroll();
    window.scrollTo(0, 0);
    $(window).resize(function(){
      console.log('resize');
      if($('body').get(0).scrollHeight <= $('body').get(0).offsetHeight && this.$store.getters.allFilteredVisitors.length > 3){
        console.log('scroll triggered');
        $(window).trigger('scroll');
      }
    }.bind(this));

    //disable tooltips on mobile
    setTimeout(() => {
      if(this.isMobile) this.$root.$emit('bv::disable::tooltip');
    }, 100);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  computed: {
    ...mapGetters([
      "galleryViewVisitors",
      "listViewVisitors",
      "allFilteredVisitors",
      "allVisitors",
      "isMobile"
    ]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  methods: {
    countdownEnded(match){
      this.$store.dispatch("removeMatchFromList", match).then(res => {
       console.log('countdownEnded res', res)
      });
    },
    showIBQ(profile_id,$event){
      this.$refs.modalIBQ.show(profile_id, $event.currentTarget);
    },
    goToProfile(visitor) {
      this.$router.push(`member-profile/profile?id=${visitor.matched_user_id}`);
    },

    removeDuplicates(arr) {
      let filtered = arr.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filtered;
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 10;

        if (bottomOfWindow) {
          this.filteredVisitorsList.push(
            ...this.$store.getters.allFilteredVisitors.slice(
              this.listItems - 1,
              this.listItems
            )
          );
          this.filteredVisitorsList = this.removeDuplicates(
            this.filteredVisitorsList
          );
          this.listItems++;

          let from = this.galleryItems;
          let to = this.galleryItems + 2;
          this.filteredVisitorsGallery.push(
            ...this.$store.getters.allFilteredVisitors.slice(
              from,
              to
            )
          );
          this.filteredVisitorsGallery = this.removeDuplicates(
            this.filteredVisitorsGallery
          );
          this.galleryItems += 2;

          let fromMobile = this.mobileItems;
          let toMobile = this.mobileItems + 2;
          this.filteredVisitorsMobile.push(
            ...this.$store.getters.allFilteredVisitors.slice(
              fromMobile,
              toMobile
            )
          );
          this.filteredVisitorsMobile = this.removeDuplicates(
            this.filteredVisitorsMobile
          );
          this.mobileItems += 2;
        }
      };
    },
    setFilteredVisitors() {
      const visitorsList = this.$store.getters.allFilteredVisitors;
      this.filteredVisitorsList = [];
      this.filteredVisitorsList.push(...visitorsList);
      this.listItems = 3;
      const visitorsGallery = this.$store.getters.allFilteredVisitors;
      this.filteredVisitorsGallery = [];
      this.filteredVisitorsGallery.push(...visitorsGallery);
      this.galleryItems = 6;
      const visitorsMobile = this.$store.getters.allFilteredVisitors;
      this.filteredVisitorsMobile = [];
      this.filteredVisitorsMobile.push(...visitorsMobile);
      this.mobileItems = 4;
    },
    /*
    initiateConversation(visitor) {
      if(!visitor.blockSend){
        this.$store.dispatch("initiateConversationVisitors", visitor).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            this.$store.dispatch("hideContent");
          }
        });
      } else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });

        localStorage.setItem("premium", 'false');
        this.$router.push('/premium-plans');
      }
    },
    */
    initiateConversation(visitor) {
      console.log('initiateConversation', visitor);
      if(visitor.blockSend){
      this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Chat'
        });
      }
      visitor.isVisitor = true;
      this.$store.dispatch("initiateConversation", visitor).then(res => {
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
          this.$store.dispatch("hideContent");
        }
      });
    },
    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    toggleBookmark(visitor, $event) {
      if(!visitor || !visitor.match_id) return false;

      console.log('toggleBookmark', $event);
      var button = $event.target;
      button.classList.add('loading');

      this.$store.dispatch("toggleBookmarkVisitors", visitor).then(res => {
        console.log('toggleBookmark res', res);
        button.classList.remove('loading');
        //this.$store.dispatch("hideLoader");
        if (res == "error") {
          //this.$store.dispatch("setErrorVisibility", true);
        } else {
          this.$store.dispatch("showContent");
        }
      });
    },
    smileSent(visitor, $event) {
      console.log('smileSent', $event);
      if(visitor.smile_sent == "1" || this.smileLoading) return false;

      //if(true){
        this.smileLoading = true;
        this.$store.dispatch("sendSmileVisitors", visitor.id).then(res => {
          console.log('smileSent res', res);
          this.smileLoading = false;
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            //this.$store.dispatch("hideContent");
          }
        });
        //this.$store.dispatch("reRenderVisitorsList");
        // this.componentKey++;
      /*
      } else {

        this.$gtm.trackEvent({
          event: 'NPM',
          category: 'NPM',
          action: 'Send Message Attempt',
          label: 'Smile'
        });

        localStorage.setItem("premium", 'false');
        this.$router.push('/premium-plans');
      }
      */
    },
    addClass(id){
      const currentId = 'visitor'+id;
      document.getElementById(currentId).classList.add('smileSent');
    },
    addClassBookmark(id){
      const currentId = 'bookmark'+id;
      !document.getElementById(currentId).classList.contains('added') ? document.getElementById(currentId).classList.add('added'): '';
    },
    sendPhotoRequest(id, $event) {
      console.log('sendPhotoRequest method', $event);
              this.$store.dispatch("sendPhotoRequest", id).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            //this.$store.dispatch("hideContent");
          } else {
              //remove photo button here
              $($event.target).remove();
          }
        });
      
    },
  }
};
</script>

<style lang="scss" scoped></style>
