<template>
  <div
    id="ageAndHeight"
    :key="componentKey"
    class="preferencesBox"
  >
    <div class="ageAndHeightWrapper preferencesContentWrapper">
      <div class="titleWrapper">
        <h1
          v-langErr :contenteditable="editable"
          @blur="
            $t($i18n.locale).keys.matchesPreferences.ageAndHeight.title =
              $event.target.innerText
          "
          class="title"
        >
          {{ $t($i18n.locale).keys.matchesPreferences.ageAndHeight.title }}
        </h1>
        <img
          v-if="editable"
          :style="{ marginLeft: '20px' }"
          :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
          alt="Translation icon"
        />
      </div>
      <div class="ageFilterWrapper filterWrapper">
        <div class="ageTextWrapper textWrapper">
          <h3
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.matchesPreferences.ageAndHeight.ageBracketQuestion =
                $event.target.innerText
            "
            class="ageText"
          >
            {{
              $t($i18n.locale).keys.matchesPreferences.ageAndHeight
                .ageBracketQuestion
            }}
          </h3>
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper range">
          <vue-slider
            id="ageFilter"
            v-model="ageFilterValue"
            :min="minAgeFilterValue"
            :max="maxAgeFilterValue"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            @change="sendAgeAndHeightValues"
            :lazy="true"
          ></vue-slider>
        </div>
      </div>
      <div class="ageImportanceWrapper filterWrapper">
        <div class="ageTextWrapper textWrapper">
          <h3
            class="ageText"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.matchesPreferences.ageAndHeight.ageDifferenceImportanceQuestions =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.matchesPreferences.ageAndHeight
                .ageDifferenceImportanceQuestions
            }}
          </h3>
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
        <div  class="sliderWrapper steps">
          <vue-slider
            id="ageImportanceFilter"
            v-model="ageImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="ageImportanceMarks"
            :adsorb="true"
            :included="true"
            @change="sendAgeAndHeightValues"
            :lazy="true"
          ></vue-slider>
        </div>
      </div>
      <div class="heightWrapper filterWrapper">
        <div class="heightTextWrapper textWrapper">
          <h3
            class="heightText"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.matchesPreferences.ageAndHeight.heightRangeQuestion =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.matchesPreferences.ageAndHeight
                .heightRangeQuestion
            }}
          </h3>
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper range">
          <vue-slider
            id="heightFilter"
            v-model="heightFilterValue"
            :min="minHeightFilterValue"
            :max="maxHeightFilterValue"
            :interval="heightFilterInterval"
            :tooltip="'always'"
            :tooltip-placement="'bottom'"
            :tooltip-formatter="heightFilterFormatter"
            :enable-cross="false"
            @change="sendAgeAndHeightValues"
            :lazy="true"
          ></vue-slider>
        </div>
      </div>
      <div class="heightImportanceWrapper filterWrapper">
        <div class="heightTextWrapper textWrapper">
          <h3
            class="heightText"
            v-langErr :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.matchesPreferences.ageAndHeight.heightDifferenceImportanceQuestion =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.matchesPreferences.ageAndHeight
                .heightDifferenceImportanceQuestion
            }}
          </h3>
          <img
            v-if="editable"
            :style="{ marginLeft: '20px' }"
            :src="require('@/assets/images/' + $store.getters.assetsPath + 'Translate-Icon.svg')"
            alt="Translation icon"
          />
        </div>
        <div class="sliderWrapper steps">
          <vue-slider
            id="heightImportanceFilter"
            v-model="heightImportanceValue"
            :min="1"
            :max="7"
            :tooltip="'none'"
            :tooltip-placement="'bottom'"
            :enable-cross="false"
            :marks="heightImportanceMarks"
            :adsorb="true"
            :included="true"
            @change="sendAgeAndHeightValues"
            :lazy="true"
          ></vue-slider>
        </div>
      </div>
      <!--
      <div class="buttonWrapper">
        <button @click="sendAgeAndHeightValues()" class="btnSave">
          {{ $t($i18n.locale).keys.generic.save }}
        </button>
        <input
          :style="{ marginLeft: '20px' }"
          v-if="editable"
          v-model="$t($i18n.locale).keys.generic.save"
          type="text"
        />
      </div>
      -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AgeAndHeight",

//  editing mark texts
  // updated() {
  //   let notImportant = document.getElementsByClassName(
  //     "editableMark-notImportant"
  //   );
  //   for (let index = 0; index < notImportant.length; index++) {
  //     notImportant[index].style.backgroundColor = "red";
  //     notImportant[index].contentEditable = true;
  //     notImportant[index].blur =
  //    this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant = notImportant[index].innerHTML;
  //   }
  // },
  data() {
    return {
      componentKey: 0,
      ageFilterValue: [40,100],
      ageImportanceValue: 3,
      minAgeFilterValue: 18,
      maxAgeFilterValue: 100,
      
      minHeightFilterValue: 140,
      maxHeightFilterValue: 200,
      heightFilterValue: [140,200],
      heightFilterInterval: 1,
      heightImportanceValue: 3,
    };
  },
  created() {
    if(!this.metricUnits){
      //set values to foot

      this.heightFilterInterval = 0.01;
      this.minHeightFilterValue = 4.5;
      this.maxHeightFilterValue = 6.6;
      this.heightFilterValue = [4.5,6.6];
      this.heightFilterFormatter = v => {
          var measurement = v;
          var feet = parseInt(measurement);
          var fraction = measurement - feet;
          var inches = parseInt((12.0 * fraction));
          return feet + 'ft ' + inches + 'in';
        };

    }else{

      this.heightFilterInterval = 1;
      this.minHeightFilterValue = 140;
      this.maxHeightFilterValue = 200;
      this.heightFilterValue = [140,200];
      this.heightFilterFormatter = "{value}cm";
    }

  },
  mounted() {
    // this.$store.dispatch("hideContent");
    // this.$store.dispatch("showLoader");
    //this.setAgeFilters();
    this.setResponsiveSteps();

  },
  computed: {
    ...mapGetters(["getAgeAndHeight","getCountry"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    metricUnits: function(){
      if(this.getCountry == 'uk' || this.getCountry == 'us') return false;
      else return true;
    },
    ageImportanceMarks: function (){
      return {
        "1": {
          label: this.$t(this.$i18n.locale + '.keys.matchesPreferences.labels.notImportant'),
        },
        "2": {
          label: "",
        },
        "3": {
          label: "",
        },
        "4": {
          label: this.$t(this.$i18n.locale + '.keys.matchesPreferences.labels.somewhatImportant'),
        },
        "5": {
          label: "",
        },
        "6": {
          label: ""
        },
        "7": {
          label: this.$t(this.$i18n.locale + '.keys.matchesPreferences.labels.veryImportant'),
        }
      }
    },
    heightImportanceMarks: function () {
      return {
        "1": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.notImportant
        },
        "2": {
          label: "",
        },
        "3": {
          label: "",
        },
        "4": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.somewhatImportant,
        },
        "5": {
          label: "",
        },
        "6": {
          label: "",
        },
        "7": {
          label: this.$t(this.$i18n.locale).keys.matchesPreferences.labels.veryImportant,
        }
      }
    },
  },
  watch:{
    getAgeAndHeight: function(newVal){
      console.log('getAgeAndHeight', newVal)
      if(!_.isEmpty(newVal))  this.setAgeFilters();
    }
  },
  methods: {
    setAgeFilters() {
          console.log("setAgeFilters", this.getAgeAndHeight);

          const today = new Date();
          if(this.getAgeAndHeight.minAge.toString().indexOf('-') != -1){
            let minAgeDate = this.getAgeAndHeight.minAge;
            const minBirthDate = new Date(minAgeDate);
            let minAge = today.getFullYear() - minBirthDate.getFullYear();
            const minMonth = today.getMonth() - minBirthDate.getMonth();
            if (
              minMonth < 0 ||
              (minMonth === 0 && today.getDate() < minBirthDate.getDate())
            ) {
              minAge--;
            }
            this.ageFilterValue[0] = minAge;
          }else{
            this.ageFilterValue[0] = this.getAgeAndHeight.minAge;
          }

          if(this.getAgeAndHeight.maxAge.toString().indexOf('-') != -1){
            let maxAgeDate = this.getAgeAndHeight.maxAge;
            const maxBirthDate = new Date(maxAgeDate);
            let maxAge = today.getFullYear() - maxBirthDate.getFullYear();
            const maxMonth = today.getMonth() - maxBirthDate.getMonth();
            if (
              maxMonth < 0 ||
              (maxMonth === 0 && today.getDate() < maxBirthDate.getDate())
            ) {
              maxAge--;
            }
            this.ageFilterValue[1] = maxAge;
          }else{
            this.ageFilterValue[1] = this.getAgeAndHeight.maxAge;
          }


          if (this.ageFilterValue[0] > 18) {
            this.minAgeFilterValue = 18;
          } else if (this.ageFilterValue[0] <= 18) {
            this.minAgeFilterValue = this.ageFilterValue[0];
          }

          if (this.ageFilterValue[1] < 100) {
            this.maxAgeFilterValue = 100;
          } else if (this.ageFilterValue[1] >= 100) {
            this.maxAgeFilterValue = this.ageFilterValue[1];
          }
          this.ageImportanceValue = this.getAgeAndHeight.ageImportance;

          if(this.getAgeAndHeight.minHeight) this.heightFilterValue[0] = this.getAgeAndHeight.minHeight;
          if(this.getAgeAndHeight.maxHeight)this.heightFilterValue[1] = this.getAgeAndHeight.maxHeight;
/*

          if (this.heightFilterValue[0] > 140) {
            this.minHeightFilterValue = 140;
          } else if (this.heightFilterValue[0] >= 140) {
            this.minHeightFilterValue = this.heightFilterValue[0];
          }

          if (this.heightFilterValue[1] < 200) {
            this.maxHeightFilterValue = 200;
          } else if (this.heightFilterValue[1] >= 200) {
            this.maxHeightFilterValue = this.heightFilterValue[1];
          }
*/
          this.heightImportanceValue = this.getAgeAndHeight.heightImportance;
          this.componentKey++;
    },
    sendAgeAndHeightValues() {
      let ageAndHeightFilters = {
        ageImportance: parseInt(this.ageImportanceValue),
        heightImportance: parseInt(this.heightImportanceValue),
        maxAge: this.ageFilterValue[1],
        maxHeight: this.heightFilterValue[1],
        minAge: this.ageFilterValue[0],
        minHeight: this.heightFilterValue[0]
      };
      localStorage.ageFilterValueFrom = this.ageFilterValue[0];
      localStorage.ageFilterValueTo = this.ageFilterValue[1];
      localStorage.heightFilterValueFrom = this.heightFilterValue[0];
      localStorage.heightFilterValueTo = this.heightFilterValue[1];
      localStorage.ageFilterValueFromVisitors = this.ageFilterValue[0];
      localStorage.ageFilterValueToVisitors = this.ageFilterValue[1];
      localStorage.heightFilterValueFromVisitors = this.heightFilterValue[0];
      localStorage.heightFilterValueToVisitors = this.heightFilterValue[1];

      this.$store
        .dispatch("updateAgeAndHeight", ageAndHeightFilters)
        /*
        .then(res => {
          //this.$store.dispatch("hideLoader");
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
          } else {

            this.$store.dispatch("fetchPreferences").then(res => {
              //this.$store.dispatch("hideLoader");
              if (res == "error") {
                //this.$store.dispatch("setErrorVisibility", true);
              } else {
                this.$store.dispatch("showContent");
              }
            });

             this.$gtm.trackEvent({
                event: 'Settings',
                category: 'Settings',
                action: 'Save',
                label: 'Age & Height'
              });
            this.componentKey++;
            this.$store.dispatch("showContent");

          }
        });
        */
    },
    setResponsiveSteps() {
    }
  }
};
</script>
