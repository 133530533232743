var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-lg",attrs:{"id":"membershipvisitorsList"}},[_c('div',{attrs:{"id":"visitorsSearch"}},[_c('VisitorsSearchBar'),(_vm.isMobile)?_c('VisitorsFilters',{on:{"filterResetList":_vm.filterResetList}}):_vm._e()],1),_c('div',{staticClass:"visitorsContainer"},[(_vm.allVisitors)?_c('div',{staticClass:"leftContainer"},[(!_vm.isMobile)?_c('VisitorsFilters',{on:{"filterResetList":_vm.filterResetList}}):_vm._e()],1):_vm._e(),(_vm.allFilteredVisitors.length)?_c('div',{staticClass:"rightContainer"},[_c('VisitorsList')],1):_vm._e(),(!this.$store.getters.userRole.includes('ROLE_PREMIUM') && false)?_c('div',{staticClass:"visitorsFreeAccountWrapper"},[_c('div',{staticClass:"contentWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'no-views.svg'),"alt":"No meetings icon"}}),_c('p',{staticClass:"becomePremiumText"},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"text1",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1 =
            $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors1))]),_c('br'),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"text2",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2 =
            $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors2))])]),_c('div',{staticClass:"premiumButtonWrapper"},[_c('router-link',{staticClass:"premiumButton",attrs:{"to":"/premium-plans/plans?direct=1"}},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.become =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.become)+" ")]),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"headerPremiumMember",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
            _vm.$i18n.locale
          ).keys.membershipArea.membershipHeader.premiumMember =
            $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipHeader.premiumMember)+" ")])])],1)])]):_vm._e(),(!_vm.allFilteredVisitors.length)?_c('div',{staticClass:"noVisitorsWrapper rightContainer"},[_c('div',{staticClass:"contentWrapper"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'no-visitors-icon.svg'),"alt":"No visitors icon"}}),_c('p',{staticClass:"noVisitorsText"},[_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"text1",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3 =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors3))]),_c('br'),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"text2",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4 =
              $event.target.innerText}}},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipArea.membershipVisitors.noVisitors4))])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }