<template>
  <div id="app" v-if="languageFetched">
    <ChangeLanguage></ChangeLanguage>

    <div
      class="editBtnWrapper"
      :class="{ editingMode: $store.getters.editable }"
      v-if="$store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR')"
    >
      <button
        :class="{
          superadmin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'),
        }"
        @click="$store.dispatch('changeEditable')"
        class="languageEditingBtn"
      >
        Enable editing
      </button>
    </div>

    <router-view v-if="dataLoaded" />
    <SiteNotifications v-if="showNotifications && dataLoaded"/>
    <ModalQuestionaire ref="modalQuestionaire" v-if="this.$store.getters.questionnaireQuestionsMissing"/>
    <ModalUserdata ref="modalUserdata" v-if="this.$store.getters.vitalQuestionsMissing" />
  </div>
</template>

<script>
import ChangeLanguage from "./components/ChangeLanguage.vue";
import SiteNotifications from "./components/generic/SiteNotifications.vue";

 import axios from "axios";
 import jwt from "jsonwebtoken";
 import ModalQuestionaire from "@/components/membership-area/ModalQuestionaire.vue";
 import ModalUserdata from "@/components/membership-area/ModalUserdata.vue";

export default {
  name: "app",
  components: {
    ChangeLanguage,
    SiteNotifications,
    ModalQuestionaire,
    ModalUserdata,
  },

  data() {
    return {
      dataLoaded: false,
      languageFetched: true,
      availableDomains: ["com", "de", "ch", "at"],
      url: process.env.VUE_APP_URL,
      gtm: {
        matches: false,
        picture: false,
        premium: false,
        uid: false,
      }
    };
  },

  computed: {
    skipAuth(){
      if(this.$route.path.indexOf('/resetPassword') != -1) return true;
      else return false;
    },
    showNotifications() {
      if(
        this.$route.path.indexOf('membership-area') == -1 &&
        this.$route.path.indexOf('premium-plans/plans') == -1 
      ) return false;

      else return true;
    },
    
  },
  mounted() {
    if(this.skipAuth) return true;
    
    if(!localStorage.getItem('token')){
      
      window.location.href = 'https://' + host +'.'+ this.$store.getters.getCountryDomainLanding
      //window.location.href = `${process.env.VUE_APP_LANDING_URL}${this.$store.getters.getCountryDomainLanding}`;
    }

    //this.$store.dispatch('loadTerms', this.$store.getters.selectedLanguage);
  },
  async created(){

    //Event handler for global mobile switch
    window.addEventListener("resize", async () => {
        if (window.innerWidth < 992) {
            this.$store.commit('set_isMobile', true)
          }else{
            this.$store.commit('set_isMobile', false)
          }
    });
    window.dispatchEvent(new Event('resize'));

    console.log('async created');

    //Event handler for global mobile switch
    window.addEventListener("resize", async () => {
        if (window.innerWidth < 992) {
            this.$store.commit('set_isMobile', true)
          }else{
            this.$store.commit('set_isMobile', false)
          }

    });
    window.dispatchEvent(new Event('resize'));

    //Set WL Logos
    

    if(host == 'affaerentreff'){
      let logos = {
        desktop: require('@/assets/images/affaerentreff/logo-desktop.png'),
        mobile: require('@/assets/images/affaerentreff/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'AffaerenTreff');
      //WL assets path
      this.$store.commit('set_assetsPath', 'affaerentreff/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_Affaerentreff.png';

    }else if(host == 'affaireclub'){
        let logos = {
        desktop: require('@/assets/images/affaireclub/logo-desktop.png'),
        mobile: require('@/assets/images/affaireclub/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'AffaireClub');
    }
    else if(host == 'casualxdates'){
      let logos = {
        desktop: require('@/assets/images/casualxdates/logo-desktop.png'),
        mobile: require('@/assets/images/casualxdates/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'CasualXdates');
      //WL assets path
      this.$store.commit('set_assetsPath', 'casualxdates/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_casualxdates.png';

    }
    else if(host == 'illicitoasis'){
      let logos = {
        desktop: require('@/assets/images/illicitoasis/logo-desktop.png'),
        mobile: require('@/assets/images/illicitoasis/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'IllicitOasis');
      //WL assets path
      this.$store.commit('set_assetsPath', 'illicitoasis/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_illicitoasis.png';

    }
    else if(host == 'xn--fremdgehbrse-djb'){
      let logos = {
        desktop: require('@/assets/images/fremdgehboerse/logo-desktop.png'),
        mobile: require('@/assets/images/fremdgehboerse/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Fremdgehbörse');
      //WL assets path
      this.$store.commit('set_assetsPath', 'fremdgehboerse/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_fremdgehboerse.png';

    }
    else if(host == 'xn--affre1-dua'){
      let logos = {
        desktop: require('@/assets/images/affaere1/logo-desktop.png'),
        mobile: require('@/assets/images/affaere1/logo-mobile.png')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Affäre1');
      //WL assets path
      this.$store.commit('set_assetsPath', 'affaere1/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_affaere1.png';

    }
    else if(host == 'seitensprungtreff69'){
      let logos = {
        desktop: require('@/assets/images/seitensprungtreff69/logo_color.svg'),
        mobile: require('@/assets/images/seitensprungtreff69/logo_color.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Seitensprungtreff69');
      //WL assets path
      this.$store.commit('set_assetsPath', 'seitensprungtreff69/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_seitensprungtreff69.png';

    }
    else if(host == 'ficktreffpunkt24'){
      let logos = {
        desktop: require('@/assets/images/ficktreffpunkt24/logo_color.svg'),
        mobile: require('@/assets/images/ficktreffpunkt24/logo_color.svg')
      }
      this.$store.commit('set_mainLogos', logos);
      //WL product name
      this.$store.commit('set_productName', 'Ficktreffpunkt24');
      //WL assets path
      this.$store.commit('set_assetsPath', 'ficktreffpunkt24/');
      // WL favicon
      document.querySelector('#favicon').href = '/favicon_ficktreffpunkt24.png';

    }

    //Set Support Email address
    this.$store.dispatch('updateSupportEmail');

    let token = localStorage.getItem("token");
    let decoded = jwt.decode(token, { complete: true });

//this.$gtm.enable(true);
    if(decoded){
      window.dataLayer.push({'uid': decoded.payload.user_id});
      this.gtm.uid = true;
      this.enableGTM();

      if(decoded.payload.roles.includes('ROLE_PREMIUM')){
        window.dataLayer.push({'is_premium': true});
        this.gtm.premium = true;
        this.enableGTM();
      }else{
        window.dataLayer.push({'is_premium': false});
        this.gtm.premium = true;
        this.enableGTM();
      }

    await this.$store.dispatch('fetchMatches').then((res)=>{
      console.log('GTM fetchMatches', res);
      window.dataLayer.push({'matchCount': this.$store.getters.allMatchesCount});
      this.gtm.matches = true;
      this.enableGTM();
    });

    axios
    .get(this.url + `/api/profile/my_picture`)
    .then(response => {
      //add to GTM tracking
        var hasPicture = false;
        if(response.data.data.public_path) hasPicture = true;
        window.dataLayer.push({'hasPicture': hasPicture});
        this.gtm.picture = true;
        this.enableGTM();
    });

   }

    //GTM fallback
    setTimeout(() => {
      console.log('GTM fallback');
      if(!this.$gtm.enabled()) this.$gtm.enable(true);
    },2000);

   },
  async beforeMount() {
    console.log('APP before mount');
    if(!this.skipAuth){

      //Load site specific data from api
      await this.$store.dispatch('fetchSiteConfig');

      //Load user specific data from api
      await this.$store.dispatch('fetchUserConfig');

      await this.$store.dispatch("checkCompleted");

      await this.$store.dispatch("getLocales");

      this.dataLoaded = true;

      this.checkAPIModals();

    }else{
      this.dataLoaded = true;
    }
    this.changeLanguage();

  },
  updated(){
    console.log('UPDATED')
    //set meta title
    var pageTitle = this.$store.getters.productName;
    if(this.$route.path.indexOf('/membership-area/messages') != -1) {
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.messages;
    }
    else if(this.$route.path == '/membership-area/matches'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.matches;
    }
    else if(this.$route.path == '/membership-area/visitors'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.visitors;
    }
    else if(this.$route.path == '/membership-area/meetings'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.haveYouMet;
    }
    else if(this.$route.path == '/membership-area/home'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.home;
    }
    else if(this.$route.path == '/membership-area/user-profile/profile'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.profile;
    }
    else if(this.$route.path == '/membership-area/matches-preferences'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.preferences;
    }
    else if(this.$route.path == '/membership-area/my-account'){
      pageTitle += ' - ' + this.$t(this.$i18n.locale).keys.membershipArea.membershipHeader.myAccount;
    }

    document.querySelector('title').text = pageTitle;
  },
  watch: {

    $route() {
      console.log('APP route watcher')
      this.checkAPIModals();
    }
  },
  methods: {
    checkAPIModals() {

      console.log('APP questionnaireQuestionsMissing', this.$store.getters.questionnaireQuestionsMissing)
      console.log('APP vitalQuestionsMissing', this.$store.getters.vitalQuestionsMissing)

      //Show first userdata modal
      if(this.$store.getters.vitalQuestionsMissing && this.$route.path.indexOf('/membership-area/') != -1){
        this.$refs.modalUserdata.show();
      }
      //Show second modal for missing questions
      if(this.$store.getters.questionnaireQuestionsMissing){
        this.$refs.modalQuestionaire.show();
      }

    },
    enableGTM() {
      console.log('enableGTM');
      if(this.gtm.matches && this.gtm.picture && this.gtm.premium && this.gtm.uid && !this.$gtm.enabled()){
         this.$gtm.enable(true);
         let gScript_init = document.createElement('script')
        gScript_init.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?l=gDataLayer')
        document.head.appendChild(gScript_init)

        let gScript_body = document.createElement('script')
        gScript_body.text = "window.gDataLayer = window.gDataLayer || [];function gtag(){gDataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-473491805');"
        document.head.appendChild(gScript_body)
      }
    },
    logout: () => {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },

    changeLanguage() {

      let localeToSet = 'en';

      /*
      let token = localStorage.getItem("token");

      if(token){
        let decoded = jwt.decode(token, { complete: true });
        localeToSet = decoded.payload.locale;
        console.log('locale from token')
      }else{
        console.log('locale from url')
        if (domain == "de" || domain == "ch" || domain == "at") {
          localeToSet = "de";
        }else if (domain == "cz") {
          localeToSet = "cz";
        }else if (domain == "pl") {
          localeToSet = "pl";
        } else {
          localeToSet = "en";
        }

      }
      */

      console.log('user', this.$store.getters.user);

      if(!this.skipAuth){
        localeToSet = this.$store.getters.user.locale;
      }else{
        console.log('locale from url')
        if (domain == "de" || domain == "ch" || domain == "at") {
          localeToSet = "de";
        }else if (domain == "cz") {
          localeToSet = "cz";
        }else if (domain == "pl") {
          localeToSet = "pl";
        } else {
          localeToSet = "en";
        }

      }

// this.availableDomains.contains(domain)

      // if (jwt.decode(token) == null) {
      //   localeToSet = "en";
      // }
      console.log("!!!!", localeToSet);
      this.$store
        .dispatch("changeLanguage", {
          locale: localeToSet,
          keys: this.$t(this.$i18n.locale),
        })
        .then(() => {
          this.languageFetched = true;
          this.$store.dispatch('loadTerms', {lang: this.$store.getters.selectedLanguage, vm: this});
        });
    },
  },
};
</script>