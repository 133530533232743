<template>
  <div id="membershipMeetingsMain">
    <!--
    <MembershipMisc />
    -->
    <div>
      <div class="container-membership">
        <HaveYouMetCards ref="childComponent" />
      </div>

    </div>

  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
import HaveYouMetCards from "@/components/membership-area/membership-meetings/HaveYouMetCards.vue";


export default {
  name: "MembershipMeetingsMain",
  components: {
    //MembershipMisc,
    HaveYouMetCards,

  },
  data() {
    return {

    };
  },
  mounted() {
    window.scrollTo(0,0);

    this.$store.dispatch("getMeetings").then( (res) => {
      this.$store.dispatch('hideLoader');
      this.$store.dispatch("addMeetingsCount");
      if(this.$store.getters.getMeetingsActionsCount == 2){
        this.$store.dispatch('hideLoader');
      }
        if(res == 'error'){
          if(this.$store.getters.getMeetingsActionsCount == 2){
            //this.$store.dispatch('setErrorVisibility', true);
          }
        } else {
          if(this.$store.getters.getMeetingsActionsCount == 2){
            this.$store.dispatch('showContent');
            this.$store.dispatch("getConversations");
          }
        }
                    // eslint-disable-next-line no-undef
           if(window.outerWidth < 768){
            var offset = 45;
            // eslint-disable-next-line no-undef
             $('#HaveYouMetCards #container').height($('.currentCard ').height()+offset);
           }
    });// uncomment his latter
  },

  methods: {

    match() {
      this.$refs.childComponent.match();
    },
    reject() {
      this.$refs.childComponent.reject();
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave');
    this.$store.dispatch('showLoader').then(next());

  }
};
</script>

<style lang="scss" scoped></style>
