<template>
  <div id="matchesPreferences">
    <!--
    <MembershipMisc />
    -->
    <div class="container-lg preferencesContainer">
      <!--
      <div class="leftContainer">
        <UserAccountMenu />
      </div>
      -->
      <div class="rightContainer" v-if="showBlocks">
        <UserAccountMembership />
        <PersonalDetails />
        <MatchingSettings />
        <Notifications />
      </div>
    </div>
  </div>
</template>

<script>
//import MembershipMisc from "@/components/membership-area/MembershipMisc.vue";
//import UserAccountMenu from "@/components/membership-area/my-account/UserAccountMenu.vue";
import UserAccountMembership from "@/components/membership-area/my-account/UserAccountMembership.vue";
import PersonalDetails from "@/components/membership-area/my-account/PersonalDetails.vue";
import MatchingSettings from "@/components/membership-area/my-account/MatchingSettings.vue";
import Notifications from "@/components/membership-area/my-account/Notifications.vue";

export default {
  name: "MatchesPreferences",
  components: {
    //MembershipMisc,
    //UserAccountMenu,
    UserAccountMembership,
    PersonalDetails,
    MatchingSettings,
    Notifications,
  },

  data() {
    return {
      idBlocks: [
        "userAccountMembership",
        "personalDetails",
        "matchingSettings",
        "notifications",
      ],
      activeBlock: "ageAndHeight",
      showBlocks: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchClientSetttings").then(() => {
      this.showBlocks = true;
      this.$store.dispatch("hideLoader");
    });
  },

  methods: {
    /*
    handleScroll(evt) {
      let ids = this.idBlocks.filter((number) => {
        const el = document.getElementById(number);
        const rect = el.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const vertInView =
          rect.top <= windowHeight && rect.top + rect.height - 275 >= 0;
        return vertInView ? number : "";
      });

      let menuItems = Array.prototype.slice.call(
        document.querySelectorAll(".menuItem")
      );
      menuItems.forEach((item) => {
        item.classList.remove("active");
      });
      document.querySelector("." + ids[0] + "").classList.add("active");
    },
     */
  },
};
</script>
