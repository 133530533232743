var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"additionalQuestion"}},[_c('div',{staticClass:"simpleHeader"},[_c('div',{staticClass:"container topnavWrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"logoWrapper"},[_c('img',{attrs:{"src":_vm.$store.getters.mainLogos.desktop,"alt":""}})])])])]),(_vm.desktopView)?_c('div',{staticClass:"container mainWrapper"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{key:_vm.componentKey,staticClass:"questionBoxWrapper col-10"},[_c('h1',{staticClass:"personalityAdditionalHeadline"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.questionnaire.additionalHeadline'))+" ")]),_c('div',{staticClass:"questionWrapper"},[(_vm.additionalQuestions)?_c('h1',{staticClass:"question"},[_vm._v(" "+_vm._s(_vm.additionalQuestions[0].translations[ _vm.$store.getters.selectedLanguage ].question)+" ")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.answer),expression:"answer"}],attrs:{"name":"answer","id":"answer","maxlength":"512","placeholder":_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion.enterAnswer},domProps:{"value":(_vm.answer)},on:{"input":function($event){if($event.target.composing)return;_vm.answer=$event.target.value}}})]),_c('div',{staticClass:"questionBoxFooter"},[_c('div',{staticClass:"leftContainer"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('div',{staticClass:"charactersLeftMessage"},[_vm._v(" "+_vm._s(512 - _vm.answer.length)+" "),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion =
                    $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion .charactersLeftMessage)+" ")])]),_c('div',{staticClass:"questionsAnsweredWrapper"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('p',{staticClass:"questionsAnsweredMessage"},[_vm._v(" "+_vm._s(_vm.distance)+" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.of)+" 7 "),_c('span',{directives:[{name:"langErr",rawName:"v-langErr"}],attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(
                      _vm.$i18n.locale
                    ).keys.questionnaire.additionalQuestion.questionsAnsweredMessage =
                      $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion .questionsAnsweredMessage)+" ")])]),_c('div',{staticClass:"sliderWrapper"},[_c('vue-slider',{staticStyle:{"width":"60%"},attrs:{"id":"questionAnswered","min":0,"max":6,"tooltip":'none',"marks":_vm.questionsMarks,"adsorb":true,"included":true,"disabled":true},model:{value:(_vm.distance),callback:function ($$v) {_vm.distance=$$v},expression:"distance"}})],1)])]),_c('div',{staticClass:"rightContainer"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('button',{staticClass:"btnSave",class:{ disabled: _vm.answer.length <= 0 },attrs:{"disabled":_vm.answer.length <= 0},on:{"click":function($event){return _vm.sendAdditionalAnswer(
                  _vm.additionalQuestions[0].id
                )}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.save)+" & "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continue)+" ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.save),expression:"$t($i18n.locale).keys.generic.save"}],attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.save)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "save", $event.target.value)}}}):_vm._e(),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$t(_vm.$i18n.locale).keys.generic.of),expression:"$t($i18n.locale).keys.generic.of"}],attrs:{"type":"text"},domProps:{"value":(_vm.$t(_vm.$i18n.locale).keys.generic.of)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.generic, "of", $event.target.value)}}}):_vm._e(),_c('p',{staticClass:"continueMessage",on:{"click":function($event){return _vm.loadNextQuestion()}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion .continueMessage)+" >> ")]),(_vm.editable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                _vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion
                  .continueMessage
              ),expression:"\n                $t($i18n.locale).keys.questionnaire.additionalQuestion\n                  .continueMessage\n              "}],attrs:{"type":"text"},domProps:{"value":(
                _vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion
                  .continueMessage
              )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$t(_vm.$i18n.locale).keys.questionnaire.additionalQuestion
                  , "continueMessage", $event.target.value)}}}):_vm._e()])]),_c('div',{staticClass:"questionBoxBottomWrapper"},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('router-link',{staticClass:"continueMessageWrapper",attrs:{"to":"upload-image"}},[(_vm.editable)?_c('img',{style:({ marginLeft: '20px' }),attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Translate-Icon.svg'),"alt":"Translation icon"}}):_vm._e(),_c('p',{directives:[{name:"langErr",rawName:"v-langErr"}],staticClass:"continueMessage",attrs:{"contenteditable":_vm.editable},on:{"blur":function($event){_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn =
                  $event.target.innerText}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale).keys.generic.continueBtn)+" >> ")])])],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }