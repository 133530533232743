<template>
  <div v-if="this.$store.getters.editable" id="changeLanguage">
    <a href="#" class="close"></a>
    <div class="locale-changer">
      <label for>Language:</label>
      <select @change="changeLanguage()" v-model="language">
        <option v-for="(lang, i) in langs" :key="`lang${i}`" :value="lang">{{ lang.toUpperCase() }}</option>
      </select>
    </div>
    

    <div class="saveChanges">
      <button
        class="save-btn genericButton"
        @click="$store.dispatch('postTranslations', {locale: language, keys:$t($i18n.locale).keys})"
      >SAVE CHANGES</button>
      <div  @click="$store.dispatch('changeEditable')" class="close-btn-container">
        <img src="../assets/images/cross.svg" />
      </div>
    </div>

    <div class="addLang-container" style="  display: flex;">
      <div class="buttonInside">
        <input  v-model="customLanguage" type="text" placeholder="Add language..." />
        <button  @click="$store.dispatch('addLanguage',customLanguage.toLowerCase().substring(0, 2))" >+</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ChangeLanguage",
  data() {
    return {
      language: "en",
      customLanguage: null
    };
  },
  computed: {
    langs() {
      return this.$store.getters.avaibableLanguages;
    }
  },
  created() {
    setTimeout(() => {
        this.language = this.$store.getters.selectedLanguage;
    }, 1000);
  
  },
  methods: {
    changeLanguage() {
      this.$store.dispatch("changeLanguage", {
        locale: this.language,
        keys: this.$t(this.$i18n.locale)
      });
    }
  }
};
</script>
<style lang="scss">
#changeLanguage {
  height: 70px;
      // margin-top: 130px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1029;
  background: #ffffff;
  .addLang-container {
    .buttonInside {
      position: relative;
      margin-bottom: 10px;
      margin-left: 20px;
    }
    #container {
      width: 300px;
    }
    input {
      width: 100%;
      padding-left: 10px;
      padding: 15px 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: large;
      height: 35px;
      margin-top: 15px;
    }
    button {
      position: absolute;
      right: 5px;
      top: 4px;
      border: none;
      height: 25px;
      width: 25px;
      border-radius: 5px;
      outline: none;
      text-align: center;
      font-weight: bold;
      padding: 0px;
      background: #33a080;
      color: #ffffff;
      margin-top: 15px;
    }
    button:hover {
      cursor: pointer;
      background: #008860;
    }
  }

  .locale-changer {
    float: left;
    height: 35px;
    margin-top: 15px;
    margin-left: 30px;

    label {
      color:grey;
      font-size: large;
    }
    select {
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 35px;
      color: grey;
    }
  }
  .saveChanges {
    float: right;
    .save-btn {
      margin-top: 15px;
      margin-right: 90px;
      width: 170px;
      background: #33a080;
      color: white;
      height: 35px;
      border-radius: 5px;
    }
    .save-btn:hover {
      background: #008860;
    }
    .close-btn-container {
      background: #f3f4f6;
      position: absolute;
      right: 0;
      top: 15px;
      width: 35px;
      height: 35px;
      border-radius: 10%;
      margin-right: 30px;
      img {
        opacity: 0.3;
        height: 20px;
        width: 20px;
        margin-top: 7.5px;
      }
      img:hover {
        opacity: 1;
      }
    }
  }
}
</style>