
import axios from "axios";
import store from "../../../store";
const BASE_URL = process.env.VUE_APP_URL;

const handlerEnabled= {timeout: false};

export default {

     updateSupportEmail({commit}) {

      var supportEmail = '';

        if(host == 'affaerentreff' ){

            if(domain == 'de')  supportEmail = 'kundenservice-de@affaerentreff.com';
            else if(domain == 'at')  supportEmail = 'kundenservice-at@affaerentreff.com';
            else if(domain == 'ch')  supportEmail = 'kundenservice-ch@affaerentreff.com';
            else  supportEmail = 'kundenservice@affaerentreff.com';

            commit('set_privacyEmail', "privacy@affaerentreff.com"); 


        }else if(host == 'affaireclub' ){

            if(domain == 'nz')  supportEmail = 'support-nz@affaireclub.net';
            else if(domain == 'uk')  supportEmail = 'support-uk@affaireclub.net';
            else  supportEmail = 'support@affaireclub.net';

            commit('set_privacyEmail', "privacy@affairsclub.com"); 


        }
        else if(host == 'casualxdates' ){

            if(domain == 'de')  supportEmail = 'kundenservice-de@casualxdates.com';
            else if(domain == 'at')  supportEmail = 'kundenservice-at@casualxdates.com';
            else if(domain == 'ch')  supportEmail = 'kundenservice-ch@casualxdates.com';
            else if(domain == 'com')  supportEmail = 'support-us@casualxdates.com';
            else if(domain == 'uk')  supportEmail = 'support-uk@casualxdates.com';
            else if(domain == 'au')  supportEmail = 'support-au@casualxdates.com';
            else  supportEmail = 'kundenservice@casualxdates.com';

            commit('set_privacyEmail', "privacy@casualxdates.com");

        }
        else if(host == 'illicitoasis' ){

             supportEmail = 'support-us@illicitoasis.com';
            commit('set_privacyEmail', "privacy@casualxdates.com");
        } 
        else if(host == 'xn--fremdgehbrse-djb' ){

          if(domain == 'de')  supportEmail = 'kundenservice-de@fremdgehbörse.com';
          else if(domain == 'at')  supportEmail = 'kundenservice-at@fremdgehbörse.com';
          else if(domain == 'ch')  supportEmail = 'kundenservice-ch@fremdgehbörse.com';
          else  supportEmail = 'kundenservice@fremdgehbörse.com';

          commit('set_privacyEmail', "privacy@fremdgehbörse.com");
        }
        else if(host == 'xn--affre1-dua' ){

          if(domain == 'de')  supportEmail = 'kundenservice-de@affäre1.com';
          else if(domain == 'at')  supportEmail = 'kundenservice-at@affäre1.com';
          else if(domain == 'ch')  supportEmail = 'kundenservice-ch@affäre1.com';
          else  supportEmail = 'kundenservice@affäre1.com';

          commit('set_privacyEmail', "privacy@affäre1.com");
        }else if(host == 'seitensprungtreff69' ){

            if(domain == 'de')  supportEmail = 'kundenservice-de@seitensprungtreff69.com';
            else if(domain == 'at')  supportEmail = 'kundenservice-at@seitensprungtreff69.com';
            else if(domain == 'ch')  supportEmail = 'kundenservice-ch@seitensprungtreff69.com';
            else  supportEmail = 'kundenservice@seitensprungtreff69.com';
  
            commit('set_privacyEmail', "privacy@seitensprungtreff69.com");

        }else if(host == 'ficktreffpunkt24' ){

          if(domain == 'de')  supportEmail = 'kundenservice-de@ficktreffpunkt24.com';
          else if(domain == 'at')  supportEmail = 'kundenservice-at@ficktreffpunkt24.com';
          else if(domain == 'ch')  supportEmail = 'kundenservice-ch@ficktreffpunkt24.com';
          else  supportEmail = 'kundenservice@ficktreffpunkt24.com';

          commit('set_privacyEmail', "privacy@ficktreffpunkt24.com");
  
        }else{
            if(domain == 'ch')  supportEmail = 'kundenservice@thecasualarcade.ch';
            else if(domain == 'at')  supportEmail = 'kundenservice@thecasualarcade.at';
            else if(domain == 'de')  supportEmail = 'kundenservice@thecasualarcade.de';
            else if(domain == 'cz')  supportEmail = 'zakaznickapodpora@thecasualarcade.cz';
            else if(domain == 'uk')  supportEmail = 'support-uk@thecasualarcade.com';
            else if(domain == 'au')  supportEmail = 'support-au@thecasualarcade.com';
            else if(domain == 'nz')  supportEmail = 'support-nz@thecasualarcade.com';
            else if(domain == 'za')  supportEmail = 'support-za@thecasualarcade.com';
            else if(domain == 'pl')  supportEmail = 'serwisklienta@thecasualarcade.com';
            else  supportEmail = 'support@thecasualarcade.com';

            commit('set_privacyEmail', "privacy@thecasualarcade.com");
        }


        commit('set_supportEmail', supportEmail);
    },

    updateAlerts({commit}, message){

        var alert = {
            showAlert: true,
            alertMessage: message
        }

        commit('set_alerts', alert);
    },

    async fetchSiteConfig({commit}){
        const response = await axios.get(BASE_URL + `/api/config/site`, handlerEnabled);
        console.log('fetchSiteConfig', response);
        if(response.data.data.welcomeMessageSender) commit('set_welcomeMessageSender', response.data.data.welcomeMessageSender);
        if(response.data.data.id) commit('set_siteId', response.data.data.id);
        return true;

    },

    async fetchUserConfig({commit}){
      const response = await axios.get(BASE_URL + `/api/config/user`, handlerEnabled);
      console.log('fetchUserConfig', response);
      console.log('AUTH user', store.getters.getAuthUser.user_id)

      if(!response.data.data.user_id) response.data.data.user_id = store.getters.getAuthUser.user_id;
      
      commit('set_user', response.data.data);

      return true;

  },

    triggerFinishEvent(data){
        console.log('action triggerFinishEvent');

        var gtagInt = setInterval(
            function(){
              console.log('try gtag event');
              if(typeof window.gtag == 'function'){
                console.log('send gtag event');
                clearInterval(gtagInt);
                if(domain == 'de' && host == "affaerentreff" ){
                  window.gtag('event', 'conversion', {'send_to': 'AW-447642286/jzCaCLzVh8UDEK71udUB'});
                }
                //window.gtag('event', 'conversion', {'send_to': 'AW-481807027/ywbICMCSz-YBELOV3-UB'});
                //window.gtag('event', 'conversion', {'send_to': 'AW-1037950593/TJrQCK_l7vQBEIG99-4D'});
              }
            },
            200
        );

        //Trigger Finish Event
        var URL = process.env.VUE_APP_URL + '/api/personality/trigger/finish';

        var device = '';
        if(store.getters.isMobile) device = 'mobile';
        else device = 'desktop';

        axios.post(URL, {
          device: device
        }).then(() => {
          console.log('Sent Finish Event');

           window.dataLayer.push({
            'event': 'registerFinish'
          });
        });

        //Trigger Tracking Events
        if(domain == 'ch'){
          console.log('Tracking Event start')
          this.trackingURL = "https://ch.dating-advisor.org/track.php?brand=acom&type=reg&gender=XX&age=35";
        }else if(domain == 'cz'){
          this.trackingURL = "https://cz.dating-advisor.net/track.php?brand=acom&type=reg&gender=XX&age=35";
        }else if(domain == 'pl'){
          this.trackingURL = "https://pl.dating-advisor.net/track.php?brand=acom&type=reg&gender=XX&age=35";
        }

        //FB tracking 
        if(typeof window.fbq != 'undefined'){
          window.fbq('track', 'CompleteRegistration',{
              currency: "GBP",
              value: 1,
            },
            { eventID: data.userId }
          );
        }

        //BING tracking (uetq set by tagmanager)
        /*
        var bingInt = setInterval(
            function(){
          console.log('try bing event');
          if(typeof window.uetq == 'object'){
            console.log('send bing event');
            clearInterval(bingInt);
            try {
              window.uetq.push('event','click',{
                'event_category': 'registrations',
                'event_label': 'reg',
                'event_value': '0'
                }
              );
            } catch (error) {
              console.log('Conversion Error', error);
            }
          }
            },
            200
          )
          */

    },
    loadTerms({commit, dispatch, getters}, data){

      console.log('loadTerms', data )

        var termsCountry;
        if(!data.country) {
            termsCountry = domain.toUpperCase();
            if(domain == 'com') termsCountry = 'US';
        }else {
            termsCountry = data.country.toUpperCase();
        }

        var langSelect = data.lang + '_' + termsCountry;
        console.log('loadTermsJSON lang', langSelect)
        
        var termsURL = `https://cdn.aisgmbh.at/tos/json/prod/termsAndPolicies_${langSelect}.json`;
        if(getters.isDEV) termsURL = `https://cdn.aisgmbh.at/tos/json/dev/termsAndPolicies_${langSelect}.json`;


        axios.get(termsURL).then((response) => {
            console.log('loadTermsJSON', response.data);

            var terms = response.data;
            terms = Object.replaceAll(terms, '%PRODUCTNAME%', getters.productName, false)
            terms = Object.replaceAll(terms, '%PRIVACYEMAIL%', getters.privacyEmail, false)
            terms = Object.replaceAll(terms, '%SUPPORTEMAIL%', getters.supportEmail, false)
            
            terms = Object.replaceAll(terms, '%PRODUCTURL%', getters.productURL, false)
            commit('set_globalTermsAndPolicies', terms)
            
        }).catch(error => {
            console.log('loadTermsJSON error', error, data.vm.$gtm.enabled())

            //Track missing Terms in Analytics/GTM 
            var GTM_event = function() { return data.vm.$gtm.trackEvent({
                                event: 'TermsError',
                                category: 'Errors',
                                action: 'Terms not found',
                                label: `Terms ${langSelect} failed to load - ${error.response.status}`
                                });
                            }
            
            if(!data.vm.$gtm.enabled()) {
                setTimeout(()=>{
                console.log('loadTermsJSON timeout', error, data.vm.$gtm.enabled())
                GTM_event()
                },200) 
            }else{
                GTM_event()
            }
            
            if(data.retry) return false;

            //Fallback to domain-specific defaults
            switch(domain) {
                case 'de': dispatch('loadTerms', {lang:'de', country:'DE', vm:data.vm, retry: true})
                break;
                case 'at': dispatch('loadTerms', {lang:'de', country:'AT', vm:data.vm, retry: true})
                break;
                case 'ch': dispatch('loadTerms', {lang:'de', country:'CH', vm:data.vm, retry: true})
                break;
                case 'cz': dispatch('loadTerms', {lang:'cz', country:'CZ', vm:data.vm, retry: true})
                break;
                case 'pl': dispatch('loadTerms', {lang:'pl', country:'PL', vm:data.vm, retry: true})
                break;
                case 'uk': dispatch('loadTerms',  {lang:'en', country:'UK', vm:data.vm, retry: true})
                break;
                case 'za': dispatch('loadTerms',  {lang:'en', country:'ZA', vm:data.vm, retry: true})
                break;
                case 'au': dispatch('loadTerms',  {lang:'en', country:'AU', vm:data.vm, retry: true})
                break;
                case 'nz': dispatch('loadTerms',  {lang:'en', country:'NZ', vm:data.vm, retry: true})
                break;
                case 'com': dispatch('loadTerms',  {lang:'en', country:'US', vm:data.vm, retry: true})
                break;
            }

        })

        return false;

        // JS FILE LOADERS
        /*
        if(typeof lang == 'undefined') return false;
        if(domain == 'at') lang = 'at';
        else if(domain == 'ch') lang = 'ch';
        else if(domain == 'com' && (host == 'casualxdates' || host == 'illicitoasis')) lang = 'us';

        //let termsURL = `https://cdn.aisgmbh.at/tos/termsAndPolicies_${lang}.js`;
        
        dispatch('makeProductURL')
        axios.get(termsURL).then(response => {
            var terms = Function( `var termsAndPolicies_async = {};"use strict";return (${response.data});`)();
            terms = Object.replaceAll(terms, '%PRODUCTNAME%', getters.productName, false)
            terms = Object.replaceAll(terms, '%PRIVACYEMAIL%', getters.privacyEmail, false)
            terms = Object.replaceAll(terms, '%SUPPORTEMAIL%', getters.supportEmail, false)
            
            terms = Object.replaceAll(terms, '%PRODUCTURL%', getters.productURL, false)
            commit('set_globalTermsAndPolicies', terms)
            
        })
        */
    },
    makeProductURL({commit, getters}) {
        console.log('makeProductURL')
        var productURL = '';
        if(host == 'xn--fremdgehbrse-djb') {
          productURL =  `www.fremdgehbörse.${getters.getCountryDomainLanding}`;
        }else if(host == 'xn--affre1-dua') {
          productURL =  `www.affäre1.${getters.getCountryDomainLanding}`;
        }else{
          productURL =  'www.' + window.host +'.'+ getters.getCountryDomainLanding;
        }
        commit('set_productURL', productURL);
      },

}