<template>
  <div id="questionType11" class="questionComponent">
   
    <h1 v-if="variant != 2" class="questionText">{{questionData.question.translations[selectedLanguage].question}}</h1>

    <div :class="{'chatStyleAnswer' : variant == 2}">
      <div class="answerWrapper">
        <p
          class="additionalMessage"
        >{{$t($i18n.locale + '.keys.questionnaire.type11.additionalMessage')}}</p>
        <textarea
          v-model="answer"
          class="genericInput"
          type="text"
          :placeholder="$t($i18n.locale).keys.questionnaire.type3.typeHere"
          :maxlength="questionData.question.id != 130 ? 32 : ''"
          :class="{'error': questionError}"
          rows="3"
        ></textarea>
        <p class="questionError" v-if="questionError">
          {{questionError}}
        </p>
      </div>
    </div>
    
    <QuestionNavigation
      v-if="variant == 2"
      :progressData="{
        ...navData,
        currentQuestion: questionData.question.orderId,
      }"
      :nextDisabled=" answer.length < 3"
      :prevDisabled="false"
      
      @sendAnswer="sendAnswer"
      @goBack="$emit('goBack')"

    />
    
    <button
      v-else
      v-langErr
      @click="sendAnswer();"
      v-bind:class="{ 'btnContinue-disabled': answer.length < 3 || answer == answerExist() }"
      :disabled="answer.length < 3 || answer == answerExist()"
      class="genericButton btnContinue"
    >{{$t($i18n.locale).keys.generic.continueBtn}}</button>
                               
    <p
      v-langErr
      v-if="questionData.question.skippable == 1"
      @click="sendAnswer({skip:true});"
      class="answerLaterMessage"
    >{{ $t($i18n.locale).keys.generic.answerLaterMessage }}</p>
  </div>
</template>

<script>
import QuestionNavigation from "@/components/personality/QuestionNavigation_variant2.vue";

export default {
  components: { QuestionNavigation },
  props: [
    "questionData",
    "variant",
    "navData"
  ],
  name: "QuestionType11",
  data() {
    return {
      answer: "",
      lockSend: false,
      verifyRegex: /^.*$/,
      questionError: null,
    };
  },
  watch: {
    questionData: function(newVal, oldVal) {
      if (newVal.question.id > oldVal.question.id) {
        this.answer = "";
      } else {
        if (this.questionData.answered) {
          this.answer = this.questionData.answered.answer;
        }
      }
    }
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
        selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
  },
  methods: {
    verifyAnswer(){
      return true //currently no validation
    },
    sendAnswer(data = {}) {
      if(this.lockSend) return true;
      if(data.skip){
        this.answersArray = [];
      }
      if(!this.verifyAnswer(this.answer)) return true
      if (!this.questionData.answered) {
        this.lockSend = true;
        this.$emit("post-answer", [this.answer]);
      }
      else if (this.questionData.answered) {
        this.lockSend = true;
        this.$emit("put-answer", {
          answered: [this.answer],
          customerAnswerId: this.questionData.answered.id
        });
      }
    },
    answerExist() {
      if (this.questionData.answered) {
        return this.questionData.answered.answer;
      } else {
        return false;
      }
    },
    loadAnswers() {
      if (this.questionData.answered) {
        this.answer = this.questionData.answered.answer;
      }
    },
    answerChanged(answer) {
      if (this.questionData.answered) {
        if (answer == this.questionData.answered.answer) {
          true;
        } else {
          false;
        }
      }
    }
  },
  mounted() {
    this.loadAnswers();
  }
};
</script>
