<template >
  <div id="userSexualPreferences">
    <Spinner v-if="isLoading" />
    <div>
      <div class="sectionTitleWrapper">
        <h1
          class="sectionTitle"
          v-langErr :contenteditable="editable"
          @blur="$t($i18n.locale).keys.currentUserProfile.userSexualPreferences.title = $event.target.innerText"
        >{{$t($i18n.locale + '.keys.currentUserProfile.userSexualPreferences.title')}}</h1>
        <img
          v-if="editable"
          :style="{marginLeft : '20px'}"
          src="@/assets/images/Translate-Icon.svg"
          alt="Translation icon"
        />
      </div>
      <div class="itemsWrapper">
        <div class="itemWrapper" v-for="item in userSexualPreferences" :key="item.key">
          <div class="item member"
            :class="{
              'active':item.status
            }"
          >
            <div class="itemStatus" v-if="item.status">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
            </div>
            <div class="itemPicture">
              <img :src='item.picture' alt="" height="100px">
            </div>
            <div class="itemName">
              <span>{{item.name}}</span>
            </div>
          </div>

        </div>

      </div>
      <div class="noItemsFound" v-if="userSexualPreferences.length == 0">
        NO SEXUAL PREFERENCES SELECTED
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "UserSexualPreferences",
  data() {
    return {
      isLoading: true,
      userSexualPreferences: []
    };
  },
  computed: {
    ...mapGetters(["MemberData", "selectedLanguage"]),
    editable: function() {
      return this.$store.getters.editable;
    }
  },
  mounted() {
    this.setupSexPrefs();
  },
  watch: {
    MemberData: function(newVar, oldVar){
      console.log('MemberData changed!',newVar, oldVar)
      if(newVar.profile_id)  this.setupSexPrefs();
    }
  },
  methods: {
    setupSexPrefs() {
        this.$store.dispatch("fetchUserSexualPreferences", this.MemberData.profile_id).then(() => {
          console.log('fetchUserSexualPreferences');
          this.userSexualPreferences = this.$store.getters.getUserSexualPreferences.filter(e => {
            if(e.status) return e;
          });

          //Set all to active if none is active
          if(this.userSexualPreferences.length == 0){
            this.userSexualPreferences = this.$store.getters.getUserSexualPreferences.map(e => {
              e.status = 1
              return e;
            });
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 100);

      });
    }
  }
};
</script>
