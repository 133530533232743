<template>
  <div id="membershipMisc" :class="{admin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'), editingMode: $store.getters.editable}" >

    <div
      class="profilePhotoWrapper"
    >
      <img 
        v-if="MemberData.profileCard.profileBackground" 
        class="profileBackground" 
        :src="profileBackgroundURL" 
      />
      <div class="container-lg photoContainer">
        <div class="profileWrapper">
          <div class="photoWrapper">
            <div
              v-if="profilePicture"
              class="profilePhoto"
              v-bind:style="{
                backgroundImage: 'url(' + profilePicture + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }"
            >
              <router-link to="/premium-plans" v-if="!$store.getters.userRole.includes('ROLE_PREMIUM') && !MemberData.profileCard.picture_unblurred">
                <div class="viewButton">
                  {{$t($i18n.locale + '.keys.memberProfile.memberGallery.viewButton')}}
                </div>
              </router-link>
            </div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 1)"
              class="profilePhoto"
              :style="{
                backgroundImage:
                  'url(' + require('../../../assets/images/silhouette-man.svg')
              }"
            ></div>
            <div
              v-if="!profilePicture && (parseInt(profileGender) == 2)"
              class="profilePhoto"
              :style="{
                backgroundImage:
                  'url(' + require('../../../assets/images/silhouette-woman.svg')
              }"
            ></div>
            <div
            v-if="!profilePicture && requestPhoto"
            class="photoRequestButton"
            @click.stop.prevent = "sendPhotoRequest"
            >
            {{
              $t($i18n.locale + '.keys.membershipArea.membershipMisc.photoRequestButton')
            }}
            </div>
            <i v-if="parseInt(MemberData.profileCard.entertainment) == 1 && showHearts" class="fa fa-heart"></i>
          </div>
          <div class="userInfoWrapper">
            <div class="userInfo">
                <h1 v-if="this.MemberData.profileCard.name">
                  {{ this.MemberData.profileCard.name }}
                </h1>
                <p>
                  {{ getUserAge(this.MemberData.profileCard.age) }}, {{ this.MemberData.profileCard.location }}
                </p>
            </div>
          </div>
        </div>
        <Countdown
          v-if="MemberData.profileCard.expires && !$store.getters.userRole.includes('ROLE_PREMIUM')"
          :end-date="new Date(MemberData.profileCard.expires*1000)" 
          @timer-ended="countdownEnded()"
          :timertype="'timer_box'"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Countdown from "@/components/generic/Countdown.vue";
import getUserAge from "../../../helpers/userAge";
export default {
  name: "MemberMisc",
  components: {
    Countdown
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      componentKey: 0,
      profileAge: 0,
      profileJob: "",
      profileLocation: "",
    };
  },
  computed: {
    profilePicture() {
      return this.MemberData.profileCard.profilePicture;
    },
    profileGender() {
      return this.MemberData.profileCard.gender;
    },
    requestPhoto() {
      return this.MemberData.photo_request;
    },
    mainBackground() {
      return `url(${require(`@/assets/images/profileBackgrounds/BG${this.MemberData.profileCard.profileBackground}.webp`)})`;
    },
    profileBackgroundURL() {
      return require(`@/assets/images/profileBackgrounds/BG${this.MemberData.profileCard.profileBackground}.jpg`);
    },
    MemberData() {
      return this.$store.getters.MemberData;
    },
    //...mapGetters(["MemberData"]),
    editable: function() {
      return this.$store.getters.editable;
    },
    showHearts: function (){
      //Auftrag von "Markus Haas" per Whatsapp am 29.3.2024
        if(
            domain != 'at'
        ){
            return true;
        }
        else return false;
    }
  },
  watch:{
    MemberData: {
      immediate:true,
      deep:false,
      handler(newValue, oldValue){
        console.log(newValue,oldValue);
        if(!newValue) return [];
      }
    }
  },
  mounted() {
    // this.profilePicture = this.MemberData.profileCard.profilePicture;
    // const backgroundID = ;

    // this.mainBackground = this.background2;
    this.profileAge = 0;
    this.profileLocation = "nowhere";
  },
  created() {
    console.log("Lifecycle CREATED");
  },
  update() {
   console.log("Lifecycle UPDATED");
  },
  destroyed() {
    console.log("Lifecycle DESTROYED");
    //this.MemberData = {};
  },
  methods: {
    sendToPremium(){
      this.$router.push(`/premium-plans`);
    },
    countdownEnded(){
      //DO SOMETHING
      console.log('countdownEnded')
      //Go to Dashboard
      this.$router.push('/membership-area/home')

    },
    // setBackground(background) {
    //   localStorage.setItem("mainBackground", `${background}`);
    // },
    getUserAge(birthday) {
      console.log('getUserAge', birthday);
      if(birthday) return getUserAge(birthday);
      return false;
    },
    sendPhotoRequest() {
      console.log('sendPhotoRequest method');
      if(this.$store.getters.userRole.includes('ROLE_PREMIUM')){
        this.$store.dispatch("sendPhotoRequest", this.MemberData.profile_id).then(res => {
          if (res == "error") {
            //this.$store.dispatch("setErrorVisibility", true);
            //this.$store.dispatch("hideContent");
          } else {
            this.$store.dispatch("fetchMemberData", {memberID: this.MemberData.profile_id, reset: true});
          }
        });
      }else{
        localStorage.setItem("premium", 'false');
        this.$router.push('/premium-plans');
      }

    }
  }
};
</script>

<style lang="scss" scoped></style>
