
export default {
    setErrorVisibility({commit}, value){
        commit('setErrorVisibility', value);
        console.log('setErrorVisibility', value);
        //console.log('setErrorVisibility commit', commit);
    },
    showLoader({commit}){
        commit('showLoader');
    },
    hideLoader({commit}){
        commit('hideLoader');
    },
    showContent({commit}){
        commit('showContent');
    },
    hideContent({commit}){
        commit('hideContent');
    }
}