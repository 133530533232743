<template>
  <div id="MemberProfileWrapper">
    <MemberProfileList v-if="isMobile" />
    <MemberProfile v-else />
  </div>
</template>

<script>
// import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";
import MemberProfile from "./MemberProfile";
import MemberProfileList from "./MemberProfileList";
import { mapGetters } from "vuex";


export default {
  name: "MemberProfileWrapper",
  components: {
    MemberProfile,
    MemberProfileList
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
