import axios from "axios";
const BASE_URL = process.env.VUE_APP_URL;
// NOTE problem with setting {handlerEnabled: true}, therefore {timeout: false} is used temporary
// const handlerEnabled= {handlerEnabled: true};
const handlerEnabled= {timeout: false};

export default {
  
  async fetchOnlineUsers({
    commit
  }) {

    try {
      const response = await axios.get(
        BASE_URL + '/api/online/',handlerEnabled
      );
      console.log('fetchOnlineUsers', response);
      const data = response.data.data;
      // console.log(data);
      data.map(function (item) {
        const today = new Date();
        const birthDate = new Date(item.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        item.age = age;
      });
      
      commit('setOnlineUsers', data);
    } catch (error) {
      return 'error';
    }
  },
 
};