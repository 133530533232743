<template>
  <div id="AlertsWrapper">
    <router-link to="/membership-area/messages" class="alert-link">
      <div id="newMessageAlert" class="basicAlert" v-if="type == 'message'">
        <i class="fa fa-envelope"></i> {{ $t($i18n.locale + '.keys.generic.alerts.newMessageAlert') }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Alerts",
  props: ["type"],
  data() {
    return {
    };
  },

  mounted() {
  }
};
</script>
