
//check for js logging
function getUrlParameter(url, parameter){
  parameter = parameter.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?|&]' + parameter.toLowerCase() + '=([^&#]*)');
  var results = regex.exec('?' + url.toLowerCase().split('?')[1]);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g,' '));
}

if( getUrlParameter(window.location.href, 'log') != '1' && process.env.NODE_ENV != 'development') {
window.console.log = function (){return false;}
}

//Load LiveAgent Integration
window.contactButton = null;
window.LA_event = null;
(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document, 'https://ais.ladesk.com/scripts/track.js',
  function(e){
    window.LA_event = e;
});

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import $axios from "./axios-instance";
import axios from "axios";
import VueGtm from 'vue-gtm';
import "./util/objectReplace.js";
// eslint-disable-next-line
import directives from "./directives"; //directive for missing keys fallback
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

Vue.use(BootstrapVue);

import "@/assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";

const themeAffaerentreff = () => import("@/assets/sass/index_WLaffaerentreff.scss")
const themeCasualxdates = () => import("@/assets/sass/index_WLcasualxdates.scss")
const themeIllicitoasis = () => import("@/assets/sass/index_WLillicitoasis.scss")
const themeFremdgehboerse = () => import("@/assets/sass/index_WLfremdgehboerse.scss")
const themeAffaere1 = () => import("@/assets/sass/index_WLaffaere1.scss")
const themeSeitensprungtreff69 = () => import("@/assets/sass/index_WLseitensprungtreff69.scss")
const themeFicktreffpunkt24 = () => import("@/assets/sass/index_WLficktreffpunkt24.scss")
const themeDefault = () => import("@/assets/sass/index.scss")


if(host == 'affaerentreff'){
  themeAffaerentreff()

}else if(host == 'casualxdates'){
  themeCasualxdates()

}else if(host == 'illicitoasis'){
  themeIllicitoasis()

}else if(host == 'xn--fremdgehbrse-djb'){
  themeFremdgehboerse()
}else if(host == 'xn--affre1-dua'){
  themeAffaere1()
}else if(host == 'seitensprungtreff69'){
  themeSeitensprungtreff69()
}else if(host == 'ficktreffpunkt24'){
  themeFicktreffpunkt24()
} else {
  themeDefault()
}

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import LoadScript from "vue-plugin-load-script";

import VueChatScroll from 'vue-chat-scroll';

import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

Vue.prototype.$http = $axios;
Vue.prototype.$axios = axios;

Vue.use(VueChatScroll);

import * as VueGoogleMaps from "vue2-google-maps";

//import VueGeolocation from "vue-browser-geolocation";
//Vue.use(VueGeolocation);

import Geocoder from "@pderas/vue2-geocoder";
import i18n from "./i18n";

import Spinner from "@/components/generic/Spinner";
Vue.component("Spinner", Spinner);


import SlideUpDown from "vue-slide-up-down";
Vue.component("slide-up-down", SlideUpDown);

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

Vue.component('VueSlider', VueSlider);

import Icons from "@/components/generic/Icons";
Vue.component('Icons', Icons);


//import VueRx from 'vue-rx';
//Vue.use(VueRx)

import VueAwesomeSwiper from 'vue-awesome-swiper';

// require styles
import 'swiper/css';

Vue.use(VueAwesomeSwiper, /* { default global options } */ )

Vue.use(Geocoder, {
  defaultCountryCode: null,
  defaultLanguage: null,
  defaultMode: "lat-lng",
  googleMapsApiKey: "AIzaSyDB7jmcrrmOm9yHaxNxqzR_C7NipFxA4h8"
});

Vue.use(LoadScript);

// Vue.loadScript(
//     "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=E3B87C16EBBCD73B799DBF2DCA7C79C3.uat01-vm-tx04");

//set language for google maps API
var localeToSet = "en";
if (domain == "de" || domain == "ch" || domain == "at") {
  localeToSet = "de";
}else if (domain == "cz") {
  localeToSet = "cz";
}else if (domain == "pl") {
  localeToSet = "pl";
} else {
  localeToSet = "en";
}

let mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDB7jmcrrmOm9yHaxNxqzR_C7NipFxA4h8&libraries=places&callback=vueGoogleMapsInit&language="+localeToSet;

Vue.loadScript(
  mapsUrl
  )
  .then(() => {
     console.log('Maps loaded')
    // Script is loaded, do something
  })
  .catch((error) => {
    console.log('Maps NOT loaded', error)
    // Failed to fetch script
  });

Vue.use(
  VueGoogleMaps,
  window.google
);

import VueSuggestion from 'vue-suggestion'


const eventHub = new Vue() // Single event hub

// Distribute to components using global mixin
Vue.mixin({
  data: function () {
    return {
      eventHub: eventHub
    }
  }
})

Vue.config.productionTip = false;

Vue.mixin({
  created: function () {
    var myOption = this.$options.myOption
    if (myOption) {
      // console.log(myOption)
    }
  }
});

//Set datalayer global
window.dataLayer = window.dataLayer || [];

Vue.use(VueGtm, {
  id: 'GTM-N53M925',
  queryParams: {  },
  enabled: false,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: []
});

/*
window.$isMobile = true;
*/


window.isMobile = false;

document.addEventListener('resize',function(){
  if (window.innerWidth < 992) {
    window.isMobile = true;
  }else{
    window.isMobile = false;
  }
})


//Global App creation
  new Vue({
    VueSuggestion,
    router,
    store,
    i18n,
    data:{
      isMobile: window.isMobile
    },
    render: h => h(App)
  }).$mount("#app");

  //check for DEV server
  store.commit('set_isDEV', (process.env.NODE_ENV == 'development'));


// store.dispatch('setErrorVisibility', true);


//  instead of timeout handlerEnabled should be used
const isHandlerEnabled = (request) => {
  return request === false;
}
// (config={}) => {
//     return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled ?
//       false : true
//   }

const requestHandler = (request) => {
  if (isHandlerEnabled(request.timeout)) {
    // console.dir(request)
    console.log(`Sending request to: ${request.url}`)
  }
  return request
}

const errorHandler = (error) => {

  console.log(`Request failed: ${error.config.url} with ${error}`);
  if (error.response) {
    //minor error
    console.log('ErrortHandler Request minor error', error.response);
  } else if (error.request) {
    //request failed or aborted
    console.log('ErrortHandler Request aborted or failed', error.request);
  } else {
    //Request failed completely
    console.log('ErrortHandler Request request failed completely', error);
    //store.dispatch('hideLoader');
    //store.dispatch('setErrorVisibility', true);
  }
  /*
   console.log('ErrortHandler', error.request);
  if (isHandlerEnabled(error.config.timeout)) {
    console.log("errorHandler", error);
    console.log(`Request failed: ${error.config.url} with ${error}`);
    store.dispatch('hideLoader');
    store.dispatch('setErrorVisibility', true);
  }
  */
  return Promise.reject({
    ...error
  });
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config.timeout)) {
    console.log(`Request done successfully: ${response.config.url}`)
    // store.dispatch('hideLoader');
  }
  return response;
}

// Add interceptors
axios.interceptors.request.use(
  request => requestHandler(request)
)

axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

//network error ... why?
/*
axios.interceptors.response.use((response) => {
  if (response.data.error) {
    return Promise.reject(response);
  }
  return response;
}, (error) => (
  store.dispatch('setErrorVisibility', true),
  console.log(error) // result -> Error: Network Error(…)
));
*/




// Vue.directive('langErr', function (value) {
//   if(value.innerText.length == 0){
//     value.innerText = 'i18n key missing'
//   }});
//   // placeholder directive

// Vue.directive('phLangErr', function (value) {

//   if(value.placeholder.length == 0) {
//     value.placeholder = 'i18n key missing'
//   }
// });