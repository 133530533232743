<template>
<div>
  <div class="chatMessage typePicRequest " :class="{notPremium: !$store.getters.userRole.includes('ROLE_PREMIUM')}">
    <div class="msgBox">
      <p
        :class="{ blurred: messageData.blurred }"
        class="messageText">
        <i class="fa fa-camera"></i> {{ messageData.message }}
        <span class="premiumLinkWrapper" v-if="messageData.blurred">
          <router-link to="/premium-plans" class="premiumLink" >
            {{$t($i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg')}}
          </router-link>
        </span>
      </p>
    </div>
    <span class="chatBubble"></span>
    <span
      ><p class="timeStamp">
        {{ formatDate(messageData.created_at) }}
      </p>
    </span>
  </div>
    <div v-if="!hasProfilePic && showPicRequest" id="pictureRequestBlock">
        <p>
        {{ this.recipientInfo.firstName }}
        <span v-if="recipientInfo.gender == 1" v-html="$t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.text_m')"></span>
        <span v-if="recipientInfo.gender == 2" v-html="$t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.text_f')"></span>
        </p>
        <router-link to="/membership-area/user-profile/gallery">
          <button>
            {{ $t($i18n.locale + '.keys.membershipChat.pictureRequestBlock.button') }}
          </button>
        </router-link>
      </div>
  </div>


</template>

<script>
import formattedDate from "../../../../helpers/dateFormatter";

export default {
  name: "TypeIcebreaker",
  props: ["messageData", "showPicRequest", "hasProfilePic", "recipientInfo"],
  data() {
    return {
    };
  },
  methods: {
    formatDate(date) {
      return formattedDate(date, this.$store.getters.selectedLanguage, true);
    },
  },
};
</script>

<style lang="scss" scoped>



</style>
