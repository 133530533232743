<template>
  <div id="membershipOnline">
    <header class="onlineHeader">
      <p
        class="onlineTitle"
        v-langErr :contenteditable="editable"
        @blur="
          $t($i18n.locale).keys.membershipArea.membershipOnline.onlineNow =
            $event.target.innerText
        "
      >
        {{ $t($i18n.locale).keys.membershipArea.membershipOnline.onlineNow }}
      </p>
    </header>
    <div class="onlineImagesWrapper">
      <swiper

        :options="swiperOption"

        id="swiperContainer"
        ref="onlineSwiper"
        @click-slide = "handleClickSlide"

      >
        <swiper-slide
          v-for="member in $store.getters.getHomeOnline"
          :key="member.id"
          @click-slide = "goToProfile(member.profile_id)"
        >
          <div class="imageWrapper">
            <img
              :src="`${member.public_path}`"
              class="onlineImage"
              v-if="member.public_path"
            />
            <img
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-man.svg')"
              class="onlineImage"
              alt=""
              v-if="!member.public_path && (parseInt(member.gender) == 1)"
            />
            <img
              :src="require('@/assets/images/' + $store.getters.assetsPath + 'silhouette-woman.svg')"
              class="onlineImage"
              alt=""
              v-if="!member.public_path && (parseInt(member.gender) == 2)"
            />
            <div class="imageOverlay">
              <span class="circle"></span>
              <div class="wrapper">
                <p class="matchName">
                  {{ member.firstname }}
                </p>
                <div class="insideWrapper">
                  <!--
                  <img v-if="parseInt(member.entertainment) == 1" class="heartIcon" :src="require('@/assets/images/' + $store.getters.assetsPath + 'heart-green.svg')" alt="Heart icon">
                  -->
                  <div class="matchAgeLocation">
                    {{ member.age }}, {{ member.city }}
                  </div>
                </div>
              </div>
            </div>
            <div class="imageOverlay" v-bind:class="[member.new ? 'new' : '']">
              <p
                class="newMessage"
                v-if="member.new"
                v-langErr
                :contenteditable="editable"
                @blur="
                  $t($i18n.locale).keys.membershipMatches.matchesList.new =
                    $event.target.innerText
                "
              >{{ $t($i18n.locale).keys.membershipMatches.matchesList.new }}</p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// const BASE_URL = process.env.VUE_APP_URL;
import { mapGetters } from "vuex";
export default {
  name: "MembershipOnline",
  components: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          // when window width is >= 320px
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 30
          }
        }
      },
      componentKey: 0,
      publicPath: process.env.VUE_APP_URL,
      lockRequests: false,
    };
  },
  computed: {
    ...mapGetters(["getHomeOnline","editable"]),
  },
  created() {
    this.slidesPerPage();
   if(this.getHomeOnline.length < this.swiperOption.slidesPerView){
      this.swiperOption.loop = false;
   }
  },

  beforeDestroy() {
    this.$refs.onlineSwiper.$swiper.destroy();
    this.$store.commit('setHomeOnline', []);
    //if(typeof _resizeHandler != 'undefined') window.removeEventListener('resize', _resizeHandler());
  },
  methods: {
    handleClickSlide(index, reallyIndex) {
        var member = this.$store.getters.getHomeOnline[reallyIndex];
        this.goToProfile(member.profile_id);
        return;
      },
     goToProfile(profile_id) {
       if(this.lockRequests) return true;
      if (this.$store.getters.userRole.includes("ROLE_PREMIUM")) {
      return new Promise((resolve, reject) => {
        this.lockRequests = true;
        axios({ url: process.env.VUE_APP_URL + `/api/dashboard/view/${profile_id}`, method: "POST" })
          .then(resp => {
            this.lockRequests = false;
         this.$router.push(`member-profile/profile?id=${profile_id}`);
            resolve(resp);
           })
          .catch(err => {
            this.lockRequests = false;
            reject(err);
          });
      });

      } else {
        localStorage.setItem("premium", 'false');
        this.$router.push(`/premium-plans`);
      }
    },
    slidesPerPage() {

      if(window.innerWidth > 0 && window.innerWidth < 320) this.swiperOption.slidesPerView = 1;
      else if (window.innerWidth >= 320 && window.innerWidth < 480) this.swiperOption.slidesPerView = 2;
      else if (window.innerWidth >= 480 && window.innerWidth < 640) this.swiperOption.slidesPerView = 3;
      else if (window.innerWidth >= 640 && window.innerWidth < 1200) this.swiperOption.slidesPerView = 4;
      else if (window.innerWidth >= 1200) this.swiperOption.slidesPerView = 6;

    }
  }
};
</script>

<style lang="scss" scoped></style>
