<template>

    <div class="icons">
        <h3>Icons</h3>
        <h4>Default</h4>
        <div class="iconGrid">
            <div>
                <Icons :icon="'message'" />
                <p>{{'message'}}</p>
            </div>
            <div>
                <Icons :icon="'profile'" />
                <p>{{'profile'}}</p>
            </div>
            <div>
                <Icons :icon="'cog'" />
                <p>{{'cog'}}</p>
            </div>
            <div>
                <Icons :icon="'preferences'" />
                <p>{{'preferences'}}</p>
            </div>
            <div>
                <Icons :icon="'envelope'" />
                <p>{{'envelope'}}</p>
            </div>
            <div>
                <Icons :icon="'puzzle'" />
                <p>{{'puzzle'}}</p>
            </div>
            <div>
                <Icons :icon="'home'" />
                <p>{{'home'}}</p>
            </div>
            <div>
                <Icons :icon="'logout'" />
                <p>{{'logout'}}</p>
            </div>
            <div>
                <Icons :icon="'no_visitors'" />
                <p>{{'no_visitors'}}</p>
            </div>
            <div>
                <Icons :icon="'close'" />
                <p>{{'close'}}</p>
            </div>
            <div>
                <Icons :icon="'camera'" />
                <p>{{'camera'}}</p>
            </div>
            <div>
                <Icons :icon="'smile'" />
                <p>{{'smile'}}</p>
            </div>
            <div>
                <Icons :icon="'icebreaker'" />
                <p>{{'icebreaker'}}</p>
            </div>
            <div>
                <Icons :icon="'pencil'" />
                <p>{{'pencil'}}</p>
            </div>
        </div>
        <h4>Solid</h4>
        <div class="iconGrid">
            <div>
                <Icons :icon="'message'" :type="'solid'" />
                <p>{{'message'}}</p>
            </div>
            <div>
                <Icons :icon="'profile'" :type="'solid'" />
                <p>{{'profile'}}</p>
            </div>
            <div>
                <Icons :icon="'home'" :type="'solid'" />
                <p>{{'home'}}</p>
            </div>
            <div>
                <Icons :icon="'cog'" :type="'solid'" />
                <p>{{'cog'}}</p>
            </div>
            <div>
                <Icons :icon="'envelope'" :type="'solid'" />
                <p>{{'envelope'}}</p>
            </div>
            <div>
                <Icons :icon="'puzzle'" :type="'solid'" />
                <p>{{'puzzle'}}</p>
            </div>
            <div>
                <Icons :icon="'eye'" :type="'solid'" />
                <p>{{'eye'}}</p>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "Icon_styles",
}

</script>

<style lang="scss" scoped>

    .icons {
        .iconGrid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            justify-items: center;
            align-items: center;
            gap: 10px;
            padding: 10px 15px;
            
            p {
                font-size: 0.7em;
                text-align: center;
                margin-top: 5px;
            }
        }
    }

</style>