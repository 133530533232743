var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"membershipChat"}},[[_c('div',{staticClass:"container-lg flexContainer",class:{ showMobileChat: _vm.chatMobileClicked }},[_c('div',{staticClass:"tabs-wrapper",attrs:{"id":"conversationWrapper"}},[_c('div',{staticClass:"searchTab-2",attrs:{"id":"conversationFilter"}},[_c('div',{staticClass:"searchBarWrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchName),expression:"searchName"},{name:"phLangErr",rawName:"v-phLangErr"}],staticClass:"searchBar",attrs:{"type":"text","placeholder":_vm.$t(_vm.$i18n.locale).keys.generic.search},domProps:{"value":(_vm.searchName)},on:{"input":function($event){if($event.target.composing)return;_vm.searchName=$event.target.value}}}),_c('div',{staticClass:"notifications-wraper",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.conversations.length)+" ")]),_c('div',{staticClass:"messagesIcon"},[_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'envelope.svg')}})])])])]),_c('div',{key:_vm.conversationsListKey,ref:"conversationListing",staticClass:"searchTab",attrs:{"id":"conversationList"},on:{"scroll":_vm.conversationsScrollHandler}},[_c('div',{staticClass:"msgHistoryWrapper",on:{"click":function($event){_vm.chatMobileClicked = true}}},_vm._l((_vm.filterConversations),function(conversation){return _c('div',{key:conversation.id,staticClass:"messageWrapper",class:{
              messageSelected: conversation.id == _vm.currentConversationId,
              welcomeMsg: conversation.id == 1,
              onlineUser: conversation.online
            },on:{"click":function($event){return _vm.getMessages(conversation.id)}}},[_c('div',{staticClass:"message"},[_c('div',{staticClass:"userPhoto"},[(conversation.public_path)?_c('img',{attrs:{"src":conversation.public_path}}):_vm._e(),(
                    !conversation.public_path &&
                      parseInt(conversation.gender) == 1
                  )?_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-man.svg')}}):_vm._e(),(
                    !conversation.public_path &&
                      parseInt(conversation.gender) == 2
                  )?_c('img',{attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'silhouette-woman.svg'),"alt":""}}):_vm._e(),_c('div',{staticClass:"imageOverlay",class:{
                    onlineUser: conversation.online,
                    'new' : conversation.new == 1
                  }},[(conversation.new == 1)?_c('p',{staticClass:"newMessage"},[_vm._v(_vm._s(_vm.$t(_vm.$i18n.locale).keys.membershipMatches.matchesList.new))]):_vm._e()])]),(conversation.id != 1)?_c('img',{staticClass:"trashIcon",attrs:{"src":require('@/assets/images/' + _vm.$store.getters.assetsPath + 'Trash-button.svg'),"alt":"Remove"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteConfirm(conversation.matched_user_id)}}}):_vm._e(),_c('div',{staticClass:"messageContent"},[_c('div',{staticClass:"wrapper"},[_c('p',{staticClass:"name"},[_c('span',{staticClass:"username"},[_vm._v(_vm._s(conversation.firstName))]),(conversation.id != 1)?_c('span',{staticClass:"age"},[_vm._v(","+_vm._s(_vm.getUserAge(conversation.birthday)))]):_vm._e()])]),(conversation.id != 1)?_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(conversation.created_at)))]):_vm._e(),(_vm.$store.getters.userRole.includes('ROLE_PREMIUM') || true)?_c('p',{staticClass:"userMessage",class:{
                    newMessage: conversation.new == 1,
                    blurred: conversation.blurred
                  }},[_vm._v(" "+_vm._s(conversation.message)+" ")]):_c('router-link',{staticClass:"premiumLink",attrs:{"to":"/premium-plans"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$i18n.locale + '.keys.membershipArea.membershipMessages.premiumOnlyMsg'))+" ")])],1)])])}),0)])]),_c('div',{class:{
          mobileChat:_vm.isMobile
        },attrs:{"id":"chatWindow"}},[(_vm.chatMobileClicked)?_c('MemberShipChatWindow',{attrs:{"conversation":_vm.currentConversation,"isLoading":_vm.chatIsLoading},on:{"return-chat":_vm.returnChat,"delete-conversation":_vm.deleteConfirm}}):_vm._e()],1)])],(_vm.trackingURL)?_c('iframe',{staticStyle:{"display":"none"},attrs:{"src":_vm.trackingURL}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }